import { Title } from "@angular/platform-browser";
import { DES } from "crypto-js";

export const LANGUAGE_DATA_PROVIDER = {
  SHIP_SHAPE: {
    TITLE: "SHIP_SHAPE.TITLE",
  },
  Marina: "Marina",
  NEW_VENDOR_MESSAGE: "NEW_VENDOR_MESSAGE",

  STEP_GUIDE: {
    WELCOMESECTION: {
      TITLE: "STEP_GUIDE.WELCOMESECTION.TITLE",
      SUBTITLE: "STEP_GUIDE.WELCOMESECTION.SUBTITLE",
    },
    BUTTONSSECTION: {
      STARTGUIDELABEL: "STEP_GUIDE.BUTTONSSECTION.STARTGUIDELABEL",
      SKIPLABEL: "STEP_GUIDE.BUTTONSSECTION.SKIPLABEL",
    },
  },
  Rejected: "Rejected",
  DISPLAY_CALENDER: "DISPLAY_CALENDER",

  OWNER_PROMPTS: {
    BOAT_VITALS: {
      TITLE: "OWNER_PROMPTS.BOAT_VITALS.TITLE",
      MESSAGE: "OWNER_PROMPTS.BOAT_VITALS.MESSAGE",
    },
    BOAT_PROFILE: {
      TITLE: "OWNER_PROMPTS.BOAT_PROFILE.TITLE",
      MESSAGE: "OWNER_PROMPTS.BOAT_PROFILE.MESSAGE",
    },
    GOT_IT: "OWNER_PROMPTS.GOT_IT",
  },
  SLIDER: {
    BOAT_OWNER: {
      REAL_TIME: "SLIDER.BOAT_OWNER.REAL_TIME",
      "AI-GENERATE": "SLIDER.BOAT_OWNER.AI-GENERATE",
      "INTRODUCING-CAPTAIN_AI": "SLIDER.BOAT_OWNER.INTRODUCING-CAPTAIN_AI",
      PERSONAL_AI: "SLIDER.BOAT_OWNER.PERSONAL_AI",
      "GEO-FENCING": "SLIDER.BOAT_OWNER.GEO-FENCING",
      GEOFENCE_MESSAGE: "SLIDER.BOAT_OWNER.GEOFENCE_MESSAGE",
      TRUSTED_VENDORS: "SLIDER.BOAT_OWNER.TRUSTED_VENDORS",
      TRUSTED_VENDORS_MESSAGE: "SLIDER.BOAT_OWNER.TRUSTED_VENDORS_MESSAGE",
    },
  },
  CHECKLIST_MANAGER: {
    SERVICE_CHECKLIST_MANAGER: {
      TITLE: "CHECKLIST_MANAGER.SERVICE_CHECKLIST_MANAGER.TITLE",
      DESCRIPTION: "CHECKLIST_MANAGER.SERVICE_CHECKLIST_MANAGER.DESCRIPTION",
    },
    VESSEL_CHECKLIST: {
      DO_NOT_SHOW_LABEL: "CHECKLIST_MANAGER.VESSEL_CHECKLIST.DO_NOT_SHOW_LABEL",
    },
    IMAGE_EDITOR: {
      TITLE: "CHECKLIST_MANAGER.IMAGE_EDITOR.TITLE",
      POPUP_MESSAGE: "CHECKLIST_MANAGER.IMAGE_EDITOR.POPUP_MESSAGE",
    },
    BOAT_CHECKLIST_MANAGER: {
      TITLE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.TITLE",
      LETS_GET_STARTED: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.LETS_GET_STARTED",
      NO_CHECKLIST_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.NO_CHECKLIST_MESSAGE",
      VIEW_CHECKLIST_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.VIEW_CHECKLIST_MESSAGE",
      SUB_DESCRIPTION: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.SUB_DESCRIPTION",
      YOUR_CURRENT_BOAT: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.YOUR_CURRENT_BOAT",
      EDIT_STARTUP_CHECKLIST: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.EDIT_STARTUP_CHECKLIST",
      NO_STARTUP_CHECKLIST_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.NO_STARTUP_CHECKLIST_MESSAGE",
      STARTUP_CHECKLIST_TITLE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.STARTUP_CHECKLIST_TITLE",
      SELECT_CHECKLIST: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.SELECT_CHECKLIST",
      SHUTDOWN_CHECKLIST_TITLE_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.SHUTDOWN_CHECKLIST_TITLE_MESSAGE",
      STARTUP_CHECKLIST_TITLE_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.STARTUP_CHECKLIST_TITLE_MESSAGE",
      EDIT_SHUTDOWN_CHECKLIST: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.EDIT_SHUTDOWN_CHECKLIST",
      NO_SHUTDOWN_CHECKLIST_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.NO_SHUTDOWN_CHECKLIST_MESSAGE",
      SHUTDOWN_CHECKLIST_TITLE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.SHUTDOWN_CHECKLIST_TITLE",
      POPOVER_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.POPOVER_MESSAGE",
      POPOVER_MESSAGE_1: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.POPOVER_MESSAGE_1",
      CUSTOM_CHECKLIST_EDITOR: {
        TITLE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.TITLE",
        TASKLIST_DESCRIPTION: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.TASKLIST_DESCRIPTION",
        TASKLIST_DESCRIPTION_2:
          "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.TASKLIST_DESCRIPTION_2",
        TASK_NAME_LABEL: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.TASK_NAME_LABEL",
        TASK_NAME_PLACEHOLDER: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.TASK_NAME_PLACEHOLDER",
        POPOVER_MESSAGE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.POPOVER_MESSAGE",
        EDIT_CHECKLIST: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.EDIT_CHECKLIST",
        ADD_PHOTO: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.ADD_PHOTO",
        EDIT_PHOTO: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.EDIT_PHOTO",
        DELETE_CHECKLIST: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.CUSTOM_CHECKLIST_EDITOR.DELETE_CHECKLIST",
      },
      STARTUP_AND_STARTUP_CHECKLIST_POPOVER:
        "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.STARTUP_AND_STARTUP_CHECKLIST_POPOVER",
      TASKS_COMPLETED: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.TASKS_COMPLETED",
      SIMPLY_CLICK: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.SIMPLY_CLICK",
      HELP_TEXT: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.HELP_TEXT",
      BUTTON_MARK_COMPLETE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.BUTTON_MARK_COMPLETE",
      DONE: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.DONE",
      START_UP_COMPLETED: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.START_UP_COMPLETED",
      SHUT_DOWN_COMPLETED: "CHECKLIST_MANAGER.BOAT_CHECKLIST_MANAGER.SHUT_DOWN_COMPLETED",
    },
  },
  ACCOUNT_DELETION: {
    DELETE_BOATBOT_ACCOUNT: "ACCOUNT_DELETION.DELETE_BOATBOT_ACCOUNT",
    CONFIRM_ACCOUNT_DELETION: "ACCOUNT_DELETION.CONFIRM_ACCOUNT_DELETION",
    DELETE_ACCOUNT_WARNING: "ACCOUNT_DELETION.DELETE_ACCOUNT_WARNING",
    ACTION_WARNING: "ACCOUNT_DELETION.ACTION_WARNING",
    ACCESS_LOSS_VENDOR_DATA: "ACCOUNT_DELETION.ACCESS_LOSS_VENDOR_DATA",
    ACCESS_LOSS_ORDER_HISTORY: "ACCOUNT_DELETION.ACCESS_LOSS_ORDER_HISTORY",
    ACCESS_LOSS_VESSEL_DATA: "ACCOUNT_DELETION.ACCESS_LOSS_VESSEL_DATA",
    CANCEL_BUTTON: "ACCOUNT_DELETION.CANCEL_BUTTON",
    DELETE_BUTTON: "ACCOUNT_DELETION.DELETE_BUTTON",
  },

  MESSAGES: {
    TITLE: "MESSAGES",
    RECENT: "MESSAGES.RECENT",
    UNREAD: "MESSAGES.UNREAD",
    TYPE_MESSAGE: "MESSAGES.TYPE_MESSAGE",
    TOOLTIP: "MESSAGES.TOOLTIP",
    MESSAGE_TOOLTIP: "MESSAGES.MESSAGE_TOOLTIP",
    MESSAGING_TOOLTIP: "MESSAGES.MESSAGING_TOOLTIP",
    SEARCH_TEXT: "MESSAGES.SEARCH_TEXT",
    ALL_FRIENDS: "MESSAGES.ALL_FRIENDS",
    NO_FRIENDS_YET: "MESSAGES.NO_FRIENDS_YET",
    NO_MESSAGES_YET: "MESSAGES.NO_MESSAGES_YET",
    WELCOME_MESSAGE: "MESSAGES.WELCOME_MESSAGE",
    NO_MESSAGE: "MESSAGES.NO_MESSAGE",
    SAY_HI_HEADER: "MESSAGES.SAY_HI_HEADER",
    CONVERSATION_START: "MESSAGES.CONVERSATION_START",
    BLOCK_USER: "MESSAGES.BLOCK_USER",
    UNBLOCK_USER: "MESSAGES.UNBLOCK_USER",
    BLOCK_SUCCESS: "MESSAGES.BLOCK_SUCCESS",
    UNBLOCK_SUCCESS: "MESSAGES.UNBLOCK_SUCCESS",
    UNBLOCK_ERROR: "MESSAGES.UNBLOCK_ERROR",
  },

  START_PAGE: {
    HOME_PAGE: {
      TITLE: "START_PAGE.HOME_PAGE.TITLE",
      SOCIAL_LOGIN_ERROR_MESSAGE: "START_PAGE.HOME_PAGE.SOCIAL_LOGIN_ERROR_MESSAGE",
      TITLE_TECHNICIAN: "START_PAGE.HOME_PAGE.TITLE_TECHNICIAN",
      GET_STARTED: "START_PAGE.HOME_PAGE.GET_STARTED",
      JOIN_SIGN_IN: "START_PAGE.HOME_PAGE.JOIN_SIGN_IN",
      SIGN_IN: "START_PAGE.HOME_PAGE.SIGN_IN",
      CONTINUE_GOOGLE: "START_PAGE.HOME_PAGE.CONTINUE_GOOGLE",
      CONTINUE_APPLE: "START_PAGE.HOME_PAGE.CONTINUE_APPLE",
      CONTINUE_FACEBOOK: "START_PAGE.HOME_PAGE.CONTINUE_FACEBOOK",
      FACEBOOK_DESCRIPTION: "START_PAGE.HOME_PAGE.FACEBOOK_DESCRIPTION",
      CONTINUE_EMAIL: "START_PAGE.HOME_PAGE.CONTINUE_EMAIL",
      TECH_SLIDER_TEXTS: {
        SLIDER_1: {
          TITLE: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_1.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_1.TEXT",
        },
        SLIDER_2: {
          TITLE: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_2.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_2.TEXT",
        },
        SLIDER_3: {
          TITLE: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_3.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_3.TEXT",
        },
        SLIDER_4: {
          TITLE: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_4.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.TECH_SLIDER_TEXTS.SLIDER_4.TEXT",
        },
      },
      BO_SLIDER_TEXTS: {
        SLIDER_1: {
          TITLE: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_1.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_1.TEXT",
        },
        SLIDER_2: {
          TITLE: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_2.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_2.TEXT",
        },
        SLIDER_3: {
          TITLE: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_3.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_3.TEXT",
        },
        SLIDER_4: {
          TITLE: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_4.TITLE",
          TEXT: "START_PAGE.HOME_PAGE.BO_SLIDER_TEXTS.SLIDER_4.TEXT",
        },
      },
    },

    SIGN_IN_PAGE: {
      TITLE: "START_PAGE.SIGN_IN_PAGE.TITLE",
      EMAIL: "START_PAGE.SIGN_IN_PAGE.EMAIL",
      PASSWORD: "START_PAGE.SIGN_IN_PAGE.PASSWORD",
      FORGOT_PASSWORD: "START_PAGE.SIGN_IN_PAGE.FORGOT_PASSWORD",
      REMEMBER_ME: "START_PAGE.SIGN_IN_PAGE.REMEMBER_ME",
      LOGIN_CHOICES: "START_PAGE.SIGN_IN_PAGE.LOGIN_CHOICES",
      SIGN_UP: "START_PAGE.SIGN_IN_PAGE.SIGN_UP",
      VESSEL_MANAGEMENT_EVOLVED: "START_PAGE.SIGN_IN_PAGE.VESSEL_MANAGEMENT_EVOLVED",
      SIGN_IN_NOTICE: "START_PAGE.SIGN_IN_PAGE.SIGN_IN_NOTICE",
      WELCOME: "START_PAGE.SIGN_IN_PAGE.WELCOME",
      ABOARD: "START_PAGE.SIGN_IN_PAGE.ABOARD",
      OR_SIGN_IN_WITH: "START_PAGE.SIGN_IN_PAGE.OR_SIGN_IN_WITH",
      DONT_HAVE_ACCOUNT: "START_PAGE.SIGN_IN_PAGE.DONT_HAVE_ACCOUNT",
    },

    SIGN_UP_PAGE: {
      TITLE: "START_PAGE.SIGN_UP_PAGE.TITLE",
      EMAIL_ERROR: "START_PAGE.SIGN_UP_PAGE.EMAIL_ERROR",
      EMAIL_ERROR_2: "START_PAGE.SIGN_UP_PAGE.EMAIL_ERROR_2",
      FIRST_NAME: "START_PAGE.SIGN_UP_PAGE.FIRST_NAME",
      LAST_NAME: "START_PAGE.SIGN_UP_PAGE.LAST_NAME",
      CELL_PHONE: "START_PAGE.SIGN_UP_PAGE.CELL_PHONE",
      CELL_PHONE_ERROR: "START_PAGE.SIGN_UP_PAGE.CELL_PHONE_ERROR",
      CELL_PHONE_ERROR_1: "START_PAGE.SIGN_UP_PAGE.CELL_PHONE_ERROR_1",
      CELL_PHONE_ERROR_2: "START_PAGE.SIGN_UP_PAGE.CELL_PHONE_ERROR_2",
      EMAIL: "START_PAGE.SIGN_UP_PAGE.EMAIL",
      EMAIL_ERROR_3: "START_PAGE.SIGN_UP_PAGE.EMAIL_ERROR_3",
      EMAIL_REQUIRED: "START_PAGE.SIGN_UP_PAGE.EMAIL_REQUIRED",
      EMAIL_VALID: "START_PAGE.SIGN_UP_PAGE.EMAIL_VALID",
      PASSWORD: "START_PAGE.SIGN_UP_PAGE.PASSWORD",
      PASSWORD_ERROR: "START_PAGE.SIGN_UP_PAGE.PASSWORD_ERROR",
      PASSWORD_ERROR_2: "START_PAGE.SIGN_UP_PAGE.PASSWORD_ERROR_2",
      CONFIRM_PASSWORD: "START_PAGE.SIGN_UP_PAGE.CONFIRM_PASSWORD",
      CONFIRM_PASSWORD_ERROR: "START_PAGE.SIGN_UP_PAGE.CONFIRM_PASSWORD_ERROR",
      CONFIRM_PASSWORD_ERROR_2: "START_PAGE.SIGN_UP_PAGE.CONFIRM_PASSWORD_ERROR_2",
      SIGN_UP_NOTICE: "START_PAGE.SIGN_UP_PAGE.SIGN_UP_NOTICE",
      AND: "START_PAGE.SIGN_UP_PAGE.AND",
      PRIVACY_POLICY: "START_PAGE.SIGN_UP_PAGE.PRIVACY_POLICY",
      TERMS_SERVICE: "START_PAGE.SIGN_UP_PAGE.TERMS_SERVICE",
      CONTINUE: "START_PAGE.SIGN_UP_PAGE.CONTINUE",
      ALREADY_HAVE_ACCOUNT: "START_PAGE.SIGN_UP_PAGE.ALREADY_HAVE_ACCOUNT",
      OR_SIGN_IN_WITH: "START_PAGE.SIGN_UP_PAGE.OR_SIGN_IN_WITH",
      TITLE_2: "START_PAGE.SIGN_UP_PAGE.TITLE_2",
      PASSWORD_MATCH: "START_PAGE.SIGN_UP_PAGE.PASSWORD_MATCH",
      CELL_PHONE_VALID: "START_PAGE.SIGN_UP_PAGE.CELL_PHONE_VALID",
    },
  },
  SERVICE_SELECT: {
    TITLE: "SERVICE_SELECT.TITLE",
    POPOVER_MESSAGE: "SERVICE_SELECT.POPOVER_MESSAGE",
    LABEL: "SERVICE_SELECT.LABEL",
    PLACEHOLDER: "SERVICE_SELECT.PLACEHOLDER",
    ERROR_MESSAGE: "SERVICE_SELECT.ERROR_MESSAGE",
  },

  ROLE: {
    OWNER: "ROLE.OWNER",
    TECHNICIAN: "ROLE.TECHNICIAN",
    PROFILE: "ROLE.PROFILE",
  },
  REQUIRED_FIELD: {
    BOAT_NAME: "REQUIRED_FIELD.BOAT_NAME",
    DOCUMENT_NUMBER: "REQUIRED_FIELD.DOCUMENT_NUMBER",
    SLIP_NUMBER: "REQUIRED_FIELD.SLIP_NUMBER",
    MANUFACTURER: "REQUIRED_FIELD.MANUFACTURER",
    MAKE_MODEL: "REQUIRED_FIELD.MAKE_MODEL",
    FUEL_TYPE: "REQUIRED_FIELD.FUEL_TYPE",
    YEAR: "REQUIRED_FIELD.YEAR",
    LENGTH: "REQUIRED_FIELD.LENGTH",
    ADDRESS: "REQUIRED_FIELD.ADDRESS",
  },
  DIALOG_BOX: {
    MODAL_TITLE: "DIALOG_BOX.MODAL_TITLE",
    MODAL_CONTENT: "DIALOG_BOX.MODAL_CONTENT",
    BUTTON_KEEP_EDITING: "DIALOG_BOX.BUTTON_KEEP_EDITING",
    BUTTON_EXIT_WITHOUT_SAVING: "DIALOG_BOX.BUTTON_EXIT_WITHOUT_SAVING",
  },
  RESET_PASSWORD: {
    TITLE: "RESET_PASSWORD.TITLE",
    REQUEST_PASSWORD: "RESET_PASSWORD.REQUEST_PASSWORD",
    EMAIL: "RESET_PASSWORD.EMAIL",
    REQUEST: "RESET_PASSWORD.REQUEST",
    REMEMBER_PASSWORD: "RESET_PASSWORD.REMEMBER_PASSWORD",
    FILL_FORM_1: "RESET_PASSWORD.FILL_FORM_1",
    FILL_FORM: "RESET_PASSWORD.FILL_FORM",
    CODE: "RESET_PASSWORD.CODE",
    CHECK_EMAIL_FOR_CODE: "RESET_PASSWORD.CHECK_EMAIL_FOR_CODE",
    RESET: "RESET_PASSWORD.RESET",
    ERROR_MESSAGE: "RESET_PASSWORD.ERROR_MESSAGE",
  },

  SUBSCRIPTION_MODAL: {
    FREE_TRIAL: {
      TITLE: "SUBSCRIPTION_MODAL.FREE_TRIAL.TITLE",
      TEXT: "SUBSCRIPTION_MODAL.FREE_TRIAL.TEXT",
      TEXT_2: "SUBSCRIPTION_MODAL.FREE_TRIAL.TEXT_2",
      TEXT_3: "SUBSCRIPTION_MODAL.FREE_TRIAL.TEXT_3",
      TEXT_4: "SUBSCRIPTION_MODAL.FREE_TRIAL.TEXT_4",
      BUTTON_LABEL: "SUBSCRIPTION_MODAL.FREE_TRIAL.BUTTON_LABEL",
    },
    ONE_DAY_LEFT: {
      TITLE: "SUBSCRIPTION_MODAL.ONE_DAY_LEFT.TITLE",
      TEXT: "SUBSCRIPTION_MODAL.ONE_DAY_LEFT.TEXT",
      BUTTON_LABEL: "SUBSCRIPTION_MODAL.ONE_DAY_LEFT.BUTTON_LABEL",
    },
    TWO_DAYS_LEFT: {
      TITLE: "SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TITLE",
      TITLE_2: "SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TITLE_2",
      TEXT: "SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TEXT",
      DAY: "SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.DAY",
      LEFT: "SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.LEFT",
      BUTTON_LABEL: "SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.BUTTON_LABEL",
    },
    UPGRADE_TO_PRO: {
      TITLE: "SUBSCRIPTION_MODAL.UPGRADE_TO_PRO.TITLE",
      TEXT: "<tu contenido dinámico aquí>",
      BUTTON_LABEL: "SUBSCRIPTION_MODAL.UPGRADE_TO_PRO.BUTTON_LABEL",
    },
    NEW_REGISTRATION: {
      TITLE: "SUBSCRIPTION_MODAL.NEW_REGISTRATION.TITLE",
      TEXT: "SUBSCRIPTION_MODAL.NEW_REGISTRATION.TEXT",
      BUTTON_LABEL: "SUBSCRIPTION_MODAL.NEW_REGISTRATION.BUTTON_LABEL",
    },
  },

  BO_ONBOARDING: {
    BOAT_INFO_ONE: {
      TITLE: "BO_ONBOARDING.BOAT_INFO_ONE.TITLE",
      DESCRIPTION: "BO_ONBOARDING.BOAT_INFO_ONE.DESCRIPTION",
      LABEL_ONE: "BO_ONBOARDING.BOAT_INFO_ONE.LABEL_ONE",
      POPOVER_MESSAGE: "BO_ONBOARDING.BOAT_INFO_ONE.POPOVER_MESSAGE",
      HULL_ID: "BO_ONBOARDING.BOAT_INFO_ONE.HULL_ID",
      MANUFACTURER: "BO_ONBOARDING.BOAT_INFO_ONE.MANUFACTURER",
      MAKE_MODEL: "BO_ONBOARDING.BOAT_INFO_ONE.MAKE_MODEL",
      FUEL_TYPE: "BO_ONBOARDING.BOAT_INFO_ONE.FUEL_TYPE",
      YEAR: "BO_ONBOARDING.BOAT_INFO_ONE.YEAR",
      BOAT_LENGTH: "BO_ONBOARDING.BOAT_INFO_ONE.BOAT_LENGTH",
      FEET: "BO_ONBOARDING.BOAT_INFO_ONE.FEET",
      METERS: "BO_ONBOARDING.BOAT_INFO_ONE.METERS",
      UPLOAD_IMAGES: "BO_ONBOARDING.BOAT_INFO_ONE.UPLOAD_IMAGES",
      UPDATE_MESSAGE: "BO_ONBOARDING.BOAT_INFO_ONE.UPDATE_MESSAGE",
      ADD_NEW_BOAT: "BO_ONBOARDING.BOAT_INFO_ONE.ADD_NEW_BOAT",
      PARKING_LOCATION: "BO_ONBOARDING.BOAT_INFO_ONE.PARKING_LOCATION",
    },
    BOAT_INFO_TWO: {
      ADD_IMAGE_SUBTITLE: "BO_ONBOARDING.BOAT_INFO_TWO.ADD_IMAGE_SUBTITLE",
      ADD_IMAGE_SUBTITLE_1: "BO_ONBOARDING.BOAT_INFO_TWO.ADD_IMAGE_SUBTITLE_1",
      ADD_IMAGE_SUBTITLE_2: "BO_ONBOARDING.BOAT_INFO_TWO.ADD_IMAGE_SUBTITLE_2",
      ADD_IMAGE_SUBTITLE_3: "BO_ONBOARDING.BOAT_INFO_TWO.ADD_IMAGE_SUBTITLE_3",
    },
    LOCATION_TAB: {
      TITTLE: "BO_ONBOARDING.LOCATION_TAB.TITTLE",
      PARKING_LOCATION: "BO_ONBOARDING.LOCATION_TAB.PARKING_LOCATION",
      LOCATION_NAME: "BO_ONBOARDING.LOCATION_TAB.LOCATION_NAME",
      POPOVER_MESSAGE: "BO_ONBOARDING.LOCATION_TAB.POPOVER_MESSAGE",
      STEP_ONE: "BO_ONBOARDING.LOCATION_TAB.STEP_ONE",
      STEP_ONE_TEXT: "BO_ONBOARDING.LOCATION_TAB.STEP_ONE_TEXT",
      STEP_TWO: "BO_ONBOARDING.LOCATION_TAB.STEP_TWO",
      STEP_TWO_TEXT: "BO_ONBOARDING.LOCATION_TAB.STEP_TWO_TEXT",
      DESCRIPTION: "BO_ONBOARDING.LOCATION_TAB.DESCRIPTION",
      POPOVER_LABEL: "BO_ONBOARDING.LOCATION_TAB.POPOVER_LABEL",
      HEADING_ONE: "BO_ONBOARDING.LOCATION_TAB.HEADING_ONE",
      SEARCH_LOCATION: "BO_ONBOARDING.LOCATION_TAB.SEARCH_LOCATION",
      STREET_ADDRESS: "BO_ONBOARDING.LOCATION_TAB.STREET_ADDRESS",
      CITY: "BO_ONBOARDING.LOCATION_TAB.CITY",
      STATE: "BO_ONBOARDING.LOCATION_TAB.STATE",
      ZIP: "BO_ONBOARDING.LOCATION_TAB.ZIP",
      SLIP_NO: "BO_ONBOARDING.LOCATION_TAB.SLIP_NO",
      VESSEL_LOCATION: "BO_ONBOARDING.LOCATION_TAB.VESSEL_LOCATION",
      DET_1: "BO_ONBOARDING.LOCATION_TAB.DET_1",
      DET_2: "BO_ONBOARDING.LOCATION_TAB.DET_2",
      DET_3: "BO_ONBOARDING.LOCATION_TAB.DET_3",
      EXACT: "BO_ONBOARDING.LOCATION_TAB.EXACT",
    },

    NEXT: "BO_ONBOARDING.NEXT",
    CONTINUE: "BO_ONBOARDING.CONTINUE",
    BACK: "BO_ONBOARDING.BACK",

    STEP: "BO_ONBOARDING.STEP",
    BOAT_PROFILE: "BO_ONBOARDING.BOAT_PROFILE",
    OWNER_PROFILE: "BO_ONBOARDING.OWNER_PROFILE",
    UP_NEXT: "BO_ONBOARDING.UP_NEXT",
    TITLE: "BO_ONBOARDING.TITLE",
    ADD: "BO_ONBOARDING.ADD",
    LOCATION: "BO_ONBOARDING.LOCATION",
    EXIT_ONBOARDING: "BO_ONBOARDING.EXIT_ONBOARDING",
    BOAT_ADDED_SUCCESS: "BO_ONBOARDING.BOAT_ADDED_SUCCESS",
  },

  YACHT_VITALS: {
    TITLE: "YACHT_VITALS.TITLE",
    SET_UP_VITALS: "YACHT_VITALS.SET_UP_VITALS",
    PLACEHOLDER: "YACHT_VITALS.PLACEHOLDER",
    POPOVER_MESSAGE: "YACHT_VITALS.POPOVER_MESSAGE",
    TOASTER_MESSAGE: "YACHT_VITALS.TOASTER_MESSAGE",
    LOADING_MESSAGE: "YACHT_VITALS.LOADING_MESSAGE",
    TOASTER_MESSAGE_DESCRIPTION: "YACHT_VITALS.TOASTER_MESSAGE_DESCRIPTION",
    GALLONS: "YACHT_VITALS.GALLONS",
    FUEL_TITLE: "YACHT_VITALS.FUEL_TITLE",
    ENGINE_NAME: "YACHT_VITALS.ENGINE_NAME",
    GENERATOR_TITLE: "YACHT_VITALS.GENERATOR_TITLE",
    ENGINE_TITLE: "YACHT_VITALS.ENGINE_TITLE",
    WATER_TITLE: "YACHT_VITALS.WATER_TITLE",
    LITERS: "YACHT_VITALS.LITERS",
    ENGINE_HOURS: "YACHT_VITALS.ENGINE_HOURS",
    HOURS: "YACHT_VITALS.HOURS",
    GENERATOR_HOURS: "YACHT_VITALS.GENERATOR_HOURS",
    LAST_UPDATE: "YACHT_VITALS.LAST_UPDATE",
    UPDATED: "YACHT_VITALS.UPDATED",
    LAST_SERVICED: "YACHT_VITALS.LAST_SERVICED",
    BLACK_WATER: "YACHT_VITALS.BLACK_WATER",
    TRIP_VALUES: "YACHT_VITALS.TRIP_VALUES",
    START: "YACHT_VITALS.START",
    END: "YACHT_VITALS.END",
    UNITS: "YACHT_VITALS.UNITS",
    OPTIONAL: "YACHT_VITALS.OPTIONAL",

    VITALS_LIST: {
      ADD_VITAL: "YACHT_VITALS.VITALS_LIST.ADD_VITAL",
      LAST_SERVICED: "YACHT_VITALS.VITALS_LIST.LAST_SERVICED",
      NO_LAST_SERVICE: "YACHT_VITALS.VITALS_LIST.NO_LAST_SERVICE",
      NEXT_SERVICE_DUE: "YACHT_VITALS.VITALS_LIST.NEXT_SERVICE_DUE",
      NO_NEXT_SERVICE_DATE: "YACHT_VITALS.VITALS_LIST.NO_NEXT_SERVICE_DATE",
      NO_OPTIONAL_VITALS: "YACHT_VITALS.VITALS_LIST.NO_OPTIONAL_VITALS",
      RETURN_TO_VITALS: "YACHT_VITALS.VITALS_LIST.RETURN_TO_VITALS",
      VITAL_NAME: "YACHT_VITALS.VITALS_LIST.VITAL_NAME",
      SET_UP: "YACHT_VITALS.VITALS_LIST.SET_UP",
      DATE_OF: "YACHT_VITALS.VITALS_LIST.DATE_OF",
      HOURS_AND: "YACHT_VITALS.VITALS_LIST.HOURS_AND",
      SERVICE: "YACHT_VITALS.VITALS_LIST.SERVICE",
      LAST: "YACHT_VITALS.VITALS_LIST.LAST",
      HOURS_LAST_SERVICE: "YACHT_VITALS.VITALS_LIST.HOURS_LAST_SERVICE",
      SERVICE_LAST: "YACHT_VITALS.VITALS_LIST.SERVICE_LAST",
      HOURS_NEXT_SERVICE: "YACHT_VITALS.VITALS_LIST.HOURS_NEXT_SERVICE",
      NEXT_SERVICE_REQUIRED: "YACHT_VITALS.VITALS_LIST.NEXT_SERVICE_REQUIRED",
      LIGHT_BOX_TEXT: "YACHT_VITALS.VITALS_LIST.LIGHT_BOX_TEXT",
    },
    CRITICAL_VITALS: {
      TITLE: "YACHT_VITALS.CRITICAL_VITALS.TITLE",
      POPOVER_MESSAGE: "YACHT_VITALS.CRITICAL_VITALS.POPOVER_MESSAGE",
      MULTIPLE_VITALS_TEXT: "YACHT_VITALS.CRITICAL_VITALS.MULTIPLE_VITALS_TEXT",
      SINGULAR_VITAL_TEXT: "YACHT_VITALS.CRITICAL_VITALS.SINGULAR_VITAL_TEXT",
      INTRODUCTION: "YACHT_VITALS.CRITICAL_VITALS.INTRODUCTION",
      POINT_1: "YACHT_VITALS.CRITICAL_VITALS.POINT_1",
      POINT_2: "YACHT_VITALS.CRITICAL_VITALS.POINT_2",
      CONCLUSION: "YACHT_VITALS.CRITICAL_VITALS.CONCLUSION",
      NOTICE: "YACHT_VITALS.CRITICAL_VITALS.NOTICE",
    },
    SET_VITAL_MODAL: {
      LABEL: "YACHT_VITALS.SET_VITAL_MODAL.LABEL",
      ALERT_BEFORE: "YACHT_VITALS.SET_VITAL_MODAL.ALERT_BEFORE",
      CURRENT_ALERT_DATE: "YACHT_VITALS.SET_VITAL_MODAL.CURRENT_ALERT_DATE",
      DAYS_BEFORE: "YACHT_VITALS.SET_VITAL_MODAL.DAYS_BEFORE",
      SET_ALERT: "YACHT_VITALS.SET_VITAL_MODAL.SET_ALERT",
      ALERT_CANT_BE_SET: "YACHT_VITALS.SET_VITAL_MODAL.ALERT_CANT_BE_SET",
      VITAL_SET_SUCCESSFULLY: "YACHT_VITALS.SET_VITAL_MODAL.VITAL_SET_SUCCESSFULLY",
      VITAL_CLEARED_SUCCESSFULLY: "YACHT_VITALS.SET_VITAL_MODAL.VITAL_CLEARED_SUCCESSFULLY",
    },
    GENERATOR: {
      TITLE: "YACHT_VITALS.GENERATOR.TITLE",
      CLOSE_MENU: "YACHT_VITALS.GENERATOR.CLOSE_MENU",
      ENTER_HOURS: "YACHT_VITALS.GENERATOR.ENTER_HOURS",
      ENTER_HOURS_PLACEHOLDER: "YACHT_VITALS.GENERATOR.ENTER_HOURS_PLACEHOLDER",
      UPDATING: "YACHT_VITALS.GENERATOR.UPDATING",
      UPDATE_DESCRIPTION: "YACHT_VITALS.GENERATOR.UPDATE_DESCRIPTION",
      UPDATE_WAIT: "YACHT_VITALS.GENERATOR.UPDATE_WAIT",
      UPDATE_SUCCESSFUL: "YACHT_VITALS.GENERATOR.UPDATE_SUCCESSFUL",
      UPDATED_MESSAGE: "YACHT_VITALS.GENERATOR.UPDATED_MESSAGE",
      UPDATED_MESSAGE_ONE: "YACHT_VITALS.GENERATOR.UPDATED_MESSAGE_ONE",
      UPDATED_ERROR_MESSAGE: "YACHT_VITALS.GENERATOR.UPDATED_ERROR_MESSAGE",
    },
    ENGINE: {
      TITLE: "YACHT_VITALS.ENGINE.TITLE",
      CLOSE_MENU: "YACHT_VITALS.ENGINE.CLOSE_MENU",
      ENTER_HOURS: "YACHT_VITALS.ENGINE.ENTER_HOURS",
      ENTER_HOURS_PLACEHOLDER: "YACHT_VITALS.ENGINE.ENTER_HOURS_PLACEHOLDER",
      UPDATING: "YACHT_VITALS.ENGINE.UPDATING",
      UPDATE_DESCRIPTION: "YACHT_VITALS.ENGINE.UPDATE_DESCRIPTION",
      UPDATE_WAIT: "YACHT_VITALS.ENGINE.UPDATE_WAIT",
      UPDATE_SUCCESSFUL: "YACHT_VITALS.ENGINE.UPDATE_SUCCESSFUL",
      UPDATED_MESSAGE: "YACHT_VITALS.ENGINE.UPDATED_MESSAGE",
      UPDATED_MESSAGE_ONE: "YACHT_VITALS.ENGINE.UPDATED_MESSAGE_ONE",
      UPDATED_ERROR_MESSAGE: "YACHT_VITALS.ENGINE.UPDATED_ERROR_MESSAGE",
    },
    FUEL: {
      TITLE: "YACHT_VITALS.FUEL.TITLE",
      CLOSE_MENU: "YACHT_VITALS.FUEL.CLOSE_MENU",
      ENTER_FUEL_LEVEL: "YACHT_VITALS.FUEL.ENTER_FUEL_LEVEL",
      ENTER_HOURS_PLACEHOLDER: "YACHT_VITALS.FUEL.ENTER_HOURS_PLACEHOLDER",
      UPDATING: "YACHT_VITALS.FUEL.UPDATING",
      UPDATE_DESCRIPTION: "YACHT_VITALS.FUEL.UPDATE_DESCRIPTION",
      UPDATE_WAIT: "YACHT_VITALS.FUEL.UPDATE_WAIT",
      UPDATE_SUCCESSFUL: "YACHT_VITALS.FUEL.UPDATE_SUCCESSFUL",
      UPDATED_MESSAGE: "YACHT_VITALS.FUEL.UPDATED_MESSAGE",
    },
    WATER: {
      TITLE: "YACHT_VITALS.WATER.TITLE",
      PERCENTAGE_TITLE: "YACHT_VITALS.WATER.PERCENTAGE_TITLE",
      CLOSE_MENU: "YACHT_VITALS.WATER.CLOSE_MENU",
      ENTER_WATER_LEVEL: "YACHT_VITALS.WATER.ENTER_WATER_LEVEL",
      ENTER_HOURS_PLACEHOLDER: "YACHT_VITALS.FUEL.ENTER_HOURS_PLACEHOLDER",
      UPDATING: "YACHT_VITALS.WATER.UPDATING",
      UPDATE_DESCRIPTION: "YACHT_VITALS.WATER.UPDATE_DESCRIPTION",
      UPDATE_WAIT: "YACHT_VITALS.WATER.UPDATE_WAIT",
      UPDATE_SUCCESSFUL: "YACHT_VITALS.WATER.UPDATE_SUCCESSFUL",
      UPDATED_MESSAGE: "YACHT_VITALS.WATER.UPDATED_MESSAGE",
    },
    VITALS_UPDATE_TOAST_MESSAGE: {
      FUEL: "YACHT_VITALS.VITALS_UPDATE_TOAST_MESSAGE.FUEL",
      WATER: "YACHT_VITALS.VITALS_UPDATE_TOAST_MESSAGE.WATER",
      BLACKWATER: "YACHT_VITALS.VITALS_UPDATE_TOAST_MESSAGE.BLACKWATER",
      ENGINE: "YACHT_VITALS.VITALS_UPDATE_TOAST_MESSAGE.ENGINE",
      GENERATOR: "YACHT_VITALS.VITALS_UPDATE_TOAST_MESSAGE.GENERATOR",
    },
    UPDATED_MESSAGE_ONE: "YACHT_VITALS.UPDATED_MESSAGE_ONE",
    UPDATED_ERROR_MESSAGE: "YACHT_VITALS.UPDATED_ERROR_MESSAGE",
  },
  LIVE_HELP: {
    TITLE: "LIVE_HELP.TITLE",
    WELCOME_TO_BOATFIX: "LIVE_HELP.WELCOME_TO_BOATFIX",
    ENGINE_ISSUES: "LIVE_HELP.ENGINE_ISSUES",
    INTRO_TEXT: "LIVE_HELP.INTRO_TEXT",
    CALL_NOW: "LIVE_HELP.CALL_NOW",
  },
  ESTIMATE: {
    TITLE: "ESTIMATE.TITLE",
    NO_ESTIMATE: "ESTIMATE.NO_ESTIMATE",
    REJECTED: "ESTIMATE.REJECTED",
    BY: "ESTIMATE.BY",
    ESTIMATE_UNAVAILABLE: "ESTIMATE.ESTIMATE_UNAVAILABLE",
    RECOMMENDED_SERVICE_REJECTED_SUCCESS: "ESTIMATE.RECOMMENDED_SERVICE_REJECTED_SUCCESS",
    RECOMMENDED_SERVICE_REJECTED_FAIL: "ESTIMATE.RECOMMENDED_SERVICE_REJECTED_FAIL",
    RECOMMENDED_SERVICE_ACCEPTED_FAIL: "ESTIMATE.RECOMMENDED_SERVICE_ACCEPTED_FAIL",
    RECOMMENDED_SERVICE_ACCEPTED_SUCCESS: "ESTIMATE.RECOMMENDED_SERVICE_ACCEPTED_SUCCESS",
    ESTMATE_REJECTED: "ESTIMATE.ESTMATE_REJECTED",
    UNABLE_ESTMATE_REJECTED: "ESTIMATE.UNABLE_ESTMATE_REJECTED",
    UNABLE_ESTMATE_ACCEPTED: "ESTIMATE.UNABLE_ESTMATE_ACCEPTED",
    ESTIMATE_ACCEPTED: "ESTIMATE.ESTIMATE_ACCEPTED",
    SEARCH_TEXT: "ESTIMATE.SEARCH_TEXT",
    FILTERS: {
      VENDOR: "ESTIMATE.FILTERS.VENDOR",
      STATUS: "ESTIMATE.FILTERS.STATUS",
      TYPE: "ESTIMATE.FILTERS.TYPE",
      RESET_FILTERS: "ESTIMATE.FILTERS.RESET_FILTERS",
      APPLY_FILTER: "ESTIMATE.FILTERS.APPLY_FILTER",
    },
  },
  OPEN_ORDERS: {
    TITLE: "OPEN_ORDERS.TITLE",
    STATUS_MESSAGE: "OPEN_ORDERS.STATUS_MESSAGE",
    INVITED: "OPEN_ORDERS.INVITED",
    CREATED: "OPEN_ORDERS.CREATED",
    SCHEDULED_START: "OPEN_ORDERS.SCHEDULED_START",
    SCHEDULED_END: "OPEN_ORDERS.SCHEDULED_END",
    COMPLETED: "OPEN_ORDERS.COMPLETED",
    SEE_MORE: "OPEN_ORDERS.SEE_MORE",
    REJECT: "OPEN_ORDERS.REJECT",
    ACCEPT: "OPEN_ORDERS.ACCEPT",
    MESSAGE_VENDOR: "OPEN_ORDERS.MESSAGE_VENDOR",
    POPOVER_MESSAGE: "OPEN_ORDERS.POPOVER_MESSAGE",
    NO_CHECKLIST: "OPEN_ORDERS.NO_CHECKLIST",
    SERVICE_LIST: {
      TITLE: "OPEN_ORDERS.SERVICE_LIST.TITLE",
      SEARCH_TEXT: "OPEN_ORDERS.SERVICE_LIST.SEARCH_TEXT",
      ADD_SERVICE_ORDER: "OPEN_ORDERS.SERVICE_LIST.ADD_SERVICE_ORDER",
      EDIT_SERVICE_ORDER: "OPEN_ORDERS.SERVICE_LIST.EDIT_SERVICE_ORDER",
      SERVICE: "OPEN_ORDERS.SERVICE_LIST.SERVICE",
      NO_DATA_TEXT: "OPEN_ORDERS.SERVICE_LIST.NO_DATA_TEXT",
      VENDOR: "OPEN_ORDERS.SERVICE_LIST.VENDOR",
      PRIORITY: "OPEN_ORDERS.SERVICE_LIST.PRIORITY",
      RECURRING: "OPEN_ORDERS.SERVICE_LIST.RECURRING",
      OPEN_BID: "OPEN_ORDERS.SERVICE_LIST.OPEN_BID",
      CHECKLIST: "OPEN_ORDERS.SERVICE_LIST.CHECKLIST",
      DETAILS: "OPEN_ORDERS.SERVICE_LIST.DETAILS",
      RECOMMENDED_SERVICE: "OPEN_ORDERS.SERVICE_LIST.RECOMMENDED_SERVICE",
      SERVICE_TO_PERFORM: "OPEN_ORDERS.SERVICE_LIST.SERVICE_TO_PERFORM",
      NOTE_HISTORY: "OPEN_ORDERS.SERVICE_LIST.NOTE_HISTORY",
      NOTE: "OPEN_ORDERS.SERVICE_LIST.NOTE",
      TITL: "OPEN_ORDERS.SERVICE_LIST.TITL",
      DATE: "OPEN_ORDERS.SERVICE_LIST.DATE",
      FREQUENCY_OF_SERVICE: "OPEN_ORDERS.SERVICE_LIST.FREQUENCY_OF_SERVICE",
      SERVICE_ORDER_TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_TITLE",
      DESCRIPTION_ONE: "OPEN_ORDERS.SERVICE_LIST.DESCRIPTION_ONE",
      DESCRIPTION_PLACEHOLDER: "OPEN_ORDERS.SERVICE_LIST.DESCRIPTION_PLACEHOLDER",
      POPOVER_MESSAGE: "OPEN_ORDERS.SERVICE_LIST.POPOVER_MESSAGE",
      DESCRIPTION: "OPEN_ORDERS.SERVICE_LIST.DESCRIPTION",
      NO_SERVICE: "OPEN_ORDERS.SERVICE_LIST.NO_SERVICE",
      REC_REASON: "OPEN_ORDERS.SERVICE_LIST.REC_REASON",
      REQUEST_SUCCESS: "OPEN_ORDERS.SERVICE_LIST.REQUEST_SUCCESS",
      FILTER_LABELS: {
        ALL: "OPEN_ORDERS.SERVICE_LIST.FILTER_LABELS.ALL",
        BID_REQUESTS: "OPEN_ORDERS.SERVICE_LIST.FILTER_LABELS.BID_REQUESTS",
      },
      SERVICE_ORDER_DETAILS: {
        TERMS_OF_SERVICE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.TERMS_OF_SERVICE",
        PRIVACY_POLICY: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.PRIVACY_POLICY",
        PRICE_CHANGE_NOTICE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.PRICE_CHANGE_NOTICE",
        IMAGE_BROWSER_TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.IMAGE_BROWSER_TITLE",
        IMAGE_BROWSER_POPUP_MESSAGE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.IMAGE_BROWSER_POPUP_MESSAGE",
        VESSEL: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.VESSEL",
        IN_PROGRESS: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.IN_PROGRESS",
        COMPLETED: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.COMPLETED",
        SERVICE_REQUEST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.SERVICE_REQUEST",
        ORDER: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.ORDER",
        DESCRIPTION: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.DESCRIPTION",
        CAMERA: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CAMERA",
        SELECT_GALLERY: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.SELECT_GALLERY",
        SUBMIT_REQUEST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.SUBMIT_REQUEST",
        UPDATE_REQUEST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.UPDATE_REQUEST",
        ESTIMATE_REQ: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.ESTIMATE_REQ",
        USES: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.USES",
        AMOUNT_DUE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.AMOUNT_DUE",
        REVIEW_ESTIMATE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.REVIEW_ESTIMATE",
        STATUS_MESSAGE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.STATUS_MESSAGE",

        BUNDLED_SERVICE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.BUNDLED_SERVICE",
        CHECKLIST: {
          TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.TITLE",
          CREATE_CUSTOM_CHECKLIST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CREATE_CUSTOM_CHECKLIST",
          POPOVER_MESSAGE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.POPOVER_MESSAGE",
          CUSTOM_CHECKLIST_DESCRIPTION:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CUSTOM_CHECKLIST_DESCRIPTION",
          SAMPLE_CHECKLIST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.SAMPLE_CHECKLIST",
          SAMPLE_CHECKLIST_DESCRIPTION:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.SAMPLE_CHECKLIST_DESCRIPTION",
          CHECKLIST_TASKS: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CHECKLIST_TASKS",
          PERSONAL_CHECKLIST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.PERSONAL_CHECKLIST",
          DESCRIPTION_2: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.DESCRIPTION_2",

          PERSONAL_CHECKLIST_DESCRIPTION:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.PERSONAL_CHECKLIST_DESCRIPTION",
          CREATE_CHECKLIST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CREATE_CHECKLIST",
          INSPECTION_FORM: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.INSPECTION_FORM",
          SAVE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.SAVE",
          USE_SAMPLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.USE_SAMPLE",
          EDIT_TASKS: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.EDIT_TASKS",
          ADD_TASKS: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.ADD_TASKS",
          CANCEL: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CANCEL",
          TASK: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.TASK",
          SAVE_CHECKLIST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.SAVE_CHECKLIST",
          EDIT_INSPECTION: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.EDIT_INSPECTION",
          ADD_A_CHECKLIST_ITEM: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.ADD_A_CHECKLIST_ITEM",
          EDIT_TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.EDIT_TITLE",
          CREATE_CHECKLIST_POPOVER: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CREATE_CHECKLIST_POPOVER",
          CREATE_CHECKLIST_POPOVER_2:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CREATE_CHECKLIST_POPOVER_2",
          CHECKLIST_TASK: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CHECKLIST_TASK",
          CHECKLIST_TASK_PLACEHOLDER:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CHECKLIST_TASK_PLACEHOLDER",
          CHECKLIST_TASK_PROMPT: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.CHECKLIST_TASK_PROMPT",
          ERROR_SAVING_CHECKLIST: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.CHECKLIST.ERROR_SAVING_CHECKLIST",
        },

        NOTE: {
          TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.NOTE.TITLE",
          NOTE_SERVICE_DETAIL: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.NOTE.NOTE_SERVICE_DETAIL",
          NOTE_SEARCH_TEXT: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.NOTE.NOTE_SEARCH_TEXT",
        },
        RECOMMENDED_SERVICE: {
          TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.TITLE",
          RECOMMENDED_SERVICE_TITLE:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.RECOMMENDED_SERVICE_TITLE",
          RECOMMENDED_SERVICE_SERVICE:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.RECOMMENDED_SERVICE_SERVICE",
          NO_RECOMMENDED_SERVICE:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.NO_RECOMMENDED_SERVICE",
          POPOVER_MESSAGE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.POPOVER_MESSAGE",
          SEARCH: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.SEARCH",
          RECOMMENDED: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.RECOMMENDED",
          NO_RECOMMENDED: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.NO_RECOMMENDED",
          NO_RECOMMENDED_MESSAGE:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.NO_RECOMMENDED_MESSAGE",
          CREATE_RECOMMENDED_SERVICE:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.CREATE_RECOMMENDED_SERVICE",
          EDIT_RECOMMENDED_SERVICE:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.EDIT_RECOMMENDED_SERVICE",
          CELL_PHONE_NUMBER: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.CELL_PHONE_NUMBER",
          EMAIL_ADDRESS: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.EMAIL_ADDRESS",
          MESSAGE_VENDOR: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.MESSAGE_VENDOR",
        },
        YACHT_WORK_HISTORY: {
          TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.TITLE",
          YACHT_WORK_HISTORY_SERVICE_DETAIL:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.YACHT_WORK_HISTORY_SERVICE_DETAIL",
          YACHT_WORK_HISTORY_SEARCH_TEXT:
            "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.YACHT_WORK_HISTORY_SEARCH_TEXT",
          ITEM_TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.ITEM_TITLE",
          ITEM_DATE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.ITEM_DATE",
        },
        ORDER_HISTORY: {
          TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.ORDER_HISTORY.TITLE",
          POPOVER_MESSAGE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.ORDER_HISTORY.POPOVER_MESSAGE",
        },
        INVOICE: {
          TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.INVOICE.TITLE",
        },
        EDIT_SERVICE_ORDER: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.EDIT_SERVICE_ORDER",
        OPEN_BID_DETAILS: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.OPEN_BID_DETAILS",
        ESTIMATE: {
          TITLE: "OPEN_ORDERS.SERVICE_LIST.SERVICE_ORDER_DETAILS.ESTIMATE.TITLE",
        },
      },
      NO_DATA: "OPEN_ORDERS.SERVICE_LIST.NO_DATA",
      NO_JOB_YET: "OPEN_ORDERS.SERVICE_LIST.NO_JOB_YET",
    },

    SERVICE_REQUEST: {
      TITLE: "OPEN_ORDERS.SERVICE_REQUEST.TITLE",
      EDIT_SERVICE_REQUEST: "OPEN_ORDERS.SERVICE_REQUEST.EDIT_SERVICE_REQUEST",
      SAVE: "OPEN_ORDERS.SERVICE_REQUEST.SAVE",
      SERVICE_ORDER_FORM: {
        TITLE: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.TITLE",
        SEARCH_YACHTS: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.SEARCH_YACHTS",
        SEARCH_SERVICES: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.SEARCH_SERVICES",
        SEARCH_VENDORS: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.SEARCH_VENDORS",
        PRIORITY: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.PRIORITY",
        RECURRING: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.RECURRING",
        AFFECTS_SHIPSHAPE: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.AFFECTS_SHIPSHAPE",
        BID_REQUEST: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.BID_REQUEST",
        DESCRIPTION: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.DESCRIPTION",
        CHECKLIST: {
          TITLE: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CHECKLIST.TITLE",
          INSPECTION_FORM: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CHECKLIST.INSPECTION_FORM",
          SAVE: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CHECKLIST.SAVE",
          EDIT_INSPECTION: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CHECKLIST.EDIT_INSPECTION",
          ADD_A_CHECKLIST_ITEM: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CHECKLIST.ADD_A_CHECKLIST_ITEM",
          EDIT_CHECKLIST_POPOVER: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CHECKLIST.EDIT_CHECKLIST_POPOVER",
          CHECLIST_STARTING_POINT_POPOVER:
            "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CHECKLIST.CHECLIST_STARTING_POINT_POPOVER",
        },
        CAMERA: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.CAMERA",
        SELECT_GALLERY: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.SELECT_GALLERY",
        ERROR_MESSAGES: {
          YACHT: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.ERROR_MESSAGES.YACHT",
          SERVICE: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.ERROR_MESSAGES.SERVICE",
          PRIORITY: "OPEN_ORDERS.SERVICE_REQUEST.SERVICE_ORDER_FORM.ERROR_MESSAGES.PRIORITY",
        },
      },
    },
  },

  INVOICES: {
    TITLE: "INVOICES.TITLE",
    MY_PACKAGES: "INVOICES.MY_PACKAGES",
    BOAT_NAME: "INVOICES.MY_PACKAGES",
    AMOUNT_DUE: "INVOICES.AMOUNT_DUE",
    PRICE: "INVOICES.PRICE",
    BILLING_INVOICES: "INVOICES.BILLING_INVOICES",
    SEARCH_TEXT: "INVOICES.SEARCH_TEXT",
    VIEW_ESTIMATE: "INVOICES.VIEW_ESTIMATE",
    OPEN_INVOICES: "INVOICES.OPEN_INVOICES",
    QUANTITY: "INVOICES.QUANTITY",
    SOURCE: "INVOICES.SOURCE",
    SERVICE_ORDER: "INVOICES.SERVICE_ORDER",
    PACKAGE: "INVOICES.PACKAGE",
    PAYMENT_HISTORY: "INVOICES.PAYMENT_HISTORY",
    TAX: "INVOICES.TAX",
    DISCOUNT: "INVOICES.DISCOUNT",
    PAYMENT_MADE: "INVOICES.PAYMENT_MADE",
    ATTACHMENT: "INVOICES.ATTACHMENT",
    CREDIT: "INVOICES.CREDIT",
    BALANCE_DUE: "INVOICES.BALANCE_DUE",
    UNIT_PRICE: "INVOICES.UNIT_PRICE",
    VALID_UNTIL: "INVOICES.VALID_UNTIL",

    FILTERS: {
      ALL_INVOICES: "INVOICES.FILTERS.ALL_INVOICES",
      PAID_INVOICES: "INVOICES.FILTERS.PAID_INVOICES",
      UNPAID_INVOICES: "INVOICES.FILTERS.UNPAID_INVOICES",
      DATE_RANGE: "INVOICES.FILTERS.DATE_RANGE",
      FILTER_RESULTS: "INVOICES.FILTERS.FILTER_RESULTS",
      RESET_FILTERS: "INVOICES.FILTERS.RESET_FILTERS",
      VENDOR: "INVOICES.FILTERS.VENDOR",
      TYPE: "INVOICES.FILTERS.TYPE",
      INVOICE_AMOUNT: "INVOICES.FILTERS.INVOICE_AMOUNT",
      DUE_DATE: "INVOICES.FILTERS.DUE_DATE",
      APPLY_FILTER: "INVOICES.FILTERS.APPLY_FILTER",
      FROM: "INVOICES.FILTERS.FROM",
      TO: "INVOICES.FILTERS.TO",
      MIN: "INVOICES.FILTERS.MIN",
      MAX: "INVOICES.FILTERS.MAX",
    },
    ALL_INVOICES: "INVOICES.ALL_INVOICES",
    UNPAID: "INVOICES.UNPAID",
    PAY: "INVOICES.PAY",
    PAID: "INVOICES.PAID",
    PENDING: "INVOICES.PENDING",
    ARCHIVE: "INVOICES.ARCHIVE",
    REQUIRE_ACTION: "INVOICES.REQUIRE_ACTION",
    ACTION: "INVOICES.ACTION",
    TYPE: "INVOICES.TYPE",
    AMOUNT_PAID: "INVOICES.AMOUNT_PAID",
    DATE: "INVOICES.DATE",
    VIEW: "INVOICES.VIEW",
    ITEMS: "INVOICES.ITEMS",
    INVOICE_ID: "INVOICES.INVOICE_ID",
    DELETE_INVOICE: "INVOICES.DELETE_INVOICE",
    RESTORE_INVOICE: "INVOICES.RESTORE_INVOICE",
    SEND_MESSAGE: "INVOICES.SEND_MESSAGE",
    ORDER: "INVOICES.ORDER",
    ISSUED: "INVOICES.ISSUED",
    INVOICE_FROM: "INVOICES.INVOICE_FROM",
    INVOICE_TO: "INVOICES.INVOICE_TO",
    SUBTOTAL: "INVOICES.SUBTOTAL",
    AMOUNT_DISCOUNT: "INVOICES.AMOUNT_DISCOUNT",
    BALANCE: "INVOICES.BALANCE",
    PAYMENT_METHOD: "INVOICES.PAYMENT_METHOD",
    PAYMENT_METHOD_DESCRIPTION: "INVOICES.PAYMENT_METHOD_DESCRIPTION",
    PAYMENT_DETAIL: "INVOICES.PAYMENT_DETAIL",
    PAYMENT_DETAIL_DESCRIPTION: "INVOICES.PAYMENT_DETAIL_DESCRIPTION",
    PAYMENT_INSTRUCTION: "INVOICES.PAYMENT_INSTRUCTION",
    PAYMENT_SUCCESSFUL: "INVOICES.PAYMENT_SUCCESSFUL",
    PAYMENT_SUCCESSFUL_DESCRIPTION_BEFRORE: "INVOICES.PAYMENT_SUCCESSFUL_DESCRIPTION_BEFRORE",
    PAYMENT_SUCCESSFUL_DESCRIPTION_AFTER: "INVOICES.PAYMENT_SUCCESSFUL_DESCRIPTION_AFTER",
    SELECT_DATE: "INVOICES.SELECT_DATE",
    NO_INVOICES_YET: "INVOICES.NO_INVOICES_YET",
    NO_INVOICES_YET_TEXT: "INVOICES.NO_INVOICES_YET_TEXT",
  },
  CAPTAIN_AI: {
    TITLE: "CAPTAIN_AI.TITLE",
    ASK_QUESTION: "CAPTAIN_AI.ASK_QUESTION",
    RECENT_QUESTION: "CAPTAIN_AI.RECENT_QUESTION",
    ASK_ANYTHING: "CAPTAIN_AI.ASK_ANYTHING",
    CLEAR: "CAPTAIN_AI.CLEAR",
    INPUT_PLACEHOLDER: "CAPTAIN_AI.INPUT_PLACEHOLDER",
    SEND: "CAPTAIN_AI.SEND",
    ASK_AGAIN: "CAPTAIN_AI.ASK_AGAIN",
    FAVORITE: "CAPTAIN_AI.FAVORITE",
    VIEW: "CAPTAIN_AI.VIEW",
    DELETE: "CAPTAIN_AI.DELETE",
    CAPTAIN_AI_TYPING: "CAPTAIN_AI.CAPTAIN_AI_TYPING",
    POWERED_BY: "CAPTAIN_AI.POWERED_BY",
    ASK_ME_ANYTHING: "CAPTAIN_AI.ASK_ME_ANYTHING",
    POPOVER_MESSAGE: "CAPTAIN_AI.POPOVER_MESSAGE",
    VOICE: "CAPTAIN_AI.VOICE",
    PROCESSING: "CAPTAIN_AI.PROCESSING",
    REVISE: "CAPTAIN_AI.REVISE",
    TRANSLATE: "CAPTAIN_AI.TRANSLATE",
    TALK_TO_ME: "CAPTAIN_AI.TALK_TO_ME",
    NOTICE: "CAPTAIN_AI.NOTICE",
    CLICK: "CAPTAIN_AI.CLICK",
    NOTICE_2: "CAPTAIN_AI.NOTICE_2",
    TITLE_2: "CAPTAIN_AI.TITLE_2",
    START: "CAPTAIN_AI.START",
    STOP: "CAPTAIN_AI.STOP",
    RECORDING: "CAPTAIN_AI.RECORDING",
    ASK_MAINTENANCE: "CAPTAIN_AI.ASK_MAINTENANCE",
    POPOVER_MESSAGE_2: "CAPTAIN_AI.POPOVER_MESSAGE_2",
  },

  IMAGE_BROWSER: {
    POPOVER_MESSAGE: "IMAGE_BROWSER.POPOVER_MESSAGE",
    IMAGE_ADDED: "IMAGE_BROWSER.IMAGE_ADDED",
    PROFILE: "IMAGE_BROWSER.PROFILE",
    SET_AS: "IMAGE_BROWSER.SET_AS",
    NO_IMAGES_TEXT: "IMAGE_BROWSER.NO_IMAGES_TEXT",
    BOAT: "IMAGE_BROWSER.BOAT",
    NO_IMAGES: "IMAGE_BROWSER.NO_IMAGES",
    CLICK_CAMERA: "IMAGE_BROWSER.CLICK_CAMERA",
    WELCOME_MESSAGE: "IMAGE_BROWSER.WELCOME_MESSAGE",
    REMOVE_IMAGE: "IMAGE_BROWSER.REMOVE_IMAGE",
    REMOVE_MESSAGE: "IMAGE_BROWSER.REMOVE_MESSAGE",
    YES: "IMAGE_BROWSER.YES",
    NO: "IMAGE_BROWSER.NO",
    ADD_PHOTO: "IMAGE_BROWSER.ADD_PHOTO",
    UPLOADING: "IMAGE_BROWSER.UPLOADING",
    PROFILE_PIC_SUCCESS: "IMAGE_BROWSER.PROFILE_PIC_SUCCESS",
    IMAGE_DELETED: "IMAGE_BROWSER.IMAGE_DELETED",
  },
  DASHBOARD: {
    NO_UPCOMING_SERVICE_ORDERS: "DASHBOARD.NO_UPCOMING_SERVICE_ORDERS",
    DESCRIPTION: "DASHBOARD.DESCRIPTION",
    UPCOMING_SERVICE_ORDERS: "DASHBOARD.UPCOMING_SERVICE_ORDERS",
    SEE_ALL: "DASHBOARD.SEE_ALL",
    VESSEL_COORDINATES: "DASHBOARD.VESSEL_COORDINATES",
    LATITUDE: "DASHBOARD.LATITUDE",
    LONGITUDE: "DASHBOARD.LONGITUDE",
    MARINA_ADDRESS: "DASHBOARD.MARINA_ADDRESS",
    PICK_BOAT_LOCATION: "DASHBOARD.PICK_BOAT_LOCATION",
    DIRECTIONS: "DASHBOARD.DIRECTIONS",
    VIEW_ORDER: "DASHBOARD.VIEW_ORDER",
    SUB_DESCRIPTION: "DASHBOARD.SUB_DESCRIPTION",
    SUB_DESCRIPTION_2: "DASHBOARD.SUB_DESCRIPTION_2",
    IMAGE_BROWSER_TITLE: "DASHBOARD.IMAGE_BROWSER_TITLE",
    IMAGE_BROWSER_POPUP_MESSAGE: "DASHBOARD.IMAGE_BROWSER_POPUP_MESSAGE",
  },
  MENU_ITEMS: {
    TITLE: "MENU_ITEMS.TITLE",
    ACCOUNT_SETTINGS: "MENU_ITEMS.ACCOUNT_SETTINGS",
    VENDOR_MANAGER: "MENU_ITEMS.VENDOR_MANAGER",
    CHECKLIST_MANAGER: "MENU_ITEMS.CHECKLIST_MANAGER",
    PROPOSAL_MANAGER: "MENU_ITEMS.PROPOSAL_MANAGER",
    INVOICE_MANAGER: "MENU_ITEMS.INVOICE_MANAGER",
    BOAT_SCHEDULE: "MENU_ITEMS.BOAT_SCHEDULE",
    BOAT_MANAGER: "MENU_ITEMS.BOAT_MANAGER",
    VESSEL_DOCUMENTS: "MENU_ITEMS.VESSEL_DOCUMENTS",
    BOAT_DOCUMENTS: "MENU_ITEMS.BOAT_DOCUMENTS",
    SUPPORT_HELP: "MENU_ITEMS.SUPPORT_HELP",
    BOAT_TRANSFER: "MENU_ITEMS.BOAT_TRANSFER",
  },
  BOAT_MANAGER: {
    TITLE: "BOAT_MANAGER.TITLE",
    DESCRIPTION: "BOAT_MANAGER.DESCRIPTION",
    VIRTUAL_BOAT_FAX: "BOAT_MANAGER.VIRTUAL_BOAT_FAX",
    CURRENT_BOAT: "BOAT_MANAGER.CURRENT_BOAT",
    ALL_BOAT: "BOAT_MANAGER.ALL_BOAT",
    ADD_BOAT: "BOAT_MANAGER.ADD_BOAT",
    GO_BACK: "BOAT_MANAGER.GO_BACK",

    GO_TO_DASHBOARD: "BOAT_MANAGER.GO_TO_DASHBOARD",
    VESSEL_DOCUMENTS: {
      TITLE: "BOAT_MANAGER.VESSEL_DOCUMENTS.TITLE",
      MAIN_DESCRIPTION: "BOAT_MANAGER.VESSEL_DOCUMENTS.MAIN_DESCRIPTION",
      SUB_DESCRIPTION: "BOAT_MANAGER.VESSEL_DOCUMENTS.SUB_DESCRIPTION",
      SUB_DESCRIPTION_2: "BOAT_MANAGER.VESSEL_DOCUMENTS.SUB_DESCRIPTION_2",
      ITS_SIMPLE: "BOAT_MANAGER.VESSEL_DOCUMENTS.ITS_SIMPLE",
      DOCUMENT_TYPE: "BOAT_MANAGER.VESSEL_DOCUMENTS.DOCUMENT_TYPE",
      ADD_DOCUMENTS: "BOAT_MANAGER.VESSEL_DOCUMENTS.ADD_DOCUMENTS",
      NO_DOCUMENTS: "BOAT_MANAGER.VESSEL_DOCUMENTS.NO_DOCUMENTS",
      VESSEL_DOCUMENT_TIPS: "BOAT_MANAGER.VESSEL_DOCUMENTS.VESSEL_DOCUMENT_TIPS",
      NO_DOCUMENTS_DESCRIPTION: "BOAT_MANAGER.VESSEL_DOCUMENTS.NO_DOCUMENTS_DESCRIPTION",
      REGISTERED_DOCUMENTS: "BOAT_MANAGER.VESSEL_DOCUMENTS.REGISTERED_DOCUMENTS",
      SUB_DESCRIPTION_3: "BOAT_MANAGER.VESSEL_DOCUMENTS.SUB_DESCRIPTION_3",
      SUCCESS_UPLOAD: "BOAT_MANAGER.VESSEL_DOCUMENTS.SUCCESS_UPLOAD",
      DELETE_MESSAGE: "BOAT_MANAGER.VESSEL_DOCUMENTS.DELETE_MESSAGE",
      STEP_ONE: "BOAT_MANAGER.VESSEL_DOCUMENTS.STEP_ONE",
      STEP_TWO_1: "BOAT_MANAGER.VESSEL_DOCUMENTS.STEP_TWO_1",
      STEP_TWO_2: "BOAT_MANAGER.VESSEL_DOCUMENTS.STEP_TWO_2",
      DOCUMENT: "BOAT_MANAGER.VESSEL_DOCUMENTS.DOCUMENT",
    },
  },
  VENDOR_MANAGEMENT: {
    TITLE: "VENDOR_MANAGEMENT.TITLE",
    POPOVER_MESSAGE: "VENDOR_MANAGEMENT.POPOVER_MESSAGE",
    ADD_VENDOR: "VENDOR_MANAGEMENT.ADD_VENDOR",
    DESCRIPTION: "VENDOR_MANAGEMENT.DESCRIPTION",
    NOTICE_3: "VENDOR_MANAGEMENT.NOTICE_3",
    VENDORS: "VENDOR_MANAGEMENT.VENDORS",
    MANAGE_VENDOR: "VENDOR_MANAGEMENT.MANAGE_VENDOR",
    ACTIVE: "VENDOR_MANAGEMENT.ACTIVE",
    PROCEED_TO_VENDOR_MANAGER: "VENDOR_MANAGEMENT.PROCEED_TO_VENDOR_MANAGER",
    PROCEED: "VENDOR_MANAGEMENT.PROCEED",
    SELECT_VENDOR: "VENDOR_MANAGEMENT.SELECT_VENDOR",
    PENDING: "VENDOR_MANAGEMENT.PENDING",
    CONFIRM_REMOVAL: "VENDOR_MANAGEMENT.CONFIRM_REMOVAL",
    CONFIRM_REMOVAL_DESCRIPTION: "VENDOR_MANAGEMENT.CONFIRM_REMOVAL_DESCRIPTION",
    INVITATION: "VENDOR_MANAGEMENT.INVITATION",
    INVITE_DESCRIPTION: "VENDOR_MANAGEMENT.INVITE_DESCRIPTION",
    BUSINESS_NAME: "VENDOR_MANAGEMENT.BUSINESS_NAME",
    CELL_PHONE: "VENDOR_MANAGEMENT.CELL_PHONE",
    EMAIL: "VENDOR_MANAGEMENT.EMAIL",
    SEND_INVITE: "VENDOR_MANAGEMENT.SEND_INVITE",
    CANCEL: "VENDOR_MANAGEMENT.CANCEL",
    DELETE: "VENDOR_MANAGEMENT.DELETE",
    REMOVAL_MESSAGE: "VENDOR_MANAGEMENT.REMOVAL_MESSAGE",
    RESET_FILTER: "VENDOR_MANAGEMENT.RESET_FILTER",
    APPLY_FILTER: "VENDOR_MANAGEMENT.APPLY_FILTER",
    STATUS: "VENDOR_MANAGEMENT.STATUS",
    SELECT_DATE_RANGE: "VENDOR_MANAGEMENT.SELECT_DATE_RANGE",
    INVITATION_2: "VENDOR_MANAGEMENT.INVITATION_2",
    NOTICE: "VENDOR_MANAGEMENT.NOTICE",
    NOTICE_2: "VENDOR_MANAGEMENT.NOTICE_2",
    INVITED: "VENDOR_MANAGEMENT.INVITED",
    ALL_VENDORS: "VENDOR_MANAGEMENT.ALL_VENDORS",
    RECENTLY_USED: "VENDOR_MANAGEMENT.RECENTLY_USED",
    SEARCH_VENDOR: "VENDOR_MANAGEMENT.SEARCH_VENDOR",
    DESCRIPTION_2: "VENDOR_MANAGEMENT.DESCRIPTION_2",
    SEARCH_FIELD: "VENDOR_MANAGEMENT.SEARCH_FIELD",
    EDIT_VENDOR: " VENDOR_MANAGEMENT.EDIT_VENDOR",
    CONTROLLED_ACCESS_TITLE: "VENDOR_MANAGEMENT.CONTROLLED_ACCESS_TITLE",
    LIGHTBULB_ICON_LABEL: "VENDOR_MANAGEMENT.LIGHTBULB_ICON_LABEL",
    VENDOR_MANAGER_LINK_TEXT: "VENDOR_MANAGEMENT.VENDOR_MANAGER_LINK_TEXT",
    VENDOR_MANAGER_LINK_LABEL: "VENDOR_MANAGEMENT.VENDOR_MANAGER_LINK_LABEL",
    SELECT_ALL_APPLY_LABEL: "VENDOR_MANAGEMENT.SELECT_ALL_APPLY_LABEL",
    PARKING_ACCESS_LABEL: "VENDOR_MANAGEMENT.PARKING_ACCESS_LABEL",
    MARINE_GATE_ACCESS_LABEL: "VENDOR_MANAGEMENT.MARINE_GATE_ACCESS_LABEL",
    INTERIOR_ACCESS_LABEL: "VENDOR_MANAGEMENT.INTERIOR_ACCESS_LABEL",
    WIFI_ACCESS_LABEL: "VENDOR_MANAGEMENT.WIFI_ACCESS_LABEL",
    MAIN_TEXT: "VENDOR_MANAGEMENT.MAIN_TEXT",
    CONNECT_TO_VENDOR_TEXT: "VENDOR_MANAGEMENT.CONNECT_TO_VENDOR_TEXT",
    VENDOR_PROFILE: "VENDOR_MANAGEMENT.VENDOR_PROFILE",
    VENDOR_PROFILE_DESCRIPTION: "VENDOR_MANAGEMENT.VENDOR_PROFILE_DESCRIPTION",
    VENDOR_PROFILE_DESCRIPTION_1: "VENDOR_MANAGEMENT.VENDOR_PROFILE_DESCRIPTION_1",
    VENDOR_PROFILE_DESCRIPTION_2: "VENDOR_MANAGEMENT.VENDOR_PROFILE_DESCRIPTION_2",
    VENDOR_PROFILE_DESCRIPTION_3: "VENDOR_MANAGEMENT.VENDOR_PROFILE_DESCRIPTION_3",
    VENDOR_MANAGER: "VENDOR_MANAGEMENT.VENDOR_MANAGER",
    RECOMMENDED_VENDOR: "VENDOR_MANAGEMENT.RECOMMENDED_VENDOR",
    VENDOR_ACCESS_UPDATE: "VENDOR_MANAGEMENT.VENDOR_ACCESS_UPDATE",
    VENDOR_ACCESS_UPDATE_FAILED: "VENDOR_MANAGEMENT.VENDOR_ACCESS_UPDATE_FAILED",
    ACCESS_CONTROLS: "VENDOR_MANAGEMENT.ACCESS_CONTROLS",
    VENDOR_SUCCESSFULLY_INVITED: "VENDOR_MANAGEMENT.VENDOR_SUCCESSFULLY_INVITED",
    SUCCESSFULLY_CONNECTED_EXISTING_VENDOR: "VENDOR_MANAGEMENT.SUCCESSFULLY_CONNECTED_EXISTING_VENDOR",
    VENDOR_SWITCHER: {
      TITLE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.TITLE",

      // STATUS
      VENDOR_ACTIVE_STATUS: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.VENDOR_ACTIVE_STATUS",
      VENDOR_PENDING_STATUS: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.VENDOR_PENDING_STATUS",
      VENDOR_REJECTED_STATUS: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.VENDOR_REJECTED_STATUS",
      VENDOR_CONNECT_STATUS: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.VENDOR_CONNECT_STATUS",

      // MODALS

      PERSONAL_VENDOR_MODAL_MESSAGE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.PERSONAL_VENDOR_MODAL_MESSAGE",
      MANAGE_VENDOR_MODAL_TITLE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.MANAGE_VENDOR_MODAL_TITLE",
      CONNECTED_VENDOR_MODAL_MESSAGE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.CONNECTED_VENDOR_MODAL_MESSAGE",
      ACTIVE_VENDOR_MODAL_MESSAGE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.ACTIVE_VENDOR_MODAL_MESSAGE",
      ACTIVE_VENDOR_MODAL_TITLE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.ACTIVE_VENDOR_MODAL_TITLE",
      YOUR_VENDOR_ACCOUNT_MODAL_MESSAGE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.YOUR_VENDOR_ACCOUNT_MODAL_MESSAGE",
      YOUR_VENDOR_ACCOUNT_MODAL_TITLE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.YOUR_VENDOR_ACCOUNT_MODAL_TITLE",
      CURRENT_VENDOR_MODAL_MESSAGE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.CURRENT_VENDOR_MODAL_MESSAGE",
      CURRENT_VENDOR_MODAL_TITLE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.CURRENT_VENDOR_MODAL_TITLE",
      ACCEPT_REJECT_MODAL_MESSAGE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.ACCEPT_REJECT_MODAL_MESSAGE",
      ACCEPT_REJECT_VENDOR_MODAL_TITLE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.ACCEPT_REJECT_VENDOR_MODAL_TITLE",
      REJECTED_VENDOR_MODAL_MESSAGE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.REJECTED_VENDOR_MODAL_MESSAGE",
      REJECTED_VENDOR_MODAL_TITLE: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.REJECTED_VENDOR_MODAL_TITLE",

      // BUTTONS
      DISCONNECT_BUTTON_TEXT: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.DISCONNECT_BUTTON_TEXT",
      CANCEL_BUTTON_TEXT: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.CANCEL_BUTTON_TEXT",
      SET_AS_CURRENT_BUTTON_TEXT: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.SET_AS_CURRENT_BUTTON_TEXT",
      ACCEPT_BUTTON_TEXT: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.ACCEPT_BUTTON_TEXT",
      REJECT_BUTTON_TEXT: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.REJECT_BUTTON_TEXT",

      SWITCHING: "VENDOR_MANAGEMENT.VENDOR_SWITCHER.SWITCHING",
    },
    VENDOR_ADDED_SUCCESSFLLY: "VENDOR_MANAGEMENT.VENDOR_ADDED_SUCCESSFLLY",
    VENDOR_REMOVED_SUCCESSFLLY: "VENDOR_MANAGEMENT.VENDOR_REMOVED_SUCCESSFLLY",
    VENDOR_CANNOT_BE_REMOVED: "VENDOR_MANAGEMENT.VENDOR_CANNOT_BE_REMOVED",
    NEW_VENDOR_INVITATION_DESCRIPTION: "VENDOR_MANAGEMENT.NEW_VENDOR_INVITATION_DESCRIPTION",
    INVITE_VENDOR_BUTTON_TEXT: "VENDOR_MANAGEMENT.INVITE_VENDOR_BUTTON_TEXT",
    FIND_A_VENDOR_BUTTON_TEXT: "VENDOR_MANAGEMENT.FIND_A_VENDOR_BUTTON_TEXT",
  },
  PACKAGES: {
    TITLE: "PACKAGES.TITLE",
    CONSUMED: "PACKAGES.CONSUMED",
    POPOVER_MESSAGE: "PACKAGES.POPOVER_MESSAGE",
    FULLY_CONSUMED: "PACKAGES.FULLY_CONSUMED",
    NOT_CONSUMED: "PACKAGES.NOT_CONSUMED",
    MAKE_PAYMENT: "PACKAGES.MAKE_PAYMENT",
    ACCEPTED: "PACKAGES.ACCEPTED",
    PAID: "PACKAGES.PAID",
    SEARCH: "PACKAGES.SEARCH",
    SEARCH_PLACEHOLDER: "PACKAGES.SEARCH_PLACEHOLDER",
    VIEW_ALL_BEFORE: "PACKAGES.VIEW_ALL_BEFORE",
    VIEW_ALL_AFTER: "PACKAGES.VIEW_ALL_AFTER",
    NO_PACKAGES_YET: "PACKAGES.NO_PACKAGES_YET",
    DELETE_PACKAGES: "PACKAGES.DELETE_PACKAGES",
    RESTORE_PACKAGES: "PACKAGES.RESTORE_PACKAGES",
    FILTERS: {
      FILTER_RESULTS: "PACKAGES.FILTERS.FILTER_RESULTS",
      RESET_FILTERS: "PACKAGES.FILTERS.RESET_FILTERS",
      VENDOR: "PACKAGES.FILTERS.VENDOR",
      TYPE: "PACKAGES.FILTERS.TYPE",
      APPLY_FILTER: "PROPOSALS.FILTERS.APPLY_FILTER",
      MIN: "PACKAGES.FILTERS.MIN",
      MAX: "PACKAGES.FILTERS.MAX",
    },
  },
  ENTITIES: {
    SERVICE_ORDER: "ENTITIES.SERVICE_ORDER",
    INVOICE: "ENTITIES.INVOICE",
  },
  SUPPORT_HELP: {
    TITLE: "SUPPORT_HELP.TITLE",
    PLACEHOLDER: "SUPPORT_HELP.PLACEHOLDER",
    DESCRIPTION: "SUPPORT_HELP.DESCRIPTION",
    SUBJECT: "SUPPORT_HELP.SUBJECT",
    UPDATED_MESSAGE: "SUPPORT_HELP.UPDATED_MESSAGE",
    UPDATED_ERROR_MESSAGE: "SUPPORT_HELP.UPDATED_ERROR_MESSAGE",
    POPOVER_MESSAGE: "SUPPORT_HELP.POPOVER_MESSAGE",
    UPLOAD_HERE: "SUPPORT_HELP.UPLOAD_HERE",
    CATEGORY: "SUPPORT_HELP.CATEGORY",
  },
  YACHT_PROFILE: {
    TITLE: "YACHT_PROFILE.TITLE",
    POPOVER_MESSAGE: "YACHT_PROFILE.POPOVER_MESSAGE",
    PROGRESS_COUNTER: "YACHT_PROFILE.PROGRESS_COUNTER",
    YOUR_BOAT: "YACHT_PROFILE.YOUR_BOAT",
    ADD_PARKING_LOCATION: "YACHT_PROFILE.ADD_PARKING_LOCATION",
    PARKING_ACCESS_WIFI: "YACHT_PROFILE.PARKING_ACCESS_WIFI",
    PARKING: "YACHT_PROFILE.PARKING",
    PARKING_LOCATION: "YACHT_PROFILE.PARKING_LOCATION",
    PARKING_WIFI_ACCESS_POPOVER: "YACHT_PROFILE.PARKING_WIFI_ACCESS_POPOVER",
    PARKING_WIFI_ACCESS_TIPS: "YACHT_PROFILE.PARKING_WIFI_ACCESS_TIPS",
    PARKING_INSTRUCTION_TIPS: "YACHT_PROFILE.PARKING_INSTRUCTION_TIPS",
    BOAT_SPECIFICS: "YACHT_PROFILE.BOAT_SPECIFICS",
    BOAT_SPECIFICS_POPOVER: "YACHT_PROFILE.BOAT_SPECIFICS_POPOVER",
    YOUR_BOAT_POPOVER: "YACHT_PROFILE.YOUR_BOAT_POPOVER",
    BOAT_FAX: {
      SERVICE_RECORDS: {
        TITLE: "YACHT_PROFILE.BOAT_FAX.SERVICE_RECORDS.TITLE",
        SEARCH_TEXT: "YACHT_PROFILE.BOAT_FAX.SERVICE_RECORDS.SEARCH_TEXT",
      },
    },
    GENERAL_INFORMATION: {
      TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.TITLE",
      BOAT_TRANSFER_TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.BOAT_TRANSFER_TITLE",
      OWNER_INFORMATION: {
        TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.OWNER_INFORMATION.TITLE",
        FIRST_NAME: "YACHT_PROFILE.GENERAL_INFORMATION.OWNER_INFORMATION.FIRST_NAME",
        LAST_NAME: "YACHT_PROFILE.GENERAL_INFORMATION.OWNER_INFORMATION.LAST_NAME",
        PHONE_NUMBER: "YACHT_PROFILE.GENERAL_INFORMATION.OWNER_INFORMATION.PHONE_NUMBER",
        EMAIL: "YACHT_PROFILE.GENERAL_INFORMATION.OWNER_INFORMATION.EMAIL",
      },
      VESSEL_INFORMATION: {
        TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.TITLE",
        YACHT_NAME: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.YACHT_NAME",
        SEARCH_TEXT: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.SEARCH_TEXT",
        VESSEL_TYPE: {
          TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.VESSEL_TYPE.TITLE",
          POWER: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.VESSEL_TYPE.POWER",
          SAIL: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.VESSEL_TYPE.SAIL",
          SEARCH_TEXT: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.VESSEL_TYPE.SEARCH_TEXT",
        },
        FUEL_TYPE: {
          TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.FUEL_TYPE.TITLE",
          DIESEL: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.FUEL_TYPE.DIESEL",
          GASOLINE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.FUEL_TYPE.GASOLINE",
          ELECTRIC: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.FUEL_TYPE.ELECTRIC",
          SEARCH_TEXT: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.FUEL_TYPE.SEARCH_TEXT",
        },
        YACHT_MEASURE: {
          TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.YACHT_MEASURE.TITLE",
          FEET: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.YACHT_MEASURE.FEET",
          METRES: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.YACHT_MEASURE.METRES",
        },
        YEAR_BUILT: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.YEAR_BUILT",
        MANUFACTURER: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.MANUFACTURER",
        SEARCH_MANUFACTURER: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.SEARCH_MANUFACTURER",
        MODEL: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.MODEL",
        SELECT_MODEL: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.SELECT_MODEL",
        DOCUMENT_NUMBER: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.DOCUMENT_NUMBER",
        SLIP_NUMBER: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.SLIP_NUMBER",
        DOCUMENT_NUMBER_POPOVER: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.DOCUMENT_NUMBER_POPOVER",
        TAKE_PICTURE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.TAKE_PICTURE",
        SELECT_PICTURE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.SELECT_PICTURE",
        UPDATED_MESSAGE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_INFORMATION.UPDATED_MESSAGE",
      },
      VESSEL_LOCATION: {
        TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.TITLE",
        MARINA_ADDRESS: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.MARINA_ADDRESS",
        ADDRESS: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.ADDRESS",
        CITY: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.CITY",
        COUNTRY: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.COUNTRY",
        STATE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.STATE",
        ZIP: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.ZIP",
        HAILING_PORT: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.HAILING_PORT",
        SLIP_NO: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.SLIP_NO",
        LONGITUDE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.LONGITUDE",
        LATITUDE: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.LATITUDE",
        PICK_LOCATION: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.PICK_LOCATION",
        SPECIAL_INSTRUCTIONS: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.SPECIAL_INSTRUCTIONS",
        INTRO: "YACHT_PROFILE.GENERAL_INFORMATION.VESSEL_LOCATION.INTRO",
      },
      MY_PACKAGES: {
        TITLE: "YACHT_PROFILE.GENERAL_INFORMATION.MY_PACKAGES.TITLE",
        TITLE_2: "YACHT_PROFILE.GENERAL_INFORMATION.MY_PACKAGES.TITLE_2",
      },
    },
    PARKING_INSTRUCTIONS: {
      TITLE: "YACHT_PROFILE.PARKING_INSTRUCTIONS.TITLE",
      PARKING_INSTRUCTIONS_WIFI_ACCESS: "YACHT_PROFILE.PARKING_INSTRUCTIONS.PARKING_INSTRUCTIONS_WIFI_ACCESS",
      PARKING_LOCATION: "YACHT_PROFILE.PARKING_INSTRUCTIONS.PARKING_LOCATION",
      PARKING_INSTRUCTIONS_TOOLTIP: "YACHT_PROFILE.PARKING_INSTRUCTIONS.PARKING_INSTRUCTIONS_TOOLTIP",
      PARKING_TIPS: "YACHT_PROFILE.PARKING_INSTRUCTIONS.PARKING_TIPS",
      PLACEHOLDER_ONE: "YACHT_PROFILE.PARKING_INSTRUCTIONS.PLACEHOLDER_ONE",
      PLACEHOLDER_TWO: "YACHT_PROFILE.PARKING_INSTRUCTIONS.PLACEHOLDER_TWO",
      PLACEHOLDER_THREE: "YACHT_PROFILE.PARKING_INSTRUCTIONS.PLACEHOLDER_THREE",
      LABEL: "YACHT_PROFILE.PARKING_INSTRUCTIONS.LABEL",
      SPECIAL_INSTRUCTION: "YACHT_PROFILE.PARKING_INSTRUCTIONS.SPECIAL_INSTRUCTION",
      UPDATED_MESSAGE: "YACHT_PROFILE.PARKING_INSTRUCTIONS.UPDATED_MESSAGE",
      UPDATED_ERROR_MESSAGE: "YACHT_PROFILE.PARKING_INSTRUCTIONS.UPDATED_ERROR_MESSAGE",
    },
    ACCESS: {
      TITLE: "YACHT_PROFILE.ACCESS.TITLE",
      MARINE_GATE_ACCESS: "YACHT_PROFILE.ACCESS.MARINE_GATE_ACCESS",
      INTERIOR_VESSEL_ACCESS: "YACHT_PROFILE.ACCESS.INTERIOR_VESSEL_ACCESS",
      KEY_REQUIRED: "YACHT_PROFILE.ACCESS.KEY_REQUIRED",
      GATE_CODE: "YACHT_PROFILE.ACCESS.GATE_CODE",
      PLACEHOLDER_ONE: "YACHT_PROFILE.ACCESS.PLACEHOLDER_ONE",
      PLACEHOLDER_EXAMPLE: "YACHT_PROFILE.ACCESS.PLACEHOLDER_EXAMPLE",
      PLACEHOLDER_TWO: "YACHT_PROFILE.ACCESS.PLACEHOLDER_TWO",
      TOOLTIP: "YACHT_PROFILE.ACCESS.TOOLTIP",
      SPECIAL_INSTRUCTION: "YACHT_PROFILE.ACCESS.SPECIAL_INSTRUCTION",
      UPDATED_MESSAGE: "YACHT_PROFILE.ACCESS.UPDATED_MESSAGE",
      UPDATED_ERROR_MESSAGE: "YACHT_PROFILE.ACCESS.UPDATED_ERROR_MESSAGE",
    },
    WIFI: {
      TITLE: "YACHT_PROFILE.WIFI.TITLE",
      WIFI_INSTRUCTIONS: "YACHT_PROFILE.WIFI.WIFI_INSTRUCTIONS",
      WIFI_INSTRUCTIONS_ONE: "YACHT_PROFILE.WIFI.WIFI_INSTRUCTIONS_ONE",
      WIFI_NAME: "YACHT_PROFILE.WIFI.WIFI_NAME",
      WIFI_PASSWORD: "YACHT_PROFILE.WIFI.WIFI_PASSWORD",
      WIFI_INSTRUCTIONS_PLACEHOLDER: "YACHT_PROFILE.WIFI.WIFI_INSTRUCTIONS_PLACEHOLDER",
      WIFI_POPOVER: "YACHT_PROFILE.WIFI.WIFI_POPOVER",
      UPDATED_MESSAGE: "YACHT_PROFILE.WIFI.UPDATED_MESSAGE",
      UPDATED_ERROR_MESSAGE: "YACHT_PROFILE.WIFI.UPDATED_ERROR_MESSAGE",
    },
    SERVICE_PROVIDERS: {
      TITLE: "YACHT_PROFILE.SERVICE_PROVIDERS.TITLE",
      SEARCH_TEXT: "YACHT_PROFILE.SERVICE_PROVIDERS.SEARCH_TEXT",
      COMPANY: "YACHT_PROFILE.SERVICE_PROVIDERS.COMPANY",
      EMAIL: "YACHT_PROFILE.SERVICE_PROVIDERS.EMAIL",
      TELEPHONE: "YACHT_PROFILE.SERVICE_PROVIDERS.TELEPHONE",
      ADD_PROVIDER: "YACHT_PROFILE.SERVICE_PROVIDERS.ADD_PROVIDER",
    },
    YACHT_SPECIFICS: {
      TITLE: "YACHT_PROFILE.YACHT_SPECIFICS.TITLE",
      OPTIONAL: "YACHT_PROFILE.YACHT_SPECIFICS.OPTIONAL",
      HELP_TEXT: "YACHT_PROFILE.YACHT_SPECIFICS.HELP_TEXT",
      ENGINE: {
        TITLE: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.TITLE",
        INBOARD: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.INBOARD",
        OUTBOARD: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.OUTBOARD",
        NUMBER_OF_ENGINES: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.NUMBER_OF_ENGINES",
        MANU_YEAR: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.MANU_YEAR",
        SEARCH_TEXT: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.SEARCH_TEXT",
        SEARCH_TEXT_ONE: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.SEARCH_TEXT_ONE",
        SEARCH_TEXT_TWO: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.SEARCH_TEXT_TWO",
        HOURS: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.HOURS",
        POPOVER_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.POPOVER_MESSAGE",
        LAST_SERVICE: "YACHT_PROFILE.YACHT_SPECIFICS.ENGINE.LAST_SERVICE",
      },
      GENERATORS: {
        TITLE: "YACHT_PROFILE.YACHT_SPECIFICS.GENERATORS.TITLE",
        NUMBER_OF_GENERATORS: "YACHT_PROFILE.YACHT_SPECIFICS.GENERATORS.NUMBER_OF_GENERATORS",
        MANU_YEAR: "YACHT_PROFILE.YACHT_SPECIFICS.GENERATORS.MANU_YEAR",
        SEARCH_TEXT: "YACHT_PROFILE.YACHT_SPECIFICS.GENERATORS.SEARCH_TEXT",
        HOURS: "YACHT_PROFILE.YACHT_SPECIFICS.GENERATORS.HOURS",
        LAST_SERVICE: "YACHT_PROFILE.YACHT_SPECIFICS.GENERATORS.LAST_SERVICE",
        POPOVER_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.GENERATORS.POPOVER_MESSAGE",
      },
      STABILIZERS: {
        TITLE: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.TITLE",
        NUMBER_OF_ENGINES: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.NUMBER_OF_ENGINES",
        FINS: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.FINS",
        GYRO: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.GYRO",
        MANU_YEAR: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.MANU_YEAR",
        SEARCH_TEXT: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.SEARCH_TEXT",
        HOURS: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.HOURS",
        LAST_SERVICE: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.LAST_SERVICE",
        UPDATED_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.UPDATED_MESSAGE",
        UPDATED_ERROR_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.UPDATED_ERROR_MESSAGE",
        POPOVER_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.STABILIZERS.POPOVER_MESSAGE",
      },
      WATERMAKER: {
        TITLE: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.TITLE",
        MANU_YEAR: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.MANU_YEAR",
        SEARCH_TEXT: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.SEARCH_TEXT",
        HOURS: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.HOURS",
        LAST_SERVICE: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.LAST_SERVICE",
        UPDATED_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.UPDATED_MESSAGE",
        UPDATED_ERROR_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.UPDATED_ERROR_MESSAGE",
        POPOVER_MESSAGE: "YACHT_PROFILE.YACHT_SPECIFICS.WATERMAKER.POPOVER_MESSAGE",
      },
      AREA_OF_VESSEL: {
        TITLE: "YACHT_PROFILE.YACHT_SPECIFICS.AREA_OF_VESSEL.TITLE",
        STATEROOMS: "YACHT_PROFILE.YACHT_SPECIFICS.AREA_OF_VESSEL.STATEROOMS",
        HEADS: "YACHT_PROFILE.YACHT_SPECIFICS.AREA_OF_VESSEL.HEADS",
        AREA_OF_VESSEL: "YACHT_PROFILE.YACHT_SPECIFICS.AREA_OF_VESSEL.AREA_OF_VESSEL",
        SELECT_ALL_THAT_APPLY: "YACHT_PROFILE.YACHT_SPECIFICS.AREA_OF_VESSEL.SELECT_ALL_THAT_APPLY",
        PLACEHOLDER: "YACHT_PROFILE.YACHT_SPECIFICS.AREA_OF_VESSEL.PLACEHOLDER",
      },
    },
    UPDATE_YACHT_LOCATION: "YACHT_PROFILE.UPDATE_YACHT_LOCATION",
    YACHT_LOCATION: "YACHT_PROFILE.YACHT_LOCATION",
    FIND_ON_MAP: {
      TITLE: "YACHT_PROFILE.FIND_ON_MAP.TITLE",
      DESCRIPTION: "YACHT_PROFILE.FIND_ON_MAP.DESCRIPTION",
      ENTER_LATITUDE: "YACHT_PROFILE.FIND_ON_MAP.ENTER_LATITUDE",
      ENTER_LONGITUDE: "YACHT_PROFILE.FIND_ON_MAP.ENTER_LONGITUDE",
      SEARCH_TEXT: "YACHT_PROFILE.FIND_ON_MAP.SEARCH_TEXT",
      SELECT_LOC: "YACHT_PROFILE.FIND_ON_MAP.SELECT_LOC",
      PROVIDE_LOCATION_TITLE: "YACHT_PROFILE.FIND_ON_MAP.PROVIDE_LOCATION_TITLE",
      STEP_ONE: "YACHT_PROFILE.FIND_ON_MAP.STEP_ONE",
      STEP_ONE_TEXT_PARKING: "YACHT_PROFILE.FIND_ON_MAP.STEP_ONE_TEXT_PARKING",
      STEP_ONE_TEXT_BOAT: "YACHT_PROFILE.FIND_ON_MAP.STEP_ONE_TEXT_BOAT",
      STEP_TWO: "YACHT_PROFILE.FIND_ON_MAP.STEP_TWO",
      STEP_TWO_TEXT: "YACHT_PROFILE.FIND_ON_MAP.STEP_TWO_TEXT",
      STEP_THREE: "YACHT_PROFILE.FIND_ON_MAP.STEP_THREE",
      SET_PARKING_LOCATION: "YACHT_PROFILE.FIND_ON_MAP.SET_PARKING_LOCATION",
      SET_BOAT_LOCATION: "YACHT_PROFILE.FIND_ON_MAP.SET_BOAT_LOCATION",
      LOCATION_UNCHARTERED: "YACHT_PROFILE.FIND_ON_MAP.LOCATION_UNCHARTERED",
      SEARCH_LOCATION_LABEL: "YACHT_PROFILE.FIND_ON_MAP.SEARCH_LOCATION_LABEL",
      LAST_SET_BOAT_LOCATION: "YACHT_PROFILE.FIND_ON_MAP.LAST_SET_BOAT_LOCATION",
      MY_LOCATION_LABEL: "YACHT_PROFILE.FIND_ON_MAP.MY_LOCATION_LABEL",
      CANCEL_BUTTON_LABEL: "YACHT_PROFILE.FIND_ON_MAP.CANCEL_BUTTON_LABEL",
      CONFIRM_BUTTON_LABEL: "YACHT_PROFILE.FIND_ON_MAP.CONFIRM_BUTTON_LABEL",
      POPOVER_MESSAGE: "YACHT_PROFILE.FIND_ON_MAP.POPOVER_MESSAGE",
      BOAT_LOCATION_UPDATE: "YACHT_PROFILE.FIND_ON_MAP.BOAT_LOCATION_UPDATE",
      PWA_LOCATION_PERMISSION: "YACHT_PROFILE.FIND_ON_MAP.PWA_LOCATION_PERMISSION",
    },
    ACCESS_TYPE: {
      NONE: "YACHT_PROFILE.ACCESS_TYPE.NONE",
      KEY: "YACHT_PROFILE.ACCESS_TYPE.KEY",
      FOB: "YACHT_PROFILE.ACCESS_TYPE.FOB",
      GATECODE: "YACHT_PROFILE.ACCESS_TYPE.GATECODE",
    },
  },

  LIVE_VIEW: {
    TITLE: "LIVE_VIEW.TITLE",
  },
  BUNDLE: {
    TITLE: "BUNDLE.TITLE",
  },
  ACCOUNT_SETTINGS: {
    TITLE: "ACCOUNT_SETTINGS.TITLE",
    LOGOUT: "ACCOUNT_SETTINGS.LOGOUT",
    TOOLTIP: "ACCOUNT_SETTINGS.TOOLTIP",
    PERSONAL_PROFILE: {
      LABEL: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.LABEL",
      TECHNICIAN: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.TECHNICIAN",
      TITLE: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.TITLE",
      FIRST_NAME: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.FIRST_NAME",
      LAST_NAME: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.LAST_NAME",
      PHONE_NUMBER: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.PHONE_NUMBER",
      COUNTRY: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.COUNTRY",
      STATE: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.STATE",
      CITY: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.CITY",
      TIME_ZONE: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.TIME_ZONE",
      ZIP: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.ZIP",
      ADDRESS: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.ADDRESS",
      EMAIL_ADDRESS: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.EMAIL_ADDRESS",
      PROFILE_UPDATE: "ACCOUNT_SETTINGS.PERSONAL_PROFILE.PROFILE_UPDATE",
    },
    ACCOUNT_SECURITY: {
      LABEL: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.LABEL",
      PASSWORD_INSTRUCTION: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.PASSWORD_INSTRUCTION",
      NEW_PASSWORD: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.NEW_PASSWORD",
      CONFIRM_NEW_PASSWORD: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.CONFIRM_NEW_PASSWORD",
      CURRENT_PASSWORD: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.CURRENT_PASSWORD",
      TWO_FACTOR_AUTHENTICATION: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.TWO_FACTOR_AUTHENTICATION",
      TWO_FACTOR_INSTRUCTION: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.TWO_FACTOR_INSTRUCTION",
      ENABLE: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.ENABLE",
      PHONE_NUMBER: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.PHONE_NUMBER",
      EMAIL_ADDRESS: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.EMAIL_ADDRESS",
      SECURITY_UPDATE: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.SECURITY_UPDATE",
      POPOVER_MESSAGE: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.POPOVER_MESSAGE",
      CONFRIM_PASSWORD_MESSAGE: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.CONFRIM_PASSWORD_MESSAGE",
      DO_NOT_MATCH: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.DO_NOT_MATCH",
      PASSWORD_REQUIRED: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.PASSWORD_REQUIRED",
      PASSWORD_LENGTH: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.PASSWORD_LENGTH",

      DELETE_ACCOUNT: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.DELETE_ACCOUNT",
      DELETE_ACCOUNT_DESCRIPTION: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.DELETE_ACCOUNT_DESCRIPTION",
      READ_OUR: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.READ_OUR",
      PASSWORD_RESET: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.PASSWORD_RESET",
    },
    BILLING_AND_PLANS: {
      LABEL: "ACCOUNT_SETTINGS.BILLING_AND_PLANS.LABEL",
    },
    SUBSCRIPTION: {
      TITLE: "ACCOUNT_SETTINGS.SUBSCRIPTION.TITLE",
      LABEL: "ACCOUNT_SETTINGS.SUBSCRIPTION.LABEL",
      CURRENT_PLAN: "ACCOUNT_SETTINGS.SUBSCRIPTION.CURRENT_PLAN",
      SUBSCRIPTION_TEXT: "ACCOUNT_SETTINGS.SUBSCRIPTION.SUBSCRIPTION_TEXT",
      SUBSCRIPTION_TRIAL_TEXT: "ACCOUNT_SETTINGS.SUBSCRIPTION.SUBSCRIPTION_TRIAL_TEXT",
      BILLING_DATE: "ACCOUNT_SETTINGS.SUBSCRIPTION.BILLING_DATE",
      SWITCH_PLAN: "ACCOUNT_SETTINGS.SUBSCRIPTION.SWITCH_PLAN",
      NOTICE: "ACCOUNT_SETTINGS.SUBSCRIPTION.NOTICE",
    },
    ACCOUNT_MANAGEMENT: {
      LABEL: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.LABEL",
      CREDIT_BALANCE: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.CREDIT_BALANCE",
      ACCOUNT_BALANCE: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.ACCOUNT_BALANCE",
      TOTAL: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TOTAL",
      DEPOSIT: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.DEPOSIT",
      TRANSACTIONS: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TRANSACTIONS",
      NO_DATA: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.NO_DATA",

      TRANSACTION_DETAIL: {
        STATUS: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TRANSACTION_DETAIL.STATUS",
        AMOUNT: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TRANSACTION_DETAIL.AMOUNT",
        TIME: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TRANSACTION_DETAIL.TIME",
        TYPE: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TRANSACTION_DETAIL.TYPE",
        NOTES: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TRANSACTION_DETAIL.NOTES",
        SUCCESS: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.TRANSACTION_DETAIL.SUCCESS",
      },
      MAKE_DEPOSIT: {
        ENTER_DEPOSIT_AMOUNT: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.MAKE_DEPOSIT.ENTER_DEPOSIT_AMOUNT",
        OKAY: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.MAKE_DEPOSIT.OKAY",
        PAY_NOW: "ACCOUNT_SETTINGS.ACCOUNT_MANAGEMENT.MAKE_DEPOSIT.PAY_NOW",
      },
    },
    VESSEL_TRANSFER: {
      LABEL: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.LABEL",
      POPOVER_MESSAGE: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.POPOVER_MESSAGE",
      WELCOME_MESSAGE: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.WELCOME_MESSAGE",
      FIRST_TEXT: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.FIRST_TEXT",
      DESCRIPTION: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.DESCRIPTION",
      STEP_ONE: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.STEP_ONE",
      STEP_2: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.STEP_2",
      STEP_3: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.STEP_3",
      BEGIN: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.BEGIN",
      BEGIN_TRANSFER: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.BEGIN_TRANSFER",
      WARNING_MESSAGE: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.WARNING_MESSAGE",
      START_NEW_TRANSFER: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.START_NEW_TRANSFER",
      SELECT_BOAT_TRANSFER: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.SELECT_BOAT_TRANSFER",
      DEFAULT: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.DEFAULT",
      UPDATED_OWNER_DETAIL: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.UPDATED_OWNER_DETAIL",
      IS_EMAIL_DUPLICATED: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.IS_EMAIL_DUPLICATED",
      SUCCESS_TRANSFER: "ACCOUNT_SETTINGS.VESSEL_TRANSFER.SUCCESS_TRANSFER",
    },
    NOTIFICATIONS: {
      LABEL: "ACCOUNT_SETTINGS.NOTIFICATIONS.LABEL",
      TITLE: "ACCOUNT_SETTINGS.NOTIFICATIONS.TITLE",
      RECEIVE_SMS: "ACCOUNT_SETTINGS.NOTIFICATIONS.RECEIVE_SMS",
      RECEIVE_EMAIL: "ACCOUNT_SETTINGS.NOTIFICATIONS.RECEIVE_EMAIL",
      SERVICE_ORDER: "ACCOUNT_SETTINGS.NOTIFICATIONS.SERVICE_ORDER",
      INVOICES: "ACCOUNT_SETTINGS.NOTIFICATIONS.INVOICES",
      PUSH_NOTIFICATIONS: "ACCOUNT_SETTINGS.NOTIFICATIONS.PUSH_NOTIFICATIONS",
      ENABLE: "ACCOUNT_SETTINGS.ACCOUNT_SECURITY.ENABLE",
      UPDATED_MESSAGE: "ACCOUNT_SETTINGS.NOTIFICATIONS.UPDATED_MESSAGE",
      UPDATED_ERROR_MESSAGE: "ACCOUNT_SETTINGS.NOTIFICATIONS.UPDATED_ERROR_MESSAGE",
      POPOVER_MESSAGE: "ACCOUNT_SETTINGS.NOTIFICATIONS.POPOVER_MESSAGE",
      NEW_MESSAGE_TONE: "ACCOUNT_SETTINGS.NOTIFICATIONS.NEW_MESSAGE_TONE",
    },

    LANGUAGE_SETTINGS: {
      LABEL: "ACCOUNT_SETTINGS.LANGUAGE_SETTINGS.LABEL",
    },

    YACHT_REFERRAL: {
      SEND_REFERRAL: "ACCOUNT_SETTINGS.YACHT_REFERRAL.SEND_REFERRAL",
      MY_ACCOUNT: "ACCOUNT_SETTINGS.YACHT_REFERRAL.MY_ACCOUNT",
      EMAIL_OR_PHONE_NUMBER: "ACCOUNT_SETTINGS.YACHT_REFERRAL.EMAIL_OR_PHONE_NUMBER",
      NAME_OF_REFERRED_YACHT_OWNER: "ACCOUNT_SETTINGS.YACHT_REFERRAL.NAME_OF_REFERRED_YACHT_OWNER",
      REFERRAL_CODE: "ACCOUNT_SETTINGS.YACHT_REFERRAL.REFERRAL_CODE",
      OR: "ACCOUNT_SETTINGS.YACHT_REFERRAL.OR",
      SHARE_QR_CODE: "ACCOUNT_SETTINGS.YACHT_REFERRAL.SHARE_QR_CODE",
    },

    YACHTS_LOCATIONS: "ACCOUNT_SETTINGS.YACHTS_LOCATIONS",
    PROMO_CODE: {
      TITLE: "ACCOUNT_SETTINGS.PROMO_CODE.TITLE",
    },
  },
  MORE_SETTINGS: {
    LANGUAGE: "MORE_SETTINGS.LANGUAGE",
    SETTINGS: "MORE_SETTINGS.SETTINGS",
    SERVICE_PROVIDERS: "MORE_SETTINGS.SERVICE_PROVIDERS",
  },

  APP_TABS: {
    HOME: "APP_TABS.HOME",
    MESSAGES: "APP_TABS.MESSAGES",
    REQUEST_SERVICE: "APP_TABS.REQUEST_SERVICE",
    SERVICE_ORDERS: "APP_TABS.SERVICE_ORDERS",
    CALENDAR: "APP_TABS.CALENDAR",
    TECH_CALENDAR: "APP_TABS.CALENDAR.TITLE",
    MORE: "APP_TABS.MORE",
    SETTINGS: "APP_TABS.SETTINGS",
    BOAT_OWNER_TAB: {
      MY_BOAT: "APP_TABS.BOAT_OWNER_TAB.MY_BOAT",
      VITALS: "APP_TABS.BOAT_OWNER_TAB.VITALS",
      REQUEST: "APP_TABS.BOAT_OWNER_TAB.REQUEST",
      ORDERS: "APP_TABS.BOAT_OWNER_TAB.ORDERS",
      MESSAGES: "APP_TABS.BOAT_OWNER_TAB.MESSAGES",
      WEATHER: "APP_TABS.BOAT_OWNER_TAB.WEATHER",
    },
  },
  PROXIMITY_ALERT: {
    CANCEL: "PROXIMITY_ALERT.CANCEL",
    CONFIRM: "PROXIMITY_ALERT.CONFIRM",
    OUT_OF_RANGE: "PROXIMITY_ALERT.OUT_OF_RANGE",
    CONFIRM_ARRIVAL: "PROXIMITY_ALERT.CONFIRM_ARRIVAL",
    SERVICE_ORDER: "PROXIMITY_ALERT.SERVICE_ORDER",
    SELECT_BOAT: "PROXIMITY_ALERT.SELECT_BOAT",
    TITLE: "PROXIMITY_ALERT.TITLE",
    MESSAGE_SECTION_1: "PROXIMITY_ALERT.MESSAGE_SECTION_1",
    MESSAGE_SECTION_2: "PROXIMITY_ALERT.MESSAGE_SECTION_2",
    MESSAGE_SECTION_3: "PROXIMITY_ALERT.MESSAGE_SECTION_3",
  },

  CALENDAR: {
    TITLE: "CALENDAR.TITLE",
    POPOVER_MESSAGE_2: "CALENDAR.POPOVER_MESSAGE_2",
    HEADER: "CALENDAR.HEADER",
    SCHEDULED_SERVICE: "CALENDAR.SCHEDULED_SERVICE",
    VESSEL_UNAVAILABLE: "CALENDAR.VESSEL_UNAVAILABLE",
    NO_EVENT: "CALENDAR.NO_EVENT",
    SCHEDULED_EVENT: "CALENDAR.SCHEDULED_EVENT",
    VIEW_SERVICE_ORDER: "CALENDAR.VIEW_SERVICE_ORDER",
    ADD_EVENT: "CALENDAR.ADD_EVENT",
    SCHEDULED_SERVICE_ORDER: "CALENDAR.SCHEDULED_SERVICE_ORDER",
    POPOVER_MESSAGE: "CALENDAR.POPOVER_MESSAGE",
    ADD_SERVICE: "CALENDAR.ADD_SERVICE",
    OWNER_OCCUPIED: "CALENDAR.OWNER_OCCUPIED",
    CALENDAR_EVENT: "CALENDAR.CALENDAR_EVENT",
    CALENDER_TITLE: "CALENDAR.CALENDER_TITLE",
    CALENDER_DESCRIPTION: "CALENDAR.CALENDER_DESCRIPTION",
    TYPE: "CALENDAR.TYPE",
    FROM_DATE: "CALENDAR.FROM_DATE",
    TO_DATE: "CALENDAR.TO_DATE",
    FROM_TIME: "CALENDAR.FROM_TIME",
    TO_TIME: "CALENDAR.TO_TIME",
    ADD_UNAVAILABILITY: "CALENDAR.ADD_UNAVAILABILITY",
    SCHEDULED_EVENT_2: "CALENDAR.SCHEDULED_EVENT_2",
    CREATE_EVENT: "CALENDAR.CREATE_EVENT",
    DELETE_EVENT: "CALENDAR.DELETE_EVENT",
    EVENT_DETAILS: "CALENDAR.EVENT_DETAILS",
    EVENT_START: "CALENDAR.EVENT_START",
    EVENT_END: "CALENDAR.EVENT_END",
    EDIT_EVENT: "CALENDAR.EDIT_EVENT",
    SAVE_EVENT: "CALENDAR.SAVE_EVENT",
    ALL_DAY: "CALENDAR.ALL_DAY",
    EXAMPLE: "CALENDAR.EXAMPLE",
    UNAVAILABILITY: "CALENDAR.UNAVAILABILITY",
    TECH_UNAVAILABILITY: "CALENDAR.TECH_UNAVAILABILITY",
    LIGHT_BOX_TEXT: "CALENDAR.LIGHT_BOX_TEXT",
    POPOVER_MESSAGE_3: "CALENDAR.POPOVER_MESSAGE_3",
    BUTTON_TEXT_1: "CALENDAR.BUTTON_TEXT_1",
    BUTTON_TEXT_2: "CALENDAR.BUTTON_TEXT_2",
  },

  TECHNICIAN: {
    BOAT_INSPECTIONS: {
      INSPECTION_NOTES: "TECHNICIAN.BOAT_INSPECTIONS.INSPECTION_NOTES",
      INSPECTION_AREA_POPOVER_MESSAGE: "TECHNICIAN.BOAT_INSPECTIONS.INSPECTION_AREA_POPOVER_MESSAGE",
      INSPECTION_AREA_LIST_POPOVER_MESSAGE: "TECHNICIAN.BOAT_INSPECTIONS.INSPECTION_AREA_LIST_POPOVER_MESSAGE",
      MULTI_POINT_GENERAL_BOAT_INSPECTIONS: {
        POPOVER_MESSAGE: "TECHNICIAN.BOAT_INSPECTIONS.MULTI_POINT_GENERAL_BOAT_INSPECTIONS.POPOVER_MESSAGE",
      },
    },

    SLIDER: {
      QUESTION_1: "TECHNICIAN.SLIDER.QUESTION_1",
      QUESTION_2: "TECHNICIAN.SLIDER.QUESTION_2",
      QUESTION_3: "TECHNICIAN.SLIDER.QUESTION_3",
      QUESTION_4: "TECHNICIAN.SLIDER.QUESTION_4",
    },
    SEARCH_JOBS: {
      SEARCH: "TECHNICIAN.SEARCH_JOBS.SEARCH",
    },
    DASHBOARD: {
      MAP: "TECHNICIAN.DASHBOARD.MAP",
      LIST: "TECHNICIAN.DASHBOARD.LIST",
      START_TIME: "TECHNICIAN.DASHBOARD.START_TIME",
      START_DATE: "TECHNICIAN.DASHBOARD.START_DATE",
      VESSEL: "TECHNICIAN.DASHBOARD.VESSEL",
      LOCATION: "TECHNICIAN.DASHBOARD.LOCATION",
      NO_JOBS_YET: "TECHNICIAN.DASHBOARD.NO_JOBS_YET",
      ASK_CAPTAIN_AI: "TECHNICIAN.DASHBOARD.ASK_CAPTAIN_AI",
      NO_ADDITIONAL_JOBS: "TECHNICIAN.DASHBOARD.NO_ADDITIONAL_JOBS",
      NO_ADDITIONAL_JOBS_MESSAGE: "TECHNICIAN.DASHBOARD.NO_ADDITIONAL_JOBS_MESSAGE",
    },
    SERVICE_ORDER_DETAILS: {
      IMAGES_OF_WORK_IN_PROGRESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.IMAGES_OF_WORK_IN_PROGRESS",
      VESSEL: "TECHNICIAN.SERVICE_ORDER_DETAILS.VESSEL",
      LOCATION: "TECHNICIAN.SERVICE_ORDER_DETAILS.LOCATION",
      PARKING: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING",
      SERVICE_REQUEST: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_REQUEST",
      ORDER: "TECHNICIAN.SERVICE_ORDER_DETAILS.ORDER",
      PARTS_NEEDED: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARTS_NEEDED",
      VIEW: "TECHNICIAN.SERVICE_ORDER_DETAILS.VIEW",
      ORDER_REMINDER: "TECHNICIAN.SERVICE_ORDER_DETAILS.ORDER_REMINDER",
      DOCK_ACCESS_CODE: "TECHNICIAN.SERVICE_ORDER_DETAILS.DOCK_ACCESS_CODE",
      INTERNET_ACCESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.INTERNET_ACCESS",
      DESCRIPTION: "TECHNICIAN.SERVICE_ORDER_DETAILS.DESCRIPTION",
      DESCRIPTION_OF_SERVICE: "TECHNICIAN.SERVICE_ORDER_DETAILS.DESCRIPTION_OF_SERVICE",
      JOB_NOTES: "TECHNICIAN.SERVICE_ORDER_DETAILS.JOB_NOTES",
      POPOVER_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.POPOVER_MESSAGE",
      NO_CHECKLIST_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NO_CHECKLIST_MESSAGE",

      CHECKLIST: {
        TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST.TITLE",
        INSPECTION_FORM: "TECHNICIAN.SERVICE_ORDER_DETAILS.INSPECTION_FORM",
        SAVE: "TECHNICIAN.SERVICE_ORDER_DETAILS.SAVE",
        EDIT_INSPECTION: "TECHNICIAN.SERVICE_ORDER_DETAILS.EDIT_INSPECTION",
        ADD_A_CHECKLIST_ITEM: "TECHNICIAN.SERVICE_ORDER_DETAILS.ADD_A_CHECKLIST_ITEM",
        UPDATE_CHECKLIST: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST.UPDATE_CHECKLIST",
        EDIT_CHECKLIST_POPOVER: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST.EDIT_CHECKLIST_POPOVER",
        CHECKLIST_STARTING_POINT_POPOVER: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST.CHECKLIST_STARTING_POINT_POPOVER",
        SWIPER_CONTENT: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST.SWIPER_CONTENT",
        BOAT_BOT_TIP: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST.BOAT_BOT_TIP",
        LIGHTBULB_ICON_LABEL: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST.LIGHTBULB_ICON_LABEL",
      },
      NOTE: {
        TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.TITLE",
        NOTE_SERVICE_DETAIL: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.NOTE_SERVICE_DETAIL",
        NOTE_SEARCH_TEXT: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.NOTE_SEARCH_TEXT",
        ADD_NOTE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.ADD_NOTE",
        EDIT_NOTE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.EDIT_NOTE",
        NOTE_ITEM_TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.NOTE_ITEM_TITLE",
        NOTE_ITEM_DATE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.NOTE_ITEM_DATE",
        NOTE_ITEM_DESCRIPTION: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.NOTE_ITEM_DESCRIPTION",
        AUTHOR: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.AUTHOR",
        DATETIME: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.DATETIME",
        LAST_EDITED: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.LAST_EDITED",
        DELETE_NOTE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.DELETE_NOTE",
        CONFIRMATION: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.CONFIRMATION",
        NO_DATA: "No Notes Yet",
        POPOVER_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.POPOVER_MESSAGE",
        NO_NOTES_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.NO_NOTES_MESSAGE",
        NO_NOTES_TEXT: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.NO_NOTES_TEXT",
        PRIVATE_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.PRIVATE_MESSAGE",
        VISIBLE_TO_OWNER: "TECHNICIAN.SERVICE_ORDER_DETAILS.NOTE.VISIBLE_TO_OWNER",
      },
      RECOMMENDED_SERVICE: {
        TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.TITLE",
        SERVICE_TO_PERFORM: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.SERVICE_TO_PERFORM",
        VENDOR: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.VENDOR",
        CREATED: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.CREATED",
        RECOMMENDED_SERVICE_DESCRIPTION:
          "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.RECOMMENDED_SERVICE_DESCRIPTION",
        SELECTED_SERVICES: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.SELECTED_SERVICES",
        CHOSE_SERVICE: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.CHOSE_SERVICE",
        REASON: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.REASON",
        RECOMMENDED_SERVICE_SERVICE: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.RECOMMENDED_SERVICE_SERVICE",
        NO_RECOMMENDED_SERVICE: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.NO_RECOMMENDED_SERVICE",
        POPOVER_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.POPOVER_MESSAGE",
        CREATE_RECOMMENDED_SERVICE: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.CREATE_RECOMMENDED_SERVICE",
        SUBMIT_RECOMMENDATIONS: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.SUBMIT_RECOMMENDATIONS",
        UPDATE_RECOMMENDATIONS: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.UPDATE_RECOMMENDATIONS",
        CREATE_POPOVER_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.CREATE_POPOVER_MESSAGE",
        ESTIMATED_HOURS: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.ESTIMATED_HOURS",
        CREATION_SUCCESSFUL: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.CREATION_SUCCESSFUL",
        UPDATE_SUCCESSFUL: "TECHNICIAN.SERVICE_ORDER_DETAILS.RECOMMENDED_SERVICE.UPDATE_SUCCESSFUL",
      },
      BOAT_LOCATION: "TECHNICIAN.SERVICE_ORDER_DETAILS.BOAT_LOCATION",
      PARKING_LOCATION: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_LOCATION",
      BOAT_ACCESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.BOAT_ACCESS",
      NO_DESCRIPTION_TEXT: "TECHNICIAN.SERVICE_ORDER_DETAILS.NO_DESCRIPTION_TEXT",

      CHECKLIST_TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.CHECKLIST_TITLE",
      WORK_HISTORY: "TECHNICIAN.SERVICE_ORDER_DETAILS.WORK_HISTORY",
      REC_SERVICE: "TECHNICIAN.SERVICE_ORDER_DETAILS.REC_SERVICE",

      YACHT_WORK_HISTORY: {
        TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.TITLE",
        YACHT_WORK_HISTORY_SERVICE_DETAIL:
          "TECHNICIAN.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.YACHT_WORK_HISTORY_SERVICE_DETAIL",
        YACHT_WORK_HISTORY_SEARCH_TEXT:
          "TECHNICIAN.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.YACHT_WORK_HISTORY_SEARCH_TEXT",
        ITEM_TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.ITEM_TITLE",
        NO_DATA: "TECHNICIAN.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.NO_DATA",
        ITEM_DATE: "TECHNICIAN.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.ITEM_DATE",
        POPOVER_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.YACHT_WORK_HISTORY.POPOVER_MESSAGE",
      },
      UPDATE_WORK_STATUS: {
        TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.TITLE",
        OPEN: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.OPEN",
        IN_PROGRESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.IN_PROGRESS",
        COMPLETE: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.COMPLETE",
        BLOCK: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.BLOCK",
        STATUS_UPDATE: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.STATUS_UPDATE",
        SWIPER_CONTENT: {
          TEXT_1: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.SWIPER_CONTENT.TEXT_1",
          TEXT_2: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.SWIPER_CONTENT.TEXT_2",
          TEXT_3: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.SWIPER_CONTENT.TEXT_3",
        },
        DESCRIPTION_PLACEHOLDER_1: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.DESCRIPTION_PLACEHOLDER_1",
        DESCRIPTION_PLACEHOLDER_2: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.DESCRIPTION_PLACEHOLDER_2",
        STATUS_NOTE: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.STATUS_NOTE",
        NOTE_LIST: {
          NOTE_1: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.NOTE_LIST.NOTE_1",
          NOTE_2: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.NOTE_LIST.NOTE_2",
          NOTE_3: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.NOTE_LIST.NOTE_3",
          NOTE_4: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.NOTE_LIST.NOTE_4",
        },
        POPOVER_MESSAGE: "TECHNICIAN.SERVICE_ORDER_DETAILS.UPDATE_WORK_STATUS.POPOVER_MESSAGE",
      },
      SERVICE_ORDER_STATUS: {
        SCHEDULED: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_ORDER_STATUS.SCHEDULED",
        IN_PROGRESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_ORDER_STATUS.IN_PROGRESS",
        COMPLETED: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_ORDER_STATUS.COMPLETED",
        ON_HOLD: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_ORDER_STATUS.ON_HOLD",
        PENDING: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_ORDER_STATUS.PENDING",
        REJECTED: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_ORDER_STATUS.REJECTED",
        APPROVED: "TECHNICIAN.SERVICE_ORDER_DETAILS.SERVICE_ORDER_STATUS.APPROVED",
      },
      GET_DIRECTION: {
        GET_DIRECTION: "TECHNICIAN.SERVICE_ORDER_DETAILS.GET_DIRECTION.GET_DIRECTION",
        NO_LOCATION: "TECHNICIAN.SERVICE_ORDER_DETAILS.GET_DIRECTION.NO_LOCATION",
      },
      PARKING_ACCESS: {
        TITLE: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.TITLE",
        PARKING_INSTRUCTIONS: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.PARKING_INSTRUCTIONS",
        PARKING: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.PARKING",
        NONE: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.NONE",
        NO_INSTRUCTION: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.NO_INSTRUCTION",
        MARINE_GATE_ACCESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.MARINE_GATE_ACCESS",
        ACCESS_TYPE: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.ACCESS_TYPE",
        GATE_CODE: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.GATE_CODE",
        NO_MARINE: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.NO_MARINE",
        INTERIOR_ACCESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.INTERIOR_ACCESS",
        NO_INTERIOR_ACCESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.NO_INTERIOR_ACCESS",
        WIFI_ACCESS: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.WIFI_ACCESS",
        WIFI_NAME: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.WIFI_NAME",
        WIFI_PASSWORD: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.WIFI_PASSWORD",
        NO_WIFI: "TECHNICIAN.SERVICE_ORDER_DETAILS.PARKING_ACCESS.NO_WIFI",
      },
    },
    INSPECTIONS: {
      TITLE: "TECHNICIAN.INSPECTIONS.TITLE",
      TITLE2: "TECHNICIAN.INSPECTIONS.TITLE2",
      POPOVER_MESSAGE1: "TECHNICIAN.INSPECTIONS.POPOVER_MESSAGE1",
      POPOVER_MESSAGE2: "TECHNICIAN.INSPECTIONS.POPOVER_MESSAGE2",
      POPOVER_MESSAGE3: "TECHNICIAN.INSPECTIONS.POPOVER_MESSAGE3",
      POPOVER_MESSAGE4: "TECHNICIAN.INSPECTIONS.POPOVER_MESSAGE4",
      BUTTON_TEXT1: "TECHNICIAN.INSPECTIONS.BUTTON_TEXT1",
      BUTTON_TEXT2: "TECHNICIAN.INSPECTIONS.BUTTON_TEXT2",
      GENERAL_SUBHEADER_TEXT: "TECHNICIAN.INSPECTIONS.GENERAL_SUBHEADER_TEXT",
      GENERAL_HEADER: "TECHNICIAN.INSPECTIONS.GENERAL_HEADER",
      GENERAL_SUBHEADER_TEXT1: "TECHNICIAN.INSPECTIONS.GENERAL_SUBHEADER_TEXT1",
      GENERAL_SUBHEADER_TEXT2: "TECHNICIAN.INSPECTIONS.GENERAL_SUBHEADER_TEXT2",
      HULL_SUBHEADER_TEXT: "TECHNICIAN.INSPECTIONS.HULL_SUBHEADER_TEXT",
      HULL_HEADER: "TECHNICIAN.INSPECTIONS.HULL_HEADER",
      HULL_SUBHEADER_TEXT1: "TECHNICIAN.INSPECTIONS.HULL_SUBHEADER_TEXT1",
      HULL_SUBHEADER_TEXT2: "TECHNICIAN.INSPECTIONS.HULL_SUBHEADER_TEXT2",
      EXTERIOR_HEADER_TEXT: "TECHNICIAN.INSPECTIONS.EXTERIOR_HEADER_TEXT",
      EXTERIOR_LIGHTBULB: "TECHNICIAN.INSPECTIONS.EXTERIOR_LIGHTBULB",
      INSPECTION_TYPE_LIGHTBULB: "TECHNICIAN.INSPECTIONS.INSPECTION_TYPE_LIGHTBULB",
      EXTERIOR_SUBHEADER_TEXT: "TECHNICIAN.INSPECTIONS.EXTERIOR_SUBHEADER_TEXT",
      MULTI_HEADER_TEXT: "TECHNICIAN.INSPECTIONS.MULTI_HEADER_TEXT",
      MULTI_SUBHEADER_TEXT: "TECHNICIAN.INSPECTIONS.MULTI_SUBHEADER_TEXT",
      COMPLETE_INSPECTION_MESSAGE: "TECHNICIAN.INSPECTIONS.COMPLETE_INSPECTION_MESSAGE",
      YES: "TECHNICIAN.INSPECTIONS.YES",
      NO: "TECHNICIAN.INSPECTIONS.NO",

      VISUAL_INSPECTION: {
        TITLE: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.TITLE",
        NOTES_HEADER_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.NOTES_HEADER_TEXT",
        NOTES_SUBHEADER_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.NOTES_SUBHEADER_TEXT",
        NOTE_PLACEHOLDER: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.NOTE_PLACEHOLDER",
        DONE_BUTTON_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.DONE_BUTTON_TEXT",
        CANCEL_BUTTON_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.CANCEL_BUTTON_TEXT",
        COMPLETE_OPTION_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.COMPLETE_OPTION_TEXT",
        DELETE_NOTE_OPTION_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.DELETE_NOTE_OPTION_TEXT",
        DELETE_NOTE_MESSAGE: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.DELETE_NOTE_MESSAGE",
        SAVE_OPTION_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.SAVE_OPTION_TEXT",
        QA_OPTION_TEXT: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.QA_OPTION_TEXT",
        EDIT_NOTE: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.EDIT_NOTE",
        DELETE_NOTE: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.DELETE_NOTE",
        LABEL: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.LABEL",
        AREA: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.AREA",
        LIGHTBULB: "TECHNICIAN.INSPECTIONS.VISUAL_INSPECTION.LIGHTBULB",
      },
    },
  },

  AUTHENTICATION: {
    LOGIN: "AUTHENTICATION.LOGIN",
    LOGOUT: "AUTHENTICATION.LOGOUT",
    REGISTER: "AUTHENTICATION.REGISTER",
    LOGIN_TITLE: "AUTHENTICATION.LOGIN_TITLE",
    LOGIN_DESCRIPTION: "AUTHENTICATION.LOGIN_DESCRIPTION",
    FORGOT: "AUTHENTICATION.FORGOT",
    REMEMBER_ME: "AUTHENTICATION.REMEMBER_ME",
    NO_ACCOUNT: "AUTHENTICATION.NO_ACCOUNT",
    SOCIALAUTH_NO_USER_FOUND: "AUTHENTICATION.SOCIALAUTH_NO_USER_FOUND",
  },

  ACTION_BUTTONS: {
    SAVE: "ACTION_BUTTONS.SAVE",
    UPDATE: "ACTION_BUTTONS.UPDATE",
    CANCEL: "ACTION_BUTTONS.CANCEL",
    CLOSE: "ACTION_BUTTONS.CLOSE",
    SUBMIT: "ACTION_BUTTONS.SUBMIT",
    CONFIRM: "ACTION_BUTTONS.CONFIRM",
    CREATE: "ACTION_BUTTONS.CREATE",
    DELETE: "ACTION_BUTTONS.DELETE",
    NEXT: "ACTION_BUTTONS.NEXT",
    YES: "ACTION_BUTTONS.YES",
    NO: "ACTION_BUTTONS.NO",
    OK: "ACTION_BUTTONS.OK",
    SEND: "ACTION_BUTTONS.SEND",
    SIGNIN: "ACTION_BUTTONS.SIGNIN",
    SIGNIN_WITH: "ACTION_BUTTONS.SIGNIN_WITH",
    SIGNUP: "ACTION_BUTTONS.SIGNUP",
    SAVE_PASSWORD: "ACTION_BUTTONS.SAVE_PASSWORD",
    MODAL_CLOSE_CONFIRMATION: "ACTION_BUTTONS.MODAL_CLOSE_CONFIRMATION",
    DELETE_CONFIRMATION: "ACTION_BUTTONS.DELETE_CONFIRMATION",
    DELETE_CONFIRMATION_INVOICE: "ACTION_BUTTONS.DELETE_CONFIRMATION_INVOICE",
  },
  BO_SERVICE_ORDER_ITEMS: {
    CHECKLIST: "BO_SERVICE_ORDER_ITEMS.CHECKLIST",
    CHECKLIST_POPOVER: "BO_SERVICE_ORDER_ITEMS.CHECKLIST_POPOVER",
  },
  CAPTAIN_TALK: {
    BASE_TWO_LAYOUT: {
      HELP: "CAPTAIN_TALK.BASE_TWO_LAYOUT.HELP",
      SERVICE_REQUEST_TEXT: "CAPTAIN_TALK.BASE_TWO_LAYOUT.SERVICE_REQUEST_TEXT",
    },
    TOAST_MESSAGES:{
      COPIED: "CAPTAIN_TALK.TOAST_MESSAGES.COPIED",
      CAPTAIN_AI_ERROR: "CAPTAIN_TALK.TOAST_MESSAGES.CAPTAIN_AI_ERROR",
      RETRY: "CAPTAIN_TALK.TOAST_MESSAGES.RETRY",
    },
    RETURN_TO_DASHBOARD: "CAPTAIN_TALK.RETURN_TO_DASHBOARD",
    DISCLAIMER: "CAPTAIN_TALK.DISCLAIMER",
    LOGIN: {
      TITLE: "CAPTAIN_TALK.LOGIN.TITLE",
      CELL_PHONE_TAB_TITLE: "CAPTAIN_TALK.LOGIN.CELL_PHONE_TAB_TITLE",
      EMAIL_TAB_TITLE: "CAPTAIN_TALK.LOGIN.EMAIL_TAB_TITLE",

      EMAIL: "CAPTAIN_TALK.LOGIN.EMAIL",
      PHONE_NUMBER: "CAPTAIN_TALK.LOGIN.PHONE_NUMBER",

      PASSWORD: "CAPTAIN_TALK.LOGIN.PASSWORD",
      SIGN_IN_BUTTON_TEXT: "CAPTAIN_TALK.LOGIN.SIGN_IN_BUTTON_TEXT",
      VALID_PASSWORD: "CAPTAIN_TALK.LOGIN.VALID_PASSWORD",
      WELCOME_BACK: "CAPTAIN_TALK.LOGIN.WELCOME_BACK",
    },
    PROFILE_SETUP: {
      TITLE: "CAPTAIN_TALK.PROFILE_SETUP.TITLE",
    },
    NAME_SETUP: {
      TITLE: "CAPTAIN_TALK.NAME_SETUP.TITLE",
      FIRST_NAME: "CAPTAIN_TALK.NAME_SETUP.FIRST_NAME",
      GET_STARTED: "CAPTAIN_TALK.NAME_SETUP.GET_STARTED",
    },
    INTRO: {
      TEXT_ONE: "CAPTAIN_TALK.INTRO.TEXT_ONE",
      TEXT_TWO: "CAPTAIN_TALK.INTRO.TEXT_TWO",
      TEXT_THREE: "CAPTAIN_TALK.INTRO.TEXT_THREE",
      TEXT_FOUR: "CAPTAIN_TALK.INTRO.TEXT_FOUR",
    },
    DASHBOARD: {
      WELCOME_TITLE: "CAPTAIN_TALK.DASHBOARD.WELCOME_TITLE",
      GET_STARTED: "CAPTAIN_TALK.DASHBOARD.GET_STARTED",
      WELCOME_MESSAGE: "CAPTAIN_TALK.DASHBOARD.WELCOME_MESSAGE",
      SELECT_OPTION_TEXT: "CAPTAIN_TALK.DASHBOARD.SELECT_OPTION_TEXT",
      ASK_CAPTAIN_AI: "CAPTAIN_TALK.DASHBOARD.ASK_CAPTAIN_AI",
      ASK_CAPTAIN_AI_DESC: "CAPTAIN_TALK.DASHBOARD.ASK_CAPTAIN_AI_DESC",
      SETUP_BOAT_PROFILE: "CAPTAIN_TALK.DASHBOARD.SETUP_BOAT_PROFILE",
      SETUP_BOAT_PROFILE_DESC: "CAPTAIN_TALK.DASHBOARD.SETUP_BOAT_PROFILE_DESC",
      RETURN_TO_DASHBOARD: "CAPTAIN_TALK.DASHBOARD.RETURN_TO_DASHBOARD",
    },
    ASK_CAPTAIN_AI: {
      DESCRIPTION: "CAPTAIN_TALK.ASK_CAPTAIN_AI.DESCRIPTION",
      SELECT_OPTION_TEXT: "CAPTAIN_TALK.ASK_CAPTAIN_AI.SELECT_OPTION_TEXT",
      DIY_TROUBLESHOOTING: "CAPTAIN_TALK.ASK_CAPTAIN_AI.DIY_TROUBLESHOOTING",
      DIY_REPAIR: "CAPTAIN_TALK.ASK_CAPTAIN_AI.DIY_REPAIR",
      DIY_MAINTENANCE: "CAPTAIN_TALK.ASK_CAPTAIN_AI.DIY_MAINTENANCE",
      MORE_FIND_SERVICE_PROVIDER: "CAPTAIN_TALK.ASK_CAPTAIN_AI.MORE_FIND_SERVICE_PROVIDER",
      MORE_FIND_VIDEOS: "CAPTAIN_TALK.ASK_CAPTAIN_AI.MORE_FIND_VIDEOS",
      MORE_AI_PART_FINDER: "CAPTAIN_TALK.ASK_CAPTAIN_AI.MORE_AI_PART_FINDER",
      MORE_REQUEST_SERVICE: "CAPTAIN_TALK.ASK_CAPTAIN_AI.MORE_REQUEST_SERVICE",
      MORE_TILE_TEXT: "CAPTAIN_TALK.ASK_CAPTAIN_AI.MORE_TILE_TEXT",
      DIY_TITLE_TEXT: "CAPTAIN_TALK.ASK_CAPTAIN_AI.DIY_TITLE_TEXT",
      ACTION_BUTTONS: {
        TALK: "CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.TALK",
        STOP: "CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.STOP",
        CAMERA: "CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.CAMERA",
        KEYBOARD: "CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.KEYBOARD",
        TYPE_OR_TALK: "CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.TYPE_OR_TALK",
        LISTENING_TEXT: "CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.LISTENING_TEXT",
      },
      SUGGESTED_QUESTION_TEXT: "CAPTAIN_TALK.ASK_CAPTAIN_AI.SUGGESTED_QUESTION_TEXT",
      CHAT_QUICK_LINKS: {
        DOCK_AND_DINE: "CAPTAIN_TALK.ASK_CAPTAIN_AI.CHAT_QUICK_LINKS.DOCK_AND_DINE",
        CATALINA_ISLAND: "CAPTAIN_TALK.ASK_CAPTAIN_AI.CHAT_QUICK_LINKS.CATALINA_ISLAND",
        RULES_OF_THE_BOOK: "CAPTAIN_TALK.ASK_CAPTAIN_AI.CHAT_QUICK_LINKS.RULES_OF_THE_BOOK",
        RADIO_CHANNEL: "CAPTAIN_TALK.ASK_CAPTAIN_AI.CHAT_QUICK_LINKS.RADIO_CHANNEL",
        ANCHOR_PROPERLY: "CAPTAIN_TALK.ASK_CAPTAIN_AI.CHAT_QUICK_LINKS.ANCHOR_PROPERLY",
        BEST_DIVE_SPOTS: "CAPTAIN_TALK.ASK_CAPTAIN_AI.CHAT_QUICK_LINKS.BEST_DIVE_SPOTS",
        WAYS_TO_AVOID_SEASICKNESS: "CAPTAIN_TALK.ASK_CAPTAIN_AI.CHAT_QUICK_LINKS.WAYS_TO_AVOID_SEASICKNESS",
      },
    },
    AI_ALERT: {
      TITLE: "CAPTAIN_TALK.AI_ALERT.TITLE",
      DESCRIPTION_ONE: "CAPTAIN_TALK.AI_ALERT.DESCRIPTION_ONE",
      DESCRIPTION_TWO: "CAPTAIN_TALK.AI_ALERT.DESCRIPTION_TWO",
      DESCRIPTION_THREE: "CAPTAIN_TALK.AI_ALERT.DESCRIPTION_THREE",
      BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.BUTTON_TEXT",
      SKILL_LEVEL: {
        TITLE: "CAPTAIN_TALK.AI_ALERT.SKILL_LEVEL.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.SKILL_LEVEL.DESCRIPTION",
        BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.SKILL_LEVEL.BUTTON_TEXT",
      },
      SIGN_UP_NOW: {
        TITLE: "CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.DESCRIPTION",
        BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.BUTTON_TEXT",
        BUTTON_TEXT_TWO: "CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.BUTTON_TEXT_TWO",
      },
      WELCOME_GUIDE: {
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.WELCOME_GUIDE.DESCRIPTION",
        GET_STARTED_TEXT: "CAPTAIN_TALK.AI_ALERT.WELCOME_GUIDE.GET_STARTED_TEXT",
      },
      POSITIVE_FEEDBACK: {
        TITLE: "CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.DESCRIPTION",
        DASHBOARD_BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.DASHBOARD_BUTTON_TEXT",
        STAY_HERE_BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.STAY_HERE_BUTTON_TEXT",
      },
      REQUEST_SERVICE: {
        TITLE: "CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.DESCRIPTION",
        REQUEST_SERVICE_BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.REQUEST_SERVICE_BUTTON_TEXT",
        NO_THANKS_BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.NO_THANKS_BUTTON_TEXT",
      },
      LOGOUT: {
        TITLE: "CAPTAIN_TALK.AI_ALERT.LOGOUT.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.LOGOUT.DESCRIPTION",
        LOGOUT_BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.LOGOUT.LOGOUT_BUTTON_TEXT",
        CANCEL_BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.LOGOUT.CANCEL_BUTTON_TEXT",
      },
      MANUAL_PREDICTION_TOAST: "CAPTAIN_TALK.AI_ALERT.MANUAL_PREDICTION_TOAST",
      LAST_SERVICE_DATE: {
        TITLE: "CAPTAIN_TALK.AI_ALERT.LAST_SERVICE_DATE.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.LAST_SERVICE_DATE.DESCRIPTION",
        BUTTON_TEXT: "CAPTAIN_TALK.AI_ALERT.LAST_SERVICE_DATE.BUTTON_TEXT",
      },
      HOW_TO_USE_AI_IDENTIFIER: {
        TITLE: "CAPTAIN_TALK.AI_ALERT.HOW_TO_USE_AI_IDENTIFIER.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.AI_ALERT.HOW_TO_USE_AI_IDENTIFIER.DESCRIPTION",
        STEP_ONE_TEXT: "CAPTAIN_TALK.AI_ALERT.HOW_TO_USE_AI_IDENTIFIER.STEP_ONE_TEXT",
        STEP_ONE_TEXT_LOCATE: "CAPTAIN_TALK.AI_ALERT.HOW_TO_USE_AI_IDENTIFIER.STEP_ONE_TEXT_LOCATE",
        STEP_TWO_TEXT: "CAPTAIN_TALK.AI_ALERT.HOW_TO_USE_AI_IDENTIFIER.STEP_TWO_TEXT",
        STEP_THREE_TEXT: "CAPTAIN_TALK.AI_ALERT.HOW_TO_USE_AI_IDENTIFIER.STEP_THREE_TEXT",
        BASE_TEXT: "CAPTAIN_TALK.AI_ALERT.HOW_TO_USE_AI_IDENTIFIER.BASE_TEXT",
      },
    },
    TROUBLESHOOTING: {
      TITLE: "CAPTAIN_TALK.TROUBLESHOOTING.TITLE",
      SYSTEM_TO_TROUBLESHOOT_QUESTION: "CAPTAIN_TALK.TROUBLESHOOTING.SYSTEM_TO_TROUBLESHOOT_QUESTION",
      SYSTEM_SYMPTOMS_TEXT: "CAPTAIN_TALK.TROUBLESHOOTING.SYSTEM_SYMPTOMS_TEXT",
      STEP_TITLE: "CAPTAIN_TALK.TROUBLESHOOTING.TITLE",
      STEP_SYSTEM_TEXT: "CAPTAIN_TALK.TROUBLESHOOTING.STEP_SYSTEM_TEXT",
      STEP_ISSUE_TEXT: "CAPTAIN_TALK.TROUBLESHOOTING.STEP_ISSUE_TEXT",
      STEP_TOP_STEPS_TITLE: "CAPTAIN_TALK.TROUBLESHOOTING.STEP_TOP_STEPS_TITLE",
      ADDITIONAL_TIPS_TEXT: "CAPTAIN_TALK.TROUBLESHOOTING.ADDITIONAL_TIPS_TEXT",
      PROBLEM_TITLE: "CAPTAIN_TALK.TROUBLESHOOTING.PROBLEM_TITLE",
      SOLUTION_TITLE: "CAPTAIN_TALK.TROUBLESHOOTING.SOLUTION_TITLE",
      DIY_VIDEOS_TEXT: "CAPTAIN_TALK.TROUBLESHOOTING.DIY_VIDEOS_TEXT",
    },
    FEEDBACK: {
      TITLE: "CAPTAIN_TALK.FEEDBACK.TITLE",
      DESCRIPTION: "CAPTAIN_TALK.FEEDBACK.DESCRIPTION",
      BUTTON_FIXED_TEXT: "CAPTAIN_TALK.FEEDBACK.BUTTON_FIXED_TEXT",
      BUTTON_NOT_FIXED_TEXT: "CAPTAIN_TALK.FEEDBACK.BUTTON_NOT_FIXED_TEXT",
      FEEDBACK_GIVEN_TEXT: "CAPTAIN_TALK.FEEDBACK.FEEDBACK_GIVEN_TEXT",
      QUESTION : "CAPTAIN_TALK.FEEDBACK.QUESTION",
    },
    REPAIR: {
      TITLE: "CAPTAIN_TALK.REPAIR.TITLE",
      SYSTEM_TO_REPAIR_TEXT: "CAPTAIN_TALK.REPAIR.SYSTEM_TO_REPAIR_TEXT",
      REPAIR_PROCESS_TEXT: "CAPTAIN_TALK.REPAIR.REPAIR_PROCESS_TEXT",
      REPAIR_DETAILS_REPAIR_SERVICE_TEXT: "CAPTAIN_TALK.REPAIR.REPAIR_DETAILS_REPAIR_SERVICE_TEXT",
      SKILLS_LEVEL_TEXT: "CAPTAIN_TALK.REPAIR.SKILLS_LEVEL_TEXT",
      PART_HEADER_TEXT: "CAPTAIN_TALK.REPAIR.PART_HEADER_TEXT",
      PROCESS_HEADER_TEXT: "CAPTAIN_TALK.REPAIR.PROCESS_HEADER_TEXT",
      WHY_HEADER_TEXT: "CAPTAIN_TALK.REPAIR.WHY_HEADER_TEXT",
    },
    MAINTENANCE: {
      TITLE: "CAPTAIN_TALK.MAINTENANCE.TITLE",
      SYSTEM_TO_MAINTENANCE_TEXT: "CAPTAIN_TALK.MAINTENANCE.SYSTEM_TO_MAINTENANCE_TEXT",
      MAINTENANCE_SCHEDULE_TEXT: "CAPTAIN_TALK.MAINTENANCE.MAINTENANCE_SCHEDULE_TEXT",
      MAINTENANCE_DETAILS_SCHEDULE_TEXT: "CAPTAIN_TALK.MAINTENANCE.MAINTENANCE_DETAILS_SCHEDULE_TEXT",
      PART_HEADER_TEXT: "CAPTAIN_TALK.MAINTENANCE.PART_HEADER_TEXT",
      STEP_HEADER_TEXT: "CAPTAIN_TALK.MAINTENANCE.STEP_HEADER_TEXT",
    },
    FIND_SERVICE_PROVIDER: {
      TITLE: "CAPTAIN_TALK.FIND_SERVICE_PROVIDER.TITLE",

      ALERT_INFOR: {
        TITLE: "CAPTAIN_TALK.FIND_SERVICE_PROVIDER.ALERT_INFOR.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.FIND_SERVICE_PROVIDER.ALERT_INFOR.DESCRIPTION",
        DESCRIPTION_TWO: "CAPTAIN_TALK.FIND_SERVICE_PROVIDER.ALERT_INFOR.DESCRIPTION_TWO",
        BUTTON_TEXT: "CAPTAIN_TALK.FIND_SERVICE_PROVIDER.ALERT_INFOR.BUTTON_TEXT",
      },
      REQUEST_A_SERVICE: "CAPTAIN_TALK.FIND_SERVICE_PROVIDER.REQUEST_A_SERVICE",
    },
    VALIDATE_USER: {
      TITLE: "CAPTAIN_TALK.VALIDATE_USER.TITLE",
      DESCRIPTION: "CAPTAIN_TALK.VALIDATE_USER.DESCRIPTION",
      DESCRIPTION_TWO: "CAPTAIN_TALK.VALIDATE_USER.DESCRIPTION_TWO",
      RESEND_BUTTON_TEXT: "CAPTAIN_TALK.VALIDATE_USER.RESEND_BUTTON_TEXT",
      RESEND_INSTRUCTIONS: "CAPTAIN_TALK.VALIDATE_USER.RESEND_INSTRUCTIONS",
      RESEND_INSTRUCTIONS_TWO: "CAPTAIN_TALK.VALIDATE_USER.RESEND_INSTRUCTIONS_TWO",
      CONTINUE_BUTTON_TEXT: "CAPTAIN_TALK.VALIDATE_USER.CONTINUE_BUTTON_TEXT",
    },
    BOAT_PROFILE_SETUP: {
      TITLE: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.TITLE",
      OPTIONAL_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.OPTIONAL_TEXT",
      DOCUMENT_NUMBER_LABEL_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.DOCUMENT_NUMBER_LABEL_TEXT",
      DOCUMENT_NUMBER_LABEL_VALIDATION_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.DOCUMENT_NUMBER_LABEL_VALIDATION_TEXT",
      INFO_QUESTION_ONE: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.INFO_QUESTION_ONE",
      SUBMIT_BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SUBMIT_BUTTON_TEXT",
      HULL_IDENTIFICATION_INFORMATIION: {
        TITLE: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.HULL_IDENTIFICATION_INFORMATIION.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.HULL_IDENTIFICATION_INFORMATIION.DESCRIPTION",
        BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.HULL_IDENTIFICATION_INFORMATIION.BUTTON_TEXT",
      },
      ADD_BOAT_ENGINE: {
        TITLE_ONE: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.ADD_BOAT_ENGINE.TITLE_ONE",
        TITLE_TWO: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.ADD_BOAT_ENGINE.TITLE_TWO",
        ENGINE_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.ADD_BOAT_ENGINE.ENGINE_TEXT",
        ENGINE_TYPE_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.ADD_BOAT_ENGINE.ENGINE_TYPE_TEXT",
        NUMBER_OF_ENGINES_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.ADD_BOAT_ENGINE.NUMBER_OF_ENGINES_TEXT",
        FUEL_TYPE_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.ADD_BOAT_ENGINE.FUEL_TYPE_TEXT",
      },
      SYSTEM_AND_KEY_PARTS: {
        SYSTEMS_TITLE_ONE: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_AND_KEY_PARTS.SYSTEMS_TITLE_ONE",
        SYSTEMS_TITLE_TWO: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_AND_KEY_PARTS.SYSTEMS_TITLE_TWO",
        CHIPS_TITLE: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_AND_KEY_PARTS.CHIPS_TITLE",
        ADD_SYSTEM_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_AND_KEY_PARTS.ADD_SYSTEM_TEXT",
        ADD_SYSTEM_DESCRIBE_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_AND_KEY_PARTS.ADD_SYSTEM_DESCRIBE_TEXT",
        ADD_BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_AND_KEY_PARTS.ADD_BUTTON_TEXT",
      },
      SYSTEM_TYPES: {
        ENGINE: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.ENGINE",
        GENERATOR: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.GENERATOR",
        STABILIZATION: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.STABILIZATION",
        ACHEAT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.ACHEAT",
        WATERMAKER: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.WATERMAKER",
        REFRIGERATOR: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.REFRIGERATOR",
        ICEMAKER: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.ICEMAKER",
        VACUUMTOILET: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.VACUUMTOILET",
        ZINCS: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SYSTEM_TYPES.ZINCS",
      },
      NEXT_BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.NEXT_BUTTON_TEXT",
      SKIP_BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.SKIP_BUTTON_TEXT",
      CONFIRM_BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE_SETUP.CONFIRM_BUTTON_TEXT",
    },
    SEARCH_DB: {
      TITLE: "CAPTAIN_TALK.SEARCH_DB.TITLE",
      DESCRIPTION: "CAPTAIN_TALK.SEARCH_DB.DESCRIPTION",
    },
    BOAT_PROFILE: {
      TITLE: "CAPTAIN_TALK.BOAT_PROFILE.TITLE",
      DESCRIPTION: "CAPTAIN_TALK.BOAT_PROFILE.DESCRIPTION",
      ALERT_INFO: {
        TITLE: "CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.TITLE",
        DESCRIPTION: "CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.DESCRIPTION",
        GET_STARTED_BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.GET_STARTED_BUTTON_TEXT",
        NO_THANKS_BUTTON_TEXT: "CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.NO_THANKS_BUTTON_TEXT",
      },
    },
    YACHT_PROFILE: {
      MENU_LABELS: {
        SERVICE_ORDER_LABEL: "CAPTAIN_TALK.YACHT_PROFILE.MENU_LABELS.SERVICE_ORDER_LABEL",
        BOAT_VITALS_LABEL: "CAPTAIN_TALK.YACHT_PROFILE.MENU_LABELS.BOAT_VITALS_LABEL",
        BOAT_SPECIFICS_LABEL: "CAPTAIN_TALK.YACHT_PROFILE.MENU_LABELS.BOAT_SPECIFICS_LABEL",
        BOAT_LOCATION_LABEL: "CAPTAIN_TALK.YACHT_PROFILE.MENU_LABELS.BOAT_LOCATION_LABEL",
        BOAT_FAX_LABEL: "CAPTAIN_TALK.YACHT_PROFILE.MENU_LABELS.BOAT_FAX_LABEL",
        PARKING_ACCESSWIFI_LABEL: "CAPTAIN_TALK.YACHT_PROFILE.MENU_LABELS.PARKING_ACCESSWIFI_LABEL",
        PARKING_LABEL: "CAPTAIN_TALK.YACHT_PROFILE.MENU_LABELS.PARKING_LABEL",
      },
      BOAT_SPECIFICS: {
        DESCRIPTION: "CAPTAIN_TALK.YACHT_PROFILE.BOAT_SPECIFICS.DESCRIPTION",
      },
    },
    WEATHER: {
      COMING_SOON_TEXT: "CAPTAIN_TALK.WEATHER.COMING_SOON_TEXT",
      DESCRIPTION: "CAPTAIN_TALK.WEATHER.DESCRIPTION",
    },
    ADD_SYSTEM_SCREEN_LABELS: {
      DESCRIPTION: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.DESCRIPTION",
      SERIAL_NUMBER_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.SERIAL_NUMBER_LABEL",
      SERIAL_NUMBER_VALIDATION_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.SERIAL_NUMBER_VALIDATION_LABEL",

      SYSTEM_NAME_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.SYSTEM_NAME_LABEL",
      NUMBER_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.NUMBER_LABEL",

      SYSTEM_TYPE_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.SYSTEM_TYPE_LABEL",
      SYSTEM_TYPE_VALIDATION_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.SYSTEM_TYPE_VALIDATION_LABEL",

      WHY_IMPORTANT_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.WHY_IMPORTANT_LABEL",

      EXAMPLE_LABEL: "CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.EXAMPLE_LABEL",
    },
    SYSTEM_IDENTIFIER: {
      HEADER_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.HEADER_TEXT",
      WELCOME_HEADER_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.WELCOME_HEADER_TEXT",
      DESCRIPTION: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.DESCRIPTION",
      MOST_ACCURATE_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.MOST_ACCURATE_TEXT",
      OPTION_ONE_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.OPTION_ONE_TEXT",
      OPTION_ONE_DESCRIPTION: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.OPTION_ONE_DESCRIPTION",
      TAKE_PHOTO_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.TAKE_PHOTO_TEXT",
      UPLOAD_PHOTO_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.UPLOAD_PHOTO_TEXT",
      DONT_LIKE_TECH_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.DONT_LIKE_TECH_TEXT",
      PROCESS: {
        HEADER: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.PROCESS.HEADER",
        DESCRIPTION: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.PROCESS.DESCRIPTION",
      },
      CONFIRMATION: {
        DESCRIPTION: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.CONFIRMATION.DESCRIPTION",
      },
      INTRO: {
        PERCENTAGE_OF_COMPLETION_TEXT_ONE: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.PERCENTAGE_OF_COMPLETION_TEXT_ONE",
        PERCENTAGE_OF_COMPLETION_TEXT_TWO: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.PERCENTAGE_OF_COMPLETION_TEXT_TWO",
        HEADER: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.HEADER",
        DESCRIPTION: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.DESCRIPTION",
        SEE_EXAMPLE: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.SEE_EXAMPLE",
        SELECT_OPTION_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.SELECT_OPTION_TEXT",

        MOST_ACCURATE: {
          HEADER: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.MOST_ACCURATE.HEADER",
          TITLE: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.MOST_ACCURATE.TITLE",
          DESCRIPTION: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.MOST_ACCURATE.DESCRIPTION",
        },

        LESS_ACCURATE: {
          HEADER: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.LESS_ACCURATE.HEADER",
          TITLE: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.LESS_ACCURATE.TITLE",
          DESCRIPTION: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.LESS_ACCURATE.DESCRIPTION",
        },

        GET_STARTED_BUTTON_TEXT: "CAPTAIN_TALK.SYSTEM_IDENTIFIER.INTRO.GET_STARTED_BUTTON_TEXT",
      },
    },
    VENDOR_SELECT: {
      DESCRIPTION: "CAPTAIN_TALK.VENDOR_SELECT.DESCRIPTION",
      DIFFERENCE_TEXT: "CAPTAIN_TALK.VENDOR_SELECT.DIFFERENCE_TEXT",

      NO_VENDOR_TEXT: "CAPTAIN_TALK.VENDOR_SELECT.NO_VENDOR_TEXT",

      INVITATION_BUTTON_TEXT: "CAPTAIN_TALK.VENDOR_SELECT.INVITATION_BUTTON_TEXT",
    },
    TOAST_MESSAGE: {
      SYSTEM_SAVE_SUCCESS: "CAPTAIN_TALK.TOAST_MESSAGE.SYSTEM_SAVE_SUCCESS",
      SYSTEM_SAVE_ERROR: "CAPTAIN_TALK.TOAST_MESSAGE.SYSTEM_SAVE_ERROR",

      SYSTEM_UPDATE_SUCCESS: "CAPTAIN_TALK.TOAST_MESSAGE.SYSTEM_UPDATE_SUCCESS",
      SYSTEM_UPDATE_ERROR: "CAPTAIN_TALK.TOAST_MESSAGE.SYSTEM_UPDATE_ERROR",

      UPDATED_SUCCESS: "CAPTAIN_TALK.TOAST_MESSAGE.UPDATED_SUCCESS",
      UPDATED_ERROR: "CAPTAIN_TALK.TOAST_MESSAGE.UPDATED_ERROR",

      SYSTEM_ADD_SUCCESS: "CAPTAIN_TALK.TOAST_MESSAGE.SYSTEM_ADD_SUCCESS",
      SYSTEM_ADD_ERROR: "CAPTAIN_TALK.TOAST_MESSAGE.SYSTEM_ADD_ERROR",
    },
  },
  BOAT_FAX: {
    REQUIRE_IMMEDIATE_ATTENTION_LABEL: "BOAT_FAX.REQUIRE_IMMEDIATE_ATTENTION_LABEL",
    ALL_VITALS_GOOD_LABEL: "BOAT_FAX.ALL_VITALS_GOOD_LABEL",
    VIATLS_REQUIRE_ATTENTION_LABEL: "BOAT_FAX.VIATLS_REQUIRE_ATTENTION_LABEL",
    INSPECTION_REPORTS_LABEL: "BOAT_FAX.INSPECTION_REPORTS_LABEL",
    RECOMMENDED_SERVICES_LABEL: "BOAT_FAX.RECOMMENDED_SERVICES_LABEL",
    SERVICE_HISTORY_LABEL: "BOAT_FAX.SERVICE_HISTORY_LABEL",
    COST_OF_SERVICE_LABEL: "BOAT_FAX.COST_OF_SERVICE_LABEL",

    ATTENTION: {
      HEADER_TEXT: "BOAT_FAX.ATTENTION.HEADER_TEXT",
      DO_NOT_PANIC_HEADER_TEXT: "BOAT_FAX.ATTENTION.DO_NOT_PANIC_HEADER_TEXT",
      DO_NOT_PANIC_DESCRIPTION_TEXT: "BOAT_FAX.ATTENTION.DO_NOT_PANIC_DESCRIPTION_TEXT",

      INSPECTION_REPORT_TEXT: "BOAT_FAX.ATTENTION.INSPECTION_REPORT_TEXT",
      STABILIZATION_TEXT: "BOAT_FAX.ATTENTION.STABILIZATION_TEXT",
      SERVICE_DUE_DATE_TEXT: "BOAT_FAX.ATTENTION.SERVICE_DUE_DATE_TEXT",
    },

    INSPECTION_REPORTS: {
      ALL_TIME_TEXT: "BOAT_FAX.INSPECTION_REPORTS.ALL_TIME_TEXT",
      TO_PRESENT_TEXT: "BOAT_FAX.INSPECTION_REPORTS.TO_PRESENT_TEXT",
      NO_INSPECTION_REPORTS_HEADER_TEXT: "BOAT_FAX.INSPECTION_REPORTS.NO_INSPECTION_REPORTS_HEADER_TEXT",
      NO_INSPECTION_REPORTS_DESCRIPTION_TEXT: "BOAT_FAX.INSPECTION_REPORTS.NO_INSPECTION_REPORTS_DESCRIPTION_TEXT",
      FOR_SELECTED_RANGE_TEXT: "BOAT_FAX.INSPECTION_REPORTS.FOR_SELECTED_RANGE_TEXT",
    },

    SERVICE_COST: {
      TITLE: "BOAT_FAX.SERVICE_COST.TITLE",
      NO_SERVICE_COST_TEXT: "BOAT_FAX.SERVICE_COST.NO_SERVICE_COST_TEXT",
      NO_SERVICE_COST_DESCRIPTION_TEXT: "BOAT_FAX.SERVICE_COST.NO_SERVICE_COST_DESCRIPTION_TEXT",

      SERVICE_COST_FILTER: {
        HEADER: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.HEADER",
        DESCRIPTION: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.DESCRIPTION",
        START_DATE_LABEL: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.START_DATE_LABEL",
        END_DATE_LABEL: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.END_DATE_LABEL",
        SERVICE_TYPE_LABEL: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.SERVICE_TYPE_LABEL",
        GROUP_BY_LABEL: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.GROUP_BY_LABEL",

        CLEAR_BUTTON_TEXT: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.CLEAR_BUTTON_TEXT",

        APPLY_BUTTON_TEXT: "BOAT_FAX.SERVICE_COST.SERVICE_COST_FILTER.APPLY_BUTTON_TEXT",
      },
    },
  },
  JOB_NOTES: {
    DESCRIPTION: "JOB_NOTES.DESCRIPTION",
    REVISE: "JOB_NOTES.REVISE",
    NO_JOB_NOTES: "JOB_NOTES.NO_JOB_NOTES",
    TEXT_FIELD_EMPTY: "JOB_NOTES.TEXT_FIELD_EMPTY",
    NOTE_DELETED: "JOB_NOTES.NOTE_DELETED",
    NOTE_DELETION_ERROR: "JOB_NOTES.NOTE_DELETION_ERROR",
    DELETE_ATTACHMENT_CONFIRM_TEXT: "JOB_NOTES.DELETE_ATTACHMENT_CONFIRM_TEXT",
    DELETE_ATTACHMENT_MESSAGE: "JOB_NOTES.DELETE_ATTACHMENT_MESSAGE",
    ATTACHMENT_DELETED: "JOB_NOTES.ATTACHMENT_DELETED",
    FAILED_TO_DELETE_ATTACHMENT: "JOB_NOTES.FAILED_TO_DELETE_ATTACHMENT",
    NO_NOTES_MESSAGE: "JOB_NOTES.NO_NOTES_MESSAGE"
  }
};
