import {
  Component,
  forwardRef,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  Output,
  OnInit,
  AfterViewInit,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";

import { ModalController } from "@ionic/angular";
import { CaptainAiTalkComponent } from "../captain-ai-talk/captain-ai-talk.component";
import { TalkService } from "src/app/shared/captain-ai-talk/talk.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { ToastService } from "../message/toast.service-impl";
import { Subscription } from "rxjs";
import { BaseService } from "../base.service";
import { Keyboard } from "@capacitor/keyboard";

@Component({
  selector: "app-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextareaComponent),
    },
  ],
})
export class TextareaComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild("textarea") textarea: ElementRef;

  @Input() hasCaptainAi = true;
  @Input() disabled = false;
  @Input() label: string;
  @Input() enablePlaceholder = true;
  @Input() placeholder: string;
  @Input() showCancelButton = false;
  @Input() rows = 1;
  @Input() icon = {
    name: "pen",
    size: "2xl",
    color: "brandDeepSeaBlue",
  };
  @Input() enterKeyHint = "enter";
  @Input() tabindex = 0;
  public control = new FormControl();
  @Input() set validators(validators: any) {
    this.control.setValidators(validators);
  }

  @Input() customErrorMessages: { [key: string]: string };
  @Input() showRevise = true;
  @Input() showVoice = true;

  @Input() scrollToView = false;

  @Output() keyUp: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() textareaFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() textareaBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() iconClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  allowKeyboardHide = true;
  public isFocused = false;
  private onChange: any = () => {
    //
  };
  onTouched: any = () => {
    //
  };
  isProcessing = false;
  response = "";
  subscriptionScroll: Subscription | undefined;

  constructor(
    private modalCtrl: ModalController,
    private talkService: TalkService,
    private toastService: ToastService,
    private baseService: BaseService
  ) {
    this.subscriptionScroll = this.baseService.response$.subscribe((response) => {
      console.log("Received response:", response);
      if (this.allowKeyboardHide && response === "scroll") {
        Keyboard.hide();
      }
    });

    this.isFocused = Boolean(this.control.value) || Boolean(this.placeholder);
    this.control.valueChanges.subscribe((value) => {
      this.isFocused = Boolean(value) || this.isFocused || Boolean(this.placeholder);
    });
  }

  ngOnInit(): void {}

  id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (Object.prototype.hasOwnProperty.call(this.control.errors, propertyName) && this.control.touched) {
        return this.getErrorMessage(propertyName);
      }
    }

    return null;
  }

  private getErrorMessage(errorName: string) {
    const defaultConfig = {
      required: "This field is required.",
      minlength: `Minimum length is ${this.control.errors["minlength"]?.requiredLength}`,
    };

    const config = { ...defaultConfig, ...this.customErrorMessages };

    return config[errorName];
  }

  ngAfterViewInit() {
    this.adjustTextareaHeight();

    if (this.scrollToView) {
      console.log("scrollToView", this.scrollToView);
      this.textarea.nativeElement.addEventListener("focus", this.scrollToElement);
    }
  }

  scrollToElement(event: FocusEvent) {
    setTimeout(() => {
      const target = event.target as HTMLElement;
      console.log({ target });
      target.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 1200);
  }

  onInput() {
    this.onChange(this.textarea.nativeElement.value);
    this.adjustTextareaHeight();
  }

  onFocus() {
    this.allowKeyboardHide = false;
    this.textareaFocus.emit();
    this.isFocused = true;
    this.adjustTextareaHeight();
  }

  onBlur() {
    this.allowKeyboardHide = true;
    this.textareaBlur.emit();

    if (!this.placeholder && this.textarea.nativeElement.value === "") {
      this.isFocused = false;
    }
  }

  onLabelClick() {
    this.isFocused = true;
    this.textarea.nativeElement.focus(); // Focus the textarea
  }

  handleIconClick() {
    this.isFocused = true;
    this.textarea.nativeElement.focus();
    this.iconClick.emit();
  }
  handleCancelClick() {
    this.cancelClick.emit();
  }

  reviseDescription() {
    const val = this.control.value; // Get the value of the textarea

    if (!val) {
      this.toastService.showToast("The text field cannot be empty.", 3000, "top", null, "danger");
      return;
    }

    this.isProcessing = true;
    const userID: any = localStorage.getItem("sessionData");
    const jsonData = JSON.parse(userID);
    const parsedUserId = jsonData?.result.userId;
    const payload = {
      prompt: val,
      userId: parsedUserId,
    };

    this.talkService.revise(payload).subscribe((data) => {
      this.isProcessing = false;
      this.response = data.response.message.trim();
      this.control.setValue(this.response); // Set the value of the textarea
      this.response = "";
    });
  }

  openCaptainAITalk() {
    const modal = this.modalCtrl
      .create({
        component: CaptainAiTalkComponent,
        componentProps: {
          data: {
            popoverMessage:
              "Write your descriptions or utilize our speech-to-text functionality by clicking on the microphone icon, which also provides the convenience of automatic language translation as well as can rewrite the message more professionally.",
            value: this.control.value,
            placeholder: this.placeholder,
            title: this.label,
          },
        },
      })
      .then((modal) => {
        modal.onDidDismiss().then((result) => {
          if (result.data) {
            this.control.setValue(result.data);
          }
        });

        modal.present();
      });
  }

  private adjustTextareaHeight() {
    const textareaEl = this.textarea.nativeElement;
    textareaEl.style.height = "auto"; // Reset height to auto so that it can shrink if needed
    const newHeight = Math.min(textareaEl.scrollHeight, 200); // Ensure the height doesn't exceed 200px
    textareaEl.style.height = `${newHeight}px`; // Set height
  }

  writeValue(value: any): void {
    this.control.setValue(value);
    this.isFocused = Boolean(value) || this.isFocused || Boolean(this.placeholder);
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
  }
}
