import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    OnDestroy,
} from "@angular/core";
import videojs from "video.js";
import { ModalController } from "@ionic/angular";
import { UtilityService } from "../utility.service";
import { Subscription } from "rxjs";
@Component({
    selector: "app-video-guide-player",
    templateUrl: "./video-guide-player.component.html",
    styleUrls: ["./video-guide-player.component.css"],
})
export class VideoGuidePlayerComponent implements OnInit, OnDestroy {
    @ViewChild("target", { static: true }) target: ElementRef;
    @Input() options = {
        autoplay: false,
        controls: true,
        fluid: true,
        muted: false,
        nativeControlsForTouch: true,
        aspectRatio: "16:9",
        overrideNative: true,
        sources: [
            {
                src: "",
                type: "video/mp4",
            },
        ],
    };
    @Input() videoId: string;
    videoURLS = [];
    player: any;
    subscription: Subscription;

    constructor(
        private modalCtrl: ModalController,
        private utilityService: UtilityService
    ) {}

    ngOnInit(): void {
        this.subscription = this.utilityService.guideVideos$.subscribe(
            (videos) => {
                console.log('videos', videos);
                if (videos) {
                    this.videoURLS = videos;
                    this.options.sources[0].src = this.getVideoUrl();
                    this.player = videojs(
                        this.target.nativeElement,
                        this.options
                    );
                }
            }
        );
    }

    getVideoUrl() {
        return this.videoURLS[this.videoId];
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        if (this.player) {
            this.player.dispose();
        }
    }

    close() {
        this.modalCtrl.dismiss();
    }
}
