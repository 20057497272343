import { EventEmitter, Injectable } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import "cordova-plugin-purchase/www/store.d";
import { HttpClient } from "@angular/common/http";
import { retry } from "rxjs";
import { EnvironmentService } from "src/app/environment.service";
import { ToastService } from "../message/toast.service-impl";
import { AccountSubscriptionSelectComponent } from "src/app/yacht-owner/account-subscriptions/account-subscription-select/account-subscription-select.component";
import { GoogleAnalyticsCustomService } from "src/app/services/google-analytics.service";
declare const CdvPurchase: any;
const BASIC_PRODUCT_KEY = "Owner_Basic_id";
const PRO_PRODUCT_KEY = "Owner_Pro_id";
const ANDROID_PRO_PRODUCT_KEY = "owner_pro_id";
const ANDROID_BASIC_PRODUCT_KEY = "owner_basic_id";
let PRODUCT_KEY = "";

@Injectable({
  providedIn: "root",
})
export class InAppService {
  purchaseEvent: EventEmitter<{ plan: string }> = new EventEmitter();
  onPurchaseDone: EventEmitter<any> = new EventEmitter();
  onRestorePurchase: EventEmitter<any> = new EventEmitter();
  isPurchasing: EventEmitter<any> = new EventEmitter();
  onFetchedPurchaseInfo: EventEmitter<any> = new EventEmitter();
  purchasedProduct: string;
  purchaseToken: any;
  // offerCode: any;
  isSubscribed = false;
  expirationTime: any;
  isReceiptVerified = false;
  isInitialbuy = true;
  purchaseId: any;
  userId: any;
  fromPurchase = false;
  isVerifyingReceipt = false;
  subscriptionSource = "";
  subscriptionState = "";
  isExpired = false;

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private env: EnvironmentService,
    private toastService: ToastService,
    private alertCtrl: AlertController,
    private modalCrtl: ModalController,
    private googleAnalyticsCustomService: GoogleAnalyticsCustomService
  ) {
    this.purchaseEvent.subscribe(async (ev: { plan: string }) => {
      // // this.offerCode = ev.androidOfferCode;
      this.isPurchasing.next(true);
      if (ev.plan == "PRO") {
        PRODUCT_KEY = this.platform.is("ios")
          ? PRO_PRODUCT_KEY
          : ANDROID_PRO_PRODUCT_KEY;
      } else {
        PRODUCT_KEY = this.platform.is("ios")
          ? BASIC_PRODUCT_KEY
          : ANDROID_BASIC_PRODUCT_KEY;
      }
      if (this.platform.is("cordova")) {
        this._Purchase();
      }
    });

    const sessionData: any = localStorage.getItem("sessionData");
    const user_data = JSON.parse(sessionData);
    this.userId = user_data?.result.userId;
    this.onRestorePurchase.subscribe((ev: any) => {
      if (this.platform.is("cordova")) {
        // this.restorePurchase();
      }
    });
  }

  public hasActiveSubscription() {
    return true    // TODO: Subscription reactivation
    if (
      this.isSubscribed &&
      (this.subscriptionState == "ACTIVE" ||
        this.subscriptionState == "TRIAL") &&
      !this.isExpired
    ) {
      return true;
    } else {
      return false;
    }
  }

  async openAccountSubscriptionsModal(action: string) {
    const modal = await this.modalCrtl.create({
      component: AccountSubscriptionSelectComponent,
      componentProps: {
        action,
      },
    });

    modal.present();
  }

  public async initialize() {
    if (!this.platform.is("cordova")) {
      return;
    }
    this.setListeners();
    this.registerProducts();

    CdvPurchase.store.initialize([
      {
        platform: this.platform.is("ios")
          ? CdvPurchase.Platform.APPLE_APPSTORE
          : CdvPurchase.Platform.GOOGLE_PLAY,
        options: {
          needAppReceipt: true,
          discountEligibilityDeterminer:
            CdvPurchase.Iaptic.appStoreDiscountEligibilityDeterminer,
        },
      },
      {
        platform: CdvPurchase.Platform.GOOGLE_PLAY,
        options: {
          showAds: false,
        },
      },
      // other platforms...
    ]);

    CdvPurchase.store.localReceipts.forEach((receipt) => {
      receipt.verify();
    });

    // [&]
    this.getSavedUserSubscriptionInformation();

    // CdvPurchase.store.localTransactions.forEach((t) => {
    //     if (t.platform === CdvPurchase.Platform.APPLE_APPSTORE) {
    //         const appleTransaction =
    //             t as CdvPurchase.AppleAppStore.SKTransaction;
    //         appleTransaction.originalTransactionId;
    //     }
    // });
  }

  private registerProducts() {
    CdvPurchase.store.register([
      {
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        id: PRO_PRODUCT_KEY,
        platform: CdvPurchase.Platform.APPLE_APPSTORE,
      },
      {
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        id: BASIC_PRODUCT_KEY,
        platform: CdvPurchase.Platform.APPLE_APPSTORE,
      },
      {
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        id: ANDROID_PRO_PRODUCT_KEY,
        platform: CdvPurchase.Platform.GOOGLE_PLAY,
      },
      {
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        id: ANDROID_BASIC_PRODUCT_KEY,
        platform: CdvPurchase.Platform.GOOGLE_PLAY,
      },
    ]);
  }

  public async getProducts() {
    if (!this.platform.is("cordova")) {
      return;
    }
    const products = await CdvPurchase.store.products;
    return products;
  }

  async _Purchase() {
    // const store: CdvPurchase.Store = CdvPurchase.Store;
    const myProduct = CdvPurchase.store.get(
      PRODUCT_KEY,
      this.platform.is("ios")
        ? CdvPurchase.Platform.APPLE_APPSTORE
        : CdvPurchase.Platform.GOOGLE_PLAY
    );

    if (myProduct) {
      this.isPurchasing.next(true);
      this.fromPurchase = true;

      try {
        let offers = await myProduct.getOffer();
        if (this.platform.is("android")) {
          // offers = this.offerCode;
        }

        // const result = await offers.order();
        const orderOption = this.purchaseToken
          ? {
            googlePlay: {
              prorationMode:
                this.purchasedProduct.toLocaleLowerCase() == ANDROID_BASIC_PRODUCT_KEY.toLocaleLowerCase()
                  ? CdvPurchase.GooglePlay.ProrationMode
                    .IMMEDIATE_AND_CHARGE_PRORATED_PRICE
                  : CdvPurchase.GooglePlay.ProrationMode.DEFERRED,
              oldPurchaseToken: this.purchaseToken,
            },
          }
          : {
            googlePlay: {
              prorationMode:
                CdvPurchase.GooglePlay.ProrationMode
                  .IMMEDIATE_AND_CHARGE_PRORATED_PRICE,
            },
          };
        const result = await CdvPurchase.store.order(offers, orderOption);

        if (result?.isError) {
          this.isPurchasing.next(false);
          this.toastService.showToast(
            "Unable to purchase this plan. Please try again later."
          );
        } else {
          // this.isPurchasing.next(false);
          // this.onPurchaseDone.next({ success: true });
          this.isReceiptVerified = false;
        }
      } catch (error) {
        this.isPurchasing.next(false);
      }
    } else {
      console.log("No product info");
    }

    CdvPurchase.store.localTransactions.findIndex(
      (transaction: CdvPurchase.Transaction) => {
        transaction.state === CdvPurchase.TransactionState;
      }
    );
  }

  async showAlert(message: string = "Error completing your purchase") {
    const alert = await this.alertCtrl.create({
      header: "Subscription",
      message,
      buttons: [
        {
          text: "Manage",
          handler: () => {
            this.manageSubscription();
          },
        },
        {
          text: "OK",
          role: "cancel",
          handler: () => {
            //   console.log('Confirm Okay');
          },
        },
      ],
    });

    await alert.present();
  }

  manageSubscription() {
    CdvPurchase.store.manageSubscriptions(
      this.platform.is("ios")
        ? CdvPurchase.Platform.APPLE_APPSTORE
        : CdvPurchase.Platform.GOOGLE_PLAY
    );
  }

  private setListeners() {
    CdvPurchase.store
      .when()
      .approved(async (transaction) => {

        console.log("approved=====>", transaction);
        if (this.platform.is("android")) {
          this.purchaseToken = transaction.nativePurchase.purchaseToken;
        }

        let receipt = "";
        if (
          CdvPurchase.store.owned(BASIC_PRODUCT_KEY) ||
          CdvPurchase.store.owned(PRO_PRODUCT_KEY) ||
          CdvPurchase.store.owned(ANDROID_BASIC_PRODUCT_KEY) ||
          CdvPurchase.store.owned(ANDROID_PRO_PRODUCT_KEY)
        ) {
          if (this.platform.is("ios")) {
            receipt = transaction?.parentReceipt?.nativeData?.appStoreReceipt;
          } else {
            receipt = transaction?.nativePurchase?.purchaseToken;
          }
          console.log("receipt=====>", receipt);
          console.log("is verifying receipt=====>", this.isVerifyingReceipt);
          if (!this.isVerifyingReceipt && receipt) {

            try {
              await this.verifyPurchaseReceipt(receipt);
            } catch (error) {
              console.log("error from purchase=====>", error);
              if (this.fromPurchase) {
                console.log("error from purchase=====>", error);
                this.showAlert(error.error.error.message);
              }
              this.isPurchasing.next(false);
              this.fromPurchase = false;
            }
          }

        }

        transaction.finish();
      })
      .updated((p: CdvPurchase.Product) => {
        if (p.owned) {
          console.log("the product is owned=====>", p);
        }
      })
      .verified(async (receipt: CdvPurchase.VerifiedReceipt) => {
        receipt.finish();
      })
      .pending(async (transaction: CdvPurchase.Transaction) => {
        transaction.verify();
      })
      .unverified(async (receipt: CdvPurchase.UnverifiedReceipt) => {
        const code = receipt.receipt.finish();
      })
      .receiptsReady(async (receipt: any) => {
        this.isPurchasing.next(false);
      })

      .finished(async (transaction) => {
        let receipt = "";
        if (
          CdvPurchase.store.owned(BASIC_PRODUCT_KEY) ||
          CdvPurchase.store.owned(PRO_PRODUCT_KEY) ||
          CdvPurchase.store.owned(ANDROID_BASIC_PRODUCT_KEY) ||
          CdvPurchase.store.owned(ANDROID_PRO_PRODUCT_KEY)
        ) {
          if (this.platform.is("ios")) {
            receipt = transaction?.parentReceipt?.nativeData?.appStoreReceipt;
          } else {
            receipt = transaction?.nativePurchase?.purchaseToken;
          }
          if (!this.isVerifyingReceipt && receipt) {
            try {
              await this.verifyPurchaseReceipt(receipt);
            } catch (error) {
              console.log("error from purchase=====>", error);
              if (this.fromPurchase) {
                console.log("error from purchase=====>", error);
                this.showAlert(error.error.error.message);
              }
              this.fromPurchase = false;
              this.isPurchasing.next(false);
            }
          }
        }
      })
      .receiptUpdated((r: CdvPurchase.Receipt) => this.receiptUpdated(r))
      .productUpdated((p: CdvPurchase.Product) => this.productUpdated(p));

    CdvPurchase.store.error((err: any) => {
      console.error("Store Error " + JSON.stringify(err));
    });
    CdvPurchase.store.ready(() => {
      console.log("Store is ready");
      CdvPurchase.store.restorePurchases();
    });
  }

  async receiptUpdated(receiptData) {
    let receipt = "";
    if (
      CdvPurchase.store.owned(BASIC_PRODUCT_KEY) ||
      CdvPurchase.store.owned(PRO_PRODUCT_KEY) ||
      CdvPurchase.store.owned(ANDROID_BASIC_PRODUCT_KEY) ||
      CdvPurchase.store.owned(ANDROID_PRO_PRODUCT_KEY)
    ) {
      if (this.platform.is("ios")) {
        receipt = receiptData?.nativeData?.appStoreReceipt;
      } else {
        receipt = receiptData?.nativePurchase?.purchaseToken;
      }
      if (!this.isVerifyingReceipt && receipt) {
        try {
          await this.verifyPurchaseReceipt(receipt);
        } catch (error) {
          if (this.fromPurchase) {
            console.log("error from purchase=====>", error);
            this.showAlert(error.error.error.message);
          }
          this.fromPurchase = false;
          this.isPurchasing.next(false);
        }
      }
    }
  }
  async productUpdated(product: CdvPurchase.Product) {
    if (product.owned) {
    }
  }

  restorePurchase() {
    if (this.platform.is("cordova")) {
      CdvPurchase.store.restorePurchases();
    }
  }

  // API CALLS

  async getSavedUserSubscriptionInformation() {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          this.env.apiUrl +
          `services/app/Billing/GetMySubscriptions?type=${ApplicationSubsciptionTypeEnum.BOAT_OWNER}`
        )
        .subscribe({
          next: (res: any) => {
            if (res.result) {
              console.log("subscription info=====>", res.result);
              this.isSubscribed = true;
              this.purchasedProduct = res.result?.basePlan?.productName;
              this.expirationTime = res.result?.basePlan?.expirationDate;
              this.subscriptionSource = res.result?.basePlan?.sourceName;
              this.subscriptionState = res.result?.basePlan?.stateName;
              this.isExpired = res.result?.basePlan?.isExpired;
              this.onFetchedPurchaseInfo.next(res.result.basePlan);
              this.googleAnalyticsCustomService.audienceData(this.purchasedProduct, this.subscriptionState);
              if (res.result.basePlan.stateName == "NOT_ACTIVE") {
                this.purchaseToken = null;
              }
              // this.isReceiptVerified = true;
            } else {
              this.isSubscribed = false;
            }
            this.isInitialbuy = false;
            resolve(res);
          },
          error: (err) => {
            this.isInitialbuy = true;
            reject(err);
          },
        });
    });
  }

  getSubscriptionPlans(): Promise<SubscriptionPlan[]> {
    const source = this.platform.is("ios")
      ? ApplicationSubscriptionSourceEnum.APPLE
      : ApplicationSubscriptionSourceEnum.GOOGLE;
    return new Promise((resolve, reject) => {
      this.http
        .get(
          this.env.apiUrl +
          `services/app/Billing/GetBaseSubscriptionListings?type=${ApplicationSubsciptionTypeEnum.BOAT_OWNER}&source=${source}`
        )
        .subscribe({
          next: (res: any) => {
            console.log("subscription plans=====>", res.result);
            resolve(res.result);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  verifyPurchaseReceipt(receipt: any): Promise<any> {
    this.isVerifyingReceipt = true;
    const source = this.platform.is("ios")
      ? ApplicationSubscriptionSourceEnum.APPLE
      : ApplicationSubscriptionSourceEnum.GOOGLE;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.env.apiUrl +
          `services/app/ApplicationSubscription/ApplyNativePlatformPurchase?type=${ApplicationSubsciptionTypeEnum.BOAT_OWNER}&source=${source}`,
          { purchase: receipt },
          { headers: { "content-type": "application/json" } }
        )
        .pipe(retry(3))
        .subscribe({
          next: (res: any) => {
            this.isPurchasing.next(false);

            if (
              (res.result.stateName == "ACTIVE" ||
                res.result.stateName == "TRIAL") && this.userId == res.result.userId
            ) {
              this.isSubscribed = true;
              this.purchasedProduct = res.result.productName;
              this.expirationTime = res.result.expirationDate;
              this.isExpired = res.result.isExpired;
              this.subscriptionSource = res.result.sourceName;
              this.subscriptionState = res.result.stateName;
              this.isReceiptVerified = true;
              console.log("is from purchase=====>", this.fromPurchase);
              if (this.fromPurchase) {
                this.onPurchaseDone.next({ success: true });
              }

              this.fromPurchase = false;
              this.isVerifyingReceipt = false;
              resolve(res.result);
            } else {
              this.isVerifyingReceipt = false;
              this.isSubscribed = false;
              reject(res.result);
            }

          },
          error: (err) => {
            this.isVerifyingReceipt = false;
            reject(err);
          },
        });
    });

  }



  // verifyPurchaseReceiptold(receipt: any): Promise<any> {
  //   const source = this.platform.is("ios")
  //     ? ApplicationSubscriptionSourceEnum.APPLE
  //     : ApplicationSubscriptionSourceEnum.GOOGLE;
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .post(
  //         this.env.apiUrl +
  //           `services/app/ApplicationSubscription/ApplyNativePlatformPurchase?type=${ApplicationSubsciptionTypeEnum.BOAT_OWNER}&source=${source}`,
  //         { purchase: receipt },
  //         { headers: { "content-type": "application/json" } }
  //       )
  //       .pipe(retry(3))
  //       .subscribe(
  //         (res: any) => {
  //           this.isPurchasing.next(false);

  //           if (
  //             (res.result.stateName == "ACTIVE" ||
  //               res.result.stateName == "TRIAL") 
  //           ) {
  //             this.isSubscribed = true;
  //             this.purchasedProduct = res.result.productName;
  //             this.expirationTime = res.result.expirationDate;
  //             this.isExpired = res.result.isExpired;
  //             this.subscriptionSource = res.result.sourceName;
  //             this.subscriptionState = res.result.stateName;
  //             this.isReceiptVerified = true;

  //             if (this.fromPurchase) {
  //               this.onPurchaseDone.next({ success: true });
  //             }

  //             this.fromPurchase = false;
  //             this.isVerifyingReceipt = false;
  //             resolve(res);
  //           } else {
  //             this.isSubscribed = false;
  //             resolve(res);
  //           }

  //           setTimeout(() => {
  //             this.isVerifyingReceipt = false;
  //           }, 5000);
  //         },
  //         (err) => {
  //           console.log("error from purchase=====>", err);
  //           this.isPurchasing.next(false);
  //           this.isSubscribed = false;
  //           if(this.fromPurchase){

  //             this.showAlert(err.error.error.message);
  //           }

  //           setTimeout(() => {
  //             this.isVerifyingReceipt = false;
  //           }, 5000);
  //           reject(err); // Reject the promise with the error
  //         }
  //       );
  //   });
  // }
}

export interface ILog {
  UserId: number;
  Action: string;
  Payload: string;
  Error: string;
}

export interface IValidate {
  id: string;
  type: string;
  products: CdvPurchase.Product[];
  transaction: CdvPurchase.Transaction;
  additionalData: CdvPurchase.AdditionalData;
}

// ENUMS
export enum ApplicationSubscriptionStateEnum {
  NOT_ACTIVE = 0,
  ACTIVE = 1,
  TRIAL = 2,
  DUE = 3,
  CANCELLED = 4,
}

export enum ApplicationSubscriptionSourceEnum {
  STRIPE = 1,
  GOOGLE = 2,
  APPLE = 3,
}

export enum ApplicationSubsciptionTypeEnum {
  VENDOR = 1,
  BOAT_OWNER = 2,
}

interface Product {
  isSubscription: boolean;
  isStatic: boolean;
  displayName: string;
  description: string;
  category: number;
  categoryName: string;
  personaType: number;
  personaTypeName: string;
  targetUserId: number | null;
  internalId: string;
  metaData: any | null;
  isVariable: boolean;
  active: boolean;
  subscriptionTierHierarchy: number;
  id: number;
}

interface Price {
  billingProductId: number;
  source: number;
  sourceName: string;
  active: boolean;
  tenantId: number;
  displayName: string;
  price: number;
  priceId: number | null;
  priceSummary: any | null;
  description: any | null;
  internalId: any | null;
  billingPeriod: number;
  sourcePriceKeyIdentifier: string;
  sourceProductKeyIdentifier: string;
  id: number;
}

export interface SubscriptionPlan {
  product: Product;
  user: any | null;
  prices: Price[];
  entitlements: any | null;
  meta: any[];
}

