import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import {
  ChangeUserLanguageDto,
  ProfileServiceProxy,
} from "src/shared/service-proxies/service-proxies";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public languages: string[] = ["en", "es", "de", "it", "ru"];

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private profileServiceProxy: ProfileServiceProxy
  ) {
    let browserLang: string;
    this.translate.addLangs(this.languages);
    const lang = localStorage.getItem("lang");
    if (lang) {
      browserLang = lang;
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : "en");
  }

  public setLanguage(lang: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.translate.use(lang);
        this.cookieService.set("lang", lang);
        localStorage.setItem("lang", lang);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public async fetchLanguageInfo() {
    try {
      const currentLang = localStorage.getItem("lang");
      if (
        currentLang &&
        currentLang !== abp.localization.currentLanguage.name
      ) {
        return await this.setUserLanguage(currentLang);
      }
    } catch (error) {
      console.error("Error while fetching language info:", error);
    }
  }

  public setUserLanguage(language: string): any {
    try {
      const langData = new ChangeUserLanguageDto();
      langData.languageName = language;

      return this.profileServiceProxy.changeLanguage(langData);
    } catch (error) {
      console.log("An Error ocuured", error.message);
    }
  }
}
