<button
    [type]="type"
    [disabled]="disabled"
    (click)="onButtonClick()"
    [class]="buttonClasses"
>
    <ion-ripple-effect></ion-ripple-effect>
    <!-- For left positioned icon -->
    <span
        *ngIf="icon.position === 'left'"
        class="bbicon-{{ icon.name }} text-{{ icon.size }} {{icon?.color}}"
    ></span>

    <!-- Label -->
    <ng-container *ngIf="!hideTextOnLoading || !loading">{{
        label
    }}</ng-container>

    <!-- For right positioned icon -->
    <span
        *ngIf="icon.position === 'right'"
        class="bbicon-{{ icon.name }} text-{{ icon.size }} {{icon?.color}}"
    ></span>

    <!-- Loading spinner if loading is true -->
    <div
        *ngIf="loading"
        class="animate-spin flex items-center justify-center"
        [ngClass]="{
            'absolute right-6': !hideTextOnLoading || !loading,
            '': hideTextOnLoading
        }"
    >
        <span
            class="bbicon-loading text-{{ icon.size ? icon.size : '2xl' }} "
        ></span>
    </div>
</button>
