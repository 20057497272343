import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlphabetsOnlyDirective } from "./alphabets-only.directive";
import { DecimalOnlyDirective } from "./decimal-only.directive";
import { NoDuplicateDirective } from "./no-duplicate-digit.directive";
import { NoSpecialCharacterDirective } from "./no-special-character.directive";
import { NumberDirective } from "./numbers-only.directive";
import { AlphaNumericOnlyDirective } from "./alphanumeric-only.directive";
import { EnterNextDirective } from "./enter-next.directive";
import { PhoneNumberMaskDirective } from "./phone-number-mask.directive";

@NgModule({
    declarations: [
        AlphaNumericOnlyDirective,
        AlphabetsOnlyDirective,
        DecimalOnlyDirective,
        NoDuplicateDirective,
        NoSpecialCharacterDirective,
        NumberDirective,
        EnterNextDirective,
        PhoneNumberMaskDirective,
    ],
    imports: [CommonModule],
    exports: [
        AlphaNumericOnlyDirective,
        AlphabetsOnlyDirective,
        DecimalOnlyDirective,
        NoDuplicateDirective,
        NoSpecialCharacterDirective,
        NumberDirective,
        EnterNextDirective,
        PhoneNumberMaskDirective,
    ],
})
export class SharedDirectivesModule {}
