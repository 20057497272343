import { Injectable } from "@angular/core";
import {
  EstimateItemOutput,
  EstimateStateEnum,
  EstimateTypeEnum,
  EstimatesServiceProxy,
  GetEstimateOutput,
  IGetEstimateOutput,
  PagedResultDtoOfGetEstimateOutput,
} from "src/shared/service-proxies/service-proxies";
import { IDisplayStatus } from "src/app/shared/row/row.component";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FinanceService, FinancialDocumentLineItem } from "./finance.service";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class EstimateService extends FinanceService {
  constructor(private estimatesServiceProxy: EstimatesServiceProxy) {
    super();
  }

  public getEstimateStatusFilterOptions(language: string): { id: EstimateStateEnum | number; value: string }[] {
    const translations = {
      en: {
        All: "All",
        Approved: "Approved",
        Rejected: "Rejected",
        Pending: "Pending",
      },
      es: {
        All: "Todos",
        Approved: "Aprobado",
        Rejected: "Rechazado",
        Pending: "Pendiente",
      },
    };
    return [
      { id: EstimateStateEnum.APPROVED, value: translations[language].Approved },
      {
        id: EstimateStateEnum.REJECTED,
        value: translations[language].Rejected,
      },
      {
        id: EstimateStateEnum.PENDING,
        value: translations[language].Pending,
      },
      { id: 0, value: translations[language].All },
    ];
  }

  public getEstimateTypeFilterOptions(language: string): { id: EstimateTypeEnum; value: string }[] {
    const translations = {
      en: {
        ServiceOrder: "Service Order",
        Package: "Package",
      },
      es: {
        ServiceOrder: "Orden de servicio",
        Package: "Paquete",
      },
    };

    return [
      {
        id: EstimateTypeEnum.PACKAGE,
        value: translations[language].Package,
      },
      {
        id: EstimateTypeEnum.ONE_OFF,
        value: translations[language].ServiceOrder,
      },
    ];
  }

  public getSortFilterOptions(language: string): { id: string; value: string }[] {
    const translations = {
      en: {
        Recent: "Recent",
        Oldest: "Oldest",
        AmountDueHighToLow: "Amount Due - High To Low",
        AmountDueLowToHigh: "Amount Due - Low To High",
        CustomerNameHighToLow: "Customer Name - High To Low",
        CustomerNameLowToHigh: "Customer Name - Low To High",
      },
      es: {
        Recent: "Reciente",
        Oldest: "Más antiguo",
        AmountDueHighToLow: "Monto adeudado - De mayor a menor",
        AmountDueLowToHigh: "Monto adeudado - De menor a mayor",
        CustomerNameHighToLow: "Nombre del cliente - De mayor a menor",
        CustomerNameLowToHigh: "Nombre del cliente - De menor a mayor",
      },
    };

    return [
      { id: "estimateDate desc", value: translations[language].Recent },
      { id: "estimateDate asc", value: translations[language].Oldest },
      { id: "Amount desc", value: translations[language].AmountDueHighToLow },
      { id: "Amount asc", value: translations[language].AmountDueLowToHigh },
      {
        id: "yachtOwner.user.name asc",
        value: translations[language].CustomerNameHighToLow,
      },
      {
        id: "yachtOwner.user.name desc",
        value: translations[language].CustomerNameLowToHigh,
      },
    ];
  }

  public getEstimateType(estimateOutput: IGetEstimateOutput): IEstimateDisplayType {
    const estimateType = estimateOutput.estimate.type;
    switch (estimateType) {
      case EstimateTypeEnum.ONE_OFF:
        return {
          name: "Service Order",
          type: estimateType,
        };
      case EstimateTypeEnum.PACKAGE:
        return {
          name: "Package",
          type: estimateType,
        };
      default:
        return {
          name: "Estimate",
          type: estimateType,
        };
    }
  }

  public getDisplayStatus(estimateOutput: IGetEstimateOutput): IDisplayStatus {
    const status: EstimateStateEnum = estimateOutput.estimate.state;
    switch (status) {
      case EstimateStateEnum.APPROVED:
        return {
          statusLabel: "Approved",
          statusClass: "status-active-green",
        };
      case EstimateStateEnum.REJECTED:
        return {
          statusLabel: "Rejected",
          statusClass: "status-active-red",
        };
      case EstimateStateEnum.PENDING:
        return {
          statusLabel: "Pending",
          statusClass: "status-blue",
        };
      default:
        return {
          statusLabel: "Pending",
          statusClass: "status-blue",
        };
    }
  }

  getEstimateActionMeta(estimateOutput: IGetEstimateOutput): IEstimateActionMeta {
    if (!estimateOutput) {
      throw new Error("Estimate is required");
    }
    const state = estimateOutput.estimate.state;
    switch (state) {
      case EstimateStateEnum.APPROVED:
        return {
          label: "Approved",
          icon: "check",
          class: "bg-dsSuccess text-white",
          actionDate: estimateOutput.estimate?.approvalDate?.toJSDate(),
          actionUser: estimateOutput.yachtOwner?.firstName + " " + estimateOutput.yachtOwner?.lastName,
        };
      case EstimateStateEnum.REJECTED:
        return {
          label: "Rejected",
          icon: "close",
          class: "bg-dsError text-white",
          actionDate: estimateOutput.estimate?.rejectionDate.toJSDate(),
          actionUser: estimateOutput.yachtOwner?.firstName + " " + estimateOutput.yachtOwner?.lastName,
        };
      case EstimateStateEnum.PENDING:
        return {
          label: "Pending",
          icon: "reload",
          class: "bg-brandSkyBlueLight text-brandDeepSeaBlue",
          actionDate: estimateOutput.estimate?.estimateDate?.toJSDate(),
        };
      default:
        return {
          label: "Pending",
          icon: "reload",
          class: "bg-brandSkyBlueLight text-brandDeepSeaBlue",
          actionDate: estimateOutput.estimate?.estimateDate?.toJSDate(),
        };
    }
  }

  public getEstimateById(invoiceId: number): Observable<GetEstimateOutput> {
    if (!invoiceId) {
      throw new Error("Estimate ID is required");
    }
    const queryParams = {
      invoiceId,
      persona: environment.persona,
    };
    return this.estimatesServiceProxy.getById(queryParams.invoiceId, queryParams.persona);
  }

  public acceptEstimate(invoiceId: number): Observable<GetEstimateOutput> {
    if (!invoiceId) {
      throw new Error("Estimate ID is required");
    }
    const queryParams = {
      invoiceId,
      persona: environment.persona,
    };
    return this.estimatesServiceProxy.acceptEstimate(queryParams.invoiceId, queryParams.persona);
  }

  public rejectEstimate(invoiceId: number): Observable<GetEstimateOutput> {
    if (!invoiceId) {
      throw new Error("Estimate ID is required");
    }
    const queryParams = {
      invoiceId,
      persona: environment.persona,
    };
    return this.estimatesServiceProxy.rejectEstimate(queryParams.invoiceId, queryParams.persona);
  }

  public fetchEstimates(filterOptions: IGetEstimatesFilterOptions): Observable<PagedResultDtoOfGetEstimateOutput> {
    const queryParams = {
      filter: filterOptions.searchTerm ?? undefined,
      sorting: filterOptions.sort ?? "estimateDate desc",
      state: filterOptions.status ?? undefined,
      type: filterOptions.type ?? undefined,
      vendorId: filterOptions.vendor ?? undefined,
      maxResultCount: filterOptions.maxResultCount ?? undefined,
      skipCount: filterOptions.skipCount ?? undefined,
      yachtId: undefined,
      prospectId: undefined,
      isDrafted: undefined,
      yachtOwnerId: undefined,
      persona: environment.persona,
      startDate: filterOptions.dateRange?.from ? DateTime.fromISO(filterOptions.dateRange?.from) : undefined,
      endDate: filterOptions.dateRange?.to ? DateTime.fromISO(filterOptions.dateRange?.to) : undefined,
    };

    return this.estimatesServiceProxy.getAll(
      queryParams.filter,
      queryParams.state,
      queryParams.type,
      queryParams.yachtOwnerId,
      queryParams.yachtId,
      queryParams.vendorId,
      queryParams.isDrafted,
      queryParams.prospectId,
      queryParams.persona,
      queryParams.startDate,
      queryParams.endDate,
      queryParams.sorting,
      queryParams.skipCount,
      queryParams.maxResultCount
    );
  }

  getLineItems(items: EstimateItemOutput[]): FinancialDocumentLineItem[] {
    const docItems: FinancialDocumentLineItem[] = items.map((item) => {
      return {
        description: item.lineItem.description,
        title: item.lineItem.title ?? item.service?.name,
        amount: item.total,
        quantity: item.lineItem.quantity,
        tax: item.lineItem.taxPercent,
        unitPrice: item.lineItem.unitPrice,
      };
    });
    return docItems;
  }
}

export interface IEstimateDisplayType {
  name: string;
  type: EstimateTypeEnum;
}

export interface IEstimateActionMeta {
  label: string;
  icon: string;
  class: string;
  actionDate: Date;
  actionUser?: string;
}

export interface IGetEstimatesFilterOptions {
  yachtId: number;
  searchTerm: string;
  vendor: number;
  type: number;
  dateRange: { from: string; to: string };
  sort: string;
  status: EstimateStateEnum;
  maxResultCount: number;
  skipCount: number;
}
