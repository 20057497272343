<ion-header class="ion-no-border bg-brandDeepSeaBlue">
  <ion-toolbar class="h-[10vh] ion-paading-start" [ngClass]="{ mobile: isMobile }">
    <button
      type="button"
      slot="start"
      (click)="onButtonClick()"
      class="p-2 rounded-full bg-brandSunsetOrange mt-2 ml-5"
      *ngIf="hasBackButton"
    >
      <ion-ripple-effect></ion-ripple-effect>
      <span class="bbicon-chevron-left text-2xl text-white"></span>
    </button>
    <ion-title class="text-white text-xl font-bold no-ellipses">
      <div class="w-full whitespace-normal">{{ headerText }}</div></ion-title
    >
    <img
      src="assets/captain-talk/captain-talk-white-sparks.svg"
      alt="captain ai logo"
      slot="end"
      height="60"
      width="60"
      [id]="'ai-click-trigger' + triggerId"
      class="mr-2"
    />
  </ion-toolbar>
</ion-header>
<ion-popover class="ai-menu" [trigger]="'ai-click-trigger' + triggerId" triggerAction="click" [dismissOnSelect]="true">
  <ng-template>
    <ion-content class="ai-menu-content" color="light">
      <ion-item lines="full" [button]="true" [detail]="false" (click)="navigateToCaptainAiDashboard()">
        <span class="bbicon-message mr-3 text-xl text-brandDeepSeaBlue"></span>
        <span>{{ LANGUAGE_DATA?.CAPTAIN_TALK.DASHBOARD.ASK_CAPTAIN_AI | translate }}</span>
      </ion-item>
      <ion-item
        lines="full"
        [button]="true"
        [detail]="false"
        routerLink="/yacht-owner/service-orders/create"
        *ngIf="isLoggedIn && enableRequestService"
      >
        <span class="bbicon-tools mr-3 text-xl text-brandDeepSeaBlue"></span>
        <span>{{ LANGUAGE_DATA?.CAPTAIN_TALK.BASE_TWO_LAYOUT.SERVICE_REQUEST_TEXT | translate }}</span>
      </ion-item>
      <ion-item lines="full" [button]="true" [detail]="false" (click)="openHelpModal()" *ngIf="toolTipText">
        <span class="bbicon-question mr-3 text-xl text-brandDeepSeaBlue"></span>
        <span>{{ LANGUAGE_DATA?.CAPTAIN_TALK.BASE_TWO_LAYOUT.HELP | translate }}</span>
      </ion-item>
    </ion-content>
  </ng-template>
</ion-popover>
