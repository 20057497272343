export enum UserRoles {
  YACHT_OWNER = "yachtowner", 
  YACHT_TECHNICIAN = "technician",
  YACHT_VENDOR = "vendor",
}

export enum PriorityEnums {
  AFFECTSHIPSHAPE = 4,
  HIGH = 3,
  // NORMAL = 2,
  MEDIUM = 1,
  LOW = 0,
}

export enum BiometricTypesEnums {
  TOUCH_ID = "TOUCH_ID",
  FACE_ID = "FACE_ID",
  FINGERPRINT = "FINGERPRINT",
  IRIS_AUTHENTICATION = "IRIS_AUTHENTICATION",
  FACE_AUTHENTICATION = "FACE_AUTHENTICATION",
  MULTIPLE = "MULTIPLE",
}
