import {
  Component,
  forwardRef,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  Renderer2,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";
import { v4 as uuidv4 } from "uuid";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from "ngx-intl-tel-input";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  @Input() disabled = false;
  @Input() label: string;
  @Input() enablePlaceholder = false;
  @Input() placeholder: string;
  @Input() showPasswordToggle = false;
  @Input() hidePassword = true;
  @Input() forceFloatingLabel = false;
  @Input() id: string;
  @Input() popoverMessage: string;

  @Input() clearErrorOnFocus = true;

  @Input() icon: {
    name: string;
    size?: "2xl" | "3xl" | string;
    color?: string;
    position?: "left" | "right" | "floating-left" | "floating-right";
  };

  @Input() enterKeyHint: string;
  @Input() tabindex = 0;
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

  public control = new FormControl();
  @Input() set validators(validators: any) {
    this.control.setValidators(validators);
  }

  @Input() customErrorMessages: { [key: string]: string };

  @Input() type = "text";
  inputType = "text";
  @Input() defaultBorderColor = "border-dsGray50";
  @Input() step = "";
  @Input() floatingLabel: "inline" | "outline" | undefined;
  @Input() model: any = "";
  @Input() required = false;
  @Input() invalid = false;
  @Input() name: string | undefined = "";
  @Input() readOnly = false;
  @Input() size: "small" | "normal" | "large" | undefined = "normal";

  @Input() validationText = "is required";
  @Input() validationTextClass = "";
  @Input() invalidLabelStyle = "";
  @Input() validLabelStyle = "";

  @Output() keyUp: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() focus: EventEmitter<void> = new EventEmitter<void>();
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() changedValue: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild("internalInput", { static: false }) internalInput: ElementRef;
  @ViewChild("telInputComp", { read: ElementRef })
  telInputCompRef: ElementRef;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  initialPhoneNumber = "";

  isFocused = false;
  isBlurred = false;
  isDisabled = false;

  defaultBackGroundColor = "bg-gray-50";

  touched = false;

  defaultSize = "p-2.5 text-base min-h-[40px]";

  defaultClass = "focus:outline-none text-gray-900 rounded-lg block w-full";
  //this manages the border/outline during focus and un focused events
  inputValidClass = "focus:ring-blue-500 focus:border-[#00005C] focus:border-blue-500 border border-dsGray60";

  //this manages the invalid border/outline during focus and un focused events
  invalidClass = "focus:ring-red-500 focus:border-red-500 border border-red-500";

  invalidLabelClass = "peer-focus:text-red-500";
  validLabelClass = "peer-focus:text-blue-600";

  value: any = "";

  telCode = "";
  locales = [];

  constructor(private renderer: Renderer2) {
    if (!this.id) {
      this.id = uuidv4();
    }

    this.control = new FormControl("");

    this.control.valueChanges.subscribe((value) => {
      this.isFocused = Boolean(value) || this.isFocused || Boolean(this.placeholder) || value === 0;
    });
  }

  onChange: any = () => {
    //
  };
  onTouched: any = () => {
    //
  };

  ngOnInit(): void {
    if (this.type === "money") {
      this.inputType = "number";
      this.step = "0.01";
    } else {
      this.inputType = this.type;
    }
  }

  getNgClass() {
    return {
      "border-dsError": this.invalid && this.required && this.isBlurred,
      [this.defaultBorderColor]: !this.invalid && !this.isFocused,
      "border-dsGray30": !this.invalid && !this.required && !this.isFocused,
      "border-2 border-brandDeepSeaBlue": this.isFocused,
      "bg-dsGray10": this.disabled,
    };
  }

  ngAfterViewInit(): void {
    if (this.type === "tel") {
      const inputElement = this.telInputCompRef.nativeElement.querySelector("input");
      if (inputElement) {
        this.renderer.listen(inputElement, "blur", (event) => {
          this.onBlur(event);
        });
        this.renderer.listen(inputElement, "focus", (event) => {
          this.onFocus(event);
        });

        // Set the tabindex of the input element to the tabindex of the component
        inputElement.setAttribute("tabindex", this.tabindex.toString());
      }
    }
  }

  checkNumber(event: any): void {
    if (this.model != null) {
      const Data: any = this.model;
      this.model = Data.number;
      this.onChange(Data.e164Number);
      if (Data.dialCode + this.initialPhoneNumber !== Data.e164Number) {
        this.onTouched(Data.e164Number);
      }
    }
  }

  handleDateLabelClick() {
    this.internalInput.nativeElement.click();
    this.internalInput.nativeElement.focus();
    this.isFocused = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.invalidLabelStyle) {
      this.invalidLabelClass = this.invalidLabelStyle;
    }

    if (this.validLabelStyle) {
      this.validLabelClass = this.validLabelStyle;
    }

    if (changes["disabled"]) {
      this.setDisabledState(changes["disabled"].currentValue);
    }

    if (changes["type"]) {
      this.type = changes["type"].currentValue;
      this.inputType = this.type;
    }
  }

  updateModel(event: any) {
    const value = event.target.value;
    this.model = value;

    this.onChange(value);
    this.changedValue.emit(value);

    this.onTouched(value);
  }

  writeValue(value: any): void {
    this.model = this.value = value === undefined ? "" : value;
    this.control.setValue(this.value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  focusInput() {
    if (this.type === "tel") {
      const inputElement = this.telInputCompRef.nativeElement.querySelector("input");
      inputElement.focus();
      this.isFocused = true;
    } else {
      this.internalInput.nativeElement.focus();
    }
  }

  blurInput() {
    if (this.type === "tel") {
      const inputElement = this.telInputCompRef.nativeElement.querySelector("input");
      inputElement.blur();
      this.isFocused = false;
    } else {
      this.internalInput.nativeElement.blur();
    }
  }

  toggleHidePassword() {
    this.hidePassword = !this.hidePassword;
    this.inputType = this.hidePassword ? "password" : "text";
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  onKeyUp(event: KeyboardEvent) {
    this.keyUp.emit(event);
  }

  onFocus(event) {
    this.isFocused = true;
    this.isBlurred = false;
    this.focus.emit(event);
  }

  onDateFocus(event: any) {
    this.isFocused = true;
    this.focus.emit();
  }

  onInput(event: any) {
    const eventTarget = event.target as HTMLInputElement;
    this.inputChange.emit(eventTarget.value);

    this.updateModel(event);
  }

  onBlur(event) {
    this.isFocused = false;
    this.isBlurred = true;
    this.onTouched(event);
    this.blur.emit(event);
  }

  get shouldLabelFloat(): boolean {
    return (
      this.isFocused || !!this.value || !!this.model || this.value === 0 || this.model === 0 || this.forceFloatingLabel
    );
  }
}
