import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextareaComponent } from "./textarea.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [TextareaComponent],
    exports: [TextareaComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
})
export class BBTextAreaModule {}
