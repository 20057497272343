import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { VesselChecklistTypeEnum } from "../yacht-owner/checklist-manager/vessel-checklist/types";

@Injectable({
  providedIn: "root",
})
export class DataService {
  navigationData: any;
  filter: any;
  techServiceOrder: any;
  signalrActionEvent = new BehaviorSubject(null);
  completeProfile = new BehaviorSubject(null);
  checkListInProgress = new BehaviorSubject(false);
  geofencingLocalNotification = new BehaviorSubject(null);
  isGuideInProgress = new BehaviorSubject(null);
  showBoatProfilePopeUp = new BehaviorSubject(false);

  checklistData = new BehaviorSubject({
    isExist: false,
    isCompleted: false,
    type: "",
  });
  openChat = new BehaviorSubject(null);
  openVendor = new BehaviorSubject(null);
  startTrip = new BehaviorSubject(null);
  dismissedTripByUser = new BehaviorSubject(null);
  isBoatLocationUpdated = new BehaviorSubject(null);
  sendLocation = new BehaviorSubject({ dist: 0, rand: 0, eligible: false });
  isEligibleForShutdown = new BehaviorSubject(false);
  distanceFilter = new BehaviorSubject(50);
  openModal = new BehaviorSubject({ type: null, isOpened: false });
  accountSettings: IAccountSettings;
  isChatPage:any;
  checkListImages = [];
  constructor() {}

  setNavigationData(data) {
    this.navigationData = data;
  }

  getNavigationData() {
    return this.navigationData;
  }

  setCompleteProfile(data) {
    this.completeProfile.next({ isComplete: data });
  }

  setChecklistData(data) {
    this.checklistData.next(data);
  }

  setActionEventType(eventType: String, serviceId: number) {
    this.signalrActionEvent.next({ eventType, serviceId });
  }
  setPendingServiceOrder(key: string, value: any) {
    localStorage.setItem("BoatBot_" + key, JSON.stringify(value));
  }

  setTripDismissedByUser(value: any) {
    this.dismissedTripByUser.next(value);
  }

  getPendingServiceOrder(key: string) {
    return JSON.parse(localStorage.getItem("BoatBot_" + key)) || [];
  }
  setTechServiceOrderDetail(data: any) {
    localStorage.setItem("techOrderDetail", JSON.stringify(data));
  }
  getTechServiceOrderDetail() {
    return JSON.parse(localStorage.getItem("techOrderDetail")) || [];
  }
  removeTechServiceOrderDetail() {
    return localStorage.removeItem("techOrderDetail");
  }
  setTechFilter(filter) {
    this.filter = filter;
  }
  getTechFilter() {
    return this.filter;
  }

  setIsChatPage(state: boolean, targetFriendId:number) {
    this.isChatPage = {isChatPage:state,targetFriendId};
  }
  getIsChatPage() {
    return this.isChatPage;
  }

  setAccountSettings(data: IAccountSettings) {
    this.accountSettings = data;
  }
  getAccountSettings() {
    return this.accountSettings;
  }

  setTempCheckListImages(
    data: any,
    entityId,
    checkListType: VesselChecklistTypeEnum
  ) {
    this.checkListImages.push({
      entityId: entityId,
      images: data,
      checkListType,
    });
  }

  getTempCheckListImages(entityId, checkListType: VesselChecklistTypeEnum) {
    return this.checkListImages.find(
      (item) =>
        item.entityId === entityId && item.checkListType === checkListType
    );
  }

  modifyTempCheckListImagesEntity(beforeEntityId, afterEntityId) {
    this.checkListImages.forEach((item) => {
      if (item.entityId === beforeEntityId) {
        item.entityId = afterEntityId;
      }
    });
  }

  removeTempCheckListImages(entityId, checkListType: VesselChecklistTypeEnum) {
    this.checkListImages = this.checkListImages.filter(
      (item) =>
        !(item.entityId === entityId) && item.checkListType === checkListType
    );
  }
}

export interface IAccountSettings {
  allowChatTone: boolean;
  allowPushNotifications: boolean;
  enableEmailNotification: boolean;
  enableSMSNotification: boolean;
}
