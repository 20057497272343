import { Component, Input, OnInit } from "@angular/core";
import { PopoverComponent } from "../ui/popover/popover.component";
import { PopoverController } from "@ionic/angular";

@Component({
    selector: "app-popover-button",
    templateUrl: "./popover-button.component.html",
    styleUrls: ["./popover-button.component.css"],
})
export class PopoverButtonComponent implements OnInit {
    @Input() message: string;
    @Input() iconSize = "medium";
    @Input() icon = "question";
    @Input() iconColor = "brandDeepSeaBlue";
    @Input() label: string;

    iconSizeClass = "xl";

    constructor(public popoverController: PopoverController) {}

    ngOnInit() {
        if (!this.message) {
            this.message = "No message";
        }

        if (this.iconSize === "small") {
            this.iconSizeClass = "xl";
        } else if (this.iconSize === "medium") {
            this.iconSizeClass = "2xl";
        } else if (this.iconSize === "large") {
            this.iconSizeClass = "3xl";
        }
    }

    async presentPopover(e: Event) {
        e.stopPropagation();
        const popover = await this.popoverController.create({
            component: PopoverComponent,
            event: e,
            componentProps: { message: this.message },
            cssClass: "default-popover",
        });

        await popover.present();
    }
}
