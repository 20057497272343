import { Injectable } from "@angular/core";
import { Geolocation, WatchPositionCallback } from "@capacitor/geolocation";
import { PermissionDeniedComponent } from "../shared/permission-denied/permission-denied.component";
import { ModalController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { Platform } from "@ionic/angular";
import { registerPlugin } from "@capacitor/core";
import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
import { DataService } from "./data.service";

interface Coordinates {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: "root",
})
export class GeolocationService {
  isLoadingModal = false;
  distanceFilter = 2;
  private currentVesselLocation: Coordinates = {
    latitude: 5.56689202,
    longitude: -0.17070583,
  };

  private watchId: string | null = null;

  private positionSource = new BehaviorSubject<GeolocationPosition | null>(null);
  livePosition$ = this.positionSource.asObservable();
  private currentPosition: GeolocationPosition | null = null;

  private backgroundPositionSource = new BehaviorSubject<Coordinates | null>(null);
  backgroundPosition$ = this.backgroundPositionSource.asObservable();

  backgroundPos: any;

  locationTimeout: any;

  BackgroundGeolocation: BackgroundGeolocationPlugin;

  constructor(private modalCtrl: ModalController, private platform: Platform, private dataService: DataService) {
    if (this.platform.is("capacitor")) {
      // Check if the plugin is already registered
      if (this.BackgroundGeolocation) {
        console.log("BackgroundGeolocation plugin already registered");
        return;
      }
      this.BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation", [
        "android",
        "ios",
      ]);
    }
  }

  async initializeGeolocation() {
    if (this.watchId) {
      await Geolocation.clearWatch({ id: this.watchId });
    }
    if (this.platform.is("capacitor")) {
      await Geolocation.requestPermissions();
    }
  }
  async showPermissionAlert(type, text?: string) {
    if (!text) {
      text =
        "To ensure your vendors and assigned technicians can easily locate and access your boat, we need access to use your location. Please turn on location access in the app settings.";
    }

    const modal = await this.modalCtrl.create({
      component: PermissionDeniedComponent,
      componentProps: {
        header: `Location Access Needed`,
        text,
        type,
      },
      cssClass: "blurry-backdrop-auto",
    });

    modal.present();
  }

  async initializeBackgroundGeolocation() {
    const callback = async (location: any, error: any) => {
      if (error) {
        if (error.code === "NOT_AUTHORIZED") {
          this.showPermissionAlert("location");
        }
        return console.error("Location Error: ", error);
      }

      if (location) {
        this.backgroundPositionSource.next(location);
      }

      return location;
    };
    try {
      if (this.platform.is("capacitor")) {
        this.watchId = await this.BackgroundGeolocation.addWatcher(
          {
            backgroundMessage: "Cancel to prevent battery drain.",
            backgroundTitle: "Tracking You.",
            requestPermissions: true,
            stale: false,
            distanceFilter: 0,
          },
          callback
        );
      }

      // Store watcher_id if needed for later removal
    } catch (error) {
      console.error("Failed to start background geolocation", error);
    }
  }

  async stopTracking() {
    if (this.platform.is("capacitor")) {
      try {
        await this.BackgroundGeolocation.removeWatcher({ id: this.watchId });
      } catch (error) {
        console.error("Failed to stop background geolocation", error);
      }
    }
  }

  private async checkPermission(): Promise<boolean> {
    if (this.platform.is("capacitor")) {
      const permission = await Geolocation.checkPermissions();
      return permission.location === "granted";
    }
    return true;
  }

  private async clearWatch(): Promise<void> {
    if (this.platform.is("capacitor")) {
      if (this.watchId) {
        await Geolocation.clearWatch({ id: this.watchId });
        this.watchId = null;
      }
    }
  }

  private async presentModal(text: string, header: string): Promise<void> {
    if (this.isLoadingModal) return;
    this.isLoadingModal = true;

    const modal = await this.modalCtrl.create({
      component: PermissionDeniedComponent,
      componentProps: { header, text },
      cssClass: "blurry-backdrop-auto",
    });

    await modal.present();
    this.isLoadingModal = false;
  }

  async pingLocation(): Promise<void> {
    const permissionGranted = await this.checkPermission();
    if (!permissionGranted) {
      this.presentModal(
        "To ensure your vendors and assigned technicians can easily locate and access your boat, we need permission to use your location. This permission was previously denied. For a smooth and efficient service experience, please enable location access in the app settings.",
        "Location Access Required"
      );
      return;
    }

    const checkPosition = async () => {
      const currentPosition = await Geolocation.getCurrentPosition();

      const coords = {
        latitude: currentPosition.coords.latitude,
        longitude: currentPosition.coords.longitude,
      };

      const distance = this.calculateDistance(currentPosition.coords, this.currentVesselLocation);

      switch (true) {
        case distance < 50:
          this.locationTimeout = setTimeout(checkPosition, 30000);
          break;
        case distance < 100:
          this.locationTimeout = setTimeout(checkPosition, 60000);
          break;
        default:
          this.locationTimeout = setTimeout(checkPosition, 600000);
          break;
      }
    };

    checkPosition();
  }

  clearPingLocation() {
    clearTimeout(this.locationTimeout);
  }

  async pingLocationWithWatch(): Promise<void> {
    const permissionGranted = await this.checkPermission();
    if (!permissionGranted) {
      this.presentModal(
        "To ensure your vendors and assigned technicians can easily locate and access your boat, we need permission to use your location. This permission was previously denied. For a smooth and efficient service experience, please enable location access in the app settings.",
        "Location Access Required"
      );
      return;
    }

    const checkPosition = async () => {
      const currentPosition = await Geolocation.getCurrentPosition();

      const coords = {
        latitude: currentPosition.coords.latitude,
        longitude: currentPosition.coords.longitude,
      };

      const distance = this.calculateDistance(currentPosition.coords, this.currentVesselLocation);

      if (distance < 50) {
        // If less than 50m, start watching the position
        this.watchPosition();
      } else {
        if (this.watchId) {
          await Geolocation.clearWatch({ id: this.watchId });
        }
        const newPingRate = distance < 100 ? 6000 : 6000;
        setTimeout(checkPosition, newPingRate);
      }
    };

    checkPosition();
  }

  calculateDistance(location1: any, location2: any) {
    const earthRadius = 6371000; // radius of the Earth in meters
    const latDiff = this.degreesToRadians(location2.latitude - location1.latitude);
    const lngDiff = this.degreesToRadians(location2.longitude - location1.longitude);

    const a =
      Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
      Math.cos(this.degreesToRadians(location1.latitude)) *
        Math.cos(this.degreesToRadians(location2.latitude)) *
        Math.sin(lngDiff / 2) *
        Math.sin(lngDiff / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in meters

    return distance;
  }

  degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  getCurrentPosition() {
    return new Promise<any | null>((resolve, reject) => {
      let permissionGranted = false;

      const checkPermissions = async () => {
        if (this.platform.is("capacitor")) {
          try {
            const permission = await Geolocation.checkPermissions();
            permissionGranted = permission.location === "granted";
          } catch (error) {
            permissionGranted = false;
          }
        } else {
          permissionGranted = true;
        }

        if (permissionGranted) {
          const permission = await Geolocation.checkPermissions();

          if (permission.location === "granted") {
            try {
              const coordinates = await Geolocation.getCurrentPosition();
              // this.locationSouce.next(coordinates);
              this.currentPosition = coordinates;
              resolve(coordinates);
            } catch (error) {
              reject(error);
            }
          } else {
            this.isLoadingModal = true;

            const text =
              "To ensure your vendors and assigned technicians can easily locate and access your boat and make use of location-based services, we need permission to use your location. This permission was previously denied. For a smooth and efficient service experience, please enable location access in the app settings. ";
            this.showPermissionAlert("location", text);
            this.isLoadingModal = false;

            resolve(null);
          }
        } else {
          //   Present the alert modal
          this.isLoadingModal = true;
          const text =
            "To ensure your vendors and assigned technicians can easily locate and access your boat and make use of location-based services, we need permission to use your location. This permission was previously denied. For a smooth and efficient service experience, please enable location access in the app settings. ";
          this.showPermissionAlert("location", text);
          this.isLoadingModal = false;

          resolve(null);
        }
      };

      checkPermissions().catch(reject);
    });
  }

  async watchPosition(): Promise<void> {
    const permissionGranted = await this.checkPermission();
    if (!permissionGranted) {
      this.presentModal(
        "To enable us track your proximity to your boat for geofencing features, we need permission to use your location. This permission was previously denied. For a smooth and efficient service experience, please enable location access in the app settings.",
        "Location Access Required"
      );
      return;
    }

    this.watchId = await Geolocation.watchPosition({}, this.handlePositionUpdate);
  }

  private handlePositionUpdate: WatchPositionCallback = async (position, err) => {
    if (err) {
      console.error("Error watching position:", err);
      return;
    }

    this.positionSource.next(position);
    const distance = this.calculateDistance(position.coords, this.currentVesselLocation);

    if (distance > 50) {
      await this.clearWatch();
      await this.startPeriodicPings();
    }
  };

  async startPeriodicPings() {
    const checkPosition = async () => {
      const currentPosition = await Geolocation.getCurrentPosition();
      const distance = this.calculateDistance(currentPosition.coords, this.currentVesselLocation);

      if (distance < 50) {
        // If less than 50m, start watching the position
        this.watchPosition();
      } else {
        // If greater than 50m, clear the watch and continue pinging periodically
        await Geolocation.clearWatch({ id: this.watchId });
        const newPingRate = distance < 100 ? 6000 : 6000; // Adjust ping rate as necessary
        setTimeout(checkPosition, newPingRate);
      }
    };

    // Start the first check immediately
    checkPosition();
  }
}
