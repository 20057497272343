import { Injectable } from "@angular/core";
import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { PhoneNumberUtil } from "google-libphonenumber";

@Injectable({
    providedIn: "root",
})
export class TelephoneService {
    constructor() {}

    phoneValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const number = phoneUtil.parse(control.value);

                const isValidNumber =
                    phoneUtil.isValidNumber(number) ||
                    this.isBypassTestNumber(number);

                return isValidNumber ? null : { invalidPhoneNumber: true };
            } catch (e) {
                return { invalidPhoneNumber: true };
            }
        };
    }

    isBypassTestNumber(value) {
        return (
            value.values_[1] == 1 &&
            (value.values_[2].toString().startsWith("999") ||
                value.values_[2].toString().startsWith("888")) &&
            value.values_[2].toString().length == 10
        );
    }
}
