import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { TalkService } from "src/app/shared/captain-ai-talk/talk.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { ModalController } from "@ionic/angular";
import { ToastService } from "../message/toast.service-impl";
import { SpeechService } from "src/app/services/speech.service";
import { Subscription } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-captain-ai-talk",
  templateUrl: "./captain-ai-talk.component.html",
  styleUrls: ["./captain-ai-talk.component.css"],
})
export class CaptainAiTalkComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() title = "";

  userId: number;

  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  cookieValue: string;
  flagvalue: string;

  placeholder: string;
  popoverMessage: string;

  message: FormControl = new FormControl("");

  isProcessing = false;
  isListening = false;

  private messageSubscription: Subscription;
  private isListeningSubscription: Subscription;

  constructor(
    private service: TalkService,
    public cookiesService: CookieService,
    private translateService: TranslateService,
    public modalCtrl: ModalController,
    private toastService: ToastService,
    private speechService: SpeechService
  ) {
    this.speechService.initializeSpeechRecognition();

    const userSessionData = JSON.parse(localStorage.getItem("sessionData"));
    this.userId = userSessionData?.result.userId;
    this.cookieValue = localStorage.getItem("lang") || "en";
  }

  ngOnInit(): void {
    this.popoverMessage =
      this.cookieValue === "es"
        ? "Utilice nuestro sistema impulsado por IA para usar nuestro reconocimiento de voz y síntesis de voz para completar cualquier elemento donde vea la opción de grabación."
        : "Use our AI Driven system to use our speech to text and text to speech to fill in any item you see the recording option.";
    this.messageSubscription = this.speechService.currentMessage.subscribe((message) => {
      this.message.setValue(message);
    });

    this.isListeningSubscription = this.speechService.isListening$.subscribe((isListening) => {
      this.isListening = isListening;
    });

    if (this.data) {
      this.message.setValue(this.data.value);
    }

    this.placeholder = this.data.placeholder;

    this.translateService.setDefaultLang(this.cookieValue);
  }

  clearText() {
    this.message.setValue("");
  }

  transformText(transformType: "translate" | "revise") {
    if (!this.isListening && !this.isProcessing) {
      const val = this.message.value;

      if (!val) {
        this.toastService.showToast("Text field cannot be empty", 3000, "bottom", null, "danger");
      } else {
        this.isProcessing = true;
        const payload = {
          prompt: val,
          userId: this.userId,
        };
        this.service[transformType](payload).subscribe((data) => {
          this.isProcessing = false;
          this.message.setValue(data.response.message.trim());
        });
      }
    }
  }

  startRecording() {
    this.message.setValue("");
    this.speechService.startListening();
  }

  stopRecording() {
    this.speechService.stopListening();
  }

  sendAndDismissModal() {
    this.modalCtrl.dismiss(this.message.value);
  }

  ngOnDestroy() {
    this.speechService.removeListeners();
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    if (this.isListeningSubscription) {
      this.isListeningSubscription.unsubscribe();
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
