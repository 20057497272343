import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { concatMap, finalize, map, Observable, Subscription } from "rxjs";
import { NetworkStatusCheckService } from "../shared/network-status-check.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EnvironmentService } from "../environment.service";
import { LoaderServiceService } from "../shared/loader-service.service";
import { ToastService } from "../shared/message/toast.service-impl";
import { AlertDialogService } from "../shared/alert-dialog/alert-dialog.service";

import { VesselProfileService } from "../services/vessel-profile.service";
import { AlertController } from "@ionic/angular";
import { LANGUAGE_DATA_PROVIDER } from "./language-data";
import { TranslateService } from "@ngx-translate/core";
import { UploadEntities } from "../shared/imageManagement/file-picker/file-upload.service";
import { BaseService } from "../shared/base.service";
import { BoatProfileService } from "../yacht-owner/boat-manager/boat-profile/boat-profile.service";

@Injectable({
  providedIn: "root",
})
export class ImageUploaderService {
  currentYacht: any;
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  cookieValue: any;
  currentBoatSubscription: Subscription;
  constructor(
    private dbService: NgxIndexedDBService,
    public networkStatusChange: NetworkStatusCheckService,
    private httpClient: HttpClient,
    private env: EnvironmentService,
    private loaderService: LoaderServiceService,
    private toastService: ToastService,
    private alertService: AlertDialogService,
    private vesselProfileService: VesselProfileService,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private baseService: BaseService,
    private boatProfileService: BoatProfileService
  ) {
    this.cookieValue = localStorage.getItem("lang") || "en";
    this.currentBoatSubscription = this.boatProfileService.currentBoat$.subscribe(async (yacht) => {
      if (!yacht) {
        await this.boatProfileService.getCurrentBoat();
      } else {
        this.currentYacht = yacht;
      }
    });
  }

  getImagesAndCount(storeName): Observable<any> {
    return this.getYachtImages().pipe(
      concatMap((data) => {
        const imagesCount = data.result.totalCount;
        const images = data?.result?.items || [];

        return this.getDataFromIndexDb(storeName).pipe(
          map((res) => {
            let processedImages: any[];

            if (imagesCount === 0 && imagesCount !== res.length) {
              processedImages = res.map((img) => this.mapImage(img, true));
            } else if (res.length === 0 && res.length !== imagesCount) {
              processedImages = images.map((img) => this.mapImage(img, true));
            } else if (data.result.items.length < res.length) {
              processedImages = res.map((img) => this.mapImage(img, false));
            } else if (images.length === res.length) {
              processedImages = images.map((img) => this.mapImage(img, true));
            } else if (images.length > res.length) {
              processedImages = images.map((img) => this.mapImage(img, true));
            } else {
              processedImages = res.map((img) => this.mapImage(img, false));
            }

            this.createObjectStore(storeName, processedImages).then();

            return {
              images: processedImages,
              imageCount: imagesCount,
            };
          })
        );
      })
    );
  }

  mapImage(img, uploaded) {
    const imageProp = img.image ? "image" : "imageUrl";

    return {
      image: img[imageProp],
      thumbImage: img[imageProp],
      isFavourite: img.isFavourite,
      isPrimary: img.isPrimary,
      id: uploaded ? img.id : null,
      clientId: img.clientId,
      uploaded: true,
    };
  }

  getImagesFromIndexDb(storeName): Observable<any[]> {
    return new Observable((observer) => {
      this.getDataFromIndexDb(storeName).subscribe((data: any) => {
        if (data.length > 0) {
          observer.next(data);
        }
        observer.complete();
      });
    });
  }

  getDataFromIndexDb(storeName) {
    return this.dbService.getAll(storeName);
  }

  async createObjectStore(storeName, imageData) {
    try {
      const db = await indexedDB.open("YachtWatchDb", 2);

      db.onupgradeneeded = (event) => {
        // @ts-ignore
        const database = event.target.result;

        if (!database.objectStoreNames.contains(storeName)) {
          const objectStore = database.createObjectStore(storeName, {
            keyPath: "id",
            autoIncrement: true,
          });
          objectStore.createIndex("indexName", "propertyToIndex");
        }
      };

      db.onsuccess = (event) => {
        try {
          this.addDataToIndexDb(storeName, imageData);
        } catch (addError) {
          //
        }
      };
    } catch (error) {
      //
    }
  }

  addDataToIndexDb(storeName: string, value: any) {
    this.dbService.clear(storeName).subscribe((successDeleted) => {
      if (successDeleted) {
        const request = indexedDB.open("YachtWatchDb", 2);

        request.onupgradeneeded = (event) => {
          const db = (event.target as any).result;

          if (db.objectStoreNames.contains(storeName)) {
            db.deleteObjectStore(storeName);
          }
          db.createObjectStore(storeName, {
            autoIncrement: true,
          });
        };

        request.onsuccess = (event) => {
          const db = (event.target as any).result;
          if (db.objectStoreNames.contains(storeName)) {
            this.dbService.bulkAdd(storeName, value).subscribe((key) => {
              this.checkForLocalCopy(storeName);
            });
          } else {
            console.error('Object store "ServiceOrderImages" not found!');
          }
        };

        request.onerror = (event) => {
          console.error("Error opening the database:", (event.target as any)?.error);
        };
      }
    });
  }

  async saveImageLocally(storeName, imageData) {
    if (!this.networkStatusChange.getNetworkConnectivity()) {
      this.checkForLocalCopy(storeName);
    } else {
      this.addDataToIndexDb(storeName, imageData);
    }
  }

  checkForLocalCopy(storeName: string): Observable<any> {
    return new Observable((observer) => {
      this.getDataFromIndexDb(storeName).subscribe((data: any) => {
        if (data.length > 0) {
          observer.next(data);
        }
        observer.complete();
      });
    });
  }

  clearLocalStore(storeName) {
    // Check if the storename exists first before clearing

    const request = indexedDB.open("YachtWatchDb", 2);

    request.onsuccess = (event) => {
      const db = (event.target as any).result;
      if (db.objectStoreNames.contains(storeName)) {
        console.log("Clearing object store", storeName);
        this.dbService.clear(storeName).subscribe({
          next: (successDeleted) => {
            //
          },
          error: (err) => {
            console.error(err);
          },
        });
      }
    };

    request.onerror = (event) => {
      console.error("Error opening the database:", (event.target as any)?.error);
    };
  }

  getYachtImages(): Observable<any> {
    return this.httpClient.get(
      this.env.apiUrl +
        "services/app/Yachts/GetMyGalleryImages?maxResultCount=10000&yachtId=" +
        this.currentYacht?.yachtId
    );
  }
  async handleDeleteImage(image, index, images): Promise<boolean> {
    const data = { image, index, images };

    try {
      const deletionConfirmed = await this.showDeleteConfirmationDialog();
      if (deletionConfirmed) {
        return await this.deleteImages(data);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  private async showDeleteConfirmationDialog(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.alertCtrl
        .create({
          header: this.cookieValue === "es" ? "Eliminar Imagen" : "Delete Image",
          message:
            this.cookieValue === "es"
              ? "¿Estás seguro de que deseas eliminar esta imagen?"
              : "Are you sure you want to delete this image?",
          buttons: [
            {
              text: this.cookieValue === "es" ? "Cancelar" : "Cancel",
              role: "cancel",
              handler: () => {
                resolve(false);
              },
            },
            {
              text: this.cookieValue === "es" ? "Eliminar" : "Delete",
              handler: () => {
                resolve(true);
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    });
  }

  private async deleteImages(image): Promise<boolean> {
    this.loaderService.show();

    try {
      if (image.image.clientId) {
        return await this.deleteByClientIds(image);
      } else if (image.image.imageId) {
        return await this.deleteByImageIds(image);
      } else {
        this.loaderService.hide();
        return false;
      }
    } catch (error) {
      this.loaderService.hide();
      return false;
    }
  }

  private deleteByImageIds(image): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.deleteimage(image.image.imageId)
        .pipe(
          finalize(() => {
            this.loaderService.hide();
          })
        )
        .subscribe(
          (data: any) => {
            this.handleDeleteSuccess(image.index);
            resolve(true);
          },
          (error: any) => {
            this.handleDeleteError();
            resolve(false);
          }
        );
    });
  }

  private deleteByClientIds(image): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.deleteByClientId(image.image.clientId)
        .pipe(
          finalize(() => {
            this.loaderService.hide();
          })
        )
        .subscribe(
          (data: any) => {
            this.handleDeleteSuccess(image.index);
            resolve(true);
          },
          (error: any) => {
            this.handleDeleteError();
            resolve(false);
          }
        );
    });
  }

  private handleDeleteSuccess(index) {
    const message = this.translate.instant(this.LANGUAGE_DATA?.IMAGE_BROWSER.IMAGE_DELETED);
    this.toastService.showToast(message, 3000, "bottom", null, "success");
  }

  private handleDeleteError() {
    this.loaderService.hide();
    this.alertService.openAlert({
      message: "Something went wrong trying to remove the image, please try again.",
      useFullAlert: true,
      error: true,
      buttonFunction: () => {
        //
      },
    });
  }

  setPrimaryImage(
    image: any,
    yachtId: number | undefined,
    view?: string | undefined,
    orderDetails?: any | undefined
  ): Observable<any> {
    console.log("image", image, view);
    if (image.clientId) {
      return this.setPrimaryImageByClientId(image.clientId, yachtId, view, orderDetails);
    } else if (image.imageId) {
      return this.setPrimaryImageByImageId(image.imageId, yachtId, view, orderDetails);
    } else {
      throw new Error("No valid image identifier found.");
    }
  }

  private setPrimaryImageByImageId(imageId: number, yachtId: number, view: string, orderDetails: any): Observable<any> {
    if (view === "RECOMMENDEDSERVICE") {
      const serviceOrderId = orderDetails?.serviceOrder ? orderDetails.serviceOrder.id : orderDetails.id;
      return this.httpClient.post(`${this.env.apiUrl}services/app/ServiceOrders/SetRecommendedServicePrimaryImage`, {
        imageId: imageId,
        serviceOrderId: serviceOrderId,
      });
    } else if (view === "SERVICEORDER") {
      const serviceOrderId = orderDetails?.serviceOrder ? orderDetails.serviceOrder.id : orderDetails.id;
      return this.httpClient.post(
        `${this.env.apiUrl}services/app/ServiceOrders/SetServiceOrderPrimaryImage`,
        {
          imageId: imageId,
          serviceOrderId: serviceOrderId,
        },
        {
          params: new HttpParams().set("persona", this.env.persona),
        }
      );
    } else {
      return this.httpClient.post(`${this.env.apiUrl}services/app/Yachts/SetPrimaryGalleryImage`, {
        id: imageId,
        yachtId: yachtId,
      });
    }
  }

  private setPrimaryImageByClientId(
    clientId: string,
    yachtId: number,
    view: string,
    orderDetails: any
  ): Observable<any> {
    if (view === UploadEntities.RECOMMENDED_SERVICE) {
      const serviceOrderId = orderDetails?.serviceOrder ? orderDetails.serviceOrder.id : orderDetails.id;
      return this.httpClient.post(`${this.env.apiUrl}services/app/ServiceOrders/SetRecommendedServicePrimaryImage`, {
        clientId: clientId,
        serviceOrderId: serviceOrderId,
      });
    } else if (view === UploadEntities.SERVICE_ORDER) {
      const serviceOrderId = orderDetails?.serviceOrder ? orderDetails.serviceOrder.id : orderDetails.id;
      return this.httpClient.post(
        `${this.env.apiUrl}services/app/ServiceOrders/SetServiceOrderPrimaryImage`,
        {
          clientId: clientId,
          serviceOrderId: serviceOrderId,
        },
        {
          params: new HttpParams().set("persona", this.env.persona),
        }
      );
    } else {
      return this.httpClient.post(`${this.env.apiUrl}services/app/Yachts/SetPrimaryGalleryImageByClientID`, {
        clientId: clientId,
        yachtId: yachtId,
      });
    }
  }

  showSuccessToast() {
    const message = this.translate.instant(this.LANGUAGE_DATA?.IMAGE_BROWSER.PROFILE_PIC_SUCCESS);
    this.toastService.showToast(message);
  }

  deleteimage(data: any): any {
    return this.httpClient.delete(this.env.apiUrl + "services/app/LightboxImages/Delete?id=" + data, {
      headers: {
        Authorization: `Bearer ${this.baseService.getToken()}`,
        "content-type": "application/json",
      },
    });
  }

  deleteByClientId(data: any): any {
    return this.httpClient.delete(this.env.apiUrl + "services/app/LightboxImages/DeleteByClientId?id=" + data, {
      headers: {
        Authorization: `Bearer ${this.baseService.getToken()}`,
        "content-type": "application/json",
      },
    });
  }
}
