import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import * as Sentry from "@sentry/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";
import { HttpInterceptorService } from "./utilities/http-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import "@capacitor-community/camera-preview";
import { ErrorInterceptor } from "./utilities/error.interceptor";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { NgxPaginationModule } from "ngx-pagination";
import "flatpickr/dist/flatpickr.css";
import { DefaultComponent } from "./authentication/default/default.component";
import { BaseService } from "./shared/base.service";
import { FlatpickrModule } from "angularx-flatpickr";

import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HammerModule } from "@angular/platform-browser";
import { RouteReuseStrategy, Router } from "@angular/router";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgHttpLoaderModule } from "ng-http-loader";
import { StreamingMedia } from "@awesome-cordova-plugins/streaming-media/ngx";
import { SharedDirectivesModule } from "./utilities/directives/shared-directives-module";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { NgImageSliderModule } from "ng-image-slider";
import { ToastService } from "./shared/message/toast.service-impl";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { DBConfig, NgxIndexedDBModule } from "ngx-indexed-db";
import { ServiceOrderCheckListComponent } from "./yacht-owner/serviceRequest/service-order-check-list/service-order-check-list.component";
import { SwiperModule } from "swiper/angular";
import { FullCalendarModule } from "@fullcalendar/angular";
import { BBIconButtonModule } from "./shared/ui/button/bb-button.module";
import { BbHeaderModule } from "./shared/bb-header/bb-header.module";
import { BBTextAreaModule } from "./shared/textarea/textarea.module";
import { TranslationService } from "./utilities/translation.service";
import { SpeechRecognitionService } from "./utilities/speech-recognition.service";
import { ApiService } from "./utilities/api.service";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { TimezoneService } from "./utilities/timezone.service";

import { XmlHttpRequestHelper } from "./helpers/XmlHttpRequestHelper";
import { environment } from "src/environments/environment";
import { merge as _merge } from "lodash";
import { AbpApplicationService } from "src/shared/abp-application.service";

import { SharedModule } from "./shared/shared.module";
import { ImageCropperModule } from "ngx-image-cropper";
import { CaptainTalkComponent } from "./captain-talk/captain-talk.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { API_BASE_URL } from "src/shared/service-proxies/service-proxies";
import { ProfileOnboardingComponent } from "./profile-onboarding/profile-onboarding.component";
import { ServiceProxyModule } from "src/shared/service-proxies/service-proxies.module";
import { ImageManagementModule } from "./shared/image-management/image-management.module";

const config: SocketIoConfig = {
  url: "https://captainai.boatbot.ai/",
  options: {
    forceNew: true,
  },
};

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

export const maskOptions: Partial<IConfig> = {
  thousandSeparator: "'",
};

const dbConfig: DBConfig = {
  name: "YachtWatchDb",
  version: 2,
  objectStoresMeta: [
    {
      store: "YachtWatchImages",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [],
    },
    {
      store: "TechImages",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },
    {
      store: "BoatProfileImages",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },

    {
      store: "ServiceOrderImages",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },
    {
      store: "userProfileImage",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },
  ],
};

function getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
  if (currentProviderName === "unspecifiedClockProvider") {
    return abp.timing.unspecifiedClockProvider;
  }
  if (currentProviderName === "utcClockProvider") {
    return abp.timing.utcClockProvider;
  }
  return abp.timing.localClockProvider;
}

function _appInitializer(abpService: AbpApplicationService) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const token = abpService.getToken();
      const requestHeaders = {};

      if (token) {
        requestHeaders["Authorization"] = "Bearer " + token;
      }

      const baseUrl = environment.abpUrl;

      XmlHttpRequestHelper.ajax(
        "GET",
        baseUrl + "AbpUserConfiguration/GetAll",
        requestHeaders,
        null,
        (response) => {
          const result = response.result;

          _merge(abp, result);

          abp.clock.provider = getCurrentClockProvider(result.clock.provider);

          resolve(true);
        },
        () => {
          resolve(true);
        }
      );
    });
  };
}

// const platform = new Platform('', new NgZone({}));
@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    ServiceOrderCheckListComponent,
    CaptainTalkComponent,
    ProfileOnboardingComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    HttpClientModule,
    NgxPaginationModule,
    FlatpickrModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      scrollAssist: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HammerModule,
    NgHttpLoaderModule.forRoot(),
    SharedDirectivesModule,
    NgxMaskModule.forRoot(maskOptions),
    NgImageSliderModule,
    FlatpickrModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    BrowserAnimationsModule,
    FullCalendarModule,
    ImageManagementModule,
    BBIconButtonModule,
    BbHeaderModule,
    BBTextAreaModule,
    SocketIoModule.forRoot(config),
    NgxIntlTelInputModule,
    ImageCropperModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsConfig.trackingID),
    NgCircleProgressModule.forRoot({
      backgroundPadding: 16,
      radius: 20,
      space: -6,
      outerStrokeWidth: 6,
      innerStrokeWidth: 6,
      showBackground: false,
      startFromZero: false,
      titleFontSize: "10",
      titleFontWeight: "700",
      unitsFontWeight: "800",
      outerStrokeColor: "#00AC31",
      innerStrokeColor: "#EEEDF3",
    }),
    ServiceProxyModule,
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: API_BASE_URL, useValue: environment.proxyServiceUrl },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslationService,
    SpeechRecognitionService,
    ApiService,
    FileOpener,
    JwtHelperService,
    StreamingMedia,
    ScreenOrientation,
    AbpApplicationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ToastService,
    TimezoneService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private baseService: BaseService) {
    GoogleAuth.initialize();
    baseService.getPlatform;
  }
}
