/* eslint-disable no-mixed-spaces-and-tabs */
import { Injectable, NgZone, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { BehaviorSubject, Subject, Observable, tap, map, catchError, throwError } from "rxjs";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EnvironmentService } from "../environment.service";
import { CameraPreview, CameraPreviewOptions, CameraSampleOptions } from "@capacitor-community/camera-preview";
import { Platform } from "@ionic/angular";
import * as CryptoJS from "crypto-js";
import { GalleryPhoto } from "@capacitor/camera";
import { Filesystem, ReadFileOptions, ReadFileResult } from "@capacitor/filesystem";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { Api } from "./api";
import * as moment from "moment";
import { Geolocation } from "@capacitor/geolocation";
import { LocationInfo } from "./maps/location-info";
import { NavigationExtras, Router } from "@angular/router";
import { DBConfig, NgxIndexedDBService } from "ngx-indexed-db";

import { TokenService } from "abp-ng2-module";
import { AbpApplicationService } from "src/shared/abp-application.service";
import { NetworkStatusCheckService } from "./network-status-check.service";
import { v4 as uuidv4 } from "uuid";
import { CheckUserExistsInput, ICheckUserExistsInput, ICheckUserExistsResult, UtilityServiceProxy } from "src/shared/service-proxies/service-proxies";

const cameraPreviewOptions: CameraPreviewOptions = {
  parent: "parent",
  position: "rear",
  toBack: true,
  enableZoom: true,
  disableAudio: true,
};
const dbConfig: DBConfig = {
  name: "YachtWatchDb",
  version: 2,
  objectStoresMeta: [
    {
      store: "YachtWatchImages",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [],
    },
    {
      store: "TechImages",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },
    {
      store: "BoatProfileImages",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },
    {
      store: "ServiceOrderImages",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },

    {
      store: "userProfileImage",
      storeConfig: { keyPath: "recordId", autoIncrement: true },
      storeSchema: [
        {
          name: "recordId",
          keypath: "recordId",
          options: { unique: true },
        },
        {
          name: "timestamp",
          keypath: "timestamp",
          options: { unique: false },
        },
      ],
    },

    // Add more object store definitions as needed
  ],
};
const cameraSampleOptions: CameraSampleOptions = {};

@Injectable({
  providedIn: "root",
})
export class BaseService {
  private contentSource = new BehaviorSubject<any>({
    Authorization: `Bearer ${this.getToken()}`,
    "Content-Type": "application/json",
  });
  currentContent = this.contentSource.asObservable();

  SetContentType(type: any): void {
    this.contentSource.next(type);
  }

  public isYachtWatch = new BehaviorSubject<boolean>(true);
  serviceRequestId = "static-yacht-watch-126453738";
  currentRequestId = "static-yacht-watch-237667899";
  credKey = "stat-352627390096";
  onboardingKey = "ob-352627390096";
  draftRequests: Array<any> = [];
  invisible = false;
  hideText = false;
  netWorkStatus = true;
  isIOS = false;
  routeHistory: any = [false];
  location!: LocationInfo;
  watchId!: any;
  cords: any;
  showTabMenu = true;
  watchYachtCoordinate: any;
  useToken = true;
  public getCurrentLocation = new Subject();
  public loadingSource = new Subject<boolean>();
  public modalSource = new Subject<any>();
  public serviceOder = new Subject<any>();
  public bidHistories = new Subject<any>();
  //hack!
  public onServiceOrderCreated: EventEmitter<any> = new EventEmitter();
  public onProfileUpdated: EventEmitter<any> = new EventEmitter();
  public canNotEdit = false;

  public searchMap = new Subject<any>();
  public parkingLocation = new Subject<any>();
  public maLocationDragEnds = new Subject<any>();

  authenticated = false;
  onboarding = false;
  loader: any;
  inputs: any;
  imageList = new Subject<any[]>();
  images = [];
  deleteList: any = [];
  // cameraImages: Array<CapturedImage> = [];
  public reRoute = new Subject<boolean>();
  public isVendorSaved = new Subject<boolean>();
  public isServiceSaved = new Subject<boolean>();
  public isYachtSaved = new Subject<boolean>();
  public isServiceOrderUpdate = new Subject<boolean>();
  public techImages = new Subject();
  public yoImages = new Subject();
  public vesselImages = new Subject();

  public fullServiceReqImageList = new BehaviorSubject([]);
  public technicianServiceSchedule = new Subject();

  fullServiceReqImageListIs = this.fullServiceReqImageList.asObservable();

  private db: IDBDatabase | null = null;

  slidesOpts = {
    speed: 400,
    slidesPerView: 5,
    spaceBetween: 10,
    pager: true,
  };

  slidesOpts4 = {
    speed: 400,
    slidesPerView: 4,
    spaceBetween: 10,
    pager: true,
  };
  imageslength = 0;
  stripePaymentUrl = `https://www.yachtwatch.ai/payments/stripe?paymentId=`;

  private openModalSubject = new Subject<void>();
  removeAuthorizationHeader = new Subject<boolean>();
  openModal$ = this.openModalSubject.asObservable();
  private mimeToExtension: { [key: string]: string } = {
    "text/plain": "txt",
    "image/jpeg": "jpg",
    "image/png": "png",
    "application/pdf": "pdf",
    // Add other MIME types and extensions as needed
  };
  private responseSubject = new Subject<any>();
  response$ = this.responseSubject.asObservable();
  constructor(
    private httpClient: HttpClient,
    public API: Api,
    private platform: Platform,
    // private flashlight: Flashlight,
    public router: Router,
    private zone: NgZone,
    private env: EnvironmentService,
    private dbService: NgxIndexedDBService,
    private _tokenService: TokenService,
    private utilityServiceProxy: UtilityServiceProxy,
    private networkStatusCheckService: NetworkStatusCheckService
  ) {
    this.initDatabase();

    platform.ready().then(async () => {
      if (this.platform.is("capacitor")) {
        await StatusBar.setStyle({ style: Style.Light });
      }
      this.networkStatusCheckService.networkStatusChangeEvent.subscribe({
        next: (status) => {
          this.zone.run(() => {
            this.netWorkStatus = status;
            this.canNotEdit = !status;
          });
        },
      });

      // Network.addListener("networkStatusChange", (status) => {
      //     this.zone.run(() => {
      //         this.netWorkStatus = status.connected;
      //         this.canNotEdit = !status.connected;
      //     });
      // });
    });

    this.hideText = screen.width <= 375;

    // document.addEventListener("click", () => {
    //     if (
    //         document.activeElement?.tagName === "BODY" &&
    //         Capacitor.isNativePlatform()
    //     ) {
    //         this.hidekeyboard();
    //     }
    // });
  }

  emitResponse(response: any) {
    this.responseSubject.next(response);
  }

  public initDatabase(): void {
    const request = window.indexedDB.open(dbConfig.name, dbConfig.version);

    request.onerror = (event) => {
      // @ts-ignore
      console.error("Database error:", event.target.error);
    };

    request.onupgradeneeded = (event) => {
      this.db = (event.target as IDBOpenDBRequest).result;

      dbConfig.objectStoresMeta.forEach((storeMeta) => {
        const objectStore = this.db.createObjectStore(storeMeta.store, storeMeta.storeConfig);

        storeMeta.storeSchema.forEach((schema) => {
          objectStore.createIndex(schema.name, schema.keypath, schema.options);
        });
      });
    };

    request.onsuccess = (event) => {
      this.db = (event.target as IDBOpenDBRequest).result;
    };
  }

  formatDate(date: any) {
    return moment(date).format("MM/DD/YYYY");
  }
  CustomFormatDate(date: any, format: any) {
    return moment(date).format(format);
  }

  formatTime(date: any) {
    return moment.utc(date).format("h:mm A");
  }
  formatDateVal(date: any, format: any, current = null) {
    return moment(date, current).format(format);
  }
  addToDate(date: any, type: any, amount: any, val: any) {
    if (type == "+") {
      // return moment(date).add(amount, val).format('dd/mm/yyyy');
      return moment(date).add(amount, val).format("YYYY-MM-DD").toString();
    } else if (type == "-") {
      return moment(date).subtract(amount, val).format("YYYY-MM-DD").toString();
    } else {
      return 0;
    }
  }

  openModal() {
    this.openModalSubject.next();
  }

  formatCurrency(amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(amount);
  }

  dateDiff(date1: any, date2: any) {
    return moment(date1).isAfter(moment());
  }
  getDate() {
    return moment().format("yyyy-MM-DD");
  }
  static uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  get getPlatform(): boolean {
    this.isIOS = this.platform.is("ios");
    return this.isIOS;
  }

  isTablet() {
    return this.platform.platforms().findIndex((x) => x === "ipad") > -1;
  }

  get getWidth(): boolean {
    const varia = this.platform.height();
    if (varia > 300) return false;
    else return true;
  }

  randomNumbers() {
    const min = 10000;
    const max = 100000000;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // removePhotoByIndex(index: any) {
  //     this.images.splice(index, 1);
  //     this.techImages.next(this.images);
  //     this.yoImages.next([]);
  // }

  removePhotoById(id: any) {}

  // removePhoto(url: any, list: CapturedImage[]) {
  //     list.filter((item) => {
  //         if (item.base64Image == url) {
  //             const index = list.indexOf(item);
  //             list.splice(index, 1);
  //         }
  //     });
  // }

  // async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  //   //Todo can we add the file type dynamically { type: 'image/png' }
  //   const res: Response = await fetch(dataUrl);
  //   const blob: Blob = await res.blob();
  //   return new File([blob], fileName, { type: "image/png" });
  // }

  // async takePicture(dataSource: any = null) {
  //     this.images = [];

  //     try {
  //         if (dataSource === null) {
  //             const image = await Camera.pickImages({ limit: 5 });
  //             const files = await Promise.all(
  //                 image.photos.map(async (photo) => this.adaptFile(photo))
  //             );
  //             files.forEach((file) =>
  //                 this.images.push({
  //                     base64Image: `${file.data}`,
  //                     imageData: file.data,
  //                     imageId: BaseService.uuid(),
  //                 })
  //             );
  //         } else {
  //             this.images = dataSource;
  //         }

  //         this.techImages.next(this.images);
  //         this.yoImages.next(this.images);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  public hasVendors(): any {
    const url = this.env.apiUrl + `/services/app/YachtOwners/HasVendors`;
    return this.httpClient.get(url);
  }

  private async adaptFile(photo: GalleryPhoto): Promise<ReadFileResult> {
    if (!Capacitor.isNativePlatform) {
      try {
        return await Filesystem.readFile(<ReadFileOptions>{
          path: photo.path,
        });
      } catch (error) {
        return null;
      }
    } else {
      const response = await fetch(photo.webPath!);
      const blob = await response.blob();
      const data = (await this.convertBlobToBase64(blob)) as string;
      return { data };
    }
  }

  private convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  switchCam() {
    CameraPreview.flip();
  }

  switchLight() {
    // this.flashlight.toggle();
  }

  getLightState() {
    // return this.flashlight.isSwitchedOn();
  }

  clearMessages() {
    this.serviceOder.next(null);
  }

  getMessage(): Observable<any> {
    return this.serviceOder.asObservable();
  }

  // async capture(source: any = { techCamera: false }) {
  //     const result = await CameraPreview.captureSample(cameraSampleOptions);
  //     if (!source.techCamera) {
  //         this.cameraImages.push({
  //             base64Image: `data:image/png;base64,${result.value}`,
  //             imageData: result.value,
  //             imageId: 0,
  //         });
  //     } else {
  //         this.cameraImages.push(source);
  //     }
  // }

  async startCamera(): Promise<any> {
    return await CameraPreview.start(cameraPreviewOptions);
  }

  stopCamera() {
    CameraPreview.stop();
  }

  createSession(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  updateAllLocalStorageFirstNames(firstName: string) {
    const localStorageKeys = ["stat-352627390096", "UserProfile", "cap_talk_authData", "captainAiData"];

    localStorageKeys.forEach((key) => {
      const item = localStorage.getItem(key);
      if (item) {
        try {
          const data = JSON.parse(item);
          if (typeof data === "object" && data !== null) {
            if ("firstName" in data) {
              data.firstName = firstName;
            } else if (
              "user" in data &&
              typeof data.user === "object" &&
              data.user !== null &&
              "firstName" in data.user
            ) {
              data.user.firstName = firstName;
            }
            localStorage.setItem(key, JSON.stringify(data));
          }
        } catch (error) {
          console.error(`Error updating firstName for ${key}:`, error);
        }
      }
    });
  }

  getSession(key: string): any {
    if (localStorage.getItem(key)) {
      return JSON?.parse(<string>localStorage.getItem(key));
    }
    return false;
  }

  createSessionData(key: string, data: any) {
    const user = this.getSession(this.credKey);
    if (user) {
      localStorage.setItem(`${key}_${user.id}`, JSON.stringify(data));
    } else {
      console.log("User not found");
    }
  }

  getUserProfile() {
    if (localStorage.getItem("UserProfile")) {
      return JSON.parse(<string>localStorage.getItem("UserProfile"));
    }
    return false;
  }

  setHasVendors() {
    localStorage.setItem("HAS_VENDOR", "true");
  }

  getHasVendors() {
    if (localStorage.getItem("HAS_VENDOR")) {
      return true;
    }
    return false;
  }

  clearSession(key: string): void {
    localStorage.removeItem(key);
  }

  setExpiry(value: any) {
    this.createSession("expiry", value);
  }

  checkIsNotFirstTimer(): boolean {
    return this.getSession("first-646464");
  }

  setSessionData(data: any): void {
    this.abpLogin(data);
    localStorage.setItem("sessionData", JSON.stringify({ result: data }));
  }

  abpLogin(sessionData: IAuthenticateResultModel) {
    const tokenExpireDate = new Date(new Date().getTime() + 1000 * sessionData.expireInSeconds);

    this._tokenService.setToken(sessionData.accessToken, tokenExpireDate);

    AbpApplicationService.setToken(sessionData.accessToken);

    if (sessionData.refreshToken) {
      const refreshTokenExpireDate = new Date(new Date().getTime() + 1000 * sessionData.refreshTokenExpireInSeconds);
      this._tokenService.setRefreshToken(sessionData.refreshToken, refreshTokenExpireDate);
    }

    localStorage.setItem(
      "AppEncryptedToken",
      JSON.stringify({
        token: sessionData.encryptedAccessToken,
        expireDate: tokenExpireDate,
      })
    );
  }

  getToken(): any {
    if (this.getSessionData().result) {
      return this.getSessionData().result.accessToken;
    }
    return false;
  }

  getRefreshToken(): any {
    if (this.getSessionData().result) {
      return this.getSessionData().result.refreshToken;
    }
    return false;
  }

  getSessionData(): any {
    if (localStorage.getItem("sessionData")) {
      return JSON.parse(<string>localStorage.getItem("sessionData"));
    }
    return false;
  }

  updateSessionData(key: string, value: any): void {
    const sessionData = this.getSessionData();
    sessionData.result[key] = value;
    localStorage.setItem("sessionData", JSON.stringify(sessionData));
  }
  closeModal() {
    this.modalSource.next(null);
  }

  public blobToFile(blob: Blob, fileName: string): File {
    const file = new File([blob], fileName, { type: blob.type, lastModified: Date.now() });
    return file;
  }

  public generateUniqueFileName(mimeType: string): string {
    const extension = this.mimeToExtension[mimeType] || "bin";
    const uuid = uuidv4();
    return `file_${uuid}.${extension}`;
  }

  generateUniqueId() {
    return uuidv4();
  }

  requestPasswordReset(email: any) {
    return this.httpClient
      .post(this.env.apiUrl + "services/app/Account/ForgotPasswordReset", { identifier: email })
      .pipe(
        catchError((err) => {
          this.handleError(err);
          throw err;
        })
      );
  }

  resetPassword(resetCode: string, email: string, newPassword: string): any {
    return this.httpClient.post(
      this.env.apiUrl + "TokenAuth/ChangePassWordWithCode",
      { resetCode, identifier: email, newPassword },
      { headers: { "content-type": "application/json" } }
    );
  }

  hidekeyboard() {
    Keyboard.hide();
  }

  isLoggedIn(): any {
    return !!localStorage.getItem("sessionData") || !!localStorage.getItem("credUser");
  }

  isLoggedIn_(): any {
    return !!localStorage.getItem("sessionData");
  }

  clearSessionData(): void {
    localStorage.removeItem("sessionData");
    localStorage.removeItem("SO_list");
    this.createSession("first-646464", false);
    this.clearLocalStore();
  }

  setUserProfile(data: any): void {
    localStorage.setItem("UserProfile", JSON.stringify(data));
  }

  //The set method is use for encrypt the value.
  set(keys: any, value: any) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys: any, value: any) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  stripePayLink(id: number): string {
    return `${this.stripePaymentUrl + id}`;
  }

  async currentLocation() {
    const options = {
      timeout: 300000,
      maximumAge: 300000,
      enableHighAccuracy: true,
    };
    this.isYachtWatch.subscribe((canUpdate) => {
      if (canUpdate) {
        try {
          this.watchId = Geolocation.watchPosition(options, (result: any, error) => {
            this.zone.run(() => {
              this.watchYachtCoordinate = result;
            });
          });
        } catch (err) {}
      } else {
        //If user does not allow permission do not track/clear watch
        Geolocation.clearWatch({ id: this.watchId });
      }
    });
  }

  getCurrent() {
    return this.watchYachtCoordinate;
  }

  // **** ROUTING **** //
  routeNav(data) {
    const navigationExtras: NavigationExtras = {
      state: { orderDetails: data, action: false, serviceAction: "view" },
    };
    this.routeHistory = [true, "/yacht-owner/dashboard"];
    this.router.navigate(["/yacht-owner/service-order"], navigationExtras);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error);
    } else {
      console.log(error);
    }
    return throwError("Something bad happened; please try again later.");
  }

  clearLocalStore() {
    this.dbService.clear("BoatProfileImages").subscribe({
      next: (successDeleted) => {
        //
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  configureKeyboard() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  resetKeyboardSettings() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Body });
    }
  }

  checkUserExists(data:ICheckUserExistsInput): Observable<ICheckUserExistsResult>{
    const checkUserExistsInput = new CheckUserExistsInput();
    Object.assign(checkUserExistsInput, data);
    return this.utilityServiceProxy.checkUserExists(checkUserExistsInput);
  }
}

export interface IAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number;
  shouldResetPassword: boolean;
  passwordResetCode: string | undefined;
  userId: number;
  requiresTwoFactorVerification: boolean;
  twoFactorAuthProviders: string[] | undefined;
  twoFactorRememberClientToken: string | undefined;
  returnUrl: string | undefined;
  refreshToken: string | undefined;
  refreshTokenExpireInSeconds: number;
  c: string | undefined;
}
