import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "../environment.service";

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  public userPreferredTimezone = '';

  constructor(    private httpClient: HttpClient,
                  private env: EnvironmentService) { }

  getUserPreferredTimezone(): string {
    return this.userPreferredTimezone;
  }

  setUserPreferredTimezone(timezone: string): void {
    this.userPreferredTimezone = timezone;
    console.log('new time zone', timezone);
  }
  getUserTimeZone(): any {
    this.getUserCurrentTimeZone().subscribe((data) => {
      this.setUserPreferredTimezone(data?.result);
      console.log('user time zone', data);

    });
  }

  passTimeZone(selected): any {
    this.setTimeZone({ timezone: selected.name }).subscribe((data) => {
      console.log('passed time zone', data);
      this.getUserTimeZone();
    });
  }

  setTimeZone(timeZone): Observable<any> {
    return this.httpClient.post(
        this.env.apiUrl + "services/app/Profile/SetUserTimeZone", timeZone
    );
  }
  getUserCurrentTimeZone(): Observable<any> {
    return this.httpClient.get(
        this.env.apiUrl + "services/app/Profile/GetUserTimeZone"
    );
  }

  getUsersTimeZone(): Observable<any> {
    return this.httpClient.get(
        this.env.apiUrl + "services/app/Profile/GetUserTimeZone"
    );
  }

  getTimeZoneList(): Observable<any> {
    return this.httpClient.get(
        this.env.apiUrl + "services/app/Timing/GetIanaTimezones?DefaultTimezoneScope=1"
    );
  }

}
