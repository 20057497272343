import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FinanceService {}

export interface FinancialDocumentDisplayData {
  vendorId: number;
  quickBooksInvoiceId?: string;
  tax?: number;
  discount?: number;
  balanceDue?: number;
}

export interface FinancialDocumentHeaderInfo {
  label: string;
  id: number;
  issuedDate: Date;
  validityDate?: Date;
  fromName: string;
  toName: string;
  logo?: string;
  relatedEntityId?: number;
  relatedEntityTypeLabel?: string;
}

export interface FinancialDocumentLineItem {
  id?: number;
  title: string;
  description?: string;
  quantity?: number;
  amount?: number;
  unitPrice?: number;
  discount?: number;
  total?: number;
  tax?: number;
  unitMeasure?: string;
}
