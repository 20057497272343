import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subject } from "rxjs";
import { AlertDialog } from "./alert-dialog";
import { AlertDialogComponent } from "./alert-dialog.component";

@Injectable({
    providedIn: "root",
})
export class AlertDialogService {
    alertDialogSub = new Subject<{ options: AlertDialog; show: boolean }>();
    constructor(private modalCtrl: ModalController) {}

    openAlert(dialog: AlertDialog) {
        this.alertDialogSub.next({ options: dialog, show: true });
    }

    async showAlert(
        title: string,
        message: string,
        actions: { label: string; handler?: () => void }[] = []
    ): Promise<any> {
        const modal = await this.modalCtrl.create({
            component: AlertDialogComponent,
            componentProps: {
                title,
                message,
                actions,
            },
            cssClass: "blurry-backdrop-auto",
        });

        await modal.present();
        return modal.onDidDismiss();
    }
}
