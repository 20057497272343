import { Injectable, NgZone } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { ActionPerformed, PushNotifications } from "@capacitor/push-notifications";
import { LoginServiceService } from "../auth/login/login-service.service";
import { FCM } from "@capacitor-community/fcm";
import "@capacitor-community/fcm";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { EnvironmentService } from "../environment.service";
import { EstimateDetailComponent } from "../yacht-owner/finance/estimate/estimate-detail/estimate-detail.component";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  notificationSource = new BehaviorSubject(null);
  currentNotification = this.notificationSource.asObservable();

  constructor(
    private router: Router,
    private loginService: LoginServiceService,
    private platform: Platform,
    private alertController: AlertController,
    private env: EnvironmentService,
    private ngZone: NgZone,
    private modalCtrl: ModalController
  ) {}

  async registerPushNotification() {
    await PushNotifications.checkPermissions();

    const permission = await PushNotifications.requestPermissions();

    if (permission?.receive === "granted") {
      PushNotifications.register();
      FCM.subscribeTo({ topic: "users" })
        .then((r) => {
          console.log(r.message + "subscription done");
          if (this.platform.is("ios")) {
            FCM.subscribeTo({ topic: "ios" }).then((res) => {
              console.log(res.message + "subscription to ios done");
            });
          } else {
            FCM.subscribeTo({ topic: "android" }).then((res) => {
              console.log(res.message + "subscription to ios done");
            });
          }
        })
        .catch((err) => console.log(err));
    }

    PushNotifications.addListener("registration", (token) => {
      this.saveDeviceToken(token.value);
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    PushNotifications.addListener("pushNotificationReceived", (notifications) => {
      console.log("Push received: " + JSON.stringify(notifications));
      this.notificationSource.next(notifications);
    });

    PushNotifications.addListener("pushNotificationActionPerformed", async (notification: ActionPerformed) => {
      console.log("Push action performed: " + JSON.stringify(notification));
      if (notification) {
        console.log("notification before platform ready", notification);
        await this.platform.ready();
        this.ngZone.run(() => {
          this.openNotificationDetails(notification);
        });
      }
    });
  }

  private async openNotificationDetails(notification) {
    const data: DeepLinkNotification = await notification.notification.data;
    switch (data.messageType) {
      case DATA_TYPE.PLAIN:
        this.openPlainNotification(notification.notification);
        break;
      case DATA_TYPE.DEEPLINK:
        switch (data.linktype) {
          case DATA_TYPE.INVOICE:
            this.handleInvoiceNotification(data.entityid);
            break;

          case DATA_TYPE.SERVICE_ORDER:
            this.handleServiceOrderNotification(data.entityid);
            break;

          case DATA_TYPE.VENDOR:
            this.handleVendorNotification(data.entityid);
            break;
          case DATA_TYPE.PACKAGE:
            this.handlePackageNotification(data.entityid);
            break;

          case DATA_TYPE.BOAT_MANAGER:
            this.handleBoatManagerNotification(data.entityid);
            break;
          case DATA_TYPE.BOAT_TRANSFER:
            this.handleBoatManagerNotification(data.entityid);
            break;
          case DATA_TYPE.ESTIMATE:
            this.handleEstimateNotification(data.entityid);
            break;

          default:
            this.router.navigate(["/default"]);
            break;
        }
        break;
      default:
        this.router.navigate(["/default"]);
        break;
    }
  }

  private async handlePackageNotification(entityId) {
    this.router.navigate(["/yacht-owner/packages"], {
      queryParams: {
        deeplink: true,
        packageId: entityId,
      },
    });
  }

  private async handleInvoiceNotification(entityid) {
    this.router.navigate([`yacht-owner/invoices/${entityid}`]);
  }

  private async handleVendorNotification(entityid) {
    const navigationExtras: NavigationExtras = {
      state: {
        deeplinkData: { vendorId: entityid },
      },
    };

    this.router.navigate(["/yacht-owner/service-providers"], navigationExtras);
  }

  private async handleBoatManagerNotification(entityid) {
    this.router.navigate(["/yacht-owner/boat-manager"]);
  }

  private async handleServiceOrderNotification(entityid) {
    if (this.env.buildType == "boatowner") {
      this.router.navigate([`/yacht-owner/service-orders/view/${entityid}`]);
    } else {
      this.router.navigateByUrl(`/technician/service-order-detail?id=${parseInt(entityid)}`);
    }
  }

  private async handleEstimateNotification(entityid) {
    const modal = await this.modalCtrl.create({
      component: EstimateDetailComponent,
      componentProps: {
        estimateId: entityid,
      },
    });
    modal.componentProps["modalRef"] = modal;

    modal.present();

    const { data } = await modal.onDidDismiss();
    this.router.navigate(["/yacht-owner/estimates"]);
  }

  resetBadgeCount() {
    PushNotifications.removeAllDeliveredNotifications();
  }

  private async saveDeviceToken(token) {
    this.loginService.saveDeviceToken(token).subscribe((res) => {
      //
    });
  }

  private async openPlainNotification(info: PlainNotification) {
    const alert = await this.alertController.create({
      header: info.title,
      subHeader: info.body,
      buttons: [
        {
          text: "Ok",
          role: "confirm",
          handler: () => {
            //
          },
        },
      ],
    });

    await alert.present();
  }
}

export const DATA_TYPE = {
  PLAIN: "plain",
  DEEPLINK: "deeplink",
  INVOICE: "invoice",
  SERVICE_ORDER: "serviceorder",
  MESSAGE: "message",
  VENDOR: "vendor",
  MULTIVENDOR: "multivendor",
  BOAT_MANAGER: "boatmanager",
  PACKAGE: "package",
  JOB_NOTES: "jobnotes",
  NOTIFICATION: "notification",
  BOAT_TRANSFER: "boattransfer",
  ESTIMATE: "estimate",
};

interface DeepLinkNotification {
  messageType: string;
  deeplink: string;
  entityid: string;
  linktype: string;
  sublinktype: string;
}
interface PlainNotification {
  title: string;
  body: string;
}
