import { Injectable } from "@angular/core";
// import { SpeechRecognition } from "@awesome-cordova-plugins/speech-recognition/ngx";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SpeechRecognitionService {
    constructor(
        // private speechRecognition: SpeechRecognition
    ) {}

    init() {
        // Initialization not required for this plugin
    }

    start(): Observable<any> {
        return new Observable((observer) => {
            const lang =
                localStorage.getItem("lang") === "en" ? "en-US" : "es-ES";
            const option: any = {
                language: lang,
                matches: 1,
                prompt: "Speech recognition",
                showPopup: false,
                showPartial: false,
            };
            observer.next({ transcript:"", blob: null });
            //                 }
            // this.speechRecognition.requestPermission().then(
            //     () =>
            //         this.speechRecognition.startListening(option).subscribe(
            //             (matches: Array<string>) => {
            //                 console.log("here is match", matches);
            //                 if (matches && matches.length > 0) {
            //                     const transcript = matches[0];
            //                     console.log("transcript", transcript);
            //                     observer.next({ transcript, blob: null });
            //                 }
            //             },
            //             (error: any) => {
            //                 console.error("Speech Recognition Error:", error);
            //                 observer.error(error);
            //             }
            //         ),
            //     () => console.log("Denied. Invalid")
            // );
        });
    }

    stop() {
        // this.speechRecognition.stopListening();
    }
}
