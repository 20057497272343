import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../../shared/base.service";
import { EnvironmentService } from "../../environment.service";
import { firstValueFrom, Observable, retry } from "rxjs";
import { LogoutService } from "src/app/yacht-owner/logout.service";
import {
  ChangePasswordWithCodeInput,
  IChangePasswordWithCodeInput,
  TokenAuthServiceProxy,
} from "src/shared/service-proxies/service-proxies";

@Injectable({
  providedIn: "root",
})
export class LoginServiceService {
  private isRefreshingToken = false;
  constructor(
    private httpClient: HttpClient,
    private env: EnvironmentService,
    private _baseService: BaseService,
    private logoutService: LogoutService,
    private tokenAuthServiceProxy: TokenAuthServiceProxy
  ) {}

  login(userDetails: any): any {
    return this.httpClient.post(
      this.env.apiUrl + "TokenAuth/Authenticate",
      userDetails,
      { headers: { "content-type": "application/json" } }
    );
  }

  public async checkIsTokenExpired() {
    const tokenExpirationTimeInMilliseconds =
      this._baseService.getSessionData()?.result?.tokenExpiryDateInSeconds;

    const currentDate = new Date();
    const timeDiff = tokenExpirationTimeInMilliseconds - currentDate.getTime();

    if (timeDiff < 0) {
      await this.logoutService.logout();
    } else if (timeDiff < 60000) {
      await this.refreshToken();
    }
  }

  refreshToken(): Promise<void> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      const refreshToken = this._baseService.getRefreshToken();

      return firstValueFrom(
        this.httpClient.post(
          this.env.apiUrl +
            "TokenAuth/RefreshToken?refreshToken=" +
            refreshToken,
          {},
          { headers: { "content-type": "application/json" } }
        )
      )
        .then((res: any) => {
          if (res.success) {
            this.isRefreshingToken = false;
            this._baseService.updateSessionData(
              "accessToken",
              res.result.accessToken
            );
            this._baseService.updateSessionData(
              "expireInSeconds",
              res.result.expireInSeconds
            );
            const tokenExpiryDateInSeconds =
              new Date().getTime() + res.result.expireInSeconds * 1000;
            this._baseService.updateSessionData(
              "tokenExpiryDateInSeconds",
              tokenExpiryDateInSeconds
            );
          } else {
            this.isRefreshingToken = false;
            this.logoutService.logout();
          }
        })
        .catch((err) => {
          this.isRefreshingToken = false;
          this.logoutService.logout();
          throw err; // Optional: rethrow the error for further handling.
        });
    } else {
      console.log("Token is already being refreshed");
      return Promise.resolve();
    }
  }

  getFBUserInfo(accessToken): Observable<any> {
    return this.httpClient.get(
      `https://graph.facebook.com/me?fields=email,last_name,first_name,gender&access_token=${accessToken}`,
      { headers: { "content-type": "application/json" } }
    );
  }

  register(registerDetails: any): any {
    return this.httpClient.post(
      this.env.apiUrl + "services/app/Account/RegisterNew",
      registerDetails
    );
  }

  getCurrentLoginInformations(): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/Session/GetCurrentLoginInformations?&persona=${this.env.persona}`,
      { headers: { "content-type": "application/json" } }
    );
  }

  /**
   * OAuth sign in service
   * @param signinData
   * @returns
   */
  OAuthSignIn(signData: any): Observable<any> {
    return this.httpClient.post(
      this.env.apiUrl + "tokenAuth/SocialAuth",
      signData,
      { headers: { "content-type": "application/json" } }
    );
  }

  /**
   * OAuth sign in service
   * @param signinData
   * @returns
   */
  OAuthAppleSignIn(signData: any): any {
    return this.httpClient.post(
      this.env.apiUrl + "TokenAuth/SocialAuth",
      signData,
      { headers: { "content-type": "application/json" } }
    );
  }

  /**
   * OAuth sign up service
   * @param signupData
   * @returns
   */
  OAuthSignUp(signupData: any): any {
    const url = this.env.apiUrl + "TokenAuth/SocialAuth";
    return this.httpClient.post(url, signupData, {
      headers: { "content-type": "application/json" },
    });
  }

  /**
   * Checks if an email address or phone number is available.
   * @param data An object containing either an `emailAddress` or a `phoneNumber`.
   * @returns An observable that resolves to the server response.
   */
  checkEmailPhoneAvailability(data: {
    emailAddress?: string;
    phoneNumber?: string;
  }): any {
    const url = this.env.apiUrl + "services/app/Utility/CheckUserExists";
    return this.httpClient.post(url, data, {
      headers: { "content-type": "application/json" },
    });
  }
  /**
   * Save user token/player id
   * @param data
   * @returns
   */
  saveDeviceToken(token) {
    const payload = {
      token: `${token}`,
      platform: this._baseService.getPlatform ? "ios" : "android",
      persona: this.env.persona,
    };
    const url = "services/app/Account/UpdateUserDeviceToken";
    return this.httpClient.put(this.env.apiUrl + url, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${this._baseService.getToken()}`,
      },
    });
  }

  authenticateByCode(details: any): any {
    return this.httpClient.post(
      this.env.apiUrl + "TokenAuth/AuthenticateByCode",
      details
    );
  }

  resendOtp(details: any): any {
    return this.httpClient.post(
      this.env.apiUrl + "services/app/Account/InitiateSignInByCode",
      details
    );
  }

  resetPassword(payload: IChangePasswordWithCodeInput): Observable<string> {
    const body = new ChangePasswordWithCodeInput(payload);
    return this.tokenAuthServiceProxy.changePassWordWithCode(body);
  }
}
