import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control: any = formGroup.controls[controlName];
        const matchingControl: any = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function MustNotMatch(currentPasswordControlName: string, newPasswordControlName: string) {
    return (formGroup: FormGroup) => {
        const currentPasswordControl: any = formGroup.controls[currentPasswordControlName];
        const newPasswordControl: any = formGroup.controls[newPasswordControlName];

        if (newPasswordControl.errors && !newPasswordControl.errors['mustNotMatch']) {
            // return if another validator has already found an error on the newPasswordControl
            return;
        }

        // set error on newPasswordControl if validation fails
        if (currentPasswordControl.value === newPasswordControl.value) {
            newPasswordControl.setErrors({ mustNotMatch: true });
        } else {
            newPasswordControl.setErrors(null);
        }
    }
}
