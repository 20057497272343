import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {from, map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}
  private readonly API_URL =
    'https://t0l2nx1w71.execute-api.us-west-2.amazonaws.com/default/ChatBots';

  getAlternativeText(text: string) {
    let bodyObj = {
      metadata: text,
      callPreProcessorFn: true,
      mode: 'simple',
      heatmapAIModel: 'boatbot',
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(this.API_URL, bodyObj, { headers }).pipe(
      map((response) => {
        if (response.response) {
          console.log(' response', JSON.parse(response.response));
          let responseObj = JSON.parse(response.response);
          return {
            severity: responseObj.importance,
            reason: responseObj.suggestion,
            alternative: responseObj.alternative,
          };
        } else {
          throw new Error(' failed.');
        }
      })
    );
  }


}
