import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EnvironmentService } from "../../environment.service";
import { BaseService } from "../../shared/base.service";
import { Observable } from "rxjs";
import { AccountServiceProxy, TokenAuthServiceProxy, UserAccountSetting, UtilityServiceProxy } from "src/shared/service-proxies/service-proxies";

@Injectable({
    providedIn: "root",
})
export class AccountSettingService {
    constructor(
        private httpClient: HttpClient,
        private env: EnvironmentService,
        private _baseService: BaseService,
        private accountServiceProxy: AccountServiceProxy,
        private utilityServiceProxy : UtilityServiceProxy,
        private tokenAuthServiceProxy: TokenAuthServiceProxy
    ) { }
    updateUserProfilePicture(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Utility/UploadProfilePicture",
            data,
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                    encType: "multipart/form-data",
                },
            }
        );
    }

    getUserProfilePicture(id: any): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/Utility/GetProfilePicture?id=${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                },
            }
        );
    }

    updateUserProfile(data: any): Observable<UserAccountSetting> {
        const params: { [key: string]: any } = Array.from(data.keys()).reduce((acc, key) => {
            acc[key as string] = data.get(key);
            return acc;
        }, {} as { [key: string]: any });  

        return this.accountServiceProxy.updateUserProfile(
            this.env.persona,
            params['userId'],
            params['firstName'],
            params['lastName'],
            params['phoneNumber'],
            params['country'],
            "",
            params['state'],
            "",
            params['city'],
            params['zipCode'],
            params['address'],
            params['emailAddress'],
            false,
            "",
            false,
            false
        );

    }

    saveSupportHelpInfo(data: any): any {
        return this.httpClient.post(
            `${this.env.apiUrl}services/app/HubSpot/SubmitTicket`,
            data
        );
    }    

    getUserProfile(): Observable<UserAccountSetting> {
        return this.accountServiceProxy.getUserProfile(this.env.persona);
    }
    
    getYoAccountDetails(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/UserWallet/GetWalletInfo`
        );
    }

    getYoCode(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/YachtOwners/GetReferralCode`
        );
    }

    referYachtOwner(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/YachtOwners/ReferYachtOwner",
            data
        );
    }

    makeDeposit(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/UserWallet/UserWalletDeposit",
            data
        );
    }

    transferAccount(data: any) {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Account/VesselTransfer",
            data
        );
    }

    deleteAccount() {
        return this.tokenAuthServiceProxy.deleteAccount();
    }

    applyPromoCode(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Discount/ApplyPromoCode",
            data,

        )
    }

}
