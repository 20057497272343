import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { BaseService } from "../../../shared/base.service";
import { EnvironmentService } from "../../../environment.service";

export const CREATE_SERVICE_REQUEST_ID = "ID.CREAT_SERVICE_REQUEST";
export const GET_SERVICE_REQUEST_ID = "ID.GET_SERVICE_REQUEST";
@Injectable({
    providedIn: "root",
})
export class ServiceRequestService {

    private serviceOrderCreateSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public serviceOrderCreate$: Observable<any> = this.serviceOrderCreateSubject.asObservable();

    constructor(
        private httpClient: HttpClient,
        private env: EnvironmentService,
        private _baseService: BaseService
    ) {}

    // Known in-use methods
    AcceptOrRejectRecommendedService(data: {
        id: any;
        isAccepted: boolean;
    }): Observable<any> {
        return this.httpClient.put(
            `${this.env.apiUrl}services/app/ServiceOrders/AcceptOrRejectRecommendedServiceForYO`,
            data
        );
    }

    setServiceOrderCreate(data: any) {
        this.serviceOrderCreateSubject.next(data);
      }
    
      getServiceOrderCreate() {
        return this.serviceOrderCreateSubject.value;
      }
    

    acceptEstimate(data: any) {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Invoices/AcceptEstimate",
            data
        );
    }

    rejectEstimate(data: any) {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Invoices/RejectEstimate",
            data
        );
    }

    // Get all services that the user can request
    getServicesForUser(): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl + "services/app/ServiceOrders/GetServicesForUser"
        );
    }

    GetVendorPaymentInfo(serviceId: any): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/Vendors/GetVendorPaymentInfo?ServiceId=${serviceId}`
        );
    }

    getDocLink(estimateId: any, fileId: any): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/FinancialAttachments/GetSecureLink?id=${fileId}&estimateId=${estimateId}&persona=${this.env.persona}`
        );
    }

    download(url: string): Observable<Blob> {
        return this.httpClient.get(url, {
            responseType: "blob",
        });
    }
    getEstimateById(estimateId: any): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/Estimates/GetById?id=${estimateId}&persona=${this.env.persona}`
        );
    }

    getInvoiceById(invoiceId: any): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/ServiceInvoices/GetById?id=${invoiceId}&persona=${this.env.persona}`
        );
    }

    getVendorPackages(): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/VendorPackage/GetAll?persona=${this.env.persona}`
        );
    }

    getPackageDetails(id) {
        return this.httpClient.get(
            this.env.apiUrl +
                `/services/app/VendorPackage/GetById?id=${id}&persona=${this.env.persona}`
        );
    }

    getProposalFilteredList(queueInfo): Observable<any> {
        let urlParams2 = new HttpParams();
        for (const key in queueInfo) {
            if (queueInfo.hasOwnProperty(key)) {
                const val = queueInfo[key];
                // console.log(val);
                if (val) {
                    urlParams2 = urlParams2.append(key, val);
                }
            }
        }
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/VendorPackage/GetAll?persona=${this.env.persona}`,
            { params: urlParams2 }
        );
    }

    searchVendorPackages(params: Map<string, any>): Observable<any> {
        const queryParams = new URLSearchParams();

        params.forEach((value, key) => {
            queryParams.set(key, value.toString());
        });

        const apiUrl = `${
            this.env.apiUrl
        }services/app/VendorPackage/GetAll?persona=${
            this.env.persona
        }&${queryParams.toString()}`;

        return this.httpClient.get(apiUrl);
    }

    // searchVendorPackages(queryParams): Observable<any> {
    //     console.log('queryParams', queryParams);

    //     const filteredParams = Object.entries(queryParams)
    //         .filter(([key, value]) => value !== null)
    //         .map(([key, value]) => `${key}=${value}`)
    //         .join("&");
    //         console.log('filteredParams', filteredParams);
    //     return this.httpClient.get(
    //         `${this.env.apiUrl}services/app/VendorPackage/GetAll?persona=${this.env.persona}${filteredParams}`
    //     );
    // }

    GetVendorsForUserByService(serviceId: any): Observable<any> {
        const url = `${
            this.env.apiUrl
        }services/app/Vendors/GetVendorsForUserByService${
            serviceId ? `?ServiceId=${serviceId}` : ""
        }`;
        return this.httpClient.get(url);
    }

    // End Known in-use methods

    saveAssignedVendor(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/ServiceOrders/AssignVendor",
            data
        );
    }

    getAllServiceOrders(skipCount: any, maxResultCount: any): Observable<any> {
        console.log("SO Env", this.env);
        return this.httpClient.get(
            this.env.apiUrl +
                "services/app/ServiceOrders/GetAll?persona=" +
                `${this.env.persona}` +
                "&SkipCount=" +
                `${skipCount}` +
                `&MaxResultCount=${maxResultCount}`
        );
    }

    getAllServiceOrdersWorkHistory(id: any): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/ServiceOrders/GetAll?persona=${this.env.persona}&YachtId=${id}&MaxResultCount=10`
        );
    }

    checkNum(searchPhrase: any): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/Utility/ParseUsNumber?input=${searchPhrase}`
        );
    }

    searchServiceOrdersWorkHistory(
        id: any,
        searchPhrase: any
    ): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                `services/app/ServiceOrders/GetAll?persona=${this.env.persona}&YachtId=${id}&MaxResultCount=10&Filter=` +
                searchPhrase
        );
    }

    getYachtList(): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl + "services/app/Yachts/GetAll?MaxResultCount=100000"
        );
    }

    getYachtById(): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl + `services/app/Yachts/GetById?id=47`
        );
    }

    getServicesList(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/Services/GetAll?MaxResultCount=100000&SkipCount=0`
        );
    }

    getServicesForServiceOrder(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/ServiceOrders/GetServicesForServiceOrder?SkipCount=0&MaxResultCount=100000&persona=${this.env.persona}`
        );
    }

    getMyVendors(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/YachtOwners/GetMyVendors?MaxResultCount=30000`
        );
    }
    getVendors(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}/services/app/Vendors/GetAll?MaxResultCount=3000`
        );
    }
    UploadTechnicianImages(data: any): any {
        // return this.httpClient.post(this.env.apiUrl + 'ServiceRequest/CreateServiceRequest', data, { headers: {
        return this.httpClient.post(
            this.env.apiUrl +
                "services/app/ServiceOrderSchedules/UploadTechnicianImages",
            data,
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                    encType: "multipart/form-data",
                },
            }
        );
    }

    UploadYachtOwnerImages(data: any): Observable<any> {
        return this.httpClient.put(
            this.env.apiUrl + "services/app/ServiceOrders/UpdateImages",
            data,
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                    encType: "multipart/form-data",
                },
            }
        );
    }

    saveServiceOrders(data: any): Observable<any> {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/ServiceOrders/CreateOrEdit",
            data,
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                    encType: "multipart/form-data",
                    ReqID: CREATE_SERVICE_REQUEST_ID,
                },
                params: new HttpParams().set("persona", this.env.persona),
            }
        );
    }

    publishServiceOrders(ServiceOrderId): any {
        return this.httpClient.put(
            this.env.apiUrl + "services/app/ServiceOrders/Publish",
            { ServiceOrderId: ServiceOrderId },
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                    "content-type": "application/json",
                },
            }
        );
    }

    getSingleServiceOrder(Id: any): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/ServiceOrders/GetById?id=${Id}&persona=${this.env.persona}`,
            {
                headers: {
                    "content-type": "application/json",
                    ReqID: GET_SERVICE_REQUEST_ID,
                },
            }
        );
    }
    // saveServiceOrders(data: any): any {
    //   return this.httpClient.post(this.env.apiUrl + 'ServiceRequest/CreateServiceRequest', data);
    // }
    saveVendor(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Vendors/CreateOrEdit",
            data
        );
    }

    saveOwnerInformation(data: any): any {
        return this.httpClient.put(
            this.env.apiUrl + "services/app/YachtOwners/UpdateProfile",
            data
        );
    }

    saveVesselInformation(data: any): any {
        return this.httpClient.put(
            this.env.apiUrl + "services/app/Yachts/UpdateVesselInformation",
            data,
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                    encType: "multipart/form-data",
                },
            }
        );
    }

    createCheckListCategory(body): Observable<any> {
        return this.httpClient.post(
            `${this.env.apiUrl}services/app/ServiceOrderChecklists/CreateCheckListCategory`,
            body
        );
    }

    UpdateServiceOrderChecklistTemplate(body): Observable<any> {
        return this.httpClient.post(
            `${this.env.apiUrl}services/app/ServiceOrderChecklists/UpdateServiceOrderChecklistTemplate`,
            body
        );
    }

    CheckServiceTaskItemOnChecklist(data): Observable<any> {
        return this.httpClient.put(
            `${this.env.apiUrl}services/app/ServiceOrderChecklists/ChangeYoCheckListState`,
            data
        );
    }

    getAllVendorCheckListCategory(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/ServiceOrderChecklists/GetCheckListCategoriesForVendor`
        );
    }

    getChecklistCategoriesForServiceOrder(serviceOrderId): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/ServiceOrderChecklists/GetCheckListCategoriesForServiceOrder?Input=${serviceOrderId}`
        );
    }

    setStatus(id): Observable<any> {
        return this.httpClient.put(
            `${this.env.apiUrl}services/app/YachtOwners/SetOnboardingStage?Stage=${id}`,
            {}
        );
    }

    getOnBoardingStatus(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/YachtOwners/GetOnboardingStage`
        );
    }
    createCheckList(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl +
                "services/app/ServiceOrderChecklists/CreateVendorCheckList",
            data,
            {
                headers: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                    encType: "multipart/form-data",
                },
            }
        );
    }

    getDefaultChecklist(queryParams): Observable<any> {
        const filteredParams = Object.entries(queryParams)
            .filter(([key, value]) => value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        return this.httpClient.get(
            `${this.env.apiUrl}services/app/ServiceOrderChecklists/GetCheckListForServiceOrders?${filteredParams}`
        );
    }

    getYachtOwnerVessel() {
        return this.httpClient.get(
            `${this.env.apiUrl}/services/app/Yachts/getVesselInformation`,
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                },
            }
        );
    }

    // url: `services/app/Yachts/getVesselInformation`,
    // headers: { 'content-type': 'application/json', 'Authorization': `${'Bearer'} ${this.baseService.getToken()}` },

    // saveVesselInformation(data: any): any {
    //   return this.httpClient.put(    this.env.apiUrl + 'services/app/Yachts/UpdateVesselInformation', data);
    // }

    saveAccess(data: any): any {
        return this.httpClient.put(
            this.env.apiUrl + "services/app/Yachts/UpdateVesselAccessControl",
            data
        );
    }

    saveParkingInstruction(data: any): any {
        return this.httpClient.put(
            this.env.apiUrl +
                "services/app/Yachts/UpdateVesselParkingInstruction",
            data
        );
    }

    saveVesselLocation(data: any): any {
        return this.httpClient.put(
            this.env.apiUrl + "services/app/Yachts/UpdateVesselLocation",
            data
        );
    }

    getOwnerInformation(): any {
        return this.httpClient.get(
            this.env.apiUrl + "services/app/YachtOwners/GetProfile"
        );
    }

    getVendors_(): Observable<any> {
        return this.httpClient.get(
            `${this.env.apiUrl}/api/services/app/Vendors/GetServiceProvider`
        );
    }

    getSubscriptionData(): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl + "services/app/YachtOwners/GetSubscriptionInfo"
        );
    }

    addServiceProvider(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl +
                "services/app/YachtOwners/AssignVendorToYachtOwner",
            data
        );
    }

    saveService(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Services/CreateOrEdit",
            data
        );
    }

    saveYacht(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "Yacht/CreateYacht",
            data
        );
    }

    saveCheckList(data: any): any {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/ServiceOrderFormDatas/CreateOrEdit",
            data
        );
    }

    createOrEditYachtProfile(data: any) {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/Yacht/CreateOrEdit",
            data
        );
    }

    searchServiceOrders(searchPhrase: any, status: any): Observable<any> {
        if (status) {
            return this.httpClient.get(
                this.env.apiUrl +
                    `services/app/ServiceOrders/GetAll?persona=${this.env.persona}&Status=${status}&MaxResultCount=30&Filter=` +
                    searchPhrase
            );
        } else {
            return this.httpClient.get(
                this.env.apiUrl +
                    `services/app/ServiceOrders/GetAll?persona=${this.env.persona}&MaxResultCount=30&Filter=` +
                    searchPhrase
            );
        }
    }

    loadServiceOrders(skipCount: any, maxResultCount: any): Observable<any> {
        return this.httpClient.get(
            this.env.apiUrl +
                "services/app/ServiceOrders/GetAll?SkipCount=" +
                `${skipCount}` +
                `&MaxResultCount=${maxResultCount}` +
                `&persona=${this.env.persona}`
        );
    }

    acceptOrderBid(data: any): Observable<any> {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/ServiceOrders/AcceptOrderBid",
            data
        );
    }

    submitOrderBid(data: any): Observable<any> {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/ServiceOrders/SubmitOrderBid",
            data
        );
    }

    rejectOrderBid(data: any): Observable<any> {
        return this.httpClient.post(
            this.env.apiUrl + "services/app/ServiceOrders/Rejectbid",
            data
        );
    }

    getFilteredList(queueInfo): Observable<any> {
        let urlParams2 = new HttpParams();
        urlParams2 = urlParams2.append("persona", this.env.persona);
        for (const key in queueInfo) {
            if (queueInfo.hasOwnProperty(key)) {
                const val = queueInfo[key];
                if (val !== null && val !== undefined) {
                    console.log(key, val);

                    urlParams2 = urlParams2.append(key, val);
                }
            }
        }
        return this.httpClient.get(
            this.env.apiUrl + "services/app/ServiceOrders/GetAll?",
            { params: urlParams2 }
        );
    }
}
