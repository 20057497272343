import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from "abp-ng2-module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import * as ApiServiceProxies from "./service-proxies";
import { ZeroTemplateHttpConfigurationService } from "./zero-template-http-configuration.service";

@NgModule({
  providers: [
    ApiServiceProxies.AuditLogServiceProxy,
    ApiServiceProxies.CachingServiceProxy,
    ApiServiceProxies.ChatServiceProxy,
    ApiServiceProxies.CommonLookupServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    ApiServiceProxies.FriendshipServiceProxy,
    ApiServiceProxies.HostSettingsServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.LanguageServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ApiServiceProxies.OrganizationUnitServiceProxy,
    ApiServiceProxies.PermissionServiceProxy,
    ApiServiceProxies.ProfileServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.TenantDashboardServiceProxy,
    ApiServiceProxies.TenantSettingsServiceProxy,
    ApiServiceProxies.TimingServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.UserLinkServiceProxy,
    ApiServiceProxies.UserLoginServiceProxy,
    ApiServiceProxies.WebLogServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.TenantRegistrationServiceProxy,
    ApiServiceProxies.HostDashboardServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.DemoUiComponentsServiceProxy,
    ApiServiceProxies.ComponentsServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.UiCustomizationSettingsServiceProxy,
    ApiServiceProxies.PayPalPaymentServiceProxy,
    ApiServiceProxies.StripePaymentServiceProxy,
    ApiServiceProxies.DashboardCustomizationServiceProxy,
    ApiServiceProxies.WebhookEventServiceProxy,
    ApiServiceProxies.WebhookSubscriptionServiceProxy,
    ApiServiceProxies.WebhookSendAttemptServiceProxy,
    ApiServiceProxies.UserDelegationServiceProxy,
    ApiServiceProxies.DynamicPropertyServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyServiceProxy,
    ApiServiceProxies.DynamicPropertyValueServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
    ApiServiceProxies.TwitterServiceProxy,
    ApiServiceProxies.ServiceInvoicesServiceProxy,
    ApiServiceProxies.QuickBooksServiceProxy,
    ApiServiceProxies.EstimatesServiceProxy,
    ApiServiceProxies.YachtOwnersServiceProxy,
    ApiServiceProxies.VendorPackageServiceProxy,
    ApiServiceProxies.ServiceOrdersServiceProxy,
    ApiServiceProxies.BoatFaxServiceProxy,
    ApiServiceProxies.VitalAreasServiceProxy,
    ApiServiceProxies.UtilityServiceProxy,
    ApiServiceProxies.YachtsServiceProxy,
    ApiServiceProxies.ServiceOrderHistoriesServiceProxy,
    ApiServiceProxies.MySettingsServiceProxy,
    ApiServiceProxies.CaptainAIServiceProxy,
    ApiServiceProxies.LightboxImagesServiceProxy,
    ApiServiceProxies.UtilityServiceProxy,
    ApiServiceProxies.YachtVitalsServiceProxy,
    ApiServiceProxies.NoteServiceProxy,
    ApiServiceProxies.WeatherServiceProxy,
    {
      provide: AbpHttpConfigurationService,
      useClass: ZeroTemplateHttpConfigurationService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
  ],
})
export class ServiceProxyModule {}
