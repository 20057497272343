import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { LoginComponent } from "../login/login.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { BaseService } from "src/app/shared/base.service";
import { SwiperComponent } from "swiper/angular";
import { MustMatch } from "../../utilities/pass-validator";
import { ModalController, NavController } from "@ionic/angular";
import { ToastService } from "src/app/shared/message/toast.service-impl";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { TranslateService } from "@ngx-translate/core";
import { TelephoneService } from "../../services/telephone.service";
import { LoginServiceService } from "../login/login-service.service";
import {
  ApiException,
  IChangePasswordWithCodeInput,
} from "src/shared/service-proxies/service-proxies";
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  @ViewChild("slide", { static: true }) slides: SwiperComponent;
  hidePassword = true;
  resetRequestForm!: FormGroup;
  resetForm!: FormGroup;
  subscription = new Subscription();
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  cookieValue: any;
  isLoading = false;
  loading = false;
  identifier = null;
  constructor(
    private fb: FormBuilder,
    public baseService: BaseService,
    public modalCtrl: ModalController,
    private toastService: ToastService,
    private translate: TranslateService,
    private telephoneService: TelephoneService,
    private navCtrl: NavController,
    private loginService: LoginServiceService
  ) {}

  ngOnInit(): void {
    this.cookieValue = localStorage.getItem("lang") || "en";

    this.resetRequestForm = this.fb.group({
      emailAddress: [
        null,
        Validators.compose([
          Validators.pattern("^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]),
      ],
      cellNumber: [null, [this.telephoneService.phoneValidator()]],
    });

    this.resetForm = this.fb.group(
      {
        code: [null, Validators.required],
        new_password: [null, Validators.required],
        confirm_password: [null, Validators.required],
      },
      { validator: MustMatch("new_password", "confirm_password") }
    );
  }

  async openLogin() {
    // this.navCtrl.back();
    this.modalCtrl.dismiss();
    // const modal = await this.modalCtrl.create({
    //   component: LoginComponent,
    // });
    // modal.present();
  }

  passReset() {
    if (this.resetForm.invalid) return;
    if (
      this.resetForm.value.new_password !==
      this.resetForm.value.confirm_password
    ) {
      this.toastService.showToast(
        "Passwords do not match. Please try again.",
        3000,
        "bottom",
        null,
        "danger"
      );
      return;
    }
    this.isLoading = true;
    const payload: IChangePasswordWithCodeInput = {
      resetCode: this.resetForm.value.code,
      identifier: this.identifier,
      newPassword: this.resetForm.value.new_password,
    };
    this.subscription.add(
      this.loginService.resetPassword(payload).subscribe({
        next: (data) => {
          this.isLoading = false;
          if (data) {
            this.modalCtrl.dismiss();
            this.toastService.showSuccessMessage(
              "You password was successfully changed. You can login now."
            );
          } else {
            this.toastService.showErrorMessage(
              "Invalid Code entered. Please try again."
            );
          }
        },
        error: (error: ApiException) => {
          console.error(error);
          this.isLoading = false;
          this.toastService.showErrorMessage(
            `An error occurred. Check your code. You cannot enter your old password.`
          );
        },
      })
    );
  }

  passResetRequest() {
    if (this.resetRequestForm.invalid) {
      return;
    }

    this.loading = true;

    const emailAddress = this.resetRequestForm.get("emailAddress").value;
    const cellNumber = this.resetRequestForm.get("cellNumber").value;

    let identifier;

    if (emailAddress && cellNumber) {
      this.toastService.showToast(
        "Please enter information for either e-mail or cell number to continue",
        3000,
        "bottom",
        null,
        "danger"
      );
      this.loading = false;
      return;
    } else if (!emailAddress && !cellNumber) {
      this.toastService.showToast(
        "Please enter information for at least e-mail or cell number ro continue",
        3000,
        "bottom",
        null,
        "danger"
      );
      this.loading = false;
      return;
    } else {
      this.identifier = emailAddress ? emailAddress : cellNumber;
    }

    this.baseService
      .requestPasswordReset(this.identifier)
      .subscribe(
        (data: any) => {
          if (data.success) {
            this.slides.swiperRef.slideNext();
            const message = this.translate.instant(
              this.LANGUAGE_DATA?.RESET_PASSWORD.CHECK_EMAIL_FOR_CODE
            );
            this.toastService.showToast(
              message,
              3000,
              "bottom",
              null,
              "success"
            );
            this.loading = false;
          } else {
            const errorMessage = this.translate.instant(
              this.LANGUAGE_DATA?.RESET_PASSWORD.ERROR_MESSAGE
            );
            this.toastService.showToast(
              errorMessage,
              3000,
              "bottom",
              null,
              "danger"
            );
            this.loading = false;
          }
        },
        (error) => {
          console.error(error);
          const errorMessage = this.translate.instant(
            this.LANGUAGE_DATA?.RESET_PASSWORD.ERROR_MESSAGE
          );
          this.toastService.showToast(
            errorMessage,
            3000,
            "bottom",
            null,
            "danger"
          );
          this.loading = false;
        }
      )
      .add(() => {
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  goBack() {
    this.slides.swiperRef.slidePrev();
  }

  // convenience getter for easy access to registerForm fields
  get f() {
    return this.resetForm.controls;
  }

  updateRepeatPassValidSate() {
    if (!this.f["confirm_password"].touched) {
      this.f["confirm_password"].markAsTouched();
    }
  }

  updatePassValidSate() {
    if (!this.f["new_password"].touched) {
      this.f["new_password"].markAsTouched();
    }
  }
}
