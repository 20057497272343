import {AfterContentInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalController, NavController, Platform} from "@ionic/angular";
import {LANGUAGE_DATA_PROVIDER} from "src/app/utilities/language-data";
import {BaseService} from "../../../shared/base.service";
import {SignalrService} from "../../signalr.service";
import {WhiteSpaceValidator} from "../../boat-manager/boat-profile/whitespace.validator";

import {ActivatedRoute, Router} from "@angular/router";
import {format, formatDistance} from "date-fns";
import {Subscription} from "rxjs";
import {DataService} from "src/app/services/data.service";
import {ToastService} from "../../../shared/message/toast.service-impl";
import {YachtMessagesService} from "../../../shared/messages.service";
import {AudioService} from "src/app/captain-talk/services/audio.service";
import {MessagingService} from "src/app/services/messaging.service";
import {BaseTwoLayoutComponent} from "src/app/captain-talk/components/base-two-layout/base-two-layout.component";

@Component({
  selector: "app-yacht-messaging",
  templateUrl: "./yacht-messaging.component.html",
  styleUrls: ["./yacht-messaging.component.css"],
})
export class YachtMessagingComponent implements OnDestroy, AfterContentInit {
  @Input() public data: any;
  public isLoadingChatMessages = false;
  private selectedFriendSubscription: Subscription;
  public LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  public form!: FormGroup;
  public ownerInfoForm!: FormGroup;
  private userID: any;
  private selfUserId: any;
  private lastMessageId;
  private isKeyboardOpen = false;
  private isModal = false;
  public chatMessages: {
    id: number;
    SharedId: string;
    message: string;
    messageObject: any;
    creationTime: string;
    readstate: number;
    receiverReadstate: number;
    side: number;
    linkType?: string;
  }[];
  private paramId: any;
  protected fromServiceOrder: any;
  public groupedChats = [];
  private chatSubscription: Subscription;
  public displayName: string;
  public messageState: number;
  public setPadding = false;
  public addAnimation = false;
  @ViewChild("scrollPageMessage", { static: false })
  private scrollPageMessage: ElementRef;

  @ViewChild(BaseTwoLayoutComponent) frame: BaseTwoLayoutComponent;

  constructor(
    private fb: FormBuilder,
    public baseService: BaseService,
    public signalrService: SignalrService,
    private router: Router,
    private dataService: DataService,
    public modalCtrl: ModalController,
    private cdr: ChangeDetectorRef,
    private yachtMessagesService: YachtMessagesService,
    private toastService: ToastService,
    private platform: Platform,
    private navCtrl: NavController,
    private actRoute: ActivatedRoute,
    private audioService: AudioService,
    private messagingService: MessagingService
  ) {
    this.baseService.resetKeyboardSettings();
    this.actRoute.queryParams.subscribe(params => {
      this.fromServiceOrder = params['key'];

    });
    this.actRoute.params.subscribe((params) => {
      console.log("params", params);
      this.paramId = +params["id"];
      this.dataService.setIsChatPage(true, this.paramId);
    });

    this.selectedFriendSubscription = this.yachtMessagesService.selectedFriend.subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });

    this.chatSubscription = this.signalrService.chatChange.subscribe((ev) => {
      if (ev?.data) {
        this.chatMessages = ev.data;
      } else if (ev && !ev.isSelf) {
        if (ev.newMessage && ev.newMessage.targetUserId === this.paramId) {
          //ensures the right friend receive signalr message
          this.addChatToGroup(ev.newMessage);
        }
        this.scrollToBottom();
      }
    });

    this.selfUserId = this.baseService.getUserProfile().id;
  }

  private async loadChatMessagesFromServer() {
    try {
      this.isLoadingChatMessages = true;
      await this.signalrService.getMessagesWithFriendID(this.data.Id);
      this.isLoadingChatMessages = false;
    } catch (error) {
      this.isLoadingChatMessages = false;
    }
  }

  ngAfterContentInit() {
    if (!this.data) {
      this.isLoadingChatMessages = true;
      setTimeout(() => {
        this.data = this.signalrService.friendListChange.value.find((item) => item.Id === this.paramId);
        this.isLoadingChatMessages = false;
        this.prepareMessages();
      }, 2000);
    } else {
      this.prepareMessages();
    }

    this.audioService.setVolume("message_sent", 0.1);
  }

  private async prepareMessages() {
    console.log("this.data", this.data);
    if (this.data?.messages) {
      this.chatMessages = this.data.messages;
    } else {
      await this.loadChatMessagesFromServer();
    }
    this.groupChatsByDate();
    this.scrollToBottom();
    if (this.data?.sender) {
      this.messageState = this.data?.sender?.state;
      this.displayName = this.data?.sender?.displayName;
      this.userID = parseFloat(this.data?.sender?.Id);
    } else {
      this.displayName = this.data?.displayName;
      this.messageState = this.data?.state;
      this.userID = parseFloat(this.data?.Id);
    }

    if (sessionStorage.getItem("yacht_new_item"))
      this.form.controls["name"].setValue(sessionStorage.getItem("yacht_new_item"));

    this.ownerInfoForm = this.fb.group({
      sendMessage: ["", [Validators.required, WhiteSpaceValidator.noWhiteSpace]],
    });

    this.lastMessageId = this.chatMessages.length ? this.chatMessages[0]?.id : null;
  }

  public unBlockUser() {
    this.yachtMessagesService.unBlockUserOnMessenger(this.data.Id).subscribe(
      (response) => {
        this.data.state = 1;
        this.toastService.showToast("User Unblocked Successfully.", 3000, "bottom", null, "success");
        // }
      },
      (error) => {
        this.toastService.showToast("Error while unblocking user.", 3000, "bottom", null, "danger");
      }
    );
  }

  public getDisplayName() {
    return this.isSelf ? "Self" : this.displayName;
  }

  get isSelf() {
    return this.userID === this.selfUserId;
  }

  public sendMessage() {
    this.addAnimation = true;
    if (this.ownerInfoForm.value.sendMessage?.match(/^\s*$/)) {
      //
    } else {
      const t = {
        type: "plain",
        data: this.ownerInfoForm.value?.sendMessage,
      };

      this.addChatToGroup({
        id: crypto.getRandomValues(new Uint32Array(1))[0],
        SharedId: crypto.randomUUID(),
        message: JSON.stringify(t),
        messageObject: t,
        creationTime: new Date().toISOString(),
        readstate: 0,
        receiverReadstate: 0,
        side: 1,
      });

      this.audioService.playAudio("message_sent");

      // this.chatMessages = [
      //   ...this.chatMessages,

      // ];
      this.scrollToBottom();
      this.ownerInfoForm.reset();
      this.signalrService.sendMessage(JSON.stringify(t), this.userID, () => {
        //
      });
    }
  }

  private addChatToGroup(newMessage) {
    const todayChat = this.groupedChats.find((chat) => chat.formattedDate === "Today");
    if (todayChat) {
      todayChat.messages = [...todayChat.messages, newMessage];
    } else {
      this.groupedChats = [
        ...this.groupedChats,
        {
          formattedDate: "Today",
          date: new Date(),
          messages: [newMessage],
        },
      ];
    }
  }

  private groupChatsByDate() {
    const grouped = this.chatMessages.reduce((acc, chat) => {
      const extractedDate = new Date(chat.creationTime).toISOString();
      const date = extractedDate.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(chat);
      return acc;
    }, {});

    this.groupedChats = Object.keys(grouped)
      .map((date) => ({
        formattedDate: this.formatDate(date),
        date: new Date(date),
        messages: grouped[date],
      }))

      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  private formatDate(date) {
    let formatted = formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    });
    if (formatted.includes("hour") || formatted.includes("minute") || formatted.includes("second")) {
      formatted = "Today";
    } else if (formatted.includes("day ago")) {
      formatted = "Yesterday";
    } else {
      formatted = format(new Date(date), "d MMM yyyy");
      // format(new Date(date), 'yyyy-dd-MM hh:mm a');
    }
    return formatted;
  }

  public async goToExpectedRoute(message) {
    let route;
    let state;
    this.modalCtrl.dismiss();

    setTimeout(async () => {
      const modal = await this.modalCtrl.getTop();
      switch (message.linktype) {
        case "invoice":
          route = `/yacht-owner/invoices`;
          this.router.navigateByUrl(route + "/" + message.EntityID);
          break;
        case "package":
          route = "/yacht-owner/packages";
          this.router.navigateByUrl(route + "/" + message.EntityID);
          break;
        case "boattransfer":
          route = "/yacht-owner/boat-manager";
          this.router.navigate([route], {
            queryParams: {
              deeplink: true,
              boatId: message.EntityID,
            },
          });
          break;
        case "serviceorder":
          if (modal) {
            modal.dismiss();
            this.modalCtrl.dismiss();
          }
          route = "/yacht-owner/service-orders/view";
          this.router.navigateByUrl(route + "/" + message.EntityID);
          break;
      }
    }, 500);
  }

  public close() {
    this.dataService.setIsChatPage(false, this.paramId);

    if (this.isModal) {
      if (this.isKeyboardOpen) {
        this.ownerInfoForm.get("sendMessage").disable();
        setTimeout(() => {
          this.modalCtrl.dismiss(this.userID);
        }, 500);
      } else {
        this.modalCtrl.dismiss(this.userID);
      }
    } else if (this.fromServiceOrder) {
      this.navCtrl.back();
    }  else {
      if (this.router.url.includes("yacht-messages")) {
        this.navCtrl.setDirection("back");
        this.router.navigateByUrl("/yacht-owner/yacht-messages");
      } else {
        this.messagingService.closeActiveChat();
      }
    }
  }

  public loadOlderMessages(event) {
    this.yachtMessagesService.getOlderMessages(this.userID, this.lastMessageId).subscribe(
      (olderMessages) => {
        this.prepareOldMessages(olderMessages);
        event.target.complete();
      },
      (error) => {
        event.target.complete();
      }
    );
  }

  private async prepareOldMessages(olderMessages: any) {
    const messageArray = olderMessages?.result?.items
      .filter((item) => {
        return this.userID === this.selfUserId && item.side === 1;
      })
      .map((item) => {
        return {
          id: item.id,
          SharedId: item.sharedMessageId,
          message: item.message,
          messageObject: JSON.parse(item.message),
          creationTime: item.creationTime,
          readstate: item.readState,
          receiverReadstate: item.receiverReadState,
          side: item.side,
        };
      });

    this.chatMessages = [...messageArray, ...this.chatMessages];
    this.cdr.detectChanges();

    this.lastMessageId = this.chatMessages.length > 0 ? this.chatMessages[0]?.id : this.lastMessageId;
  }

  public onPassWordFocused() {
    this.isKeyboardOpen = true;
  }

  public onPasswordBlur() {
    this.setPadding = false;
    this.isKeyboardOpen = false;
  }

  public isNotWeb(): boolean {
    return !this.platform.is("mobileweb") && !this.platform.is("desktop");
  }

  private scrollToBottom(duration = 400) {
    setTimeout(() => {
      this.frame.content.scrollToBottom(duration);
    }, 100);
  }

  private clearUnreadCount() {
    if (this.data.unreadMessageCount > 0) {
      this.signalrService.clearFriendUnreadCount({
        userId: this.userID,
        tenantId: 1,
      });
    }
  }

  ngOnDestroy() {
    this.signalrService.chatChange.next(null);
    if (this.chatSubscription) this.chatSubscription.unsubscribe();
    if (this.selectedFriendSubscription) this.selectedFriendSubscription.unsubscribe();
    this.clearUnreadCount();
    this.chatMessages = [];
    this.dataService.setIsChatPage(false, this.paramId);
  }
}
