import { SharedModule } from "src/app/shared/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageCropComponent } from "./image-crop/image-crop.component";
import { ImageBrowserComponent } from "./image-browser/image-browser.component";
import { TranslateModule } from "@ngx-translate/core";
import { ImageCropperModule } from "ngx-image-cropper";
import { SwiperModule } from "swiper/angular";

@NgModule({
  declarations: [ImageCropComponent, ImageBrowserComponent],
  imports: [CommonModule, SharedModule, TranslateModule, ImageCropperModule, SwiperModule],
})
export class ImageManagementModule {}
