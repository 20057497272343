// Geolocation.service.ts
import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Subscription, switchMap, timer } from "rxjs";
import { BaseService } from "../shared/base.service";
import "leaflet";
import { ProximityAlertComponent } from "../shared/proximity-alert/proximity-alert.component";
import { ModalController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GpsLoggerService } from "./gps-logger/gps-logger.service";
import { PermissionDeniedComponent } from "../shared/permission-denied/permission-denied.component";
import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
import { registerPlugin } from "@capacitor/core";

declare let L: any;

interface Coordinates {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: "root",
})
export class GpsLocationService {
  public isLocationWatch = new BehaviorSubject<boolean>(false);
  public myTodayYachtsOb$ = new BehaviorSubject<any>(null);
  public proximityResults$ = new BehaviorSubject<any>(null);
  public isOnProximityAlertOpen = new BehaviorSubject<boolean>(false);
  public onRecentlyInGeofence = new BehaviorSubject<boolean>(false);
  public onRecentlyOutOfGeofence = new BehaviorSubject<boolean>(false);
  public isOnProximityRecentlyDismissed = new BehaviorSubject<any>({
    status: false,
    dismissedDateTime: 0,
  });
  backgroundPositionSubscription: any;
  watchId!: any;
  watchCoordinate: any;
  comparedWatchCoordinate: any;
  onProximityAlertOpen = false;
  isRecentlyDismissed = false;
  dismissedDateTime = 0;
  userInfo: any;
  wasRecentlyInGeofence = false;

  // The distance to trigger proximity alert
  distInMeters = 20;
  outOfGeofenceDistance = 40;

  showButton = false;
  GETCURRENTDISTANCE: any;

  minutes: number = 1 * 60 * 1000;
  subscription = new Subscription();
  currentYachtId: any;

  private backgroundPositionSource = new BehaviorSubject<Coordinates | null>(null);
  backgroundPosition$ = this.backgroundPositionSource.asObservable();

  BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation", ["android", "ios"]);
  constructor(
    private baseService: BaseService,
    private httpClient: HttpClient,
    public logger: GpsLoggerService,
    private modalCtrl: ModalController
  ) {
    this.getTrackingValue();
    this.userInfo = this.baseService.getSessionData();
    this.isOnProximityAlertOpen.subscribe((value) => {
      this.onProximityAlertOpen = value;
    });
    this.isOnProximityRecentlyDismissed.subscribe((value) => {
      this.isRecentlyDismissed = value.status;
      this.dismissedDateTime = value.dismissedDateTime;
    });

    this.onRecentlyInGeofence.subscribe((value) => {
      this.wasRecentlyInGeofence = value;
    });
  }

  initiateGetAllYacht() {
    this.subscription = timer(0, this.minutes)
      .pipe(
        switchMap(() => {
          return this.httpClient.get(
            `${environment.apiUrl}services/app/ServiceOrderSchedules/GetMyYachtLocationsForToday`
          );
        })
      )
      .subscribe((data: any) => {
        if (data.success) {
          const myNewTodayYachts: any[] = data.result.items;
          this.myTodayYachtsOb$.next(myNewTodayYachts);
          //   this.isOnProximityAlertOpen.next(false);
        }
      });
  }

  getSignalRMyTodayYachtLocation() {
    this.httpClient
      .get(`${environment.apiUrl}services/app/ServiceOrderSchedules/GetMyYachtLocationsForToday`)
      .subscribe((data: any) => {
        if (data.success) {
          const myNewTodayYachts: any[] = data.result.items;
          this.myTodayYachtsOb$.next(myNewTodayYachts);
        }
      });
  }

  async showPermissionAlert() {
    const header = "Location Access Needed";
    const text =
      "To ensure you easily locate assigned clients, we need permission to use your location. This permission was previously denied. For a smooth and efficient service experience, please enable location access in the app settings. ";

    const modal = await this.modalCtrl.create({
      component: PermissionDeniedComponent,
      componentProps: { header, text },
      cssClass: "blurry-backdrop-auto",
    });

    await modal.present();
  }

  async initializeBackgroundGeolocation() {
    const callback = async (location: any, error: any) => {
      if (error) {
        if (error.code === "NOT_AUTHORIZED") {
          if (
            window.confirm(
              "This app needs your location, " + "but does not have permission.\n\n" + "Open settings now?"
            )
          ) {
            this.BackgroundGeolocation.openSettings();
          }
        }
        return console.error("Location Error: ", error);
      }

      if (location) {
        this.backgroundPositionSource.next(location);
      }

      return location;
    };
    try {
      this.watchId = await this.BackgroundGeolocation.addWatcher(
        {
          backgroundMessage: "Cancel to prevent battery drain.",
          backgroundTitle: "Tracking You.",
          requestPermissions: true,
          stale: false,
          distanceFilter: 0,
        },
        callback
      );

      // Store watcher_id if needed for later removal
    } catch (error) {
      console.error("Failed to start background geolocation", error);
    }
  }

  async stopTracking() {
    await this.BackgroundGeolocation.removeWatcher({ id: this.watchId });
  }

  async trackProximity() {
    await this.initializeBackgroundGeolocation();

    this.backgroundPositionSubscription = this.backgroundPositionSource.subscribe((location) => {
      if (location) {
        const { latitude, longitude } = location;
        if (latitude != null && longitude != null) {
          const onProximityAlertOpen = JSON.parse(localStorage.getItem(`onProximityAlertOpen`));

          if (this.baseService && this.baseService.getCurrentLocation) {
            this.baseService.getCurrentLocation.next({
              lat: latitude,
              long: longitude,
            });

            if (this.isRecentlyDismissed && this.dismissedForTenMinutes()) {
              this.clearDismissedTime();
              this.isOnProximityRecentlyDismissed.next({
                status: false,
                dismissedDateTime: 0,
              });
              this.isOnProximityAlertOpen.next(false);
            }

            if (!this.onProximityAlertOpen) {
              this.proximityLogic(location);
            }
          } else {
            //
          }
        } else {
          //
        }
      }
    });
  }

  wasDismissedRecently(itemId: string): boolean {
    const dismissedTimestamp = this.getLocalStorage(`dismissed_${itemId}`);
    if (!dismissedTimestamp) return false;

    const tenMinutesInMilliseconds = 10 * 60 * 1000;
    return Date.now() - dismissedTimestamp < tenMinutesInMilliseconds;
  }

  // add 10 minutes to dismissed date time and compare with current time
  // if current time is greater than dismissed time by 2 minutes return false
  // else return true

  dismissedForTenMinutes(): boolean {
    const allowedDateTime = this.dismissedDateTime + 10 * 60 * 1000;
    const currentTime = Date.now();
    return currentTime > allowedDateTime;
  }

  proximityLogic(location: Coordinates) {
    this.myTodayYachtsOb$.subscribe((data) => {
      const proximityResults = [];
      data
        ?.map((item: any) => {
          let proximityTriggered = false;
          this.currentYachtId = item.yachtId;
          const serviceOrderSchedules = item.serviceOrderSchedules || [];
          const filteredSchedules = serviceOrderSchedules.filter(
            (schedule) => !schedule.confirmedToday && schedule.status === 2
          );

          if (filteredSchedules.length > 0) {
            const distance = L.latLng(location.latitude, location.longitude);

            const distanceInMeters = parseFloat(distance.distanceTo(L.latLng(item?.latitude, item?.longitude)));
            item.distance = distanceInMeters;

            if (distanceInMeters >= this.outOfGeofenceDistance && this.wasRecentlyInGeofence) {
              this.onRecentlyOutOfGeofence.next(true);
            }
            if (distanceInMeters <= this.distInMeters && !this.onProximityAlertOpen) {
              proximityResults.push(item);
              this.onRecentlyInGeofence.next(true);
              proximityTriggered = true;

              setTimeout(() => {
                this.openProximityAlert();
              }, 2000);

              // }
            } else {
              //   this.isOnProximityAlertOpen.next(false);
            }

            this.logger.processGpsLog(distanceInMeters);
          }

          return {
            ...item,
            serviceOrderSchedules: filteredSchedules,
            proximityTriggered: proximityTriggered,
          };
        })
        .filter((item) => item.serviceOrderSchedules.length > 0 && item.proximityTriggered);
      this.proximityResults$.next(proximityResults);
    });
  }

  // Get data from LocalStorage
  getLocalStorage(key: string): any {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  // Remove data from LocalStorage
  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  // clear dismissed time
  clearDismissedTime() {
    this.removeLocalStorage(`dismissed_${this.currentYachtId}`);
  }

  /**
   * Update server with current tech location every 5min
   * @param data
   */
  async updateLocation() {
    // Prepare location payload
    const payload = {
      latitude: this.watchCoordinate?.coords?.latitude,
      longitude: this.watchCoordinate?.coords?.longitude,
      goeg: "", // Not sure about the purpose of this property
    };

    if (this.baseService.isLoggedIn() && this.watchCoordinate !== null) {
      // Set time interval to update technician location to the server
      const params = new HttpParams().set("Hide-Loader", "true");

      // Retrieve the interval from localStorage or use default
      let interval = parseInt(localStorage.getItem("lastLocation"));
      if (isNaN(interval) || interval === null || interval === undefined) {
        interval = this.minutes; // Using a default interval if not set
      }

      // Subscribe to a timer with the specified interval
      this.subscription = timer(0, interval)
        .pipe(
          switchMap(() => {
            // Make a POST request to send live location
            return this.httpClient.post(`${environment.apiUrl}services/app/Technicians/SendLiveLocation`, payload, {
              params,
            });
          })
        )
        .subscribe((data: any) => {
          if (data.success) {
            // Update comparedWatchCoordinate upon success
            this.comparedWatchCoordinate = payload;
          }
        });
    }
  }

  // Get boat last known location
  async getCurrentUserLocation() {
    const params = new HttpParams().set("Hide-Loader", "true");

    this.httpClient
      .get(`${environment.apiUrl}services/app/PositionUpdates/GetLastPosition`, { params })
      .subscribe((data: any) => {
        if (data.success) {
          if (data.result !== null) {
            this.comparedWatchCoordinate = data.result;
          }
        }
      });
    // }
  }

  /**
   * Set new track value
   * @param trackValue
   */
  setTrackingValue(trackValue: boolean) {
    localStorage.setItem("enable-watch", JSON.stringify(trackValue));
    this.getTrackingValue();
  }

  //Initialize tracking value
  getTrackingValue() {
    const data: any = localStorage.getItem("enable-watch");
    const trackValue = JSON.parse(data);
    if (trackValue) {
      this.isLocationWatch.next(true);
    } else {
      this.isLocationWatch.next(false);
    }
  }

  public returnCurrentLocation() {
    return this.watchCoordinate;
  }

  /**
   * Open Proximity modal
   */
  async openProximityAlert() {
    const modal = await this.modalCtrl.create({
      component: ProximityAlertComponent,
      cssClass: "blurry-backdrop-auto",
    });
    if (!this.onProximityAlertOpen) {
      this.isOnProximityAlertOpen.next(true);
      modal.present();
    }
  }

  //Confirm technician arrival
  confirmArrival(id: any, data: any): any {
    const url =
      environment.apiUrl + "services/app/ServiceOrderSchedules/ConfirmArrival?serviceOrderScheduleId=" + `${id}`;
    return this.httpClient.put(url, data);
  }

  locate(info: any): any {
    return this.httpClient.get(`https://photon.komoot.io/api/?q=${info}`);
  }

  locateReverse(info: any) {
    return this.httpClient.get(`https://photon.komoot.io/reverse/?lon=${info.longitude}&lat=${info.latitude}`);
  }
  getHouseAddress(info: any) {
    return this.httpClient.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lon=${info.longitude}&lat=${info.latitude}`
    );
  }

  unsubscribeTracking() {
    this.isLocationWatch.unsubscribe();
  }
}
