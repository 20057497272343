import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class GpsLoggerService {
    // currentLog = {freq: 0, locationTo: 'USA', latestLocation: '', batch:[]}
    currentLog = [];
    currentLocation = 0;
    lastLocation = 0;
    yachtLocation = 0;
    yachtName = "Seyi Yacht";
    yachtAddress = "Marina, USA";
    dist = this.currentLocation;
    freq = 0;
    safeArea = 10;
    besideYacht = 50;
    logs;
    countdown = 0;
    that = this;
    batch = [];

    constructor() {
        this.logs = localStorage.getItem("gpslogs");
        this.freq = (this.yachtLocation / 10 - 2) / 60;
    }

    ngOnInit(): void {}
    moment() {
        // return moment(date).format("MM/DD/YYYY");
    }
    processGpsLog(location) {
        // alert(location)
        this.yachtLocation = location;
        this.runformular(location);
    }

    refreshGpsData() {
        this.currentLocation = 0;
        this.lastLocation = 0;
        this.yachtLocation = 0;
        this.freq = 0;
    }

    runformular(location = null) {
        const yl = this.yachtLocation;

        let lastloc = parseInt(localStorage.getItem("lastLocation"));

        if (lastloc == null || lastloc == undefined) {
            lastloc = location;
        }

        let freq = this.freq;
        let by = this.yachtLocation <= 50;
        let safearea = this.safeArea;

        if (
            this.currentLocation > lastloc + safearea ||
            this.currentLocation < lastloc - safearea
        ) {
            //  console.error('you are not in safe zone')
            safearea = 0;
        } else {
            //  safearea = 1
            //  console.warn('you are  in safe zone')
        }

        let formular = {
            "the yacht is this far from you": yl,
            "your last location": lastloc,
            "timer frequency": freq,
            "are you beside the yacht": by,
            date: Date.now(),
        };

        //  console.log(formular)
        let timer = freq * 6000;
        this.countdown = freq * 60;
        setInterval(() => {
            // let time = timer
            this.countdown -= 1;
            //  console.log('counting')
            //  console.log(this.countdown)
        }, 1000);

        setTimeout(() => {
            if (safearea == 0) {
                this.lastLocation = this.currentLocation;
                localStorage.setItem(
                    JSON.stringify(this.currentLocation),
                    "lastLocation"
                );
                this.refreshData(formular);
            }
            // timer
        }, timer);
    }

    refreshData(batch) {
        this.reloadLocations();
        if (this.yachtLocation < this.besideYacht) {
            this.logs.push(batch);
            localStorage.setItem(JSON.stringify(this.logs), "gpslogs");

            this.batch = [];
            this.logs = [];

            this.saveLog();
        } else {
            this.batch.push(batch);
            this.runformular();
        }
    }
    reloadLocations() {
        //run gps calcs
    }
    saveLog() {
        localStorage.setItem("enable-watch", "false");
        localStorage.setItem("lastLocation", null);
        this.refreshGpsData();
    }
}
