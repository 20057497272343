
        <div class="w-full space-y-2 pb-8">
            <ion-header class="ion-no-border bg-brandDeepSeaBlue">
                <ion-toolbar class="h-[10vh] ion-paading-start" >
                    <button (click)="openLogin()"
                            type="button"
                            slot="start"
                            class="p-2 rounded-full bg-brandSunsetOrange mt-2 ml-5"
                    >
                        <ion-ripple-effect></ion-ripple-effect>
                        <span class="bbicon-chevron-left text-2xl text-white"></span>
                    </button>

                    <div class="w-full whitespace-normal text-white text-xl font-bold no-ellipses pt-5 text-center "> <span></span></div>
                </ion-toolbar>
            </ion-header>
            <swiper
                #slide
                [grabCursor]="true"
            >
                <ng-template
                    swiperSlide
                    class="swiper-no-swiping"
                >
                    <div class="flex justify-center px-6 pt-3">
                        <h1 class="text-xs w-full text-center">
                            {{
                                LANGUAGE_DATA?.RESET_PASSWORD.FILL_FORM_1
                                    | translate
                            }}
                        </h1>
                    </div>
                    <form
                        [formGroup]="resetRequestForm"
                        class="mt-5 px-4"
                    >
                        <div class="w-[95%] m-auto">
                            <div
                                class="relative flex gap-x-2 flex-col items-center w-full mb-3"
                            >

                                <div class="w-full">
                                    <app-input
                                        [label]="
                            LANGUAGE_DATA.VENDOR_MANAGEMENT.CELL_PHONE
                                | translate
                        "
                                        [floatingLabel]="'outline'"
                                        [icon]="{
                            name: 'phone-outline',
                            size: '2xl'
                        }"
                                        formControlName="cellNumber"
                                        [type]="'tel'"
                                    ></app-input>
                                </div>
                            </div>

                            <div
                                class="px-2 flex items-center justify-between legend w-full my-5"
                            >
                                <hr class="w-[45%] text-gray-300" />
                                <span class="italic text-xs font-normal text-gray-500">Or</span>
                                <hr class="w-[45%] text-gray-300" />
                            </div>

                            <div class="w-full">
                                <app-input
                                    [label]="
                                                LANGUAGE_DATA?.RESET_PASSWORD
                                                    .EMAIL | translate
                                            "
                                    size="normal"
                                    [type]="'email'"
                                    [icon]="{
                        name: 'email-alt-outline',
                        size: '2xl'
                    }"
                                    floatingLabel="outline"
                                    formControlName="emailAddress"
                                    customBackGroundClass="bg-white"
                                    customClass="w-full"
                                    [enterKeyHint]="'next'"
                                    appEnterNext
                                    [tabindex]="1"
                                    customBorderClass="focus:border-blue-900 focus:border-2"
                                ></app-input>
                            </div>



                        </div>

                        <div class="pt-10 px-8">
                            <div class="space-y-6">
                                <app-button
                                    [disabled]="!resetRequestForm.valid"
                                    (buttonClick)="passResetRequest()"
                                    [loading]="loading"
                                    [variant]="'secondary'"
                                    [label]="
                            LANGUAGE_DATA?.RESET_PASSWORD.REQUEST
                                | translate
                        "
                                ></app-button>
                            </div>
                        </div>
                    </form>

                    <div class="flex justify-center px-8 pt-3 pb-2">
                        <label class="text-gray-400 font-light text-[14px]"
                            >{{
                                LANGUAGE_DATA?.RESET_PASSWORD.REMEMBER_PASSWORD
                                    | translate
                            }}
                            <b
                                (click)="openLogin()"
                                class="font-bold text-gray-600"
                                >{{
                                    LANGUAGE_DATA?.START_PAGE.HOME_PAGE.SIGN_IN
                                        | translate
                                }}</b
                            ></label
                        >
                    </div>
                </ng-template>

                <ng-template
                    swiperSlide
                    class="swiper-no-swiping"
                >
                    <div class="flex justify-center px-6 pt-3">
                        <h1 class="text-xs w-full text-center">
                            {{
                                LANGUAGE_DATA?.RESET_PASSWORD.FILL_FORM
                                    | translate
                            }}
                        </h1>
                    </div>
                    <form
                        [formGroup]="resetForm"
                    >
                        <div class="pt-8 px-8 flex flex-col justify-center">
                            <div
                                class="relative flex gap-x-2 items-center w-full mb-3"
                            >
                                <span
                                    class="bbicon-lock-outline text-2xl"
                                ></span>
                                <div class="w-full">
                                    <app-input
                                        [label]="
                                            LANGUAGE_DATA?.RESET_PASSWORD.CODE
                                                | translate
                                                | titlecase
                                        "
                                        size="normal"
                                        [required]="true"
                                        floatingLabel="outline"
                                        formControlName="code"
                                        customBackGroundClass="bg-white"
                                        customClass="w-full"
                                        [tabindex]="2"
                                        type="text
                                 "
                                        customBorderClass="focus:border-blue-900 focus:border-2"
                                    ></app-input>
                                </div>
                            </div>

                            <div
                                class="relative flex gap-x-2 items-center w-full mb-3"
                            >
                                <span
                                    class="bbicon-lock-outline text-2xl"
                                ></span>
                                <div class="w-full">
                                    <app-input
                                        [label]="
                                            LANGUAGE_DATA?.ACCOUNT_SETTINGS
                                                .ACCOUNT_SECURITY.NEW_PASSWORD
                                                | translate
                                                | titlecase
                                        "
                                        size="normal"
                                        [required]="true"
                                        floatingLabel="outline"
                                        formControlName="new_password"
                                        customBackGroundClass="bg-white"
                                        customClass="w-full"
                                        [tabindex]="2"
                                        [type]="'password'"
                                        (blur)="updatePassValidSate()"
                                        [showPasswordToggle]="true"
                                        customBorderClass="focus:border-blue-900 focus:border-2"
                                    ></app-input>
                                </div>
                            </div>

                            <div
                                *ngIf="
                                    f['new_password']?.errors &&
                                    f['new_password']?.touched
                                "
                                style="margin-top: 0px"
                            >
                                <span
                                    *ngIf="
                                        f['new_password']?.errors['required']
                                    "
                                    class="col-span-2 pl-8 text-red-500 text-xs"
                                >
                                    {{
                                        LANGUAGE_DATA?.START_PAGE.SIGN_UP_PAGE
                                            .PASSWORD_ERROR | translate
                                    }}
                                </span>
                                <span
                                    *ngIf="
                                        f['new_password']?.errors['minlength']
                                    "
                                    class="col-span-2 pl-8 text-red-500 text-xs"
                                >
                                    {{
                                        LANGUAGE_DATA?.START_PAGE.SIGN_UP_PAGE
                                            .PASSWORD_ERROR_2 | translate
                                    }}
                                </span>
                            </div>

                            <div
                                class="relative flex gap-x-2 items-center w-full mb-3"
                            >
                                <span
                                    class="bbicon-lock-outline text-2xl"
                                ></span>
                                <div class="w-full">
                                    <app-input
                                        [label]="
                                            LANGUAGE_DATA?.ACCOUNT_SETTINGS
                                                .ACCOUNT_SECURITY
                                                .CONFIRM_NEW_PASSWORD
                                                | translate
                                                | titlecase
                                        "
                                        size="normal"
                                        [required]="true"
                                        floatingLabel="outline"
                                        formControlName="confirm_password"
                                        customBackGroundClass="bg-white"
                                        customClass="w-full"
                                        [tabindex]="2"
                                        [type]="'password'"
                                        customBorderClass="focus:border-blue-900 focus:border-2"
                                        [showPasswordToggle]="true"
                                        (blur)="updateRepeatPassValidSate()"
                                    ></app-input>
                                </div>
                            </div>

                            <div
                                *ngIf="
                                    f['confirm_password']?.errors &&
                                    f['confirm_password']?.touched
                                "
                                style="margin-top: 0px"
                            >
                                <span
                                    *ngIf="
                                        f['confirm_password']?.errors[
                                            'required'
                                        ]
                                    "
                                    class="col-span-2 pl-8 text-red-500 text-xs"
                                >
                                    {{
                                        LANGUAGE_DATA?.START_PAGE.SIGN_UP_PAGE
                                            .CONFIRM_PASSWORD_ERROR | translate
                                    }}
                                </span>
                                <span
                                    *ngIf="
                                        f['confirm_password']?.errors[
                                            'mustMatch'
                                        ]
                                    "
                                    class="col-span-2 pl-8 text-red-500 text-xs"
                                >
                                    {{
                                        LANGUAGE_DATA?.START_PAGE.SIGN_UP_PAGE
                                            .CONFIRM_PASSWORD_ERROR_2
                                            | translate
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="pt-10 px-8">
                            <div class="space-y-6">
                                <app-button
                                    [disabled]="!resetForm.valid"
                                    (buttonClick)="passReset()"
                                    [loading]="isLoading"
                                    [variant]="'secondary'"
                                    [label]="
                            LANGUAGE_DATA?.RESET_PASSWORD.RESET
                                | translate
                        "
                                ></app-button>

                            </div>
                        </div>
                    </form>
                    <div class="flex justify-center px-8 pt-3 pb-2">
                        <label class="text-gray-400 font-light text-[14px]"
                            >{{
                                LANGUAGE_DATA?.RESET_PASSWORD.REMEMBER_PASSWORD
                                    | translate
                            }}
                            <b
                                (click)="openLogin()"
                                class="font-bold text-gray-600"
                                >{{
                                    LANGUAGE_DATA?.START_PAGE.HOME_PAGE.SIGN_IN
                                        | translate
                                }}</b
                            ></label
                        >
                    </div>
                </ng-template>
            </swiper>
        </div>

