import { Component, OnInit } from "@angular/core";
import { BaseService } from "../../shared/base.service";
import { Router } from "@angular/router";
import { GpsLocationService } from "../../technician/gps-location.service";
import { PermissionCheckerService } from "abp-ng2-module";
import { environment } from "src/environments/environment";
import { LogoutService } from "../../yacht-owner/logout.service";
import { AbpApplicationService } from "src/shared/abp-application.service";
import { LanguageService } from "../../utilities/language.service";
import { BoatProfileService } from "src/app/yacht-owner/boat-manager/boat-profile/boat-profile.service";
@Component({
  selector: "app-default",
  templateUrl: "./default.component.html",
  styleUrls: ["./default.component.css"],
})
export class DefaultComponent implements OnInit {
  constructor(
    private baseService: BaseService,
    public abpService: AbpApplicationService,
    private router: Router,
    private locationService: GpsLocationService,
    private permissionChecker: PermissionCheckerService,
    private logoutService: LogoutService,
    private languageService: LanguageService,
    private boatProfileService: BoatProfileService
  ) {}

  async ngOnInit() {
    const user = this.baseService.getSession(this.baseService.credKey);
    await this.abpService.updateAbp().toPromise();
    if (user) {
      this.baseService.authenticated = true;
      await this.languageService.fetchLanguageInfo();

      if (environment.buildType === "boatowner" && this.permissionChecker.isGranted("Pages.YachtOwners")) {
        this.router.navigate(["/captain-talk/ask-captain-ai"]);
      } else if (environment.buildType === "technician" && this.permissionChecker.isGranted("Pages.Technicians")) {
        this.locationService.setTrackingValue(true);
        this.router.navigate(["/technician/dashboard"]);
      } else {
        this.logoutService.logout();
      }
    }
  }
}
