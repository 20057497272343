import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { VideoGuidePlayerComponent } from "../video-guide-player/video-guide-player.component";

@Component({
  selector: "app-basic-alert",
  templateUrl: "./basic-alert.component.html",
  styleUrls: ["./basic-alert.component.css"],
})
export class BasicAlertComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() buttons: Button[] = [];
  @Input() videoId: string;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    if (this.buttons.length === 0) {
      this.buttons.push({
        label: "Okay",
        variant: "secondary",
        handler: () => this.modalCtrl.dismiss(),
      });
    }
  }

  async openVideoGuide() {
    const modal = await this.modalCtrl.create({
      component: VideoGuidePlayerComponent,
      componentProps: {
        videoId: this.videoId,
      },
      cssClass: "video-guide-modal",
    });

    await modal.present();
  }

  onBackClick() {
    this.modalCtrl.dismiss();
  }
}

interface Button {
  label: string;
  variant: string;
  handler: () => void;
}
