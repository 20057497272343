import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DefaultComponent } from "./authentication/default/default.component";
import { AuthGuard } from "./utilities/auth.guard";
import { environment } from "src/environments/environment";

const buildType = environment.buildType;

const routes: Routes = [
  {
    path: "",
    redirectTo: "/default",
    pathMatch: "full",
  },
  {
    path: "default",
    canActivate: [AuthGuard],
    component: DefaultComponent,
  },
  {
    path: "captain-talk",
    loadChildren: () => import("./captain-talk/captain-talk.module").then((m) => m.CaptainTalkModule),
  },
  {
    path: "yacht-owner",
    loadChildren: () => import("./yacht-owner/yacht-owner.module").then((m) => m.YachtOwnerModule),
  },
  {
    path: "technician",
    loadChildren: () => import("./technician/technician.module").then((m) => m.TechnicianModule),
  },

  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
