<ion-header class="ion-no-border">
  <ion-toolbar class="bg-clear">
    <button
      type="button"
      slot="start"
      (click)="onBackClick()"
      class="p-2 rounded-full bg-brandSunsetOrange mt-2 ml-5"
    >
      <ion-ripple-effect></ion-ripple-effect>
      <span class="bbicon-chevron-left text-2xl text-white"></span>
    </button>
  </ion-toolbar>
</ion-header>
<div class="px-6 pb-6">
  <h2 class="text-3xl font-bold text-brandDeepSeaBlue mb-4 text-center">
    {{ title }}
  </h2>
  <p
    class="text-base text-center font-medium text-black leading-normal"
    [innerHTML]="message"
  ></p>
  <div class="flex justify-center my-4" *ngIf="videoId">
    <div
      class="p-4 flex justify-center items-center text-brandDeepSeaBlue"
      (click)="openVideoGuide()"
    >
      <span class="bbicon-videocam text-3xl text-brandDeepSeaBlue"></span>
      <span class="text-lg font-bold">Watch the Video</span>
    </div>
  </div>
  <div class="mt-4">
    <ng-container *ngIf="buttons.length">
      <app-button
        *ngFor="let button of buttons"
        [variant]="button.variant"
        [label]="button.label"
        (buttonClick)="button.handler()"
      ></app-button>
    </ng-container>
  </div>
</div>
