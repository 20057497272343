import { Injectable } from "@angular/core";
import {
    NativeSettings,
    AndroidSettings,
    IOSSettings,
} from "capacitor-native-settings";

@Injectable({
    providedIn: "root",
})
export class NativeSettingsService {
    constructor() {}

    openSettings() {
        NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
        });
    }

    openLocationSettings() {
        NativeSettings.open({
            optionAndroid: AndroidSettings.Location,
            optionIOS: IOSSettings.App,
        });
    }
}
