import { Injectable } from "@angular/core";
import * as AWS from "aws-sdk/";
import * as S3 from "aws-sdk/clients/s3";
import { Observable } from "rxjs";
import { AwsCredentialsService } from "./aws-credentials.service";
import { environment } from "src/environments/environment";

export interface FileUploadModel {
  fileName: string;
  file: Buffer | Uint8Array | Blob | string;
  metadata: { [key: string]: string };
}

export const UploadEntities = Object.freeze({
  SERVICE_ORDER: "SERVICE_ORDER",
  TECHNICIAN_SERVICE_ORDER_SCHEDULE: "TECHNICIAN_SERVICE_ORDER_SCHEDULE",
  YACHT_PROFILE_IMAGES: "YACHT_PROFILE_IMAGES",
  PROFILE_PICTURE_IMAGE: "PROFILE_PICTURE_IMAGE",
  GALLERY_IMAGE: "GALLERY_IMAGE",
  RECOMMENDED_SERVICE: "RECOMMENDED_SERVICE",
  CHECKLIST_ITEM: "CHECKLIST_ITEM",
  MULTIPOINT_INSPECTION_CHECKLIST: "MULTIPOINT_INSPECTION_CHECKLIST",
  VISUAL_INSPECTION_CHECKLIST: "VISUAL_INSPECTION_CHECKLIST",
});

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  awsBucketName = "";
  env = environment;

  constructor(
    private credentialService: AwsCredentialsService,
  ) {}

  getAwsCredentials() {
    return new Promise((resolve, reject) => {
      const data = this.credentialService.getData();
      if (data && data.accessKeyId && data.secretAccessKey) {
        this.configAWS(data);
        resolve(true);
      }
    });
  }

  configAWS(data) {
    const { accessKeyId, secretAccessKey, bucketName } = data;
    this.awsBucketName = bucketName;
    AWS.config.update({ accessKeyId, secretAccessKey });
  }

  async uploadFile(uploadInput: FileUploadModel) {
    const storageData: any = localStorage.getItem("UserProfile");
    const user_data = JSON.parse(storageData);
    await this.getAwsCredentials();

    uploadInput.metadata["userId"] = `${user_data.id}`;
    uploadInput.metadata["role"] = user_data.role;
    uploadInput.metadata["apiUrl"] = this.env.apiUrl;

    const bucket = new S3({ params: { Bucket: this.awsBucketName } });
    const params: S3.Types.PutObjectRequest = {
      Key: uploadInput.fileName,
      Body: uploadInput.file,
      Bucket: this.awsBucketName,
      Metadata: uploadInput.metadata,
    };
    return new Observable((subscriber) => {
      bucket.upload(params, (err, data) => {
        if (err) {
          subscriber.error(err);
        }
        subscriber.next(data);
      });
    });
  }
}
