import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { EnvironmentService } from "../environment.service";
import { BehaviorSubject, Observable, of, tap } from "rxjs";

import { BaseService } from "../shared/base.service";
import { environment } from "../../environments/environment";
import {
  CreateYachtTransferInput,
  ICreateYachtTransferInput,
  IGetVesselInformation,
  YachtsServiceProxy,
  YachtTransferOutput,
} from "../../shared/service-proxies/service-proxies";

@Injectable({
  providedIn: "root",
})
export class VesselProfileService {
  private currentYachtSubject = new BehaviorSubject<any>(null);
  public currentYacht$ = this.currentYachtSubject.asObservable();
  public yachtStatus = new BehaviorSubject<any>(null);
  private currentTransferYachtSubject = new BehaviorSubject<IGetVesselInformation>(null);

  isLoadingCurrentYacht = false;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private baseService: BaseService,
    private yachtsServiceProxy: YachtsServiceProxy
  ) {}

  getCurrentYacht() {
    if (this.currentYachtSubject.getValue()) {
      return this.currentYachtSubject.getValue();
    } else {
      return this.getCurrentYachtData();
    }
  }

  async fetchCurrentYacht() {
    if (this.currentYachtSubject.getValue()) {
      return this.currentYachtSubject.getValue();
    } else if (this.baseService.getSession("currentYacht")) {
      return this.baseService.getSession("currentYacht");
    } else {
      return await this.fetchCurrentYachtData();
    }
  }

  setCurrentTransferYacht(yacht: IGetVesselInformation) {
    this.currentTransferYachtSubject.next(yacht);
  }

  getYachtInformation(yachtId?: number): Observable<IGetVesselInformation> {
    const currentYacht = this.currentYachtSubject.getValue() || this.getCurrentYachtData();
    if (!yachtId) {
      if (currentYacht) {
        this.currentYachtSubject.next(currentYacht);
      }
    }
    return this.yachtsServiceProxy.getDefaultYachtWithInformation();
  }

  updateCurrentYachtInformation(): Observable<any> {
    const currentYacht = this.currentYachtSubject.getValue() || this.getCurrentYachtData();

    if (currentYacht) {
      this.currentYachtSubject.next(currentYacht);
      return of(currentYacht);
    } else {
      return this.getYachtInformation(currentYacht.yachtId).pipe(
        tap((res) => {
          if (res) {
            this.storeYachtData(res.result);
            this.currentYachtSubject.next(res.result);
          }
        })
      );
    }
  }

  updateVesselInformation(data: any): any {
    return this.http
      .put(this.env.apiUrl + "services/app/Yachts/UpdateVesselInformation", data, {
        headers: {
          Authorization: `Bearer ${this.baseService.getToken()}`,
          encType: "multipart/form-data",
        },
      })
      .pipe(
        tap((res) => {
          const yacht = res["result"];
          if (res) {
            const yachtId = yacht.yachtId;
            const currentYacht = this.currentYachtSubject.getValue() || this.baseService.getSession("currentYacht");
            if (yachtId === currentYacht.yachtId) {
              this.storeYachtData(yacht);
            } else {
              this.getCurrentYachtData();
            }
          }
          return res;
        })
      );
  }

  getCurrentYachtData() {
    if (this.isLoadingCurrentYacht) {
      return;
    }
    const currentYacht = this.currentYachtSubject.getValue() || this.baseService?.getSession("currentYacht");
    if (currentYacht) {
      return currentYacht;
    } else {
      this.isLoadingCurrentYacht = true;
      this.yachtsServiceProxy.getDefaultYachtWithInformation().subscribe({
        next: (res) => {
          this.isLoadingCurrentYacht = false;
          this.storeYachtData(res);
          return res;
        },
        error: (err) => {
          this.isLoadingCurrentYacht = false;
          console.log(err);
        },
      });
    }
  }

  fetchCurrentYachtData(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isLoadingCurrentYacht) {
        reject();
      }
      const currentYacht = this.currentYachtSubject.getValue();
      if (currentYacht) {
        return currentYacht;
      } else {
        this.isLoadingCurrentYacht = true;
        this.yachtsServiceProxy.getDefaultYachtWithInformation().subscribe({
          next: (res) => {
            this.isLoadingCurrentYacht = false;
            this.storeYachtData(res);
            resolve(res);
          },
          error: (err) => {
            this.isLoadingCurrentYacht = false;
            console.log(err);
            reject();
          },
        });
      }
    });
  }

  transferYacht(payload: CreateYachtTransferInput): Observable<YachtTransferOutput> {
    const persona = this.env.persona;
    return this.yachtsServiceProxy.createYachtTransfer(persona, payload);
  }

  getAllTransfers(YachtId?: number, Filter?: string, MaxResultCount = 10, SkipCount = 0): Observable<any> {
    const params = {
      MaxResultCount,
      SkipCount,
      Filter: Filter || "",
    };
    if (YachtId) {
      params["YachtId"] = YachtId;
    }
    return this.http.get(this.env.apiUrl + "services/app/Yachts/GetAllYachtTransfers", {
      params,
    });
  }

  deleteYachtTransfer(transferId: number) {
    return this.http.delete(this.env.apiUrl + "services/app/Yachts/DeleteYachtTransfer?id=" + transferId);
  }

  getAllYachts(MaxResultCount = 10, SkipCount = 0): Observable<any> {
    return this.http.get(this.env.apiUrl + "services/app/Yachts/GetAll", {
      params: {
        MaxResultCount,
        SkipCount,
      },
    });
  }

  geAllComponents(yachtId): Observable<any> {
    return this.http.get(this.env.apiUrl + "services/app/Components/GetAllComponent?yachtId=" + yachtId);
  }

  getComponentDetails(yachtId, systemTypeId): Observable<any> {
    return this.http.get(
      this.env.apiUrl + "services/app/Components/GetComponentDetails?YachtId=" + yachtId + "&SystemType=" + systemTypeId
    );
  }

  getComponentDetailsById(yachtId, Id): Observable<any> {
    return this.http.get(
      this.env.apiUrl + "services/app/Components/GetComponentDetails?YachtId=" + yachtId + "&Id=" + Id
    );
  }

  getDefaultYacht(): Observable<any> {
    return this.http.get(this.env.apiUrl + "services/app/Yachts/GetDefaultYacht");
  }
  getVitalAreaStatus(yachtId: number): Observable<any> {
    return this.http.get(this.env.apiUrl + "services/app/VitalAreas/GetVitalsAreaStatusForYacht?yachtId=" + yachtId);
  }

  createNewYacht(data): any {
    return this.http.post(this.env.apiUrl + "services/app/Yachts/CreateYacht", data);
  }

  setCurrentYacht(yachtId?: number, callback: any = null) {
    return new Promise((resolve, reject) => {
      this.setDefaultBoat(yachtId);
      if (yachtId) {
        this.getYachtInformation(yachtId).subscribe({
          next: (res) => {
            this.storeYachtData(res);

            if (callback) {
              callback();
            }
            resolve(res);
          },
        });
      } else {
        if (!localStorage.getItem("currentYacht")) {
          this.yachtsServiceProxy.getDefaultYachtWithInformation().subscribe({
            next: (res) => {
              if (res) {
                this.storeYachtData(res);
              }

              if (callback) {
                callback();
              }
              resolve(res);
            },
            error: (err) => {
              reject(err);
            },
          });
        } else {
          this.currentYachtSubject.next(JSON.parse(localStorage.getItem("currentYacht")));
          resolve(JSON.parse(localStorage.getItem("currentYacht")));
          if (callback) {
            callback();
          }
        }
      }
    });
  }

  storeYachtData(yacht: any) {
    localStorage.setItem("currentYacht", JSON.stringify(yacht));
    this.currentYachtSubject.next(yacht);
  }

  setDefaultBoat(yachtId: number) {
    this.setDefaultYacht({
      yachtId: yachtId,
    }).subscribe(() => {
      //
    });
  }

  setDefaultYacht(data): any {
    return this.http.put(this.env.apiUrl + "services/app/Yachts/SetDefaultYacht", data);
  }

  saveBoatSystems(payload) {
    return this.http.post(this.env.apiUrl + "services/app/Components/BulkAddSystemComponents", payload);
  }

  deleteComponent(Id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}services/app/Components/DeleteComponent?Id=${Id}`);
  }
}
