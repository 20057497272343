import { BehaviorSubject, Subject } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { EventEmitter, Injectable, NgZone } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { BaseService } from "src/app/shared/base.service";
import { GpsLocationService } from "../technician/gps-location.service";
import { YachtMessagesService } from "../shared/messages.service";
import { environment } from "src/environments/environment";
import { DATA_TYPE } from "../shared/push-notification.service";
import { Platform } from "@ionic/angular";
// import { AppAlertsService } from "../services/app-alerts.service";

export interface ChatData {
  message: string;
  creationTime: string;
  side: number;
  targetUserId: number;
}

export interface FriendsData {
  friendUserId: number;
  friendTenantId: number;
  friendUserName: string;
  friendTenancyName: string;
  friendProfilePictureId: string;
  unreadMessageCount: number;
  isOnline: boolean;
  state: number;
}
type ChatContainer = {
  Users: ChatUser[];
};

type ChatUser = {
  displayName: string;
  Id: number;
  messages: ChatMessage[]; // Messages that match the id of the User
  isOnline: boolean;
  unreadLiveCount: number;
  friendProfilePictureId: string;
  lastMessage: string;
  lastMessageTime: Date;
  linkType: string;
};

type ChatMessage = {
  id: number;
  SharedId: string;
  message: string;
  messageObject: any; // You can deserialize here, so maybe not string
  creationTime: Date; // You should order them based on this
  readstate: number;
  receiverReadstate: number;
  side: number; // From you or the other party
  linkType: string;
};

type RealTimeEntityChange = {
  notificationType: string;
  entityid: number;
  metaData: string;
};

@Injectable({ providedIn: "root" })
export class SignalrService {
  chatChange = new BehaviorSubject(null);
  public friendListChange = new BehaviorSubject(null);
  friendChange: EventEmitter<FriendsData> = new EventEmitter();
  chat: ChatContainer = { Users: [] };
  unreadMessageCounts = 0;
  unreadMessageSignal = new BehaviorSubject(null);
  pushMessage = new BehaviorSubject(null);
  pushMessageReceived = this.pushMessage.asObservable();
  userId: any;
  notificationSource = new BehaviorSubject(null);
  currentNotification = this.notificationSource.asObservable();

  yachtProfileNoticeSource = new BehaviorSubject(null);
  yachtProfileNotice$ = this.yachtProfileNoticeSource.asObservable();

  yachtAccessChange = new Subject();
  isChatPage = false;

  // TODO: Remove this when pagination is implemented in the backend
  allFriends = [];
  friendsPerPage = 8;
  startIndex = 0;

  constructor(
    private baseService: BaseService,
    private yachtMessagesService: YachtMessagesService,
    private locationService: GpsLocationService,
    private platform: Platform,
    private dataService: DataService
  ) {
    const sessionData: any = localStorage.getItem("sessionData");
    const user_data = JSON.parse(sessionData);
    this.userId = user_data?.result.userId;
  }

  hubConnection: signalR.HubConnection;

  startConnection = () => {
    if (this.hubConnection != null) {
      if (this.hubConnection.state == "Disconnected") {
        this.chat = { Users: [] };
        this.startSignalR();
      }
    } else {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`https://${environment.apiHost}/signalr-chat`, {
          accessTokenFactory: () => this.baseService.getToken(),
        })
        .withAutomaticReconnect()
        .build();
      this.chat = { Users: [] };
      this.hubConnection.on("getUserConnectNotification", (user, isConnected) => {
        this.chat.Users.find((e) => e.Id == user.userId).isOnline = isConnected;
      });

      this.hubConnection.on("RealTimeNotifications", (data) => {
        //
      });

      this.hubConnection.on("getNotification", (data) => {
        const receivedData = JSON.parse(data?.metaData);

        if (data.typeName == "ENTITY CHANGE" && receivedData.EntityType == "YACHT") {
          this.yachtProfileNoticeSource.next(receivedData);
        }

        if (receivedData?.EntityType == "YACHT" && environment.buildType === "technician") {
          this.locationService.getSignalRMyTodayYachtLocation();
        }
        if (data.typeName == "SERVICE PROVIDER ADDED") {
          this.dataService.setActionEventType("RELOAD_SERVICE_PROVIDER_LIST", receivedData.EntityId);
        }

        if(data.typeName == "FRIENDLIST UPDATED" ){
          this.getFriendsChatsAndSettings();
          console.log("friend list updated", data);
          console.log("receivedData", receivedData);

        }

        if (data.typeName == "YACHT ACCESS CONTROL CHANGED") {
          this.yachtAccessChange.next(data);
          //
        }

        if (data.typeName == "ENTITY CHANGE" && receivedData.EntityType == "PROPOSAL") {
          this.dataService.setActionEventType("RELOAD_PROPOSAL_COUNT", receivedData.EntityId);
        }
        if (data.typeName == "ENTITY CHANGE" && receivedData.EntityType == "SERVICEORDER") {
          this.dataService.setActionEventType("RELOAD_SERVICE_ORDER", receivedData.EntityId);
          this.dataService.setActionEventType("RELOAD_SERVICE_LIST", receivedData.EntityId);
        } else if (data.typeName == "ENTITY CHANGE" && receivedData.EntityType == "SERVICEORDERNOTE") {
          this.dataService.setActionEventType("RELOAD_SERVICE_ORDER_NOTES", receivedData.EntityId);
          if (receivedData.Message?.serviceOrderNote?.creatorId != this.userId) {
            const note = JSON.parse(receivedData.Message);
            const msg = `You have a new Job Note from ${note.serviceOrderNote.creator} on your service order #${note.serviceOrderNote.serviceOrderId}`;

            this.pushMessage.next({ msg, payload: note });
          }
        } else if (data.typeName == "VENDOR ALERT" && receivedData.EntityType == "SERVICEORDER") {
          // SOMETHING
        }
      });

      this.hubConnection.on("getChatMessage", (chatMessage) => {
        console.log("arrived", chatMessage);
        let isSelf = false;
        const userID = chatMessage.userId;
        const newMessage = {
          id: chatMessage?.id,
          SharedId: chatMessage.sharedMessageId,
          message: chatMessage.message,
          messageObject: JSON.parse(chatMessage.message),
          creationTime: chatMessage.creationTime,
          readstate: chatMessage.readState,
          receiverReadstate: chatMessage.receiverReadState,
          side: chatMessage.side,
          linkType: chatMessage.linkType,
          targetUserId: chatMessage.targetUserId,
        };

        if (userID === this.userId && chatMessage.side === 1) {
          isSelf = true;
          this.friendListChange.value.forEach((element) => {
            if (element.Id == chatMessage.targetUserId) {
              element.lastMessage = chatMessage.message ? JSON.parse(chatMessage.message)?.data : "";
              element.lastMessageTime = chatMessage.creationTime;
              if (element.messages && element.messages.length > 0) {
                element.messages = [...element.messages, newMessage];
              } else if (element.messages && element.messages.length == 0) {
                element.messages = [newMessage];
              }
            }
          });
        }

        if (userID === this.userId && chatMessage.side === 2) {
          this.friendListChange.value.forEach((element) => {
            if (element.Id == chatMessage.targetUserId) {
              element.unreadMessageCount += 1;
              element.lastMessage = chatMessage.message ? JSON.parse(chatMessage.message)?.data : "";
              element.lastMessageTime = chatMessage.creationTime;
              element.lastMessageSide = chatMessage.side;
              if (element.messages && element.messages.length > 0) {
                element.messages.push(newMessage);
                this.chatChange.next({ newMessage, isSelf });
              }
            }
          });
          this.unreadMessageCounts += 1;

          this.unreadMessageSignal.next({
            unreadMessageCount: this.unreadMessageCounts,
          });
          // this.chatChange.next(newMessage);
          const response = this.dataService.getIsChatPage();
          this.isChatPage = response.isChatPage;
          const targetFriendId = response.targetFriendId;
          console.log({ targetFriendId });
          console.log({ isChatPage: response.isChatPage });
          if (!this.isChatPage || (this.isChatPage && targetFriendId !== newMessage.targetUserId)) {
            this.sendNotification(chatMessage, newMessage);
          }
        }
        this.sortFriendsList(isSelf);

        // }
        // if (
        //   this.platform.is("capacitor") &&
        //   this.dataService.getAccountSettings().allowChatTone
        // ) {
        //   NativeAudio.play({ assetId: "message_chime" });
        // }
      });

      this.startSignalR();
    }
  };

  startSignalR() {
    this.hubConnection
      .start()
      .then(() => {
        // this.getFriendsChatsAndSettings();
      })
      .catch((err) => {
        setTimeout(() => {
          this.startSignalR();
        }, 10000);
      });
  }

  sendNotification(chatMessage, newMessage) {
    const sender = this.friendListChange.value.find((element) => element.Id == chatMessage.targetUserId);
    console.log({ sender });
    console.log({ newMessage });
    const msg = `You have a new message from ${sender.displayName} -  \n msg: ${newMessage.messageObject?.data}`;

    this.pushMessage.next({
      msg,
      payload: sender,
      linkType: DATA_TYPE.MESSAGE,
    });
  }

  sortFriendsList(isSelf?: boolean) {
    this.friendListChange.value.sort((a, b) => {
      if (a.lastMessageTime && b.lastMessageTime) {
        return new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime();
      } else if (a.lastMessageTime) {
        return -1;
      } else if (b.lastMessageTime) {
        return 1;
      } else {
        return 0;
      }
    });
    this.chatChange.next({ data: null, isSelf });
    this.friendListChange.next(this.friendListChange.value);
  }

  getFriendsChatsAndSettings(): Promise<any> {
    this.chat.Users = [];
    this.unreadMessageCounts = 0;

    return new Promise((resolve, reject) => {
      this.yachtMessagesService.GetUserChatFriendsWithSettings().subscribe(
        (data: any) => {
          const friends = data.result?.friends.map((friend) => {
            this.calculateAllUnreadMessages(friend);
            return this.mapFriendToChatUser(friend);
          });

          this.unreadMessageSignal.next({
            unreadMessageCount: this.unreadMessageCounts,
          });

          this.friendListChange.next(friends);

          resolve(friends);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  mapFriendToChatUser(friend) {
    const lastMessage = friend?.latestMessage ? JSON.parse(friend?.latestMessage?.message)?.data : "";
    const messages = null;
    return {
      displayName: friend.friendUserName,
      Id: friend.friendUserId,
      messages,
      isOnline: friend.isOnline,
      unreadMessageCount: friend.unreadMessageCount,
      friendProfilePictureId: "",
      lastMessage: lastMessage,
      lastMessageTime: friend.latestMessage ? friend.latestMessage.creationTime : "",
      lastMessageSide: friend?.latestMessage?.side,
      state: friend.state,
    };
  }

  calculateAllUnreadMessages(friend) {
    this.unreadMessageCounts = this.unreadMessageCounts + friend?.unreadMessageCount || 0;
  }

  async loadNextFriends() {
    const startIndex = this.chat.Users.length;
    const endIndex = startIndex + this.friendsPerPage;

    if (this.allFriends.length > this.friendsPerPage) {
      const nextFriends = this.allFriends.slice(this.startIndex, this.friendsPerPage + 8);

      nextFriends.map((user) => {
        return new Promise<void>((userResolve, userReject) => {
          this.yachtMessagesService.GetUserChatMessages(user.Id).subscribe((messages: any) => {
            const messageArray = messages.result?.items
              .filter((item) => {
                return !(this.userId === item.targetUserId && item.side === 2);
              })
              .map((item) => {
                let messageObject = {};
                try {
                  messageObject = JSON.parse(item.message);
                } catch (err) {
                  // console.log(err);
                }
                return {
                  id: item.id,
                  SharedId: item.sharedMessageId,
                  message: item.message,
                  messageObject,
                  creationTime: item.creationTime,
                  readstate: item.readState,
                  receiverReadstate: item.receiverReadState,
                  side: item.side,
                  state: item.state,
                };
              });

            user.messages.push(...messageArray);
            const userMessages = [...user.messages].reverse();
            user.lastMessage = userMessages[0]?.messageObject?.data;
            user.lastMessageTime = userMessages[0]?.creationTime;

            userResolve();
          }, userReject);
        });
      });
      this.startIndex = this.friendsPerPage;
      this.friendsPerPage += 8;

      this.chat.Users.push(...nextFriends);
    }
    return this.chat.Users;
  }

  getMessagesWithFriendID(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.yachtMessagesService.GetUserChatMessages(id).subscribe(
        (messages: any) => {
          try {
            const messageArray = messages.result?.items
              .filter((item) => {
                return !(this.userId === item.targetUserId && item.side === 2);
              })
              .map((item) => {
                let messageObject = {};
                try {
                  messageObject = JSON.parse(item.message);
                } catch (err) {
                  // console.log(err);
                }
                return {
                  id: item.id,
                  SharedId: item.sharedMessageId,
                  message: item.message,
                  messageObject,
                  creationTime: item.creationTime,
                  readstate: item.readState,
                  receiverReadstate: item.receiverReadState,
                  side: item.side,
                  state: item.state,
                };
              });

            this.friendListChange.value.forEach((element) => {
              if (element.Id == id) {
                element.messages = messageArray;
                element.lastMessage = messageArray.length
                  ? messageArray[messageArray.length - 1]?.messageObject?.data
                  : "";
                element.lastMessageTime = messageArray.length
                  ? messageArray[messageArray.length - 1]?.creationTime
                  : "";
              }
            });
            this.friendListChange.next(this.friendListChange.value);
            this.chatChange.next({ data: messageArray });

            resolve(messageArray);
          } catch (error) {
            reject(error);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async stopConnection() {
    try {
      await this.hubConnection.stop();
    } catch (err) {
      //
    }
  }

  clearFriendUnreadCount(data: { userId: number; tenantId: number }) {
    this.unreadMessageCounts = 0;
    this.friendListChange.value.forEach((element) => {
      if (element.Id == data.userId) {
        element.unreadMessageCount = 0;
      }
      this.calculateAllUnreadMessages(element);
    });
    this.unreadMessageSignal.next({
      unreadMessageCount: this.unreadMessageCounts,
    });
    this.friendListChange.next(this.friendListChange.value);
    this.clearChatCountFromServer(data);
  }

  clearChatCountFromServer(data: { userId: number; tenantId: number }) {
    this.yachtMessagesService.MarkAllUnreadMessagesOfUserAsRead(data).subscribe(
      (data) => {},
      (err) => {
        // error
      }
    );
  }

  async sendMessage(messageVal, useridNumber, callback?: any) {
    try {
      await this.hubConnection.invoke("SendMessage", {
        message: messageVal,
        tenantid: 1,
        userid: useridNumber,
        TenancyName: "default",
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      //
    }
  }
}
