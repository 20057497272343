import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-alert-dialog",
    templateUrl: "./alert-dialog.component.html",
    styleUrls: ["./alert-dialog.component.css"],
})
export class AlertDialogComponent implements OnInit {
    @Input() title: string;
    @Input() message: string | undefined = "";
    @Input() actions: {
        label: string;
        handler?: () => void;
        variant?: string;
    }[] = [];

    showAlert = false;
    alertType: "danger" | "info" | "warning" | "success" | undefined =
        "success";
    // message: string | undefined = '';
    timeOut: any;
    clickFunction!: () => void;
    showButton = false;
    buttonText? = "";
    errorAlert: boolean | undefined = false;
    useFullAlert: boolean | undefined = false;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    actionClicked(action) {
        if (action.handler) {
            action.handler();
        }
        this.dismiss(action.label);
    }

    dismiss(result: string = null) {
        this.modalCtrl.dismiss(result);
    }

    close() {
        clearTimeout(this.timeOut);
        this.showAlert = false;
        this.useFullAlert = false;
    }

    openItem() {
        this.close();
        this.clickFunction();
    }
}
