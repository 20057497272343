import { Injectable } from "@angular/core";
import { NativeAudio } from "@capacitor-community/native-audio";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AudioService {
  private audioList = [
    "welcome",
    "get_your_ship_together",
    "i_am_captain_ai",
    "think_of_me",
    "chime-listen",
    "welcome_es",
    "get_your_ship_together_es",
    "i_am_captain_ai_es",
    "think_of_me_es",
    "message_sent",
  ];
  private welcomeAudioList = [
    "welcome",
    "get_your_ship_together",
    "i_am_captain_ai",
    "think_of_me",
    "welcome_es",
    "get_your_ship_together_es",
    "i_am_captain_ai_es",
    "think_of_me_es",
  ];
  constructor(private platform: Platform) {
    //
  }

  async configureAudio(options: { overrideBackgroundMusic: boolean }) {
    try {
      if (this.platform.is("capacitor")) {
        await NativeAudio.configure({ focus: options.overrideBackgroundMusic });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async playAudio(audioName: string) {
    try {
      if (this.platform.is("capacitor")) {
        await NativeAudio.play({ assetId: audioName });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async stopAudio(audioName: string) {
    try {
      if (this.platform.is("capacitor")) {
        await NativeAudio.stop({ assetId: audioName });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async stopAllAudio() {
    try {
      if (this.platform.is("capacitor")) {
        this.audioList.forEach(async (audio) => {
          await NativeAudio.stop({ assetId: audio });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async setVolume(audioName: string, volume: number) {
    try {
      if (this.platform.is("capacitor")) {
        await NativeAudio.setVolume({ assetId: audioName, volume });
      }
    } catch (error) {
      console.error(error);
    }
  }

  muteAll() {
    try {
      if (this.platform.is("capacitor")) {
        this.welcomeAudioList.forEach(async (audio) => {
          await NativeAudio.setVolume({ assetId: audio, volume: 0 });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  unMuteAll() {
    try {
      if (this.platform.is("capacitor")) {
        this.welcomeAudioList.forEach(async (audio) => {
          await NativeAudio.setVolume({ assetId: audio, volume: 1 });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadAudio() {
    try {
      if (this.platform.is("capacitor")) {
        this.audioList.forEach(async (audio) => {
          await NativeAudio.preload({
            assetId: audio,
            assetPath: `${audio}.mp3`,
            isUrl: false,
          });
        });
      }
    } catch (error) {
      console.error({ error });
    }
  }

  async unloadAudio() {
    try {
      if (this.platform.is("capacitor")) {
        this.audioList.forEach(async (audio) => {
          await NativeAudio.unload({
            assetId: audio,
          });
        });
      }
    } catch (error) {
      console.error({ error });
    }
  }
}
