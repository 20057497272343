import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsCustomService {
	constructor(
		private platform: Platform,
		private gaService: GoogleAnalyticsService
	) {
		if (
			!this.platform.is('ios') &&
			environment.googleAnalyticsConfig.firebaseAnalyticsTracking
		) {
			FirebaseAnalytics.enable();
		}
	}
	async requestTrackingPermission() {
		try {
			const { status } = await AppTrackingTransparency.getStatus();
		if(status === 'notDetermined'){
			const response = await AppTrackingTransparency.requestPermission();
			if(response.status === 'authorized' && environment.googleAnalyticsConfig.firebaseAnalyticsTracking){
				await FirebaseAnalytics.enable();
			}else{
				await FirebaseAnalytics.disable();
			}
		}else if(status === 'authorized' && environment.googleAnalyticsConfig.firebaseAnalyticsTracking){
			await FirebaseAnalytics.enable();
		} else {
			await FirebaseAnalytics.disable();
		}
		} catch (error) {
			console.log('Error requesting permissions');
		}
		

	
	}

	setScreenName(screenName: string, screenClassOverride?: string): void {
		FirebaseAnalytics.setScreenName({
			screenName,
			nameOverride: screenClassOverride || screenName,
		});
	}

	logEvent(eventName: string, eventParams?: { [key: string]: any }): void {
		FirebaseAnalytics.logEvent({
			name: eventName,
			params: eventParams || {},
		});
	}

	setUserProperty(propertyName: string, propertyValue: string): void {
		FirebaseAnalytics.setUserProperty({
			name: propertyName,
			value: propertyValue,
		});
	}

	setUserId(userId: string): void {
		FirebaseAnalytics.setUserId({
			userId,
		});
		// this is for google analytics
	}
	resetAnalyticsData(): void {
		FirebaseAnalytics.reset();
	}
	audienceData(subscription: string, trial: string): void {
		this.setUserProperty('membership_level', subscription); // Set user_id
		this.gaService.set('membership_level', subscription);
		this.setUserProperty('subscription_status', trial);
		this.gaService.set('subscription_status', trial);
	}
	signUpEvent(): void {
		this.logEvent('account_created', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('account_created'); // this is for google analytics
		}
	}
	LoginEvent(): void {
		this.logEvent('login', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('login'); // this is for google analytics
		}
	}
	boatImageUploadEvent(): void {
		this.logEvent('added_boat_picture', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('added_boat_picture'); // this is for google analytics
		}
	}
	addBoatEvent(): void {
		this.logEvent('added_boat', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('added_boat'); // this is for google analytics
		}
	}
	engageCaptainAIEvent(): void {
		this.logEvent('started_captainai_chat', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('started_captain_chat'); // this is for google analytics
		}
	}
	CaptainAIChatEvent(): void {
		this.logEvent('captainai_chat_count', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('captainai_chat_count'); // this is for google analytics
		}
	}
	addedVendorEvent(): void {
		this.logEvent('added_vendor', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('added_vendor'); // this is for google analytics
		}
	}
	addedParkingEvent(): void {
		this.logEvent('added_parking', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('added_parking'); // this is for google analytics
		}
	}
	requestServiceEvent(): void {
		this.logEvent('requested_service', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('requested_service'); // this is for google analytics
		}
	}
	wifiAccessEvent(): void {
		this.logEvent('added_access_and_wifi', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('added_access_and_wifi'); // this is for google analytics
		}
	}
	addedBoatSpecificsEvent(): void {
		this.logEvent('added_boat_specifics', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('added_boat_specifics'); // this is for google analytics
		}
	}
	notificationOptInEvent(): void {
		this.logEvent('notification_opt_in', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('notification_opt_in'); // this is for google analytics
		}
	}
	notificationOptOutEvent(): void {
		this.logEvent('notification_opt_out', {
			device_type: 'mobile',
			platform: Capacitor.getPlatform(),
			interaction_type: 'click',
			clicked_at: new Date().toLocaleString(),
		});
		if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
			this.gaService.event('notification_opt_out'); // this is for google analytics
		}
	}
}
