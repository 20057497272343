import { Injectable } from "@angular/core";
import { SpeechRecognition } from "@capacitor-community/speech-recognition";
import { ModalController, Platform } from "@ionic/angular";
import { PermissionDeniedComponent } from "./../shared/permission-denied/permission-denied.component";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SpeechService {
  message = "";

  public isListeningSource = new BehaviorSubject<boolean>(false);
  isListening$ = this.isListeningSource.asObservable();

  public messageSource = new BehaviorSubject<string>("");
  currentMessage = this.messageSource.asObservable();

  constructor(private modalCtrl: ModalController, private platform: Platform) {}

  async initializeSpeechRecognition() {
    if (this.platform.is("capacitor")) {
      try {
        await SpeechRecognition.requestPermissions();
      } catch (error) {
        console.log(error)
      }
     
    }
  }

  async startListening() {
    if (this.platform.is("capacitor")) {
      const permission = await SpeechRecognition.checkPermissions();
      if (permission.speechRecognition === "denied") {
        const text =
          "To capture and update your profile and boat pictures instantly, we need access to your camera. This lets you take photos directly within the app for a seamless experience. This permission was previously denied. To enjoy this feature, please enable camera access in the app settings.";
        const modal = await this.modalCtrl.create({
          component: PermissionDeniedComponent,
          componentProps: {
            header: `Microphone Access Needed`,
            text,
          },
          cssClass: "blurry-backdrop-auto",
        });
        await modal.present();
      } else {
        const isAvailable = SpeechRecognition.available();
        if (isAvailable) {
          this.isListeningSource.next(true);
          let language = "en-US";
          if (localStorage.getItem("lang")) {
            language =
              localStorage.getItem("lang") === "en" ? "en-US" : "es-ES";
          }
          await SpeechRecognition.start({
            partialResults: true,
            maxResults: 2,
            popup: false,
            language,
          });

          SpeechRecognition.addListener("partialResults", (data: any) => {
            if (data.matches && data.matches.length > 0) {
              this.messageSource.next(data.matches[0]);
            }
          });
        }
      }
    }
  }

  async stopListening(clearText = false) {
    this.isListeningSource.next(false);
    if(clearText) {
      this.messageSource.next("");
    }
    if (this.platform.is("capacitor")) {
      await SpeechRecognition.stop();
    }
  }

  removeListeners() {
    if (this.platform.is("capacitor")) {
      SpeechRecognition.removeAllListeners();
    }
  }

  ngOnDestroy() {
    this.removeListeners();
      this.messageSource.next("");
  }
}
