import { Injectable } from "@angular/core";
import { AbpHttpConfigurationService, IErrorInfo, LogService, MessageService } from "abp-ng2-module";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class ZeroTemplateHttpConfigurationService extends AbpHttpConfigurationService {
    constructor(messageService: MessageService, logService: LogService, private _route: Router) {
        super(messageService, logService);
    }

    // Override handleUnAuthorizedRequest so it doesn't refresh the page during failed login attempts.
    override handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {
        return;
    }

    override showError(error: IErrorInfo) {
        return;
    }
}
