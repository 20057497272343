import { IEnvironment } from './ienvironment';
import { PersonaEnum } from 'src/shared/service-proxies/service-proxies';

const apiHost = "api.boatbot-staging.dev";
const apiUrl = `https://${apiHost}/api/`;
const abpUrl = `https://${apiHost}/`;
const proxyServiceUrl = `https://${apiHost}`;
const persona = PersonaEnum.YACHT_OWNER;
const mapsKey = "AIzaSyA7ZHFozTS_NFJJwaq3Gi8UfYbg-vYfT74";
const geoLocationTimer = 1000;
export const environment: IEnvironment = {
	apiHost,
	apiUrl,
	proxyServiceUrl,
	persona,
	mapsKey,
	abpUrl,
	geoLocationTimer,
	enableDebugTools: false,
	logLevel: 'debug',
	production: true,
	buildType: "boatowner",
	sentry: {
		dsn: 'https://68d64400bfc2b4ffaa557003cf76a863@o4507352705597440.ingest.us.sentry.io/4507352803508224',
		traceTargets: ["https://owner.boatbot-staging.dev/","https://api.boatbot-staging.dev/","https://captainai.boatbot.ai/"],
		sentryEnvironment: "staging",
	},
	googleAnalyticsConfig: {
		trackingID: 'TRACKING_ID',
		firebaseAnalyticsTracking: false,
	},
};
