import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-image-preview",
    templateUrl: "./image-preview.component.html",
    styleUrls: ["./image-preview.component.css"],
})
export class ImagePreviewComponent implements OnInit {
    @Input() imageUrls: string;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {}

    close() {
        this.modalCtrl.dismiss();
    }
}
