import { Injectable } from "@angular/core";
import {
  CreateYachtInput,
  DefaultYachtDtoInput,
  ICreateYachtInput,
  IDefaultYachtDtoInput,
  IGetVesselInformation,
  IPagedResultDtoOfGetYachtForViewDto,
  ISetPrimaryGalleryImageByClientIdInput,
  SetPrimaryGalleryImageByClientIdInput,
  YachtsServiceProxy,
} from "src/shared/service-proxies/service-proxies";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BoatManagementService {
  private vesselInformationSubject = new BehaviorSubject<IGetVesselInformation | null>(null);
  public vesselInformation$ = this.vesselInformationSubject.asObservable();

  private allBoatsSubject = new BehaviorSubject<IPagedResultDtoOfGetYachtForViewDto | null>(null);
  public allBoats$ = this.allBoatsSubject.asObservable();

  private reloadBoatsSubject = new Subject<boolean>();
  public reloadBoats$ = this.reloadBoatsSubject.asObservable();

  public isOnboarding$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private yachtService: YachtsServiceProxy) {}

  getOnboardingStatus() {
    return this.isOnboarding$.value;
  }

  setOnboardingStatus(status: boolean) {
    this.isOnboarding$.next(status);
  }

  setReloadBoats(): void {
    this.reloadBoatsSubject.next(true);
  }

  setVesselFormData(newBoat: IGetVesselInformation): void {
    this.vesselInformationSubject.next(newBoat);
  }

  getVesselFormData(): IGetVesselInformation | null {
    return this.vesselInformationSubject.value;
  }

  resetVesselFormData(): void {
    this.vesselInformationSubject.next(null);
  }

  getBoats(filterOptions?: IGetBoatsFilterOptions): Observable<IPagedResultDtoOfGetYachtForViewDto> {
    const defaultFilterOptions: IGetBoatsFilterOptions = {
      sorting: "creationTime desc",
      skipCount: 0,
      maxResultCount: 10,
    };

    if (!filterOptions) {
      filterOptions = defaultFilterOptions;
    }

    const queryParams = {
      filter: filterOptions.filter ?? undefined,
      forSale: filterOptions.forSale ?? undefined,
      hasActiveTransfer: filterOptions.hasActiveTransfer ?? undefined,
      sorting: filterOptions.sorting ?? defaultFilterOptions.sorting,
      skipCount: filterOptions.skipCount ?? defaultFilterOptions.skipCount,
      maxResultCount: filterOptions.maxResultCount ?? defaultFilterOptions.maxResultCount,
    };

    return this.yachtService.getAll(
      queryParams.filter,
      queryParams.forSale,
      queryParams.hasActiveTransfer,
      queryParams.sorting,
      queryParams.skipCount,
      queryParams.maxResultCount
    );
  }

  createBoat(payload: ICreateYachtInputPayload): Observable<IGetVesselInformation> {
    const body: ICreateYachtInput = {
      manufacturerId: payload.manufacturerId ?? undefined,
      vesselModelId: payload.vesselModelId ?? undefined,
      yachtName: payload.yachtName ?? undefined,
      documentNumber: payload.documentNumber ?? undefined,
      fuelType: payload.fuelType ?? undefined,
      vesselType: payload.vesselType ?? undefined,
      yachtLength: payload.yachtLength ?? undefined,
      measureUnit: payload.measureUnit ?? undefined,
      yearBuilt: payload.yearBuilt ?? undefined,
      stateId: payload.stateId ?? undefined,
      stateName: payload.stateName ?? undefined,
      latitude: payload.latitude ?? undefined,
      longitude: payload.longitude ?? undefined,
      slipNumber: payload.slipNumber ?? undefined,
      hailingPort: payload.hailingPort ?? undefined,
      address: payload.address ?? undefined,
      streetName: payload.streetName ?? undefined,
      city: payload.city ?? undefined,
      zipCode: payload.zipCode ?? undefined,
      specialInstructions: payload.specialInstructions ?? undefined,
      isFavorite: payload.isFavorite ?? undefined,
      isDefault: payload.isDefault ?? undefined,
      isForSale: payload.isForSale ?? undefined,
      manufacturerName: payload.manufacturerName ?? undefined,
      vesselModelName: payload.vesselModelName ?? undefined,
      fuelTypeName: payload.fuelTypeName ?? undefined,
    };

    const newYacht = new CreateYachtInput(body);

    return this.yachtService.createYacht(newYacht);
  }

  updateBoat(yachtId: number, payload: ICreateYachtInputPayload): Observable<IGetVesselInformation> {
    if (!yachtId) {
      throw new Error("Yacht ID is required to update the boat");
    }

    const body: IUpdateYachtInputPayload = {
      yachtId,
      manufacturerId: payload.manufacturerId ?? undefined,
      vesselModelId: payload.vesselModelId ?? undefined,
      yachtName: payload.yachtName ?? "",
      documentNumber: payload.documentNumber ?? "",
      fuelType: payload.fuelType ?? undefined,
      vesselType: payload.vesselType ?? undefined,
      yachtLength: payload.yachtLength ?? undefined,
      measureUnit: payload.measureUnit ?? undefined,
      yearBuilt: payload.yearBuilt ?? undefined,
      slipNumber: payload.slipNumber ?? undefined,
    };

    return this.yachtService.updateVesselInformation(
      yachtId,
      body.manufacturerId,
      body.vesselModelId,
      body.yachtName,
      body.documentNumber,
      body.fuelType,
      body.vesselType,
      body.yachtLength,
      body.measureUnit,
      body.yearBuilt,
      body.slipNumber
    );
  }

  getBoatById(yachtId: number): Observable<IGetVesselInformation> {
    return this.yachtService.getVesselInformation(yachtId);
  }

  getDefaultBoat(): Observable<IGetVesselInformation> {
    return this.yachtService.getDefaultYachtWithInformation();
  }

  setDefaultBoat(payload: IDefaultYachtDtoInput): Observable<string> {
    const body = new DefaultYachtDtoInput(payload);
    return this.yachtService.setDefaultYacht(body);
  }

  setBoatPrimaryImage(yachtId: number, clientId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const payload: ISetPrimaryGalleryImageByClientIdInput = {
        yachtId,
        clientId,
      };
      const body = new SetPrimaryGalleryImageByClientIdInput(payload);
      this.yachtService.setPrimaryGalleryImageByClientID(body).subscribe({
        next: (response) => {
          if (response) {
            resolve(true);
          } else {
            reject(false);
          }
        },
        error: (err) => {
          reject(false);
          console.error("Error setting primary image", err);
        },
      });
    });
  }
}

export interface IUpdateYachtInputPayload {
  yachtId: number;
  yachtName: string;
  manufacturerId?: number | undefined;
  vesselModelId?: number | undefined;
  documentNumber?: string | undefined;
  fuelType?: string | undefined;
  vesselType?: string | undefined;
  yachtLength?: number;
  measureUnit?: string | undefined;
  yearBuilt?: number;
  slipNumber?: string | undefined;
}

export interface ICreateYachtInputPayload {
  manufacturerId?: number | undefined;
  vesselModelId?: number | undefined;
  yachtName?: string | undefined;
  documentNumber?: string | undefined;
  fuelType?: string | undefined;
  vesselType?: string | undefined;
  yachtLength?: number;
  measureUnit?: string | undefined;
  yearBuilt?: number;
  stateId?: number | undefined;
  stateName?: string | undefined;
  latitude?: number | undefined;
  longitude?: number | undefined;
  slipNumber?: string | undefined;
  hailingPort?: string | undefined;
  address?: string | undefined;
  streetName?: string | undefined;
  city?: string | undefined;
  zipCode?: string | undefined;
  specialInstructions?: string | undefined;
  isFavorite?: boolean;
  isDefault?: boolean;
  isForSale?: boolean | undefined;
  manufacturerName?: string | undefined;
  vesselModelName?: string | undefined;
  fuelTypeName?: string | undefined;
}

export interface IGetBoatsFilterOptions {
  filter?: string | undefined;
  forSale?: boolean | undefined;
  hasActiveTransfer?: boolean | undefined;
  sorting: string | undefined;
  skipCount: number | undefined;
  maxResultCount: number | undefined;
}
