import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as SentryWeb from '@sentry/angular';
import * as SentryMobile from '@sentry/capacitor';
// import * as SentrySibling from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Platform } from '@ionic/angular';
import { NgZone } from '@angular/core';
import { replayIntegration } from '@sentry/angular';

const platform = new Platform('', new NgZone({}));

if (environment.production) {
	if (platform.is('mobileweb') || platform.is('desktop')) {
		SentryWeb.init({
			dsn: environment.sentry.dsn,
			integrations: [
				SentryWeb.browserTracingIntegration(),
				SentryWeb.replayIntegration(),
			],
			// Performance Monitoring
			tracesSampleRate: 0.1, //  Capture 100% of the transactions
			environment: environment.sentry.sentryEnvironment,
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: environment.sentry.traceTargets,
			// Session Replay
			replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			maxBreadcrumbs: 10,
		});
		enableProdMode();
	} else {
		SentryMobile.init(
			{
				beforeBreadcrumb(breadcrumb, hint) {
					if (
						breadcrumb.message == 'Inspection not found' ||
						(breadcrumb?.category == 'console' && breadcrumb?.level == 'log')
					) {
						return null;
					} else {
						return breadcrumb;
					}
				},
				dsn: environment.sentry.dsn,
				// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
				// We recommend adjusting this value in production.
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0,
				tracesSampleRate: 0.1,
				maxBreadcrumbs: 10,
				integrations: [
					SentryWeb.browserTracingIntegration(),
					replayIntegration({
						maskAllText: true,
						blockAllMedia: true,
					}),
				],
				// Set "tracePropagationTargets" to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: environment.sentry.traceTargets,
				environment: environment.sentry.sentryEnvironment,
			},
			// Forward the init method to the sibling Framework.
			SentryWeb.init
		);
	}
}

// Sentry.init({
// 	dsn: 'https://98373e8c2f164e43839ecf82fa924577@o4504091502116864.ingest.sentry.io/4504092500492288',
// 	integrations: [
// 		new BrowserTracing({
// 			tracingOrigins: [
// 				'localhost',
// 				'http://localhost:4200',
// 				'http://localhost:8100',
// 			],
// 			routingInstrumentation: Sentry.routingInstrumentation,
// 		}),
// 	],
// 	tracesSampleRate: 1.0,
// });
enableProdMode();
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err: any) => console.error(err));

defineCustomElements(window);
