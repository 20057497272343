import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { LoaderService } from "./loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
})
export class LoaderComponent implements OnInit, OnDestroy {
  showLoader = false;
  @Input() isSlowInternet = false;

  constructor(private loader: LoaderService) {
    loader.loaderSub.subscribe((load: boolean) => {
      this.showLoader = load;
    });
  }

  ngOnInit() {
    this.loader.slowInternet$.subscribe((isSlow: boolean) => {
      this.isSlowInternet = isSlow;
    });
  }

  ngOnDestroy() {
    this.loader.setSlowInternet(false);
  }
}
