import { Injectable } from "@angular/core";
import { IAwsImageConfigDto, UtilityServiceProxy } from "src/shared/service-proxies/service-proxies";

@Injectable({
  providedIn: "root",
})
export class AwsCredentialsService {
  private awsCredential: IAwsImageConfigDto;

  constructor(private utilityServiceProxy: UtilityServiceProxy) {}

  setData(configData: IAwsImageConfigDto) {
    this.awsCredential = configData;
  }

  getData(): IAwsImageConfigDto {
    return this.awsCredential;
  }

  getAWSCredentials(): Promise<IAwsImageConfigDto> {
    return new Promise((resolve, reject) => {
      this.utilityServiceProxy.getAwsCredentials().subscribe({
        next: (data: IAwsImageConfigDto) => {
          resolve(data);
          this.setData(data);
        },
        error: (err) => {
          reject(err);
          console.log(err);
        },
      });
    });
  }
}
