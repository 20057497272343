<div class="p-6">
    <div class="text-center w-full mb-4">
        <h1 class="text-xl text-brandDeepSeaBlue font-bold text-uppercase mt-0">
            {{ header }}
        </h1>
        <p class="text-sm font-normal text-black">
            {{ text }}
        </p>
    </div>
    <div class="grid grid-cols-2 gap-2">
        <app-button
            [variant]="'tertiary'"
            [label]="'Cancel'"
            (buttonClick)="closeModal()"
        ></app-button>
        <app-button
            [variant]="'secondary'"
            [label]="'Open Settings'"
            (buttonClick)="openSettings()"
        ></app-button>
    </div>
</div>
