import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Socket } from "ngx-socket-io";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class TalkService {
    baseUrl = "https://captainai.boatbot.ai/";
    subject = new BehaviorSubject("");
    constructor(private httpClient: HttpClient, private socket: Socket) {
        // this.socket.on("data", (data) => {
        //     this.subject.next(data);
        // });
    }

    sendMessage(payload: any): Observable<any> {
        const result = this.httpClient.post(this.baseUrl + "ai", payload);
        return result;
    }

    translate(payload: any): Observable<any> {
        return this.httpClient.post(this.baseUrl + "translation", payload);
    }

    revise(payload: any): Observable<any> {
        return this.httpClient.post(this.baseUrl + "revision", payload);
    }

    aiBoating(payload: any): Observable<any> {
        return this.httpClient.post(this.baseUrl + "ai-boating", payload);
    }

    aiTech(payload: any): Observable<any> {
        return this.httpClient.post(this.baseUrl + "ai-tech", payload);
    }

    saveMessage(payload: any): Observable<any> {
        return this.httpClient.post(this.baseUrl + "fav", payload);
    }

    getSavedMessage(payload: any): Observable<any> {
        return this.httpClient.get(this.baseUrl + "fav/" + payload);
    }
    deleteMessage(id: any): Observable<any> {
        return this.httpClient.delete(this.baseUrl + "fav/" + id);
    }
    connect() {
        const userID: any = localStorage.getItem("sessionData");
        const jsonData = JSON.parse(userID);
        const parsedUserId = jsonData?.result.userId;

        this.socket.emit("userConnected", { userId: parsedUserId });
    }
    disconnect() {
        this.socket.emit("userDisconnected");
        this.socket.disconnect();
        this.subject.next("");
    }
}
