import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import * as Sentry from "@sentry/angular";
import { catchError, Observable, throwError } from "rxjs";
import { BaseService } from "../shared/base.service";
import { AlertDialogService } from "../shared/alert-dialog/alert-dialog.service";
import { GpsLocationService } from "../technician/gps-location.service";
import { Router } from "@angular/router";
import { LogoutService } from "../yacht-owner/logout.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private baseService: BaseService,
        private alertService: AlertDialogService,
        private logoutService: LogoutService,
        private router: Router
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            // @ts-ignore
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    // console.log("Active Error", error);
                } else {
                    // console.log("Some Error", error);
                    if (error.status === 401) {
                        this.alertService.openAlert({
                            message:
                                error.error.result?.Message ||
                                error?.error?.error?.message ||
                                error?.error?.result,
                            useFullAlert: true,
                            error: true,
                            type: "danger",
                            buttonFunction: () => {},
                        });
                        Sentry.captureException(
                            error.error.result?.Message ||
                                error?.error?.error?.message ||
                                error?.error?.result
                        );

                        this.logoutService.logout();
                    }
                    if (error.status === 400) {
                        this.alertService.openAlert({
                            message:
                                error.error.result?.Message ||
                                error?.error?.error?.message ||
                                error?.error?.result,
                            useFullAlert: true,
                            error: true,
                            type: "danger",
                            buttonFunction: () => {},
                        });
                        Sentry.captureException(
                            error.error.result?.Message ||
                                error?.error?.error?.message ||
                                error?.error?.result
                        );
                    }
                    if (error.status === 403) {
                        this.alertService.openAlert({
                            message:
                                error.error?.error?.message ||
                                error?.error?.error?.message ||
                                error?.error?.result,
                            useFullAlert: true,
                            error: true,
                            type: "danger",
                            buttonFunction: () => {},
                        });
                        Sentry.captureException(
                            error.error?.error?.message ||
                                error?.error?.error?.message ||
                                error?.error?.result
                        );
                    }
                    if (error.status === 500) {
                        this.alertService.openAlert({
                            message:
                                error.error?.result?.Message ||
                                error?.error?.error?.message ||
                                error?.error?.result,
                            useFullAlert: true,
                            error: true,
                            type: "danger",
                            buttonFunction: () => {},
                        });
                        Sentry.captureException(
                            error.error?.result?.Message ||
                                error?.error?.error?.message ||
                                error?.error?.result
                        );
                    }
                }
                return throwError(error);
            })
        );
    }
}
