<div
    *ngIf="imageUrls.length === 1"
    class="relative"
>
    <button
        class="absolute top-3 bg-white right-3 flex items-center justify-center rounded-full w-10 h-10 outline-none ring-0 text-black hover:bg-brandSunsetOrange hover:text-white transition duration-300 z-10"
        (click)="close()"
    >
        <span class="bbicon-close text-xl"></span>
    </button>
    <img
        [src]="imageUrls[0]"
        class="w-full h-full object-contain"
    />
</div>
