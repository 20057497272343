import { EventEmitter, Injectable } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { InAppModalComponent } from "../in-app-modal/in-app-modal.component";
import { InAppService } from "./in-app-service";

const START_TIME_KEY = "subscriptionStartTime";

@Injectable({
    providedIn: "root",
})
export class NewSubscriptionService {
    userId: any;
    isProNotificationOpened = false;
    isModalOpenedEvent: EventEmitter<any> = new EventEmitter(null);
    constructor(
        private modalCtrl: ModalController,
        private platform: Platform,
        private inApp: InAppService
    ) {}

    /// TODO: Subscription reactivation
    // checkSubscriptionStatus() {
    //     const daysLeft = this.getSubscriptionDaysLeft();
    //     console.warn("Subscription days left: " + daysLeft);
    //     if (!this.isEndTimeExisting() && daysLeft >= 30) {
    //         this.saveEndTime();
    //         this.openNotificationModal("free-trial");
    //     } else {
    //         if (
    //             daysLeft < 3 &&
    //             daysLeft > 0 &&
    //             !this.isModalOpened("2-days-left")
    //         ) {
    //             localStorage.setItem(
    //                 `${this.userId}_2-days-left`,
    //                 "2-days-left"
    //             );
    //             this.openNotificationModal("2-days-left");
    //         } else if (
    //             daysLeft < 4 &&
    //             daysLeft > 2 &&
    //             !this.isModalOpened("3-days-left")
    //         ) {
    //             localStorage.setItem(
    //                 `${this.userId}_3-days-left`,
    //                 "3-days-left"
    //             );
    //             this.openNotificationModal("2-days-left");
    //         } else if (
    //             daysLeft < 21 &&
    //             daysLeft > 14 &&
    //             !this.isModalOpened("20-days-left")
    //         ) {
    //             localStorage.setItem(
    //                 `${this.userId}_20-days-left`,
    //                 "20-days-left"
    //             );
    //             this.openNotificationModal("2-days-left");
    //         } else if (daysLeft < 2 && daysLeft > 0) {
    //             if (!this.isModalOpened("1-day-left")) {
    //                 localStorage.setItem(
    //                     `${this.userId}_1-day-left`,
    //                     "1-day-left"
    //                 );
    //                 this.openNotificationModal("1-day-left");
    //             }
    //         } else if (daysLeft < 1 && !this.isProNotificationOpened) {
    //             this.openNotificationModal("pro");
    //             this.isProNotificationOpened = true;
    //         }
    //     }
    // }

    isModalOpened(modalId: string): boolean {
        const sessionData: any = localStorage.getItem("sessionData");
        const user_data = JSON.parse(sessionData);
        this.userId = user_data?.result.userId;
        const key = `${user_data?.result.userId}_${modalId}`;
        const isModalOpened = localStorage.getItem(key);
        if (isModalOpened) {
            return true;
        } else {
            return false;
        }
    }

    openNotificationModal(modalId: string, action?: string, modalHtml?: any) {
        this.isModalOpenedEvent.next(true);
        this.modalCtrl
            .create({
                component: InAppModalComponent,
                componentProps: {
                    modalId,
                    modalHtml,
                    action,
                },
                cssClass: "notification-modal",
            })
            .then(async (modal) => {
                modal.present();
                await modal.onDidDismiss();
                this.isModalOpenedEvent.next(false);
            });
    }

    getSubscriptionKey() {
        const sessionData: any = localStorage.getItem("sessionData");
        const user_data = JSON.parse(sessionData);
        return `${user_data?.result.userId}_${START_TIME_KEY}`;
    }

    isEndTimeExisting(): boolean {
        const endTime = localStorage.getItem(this.getSubscriptionKey());
        if (endTime) {
            return true;
        } else {
            return false;
        }
    }

    saveEndTime() {
        // substract 30 days from current date and save it as start time
        const today = new Date();
        today.setDate(today.getDate() + 30);
        localStorage.setItem(this.getSubscriptionKey(), today.toISOString());

        // localStorage.setItem(
        //     this.getSubscriptionKey(),
        //     new Date().toISOString()
        // );
        console.info("Start time saved as " + this.getEndTime());
    }

    getEndTime() {
        return localStorage.getItem(this.getSubscriptionKey());
    }

    clearStartTime() {
        localStorage.removeItem(this.getSubscriptionKey());
    }

    getSubscriptionDaysLeft(): number {
        // const endTime = this.getEndTime();
        const endTime = this.inApp.expirationTime;
        if (endTime) {
            const today = new Date();
            const end = new Date(endTime);
            const diff = end.getTime() - today.getTime();
            const days = Math.ceil(diff / (1000 * 3600 * 24));
            const minutes = Math.ceil(diff / (1000 * 60));
            return days;
        } else {
            return 0;
        }
    }
}
