import { Injectable } from '@angular/core';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { FacebookLogin } from '@capacitor-community/facebook-login';

@Injectable({
  providedIn: 'root'
})
export class FacebookTrackingService {

  constructor() { }

  public async requestPermissions() {



    try {


      const {status}  =  await AppTrackingTransparency.getStatus();
      console.log('status====>>>>', status);

      if(status === "notDetermined"){
        const response  = await AppTrackingTransparency.requestPermission();
        if(response.status === 'authorized'){
          await FacebookLogin.setAdvertiserTrackingEnabled({enabled: true});
        } else {
          await FacebookLogin.setAdvertiserTrackingEnabled({enabled: false});
        }
      }else if(status === "authorized"){
        await FacebookLogin.setAdvertiserTrackingEnabled({enabled: true});
      }else{
        await FacebookLogin.setAdvertiserTrackingEnabled({enabled: false});
      }

    } catch (error) {
      console.error('Error requesting permissions', error);
    }
    

  }

}
