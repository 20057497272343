import { Inject, Injectable, InjectionToken } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from "@angular/common/http";
import { from, mergeMap, Observable } from "rxjs";

export interface IFileCacheService {
    provideCachedFile(body: any): Observable<any>;
    getHeaderMatch(): string;
}

export const FILE_CACHE_PROVIDER = new InjectionToken<IFileCacheService>(
    "File cache"
);

@Injectable()
export class NullCachedFilesProvider implements IFileCacheService {
    provideCachedFile(body: any): Observable<any> {
        const observable = from(new Promise((resolve, reject) => resolve([])));
        return observable;
    }

    getHeaderMatch(): string {
        return "";
    }
}

@Injectable()
export class FileCacheInterceptor implements HttpInterceptor {
    constructor(
        @Inject(FILE_CACHE_PROVIDER)
        private cachedFileProviders: IFileCacheService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            mergeMap((value: HttpEvent<any>) => {
                return new Observable<HttpEvent<any>>((observer) => {
                    if (
                        value instanceof HttpResponse &&
                        request.headers.get("reqid") ===
                            this.cachedFileProviders.getHeaderMatch()
                    ) {
                        this.cachedFileProviders
                            .provideCachedFile(value.body)
                            .subscribe((files) => {
                                const lightbox = files.map((file) => ({
                                    imageUrl: file,
                                    thumbnailUrl: file,
                                }));
                                if (
                                    !value.body.result.serviceOrder
                                        .lightboxImages?.length
                                ) {
                                    const resBody = value.body;
                                    resBody.result.serviceOrder.lightboxImages =
                                        lightbox;
                                    const newRes = value.clone({
                                        body: resBody,
                                    });
                                    observer.next(newRes);
                                }
                                observer.next(value);
                            });
                    } else observer.next(value);
                });
            })
        );
    }
}
