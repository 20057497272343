import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { ToastService } from "src/app/shared/message/toast.service-impl";
import { finalize } from "rxjs";
import { BaseService } from "../../shared/base.service";
import { environment } from "../../../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LANGUAGE_DATA_PROVIDER } from "../../utilities/language-data";
import { ResetPasswordComponent } from "../../auth/reset-password/reset-password.component";
import { Browser } from "@capacitor/browser";
import { InputComponent } from "../../shared/ui/input/input.component";
import { UtilityService } from "../../captain-talk/services/utility.service";
import {
  ApiException,
  AuthenticateModel,
  IAuthenticateModel,
  SessionServiceProxy,
  TokenAuthServiceProxy,
} from "../../../shared/service-proxies/service-proxies";
import { EnvironmentService } from "../../environment.service";
import { ProxyErrorHandler } from "src/shared/service-proxies/proxy-error-handler";
import { GoogleAnalyticsCustomService } from "src/app/services/google-analytics.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  protected readonly LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  @Input() isModal = false;
  public signInForm: FormGroup;
  public isLoading = false;
  public rememberMe = false;
  protected buildType: string;
  public isTechnician = false;
  public elementId: string;
  public selectedOption = "phone";
  @ViewChild("passwordInput", { static: false })
  private passwordInput: InputComponent;
  @ViewChild("scrollPageLogin", { static: false }) scrollPageLogin: ElementRef;
  @ViewChild("userNameOrEmailAddressInput")
  userNameOrEmailAddressInput: InputComponent;
  constructor(
    private tokenAuthService: TokenAuthServiceProxy,
    private sessionServiceProxy: SessionServiceProxy,
    private env: EnvironmentService,
    private navCtrl: NavController,
    private router: Router,
    private fb: FormBuilder,
    private loader: LoaderService,
    public baseService: BaseService,
    private toastService: ToastService,
    public modalCtrl: ModalController,
    private jwtHelper: JwtHelperService,
    private utilityService: UtilityService,
    private googleAnalyticsCustomService: GoogleAnalyticsCustomService
  ) {
    this.buildType = environment.buildType;
    this.elementId = this.baseService.generateUniqueId();
  }

  ngOnInit(): void {
    this.isTechnician = this.buildType === "technician";
    this.signInForm = this.fb.group({
      userNameOrEmailAddress: [null, Validators.required],
      password: [null, Validators.required],
    });
    this.googleAnalyticsCustomService.setScreenName("login", "LoginScreen");
  }

  updateValidators() {
    const userNameOrEmailAddressControl = this.signInForm.get(
      "userNameOrEmailAddress"
    );

    if (this.selectedOption === "email") {
      userNameOrEmailAddressControl.setValidators([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
      ]);
    } else if (this.selectedOption === "phone") {
      userNameOrEmailAddressControl?.setValidators([
        Validators.required,
        this.usPhoneNumberValidator,
      ]);
    } else {
      userNameOrEmailAddressControl.setValidators([Validators.required]);
    }

    userNameOrEmailAddressControl.updateValueAndValidity(); // Refresh the form control validation
  }

  usPhoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const validPhoneNumberPattern = /^\+\d{11}$/;
    const isValid = validPhoneNumberPattern.test(control.value);
    return isValid ? null : { invalidPhoneNumber: true };
  }

  ngAfterViewInit(): void {
    const isRememberMeRaw = localStorage.getItem("REMEMBER_ME");

    try {
      const isRememberMe = isRememberMeRaw ? JSON.parse(isRememberMeRaw) : false;

      if (isRememberMe) {
        const credPassRaw = localStorage.getItem("credPass");
        const credUserRaw = localStorage.getItem("credUser");

        const credPass = credPassRaw ? JSON.parse(credPassRaw) : null;
        const credUser = credUserRaw ? JSON.parse(credUserRaw) : null;

        if (credPass && credUser) {
          const decryptPassword = this.baseService.get("password", credPass);
          const decryptUserName = this.baseService.get("username", credUser);

          this.signInForm.get("userNameOrEmailAddress").setValue(decryptUserName);
          this.signInForm.get("password").setValue(decryptPassword);

          this.selectedOption = decryptUserName.startsWith("+") ? "phone" : "email";
          this.rememberMe = isRememberMe;
        } else {
          console.warn("Invalid or missing credentials in localStorage.");
          this.toastService.showToast(
              "An error occurred during authentication. Invalid or missing credentials",
              3000,
              "bottom",
              null,
              "danger"
          );
        }
      }
    } catch (error) {
      console.error("Error parsing localStorage data:", error);
      this.toastService.showToast(
          "An error occurred during authentication. Invalid or missing credentials",
          3000,
          "bottom",
          null,
          "danger"
      );
    }
  }

  async openForgotPass() {
    // this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: ResetPasswordComponent,
      initialBreakpoint: 0.9,
      breakpoints: [0, 0.9],
    });

    await modal.present();
  }

  selectOption(option: string) {
    if (option === this.selectedOption) {
      return;
    }
    this.signInForm.get("userNameOrEmailAddress").setValue(null);
    // Focus on the input field
    this.userNameOrEmailAddressInput.focusInput();
    this.selectedOption = option;
    this.updateValidators();
  }

  get f() {
    return this.signInForm.controls;
  }

  focusOnPassword() {
    this.passwordInput.focusInput();
  }

  goBack() {
    if (this.isModal) {
      this.modalCtrl.dismiss();
    } else {
      this.navCtrl.back();
    }
  }

  signIn(): void {
    try {
      if (this.signInForm.invalid) return;

      this.prepareForLogin();

      const creds = this.signInForm.value;
      const loginData: IAuthenticateModel = this.buildLoginData(creds);

      this.storeRememberMeFlag();

      if (!this.baseService.netWorkStatus) {
        this.handleOfflineLogin(loginData);
      } else {
        this.handleOnlineLogin(loginData);
      }
    } catch (error) {
      this.handleUnexpectedError("An unexpected error occurred during sign-in");
    }
  }

  private prepareForLogin(): void {
    this.baseService.clearSessionData();
    this.baseService.clearSession(this.baseService.credKey);
    this.googleAnalyticsCustomService.LoginEvent();
  }

  private buildLoginData(creds: any): IAuthenticateModel {
    return {
      userNameOrEmailAddress: creds.userNameOrEmailAddress,
      password: creds.password,
      twoFactorVerificationCode: undefined,
      rememberClient: this.rememberMe || false,
      twoFactorRememberClientToken: undefined,
      singleSignIn: undefined,
      returnUrl: undefined,
      captchaResponse: undefined,
      isMobile: true,
    };
  }

  private storeRememberMeFlag(): void {
    localStorage.setItem("REMEMBER_ME", JSON.stringify(this.rememberMe));
    if (!localStorage.getItem("loggedIn")) {
      localStorage.setItem("loggedIn", "true");
    }
    localStorage.setItem("isPreviousLogin", "true");
  }

  private handleOfflineLogin(loginData: IAuthenticateModel): void {
    try {
      const credPass = this.retrieveDecryptedCredential("credPass");
      const credUser = this.retrieveDecryptedCredential("credUser");

      if (
          loginData.userNameOrEmailAddress === credUser &&
          loginData.password === credPass
      ) {
        this.successfulLogin("Login Successful");
      } else {
        this.failedLogin("User Name or Password Incorrect");
      }
    } catch {
      this.failedLogin("An error occurred while handling offline credentials");
    }
  }

  private retrieveDecryptedCredential(key: string): string | null {
    const rawData = localStorage.getItem(key);
    return rawData ? this.baseService.get(key, JSON.parse(rawData)) : null;
  }

  private handleOnlineLogin(loginData: IAuthenticateModel): void {
    this.isLoading = true;
    this.blurInputFields();

    this.tokenAuthService
        .authenticate(new AuthenticateModel(loginData))
        .pipe(finalize(() => this.loader.close()))
        .subscribe({
          next: (data) => this.processAuthenticationResponse(data, loginData),
          error: (error) => this.processAuthenticationError(error),
        });
  }

  private processAuthenticationResponse(data: any, loginData: IAuthenticateModel): void {
    console.log('data', data);
    try {
      const token = data?.accessToken;
      console.log('token', token);
      if (token && this.isAuthenticationAllowed(this.jwtHelper.decodeToken(token))) {
        this.setUserData(data);
        this.baseService.authenticated = true;
        this.encryptCredentials(loginData);
        this.getCurrentLoggedInUser("email");
      } else {
        this.failedLogin("Invalid credentials or token");
      }
    } catch {
      this.failedLogin("An error occurred during authentication");
    }
  }

  private processAuthenticationError(error: any): void {
    this.isLoading = false;
    const errorMessage = error instanceof ApiException
        ? new ProxyErrorHandler(error).getErrorMessage()
        : "An error occurred while processing your request";
    this.toastService.showToast(errorMessage, 3000, "bottom", null, "danger");
  }

  private blurInputFields(): void {
    this.userNameOrEmailAddressInput.blurInput();
    this.passwordInput.blurInput();
  }

  private encryptCredentials(data: any): void {
    const encryptedPassword = this.baseService.set("password", data.password);
    const encryptedUserName = this.baseService.set(
        "username",
        data.userNameOrEmailAddress
    );
    localStorage.setItem("credPass", JSON.stringify(encryptedPassword));
    localStorage.setItem("credUser", JSON.stringify(encryptedUserName));
  }

  private successfulLogin(message: string): void {
    this.toastService.showToast(message, 3000, "bottom", null, "success");
    this.baseService.canNotEdit = true;
    this.loader.close();
    this.getCurrentLoggedInUser("email");
  }

  private failedLogin(message: string): void {
    this.isLoading = false;
    this.loader.close();
    this.toastService.showToast(message, 3000, "bottom", null, "danger");
  }

  private handleUnexpectedError(message: string): void {
    this.isLoading = false;
    this.toastService.showToast(message, 3000, "bottom", null, "danger");
  }

  isAuthenticationAllowed(decodedToken: any): boolean {
    console.log('decodedToken', decodedToken);
    const key = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
    console.log('key', key);
    if (decodedToken[key] === "YachtOwner" && this.buildType == "technician") {
      return false;
    } else return !(decodedToken[key] === "Technician" &&
        this.buildType == "boatowner");
  }

  getCurrentLoggedInUser(providerType: string) {
    try {
      this.sessionServiceProxy
        .getCurrentLoginInformations(this.env.persona)
        .pipe()
        .subscribe({
          next: (data) => {
            try {
              const userInfo = data.user;
              const user = {
                emailAddress: userInfo?.emailAddress,
                firstName: userInfo?.firstName,
                id: userInfo?.id,
                isFirstLogin: userInfo?.isFirstLogin,
                lastName: userInfo?.lastName,
                profilePictureId: userInfo?.profilePictureId,
                role: userInfo?.role,
                provider: providerType,
                isNewUser: userInfo?.isNewUser,
              };

              const captainTalkData = {
                emailAddress: userInfo?.emailAddress,
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
                phoneNumber: userInfo?.phoneNumber,
                role: userInfo?.role,
                id: userInfo?.id,
              };

              localStorage.setItem(
                "cap_talk_authData",
                JSON.stringify(captainTalkData)
              );
              this.utilityService.storeData({ firstName: userInfo?.firstName });

              if (user) {
                this.baseService.authenticated = true;
                this.baseService.createSession(this.baseService.credKey, user);
                this.baseService.setUserProfile(user);
                if (this.isModal) {
                  this.modalCtrl.dismiss({ isLoggedIn: true });
                } else {
                  window.location.href = "/default";
                }
              } else {
                this.isLoading = false;
              }
            } catch (userInfoError) {
              this.isLoading = false;
              console.error(
                "Error processing user information:",
                userInfoError
              );
            }
          },
          error: (err) => {
            this.isLoading = false;
            console.error("Error fetching user information:", err);
          },
        });
    } catch (error) {
      this.isLoading = false;
    }
  }

  setUserData(payload: any): void {
    payload.tokenExpiryDateInSeconds =
      new Date().getTime() + payload.expireInSeconds * 1000;
    this.baseService.setSessionData(payload);
  }

  async openRegister() {
    if (this.isModal) {
      this.modalCtrl.dismiss();
    }
    this.router.navigate(["/captain-talk/authentication/welcome"]);
  }

  openTerms = async () => {
    await Browser.open({ url: "https://boatbot.ai/terms-of-use/" });
  };

  openPrivacy = async () => {
    await Browser.open({ url: "https://boatbot.ai/privacy-policy/" });
  };
}
