<!-- <ion-header>
  <ion-toolbar>
    <app-bb-header
      [hasShadow]="true"
      [title]="data?.title || (LANGUAGE_DATA?.CAPTAIN_AI.TITLE_2 | translate)"
      [variant]="'inline-text'"
      [showBrand]="false"
      [videoUrl]="'null'"
      [popoverMessage]="popoverMessage"
    ></app-bb-header>
  </ion-toolbar>
</ion-header> -->
<app-base-two-layout
  class="h-full"
  [headerText]="data?.title || (LANGUAGE_DATA?.CAPTAIN_AI.TITLE_2 | translate)"
  [hasTextArea]="false"
  [hasGreeting]="false"
  [toolTipText]="popoverMessage"
  (goBack)="close()"
>
  <div class="flex flex-col h-full w-full overflow-y-auto max-h-screen">
    <div class="px-4 mt-2">
      <div>
        <div class="w-full flex items-center justify-start gap-2 p-4 pr-0">
          <div class="flex items-center gap-2 justify-start">
            <img src="assets/captain-talk/captain-talk.svg" class="w-20" />
          </div>
          <div class="flex items-center gap-2">
            <span class="text-lg text-dsGray70 font-medium">{{
              LANGUAGE_DATA?.CAPTAIN_AI.TALK_TO_ME | translate
            }}</span>
            <img src="assets/icons/audio-waves.svg" class="h-6" />
          </div>
        </div>
        <div
          class="relative border rounded-lg mt-2 h-[180px] mb-2 overflow-hidden"
        >
          <div class="relative">
            <div
              class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center rounded-lg z-10"
              *ngIf="isProcessing"
            >
              <div
                class="flex items-center gap-2 rounded-full px-3 bg-white drop-shadow-md py-1"
              >
                <span class="bbicon-loading text-dsGray70 animate-spin"></span>
                <span class="text-dsGray70 font-medium text-sm">{{
                  LANGUAGE_DATA?.CAPTAIN_AI.PROCESSING | translate
                }}</span>
              </div>
            </div>
            <textarea
              class="w-full h-[100px] p-4 pr-8 outline-none focus:ring-0 z-0"
              placeholder="{{ placeholder }}"
              [formControl]="message"
            ></textarea>
          </div>
          <div
            class="absolute bottom-2 left-0 items-center w-full flex justify-between px-4 pl-2"
          >
            <button
              class="py-3 px-4 rounded-full border-2 border-solid flex items-center shrink-0 gap-2"
              [ngClass]="{
                'border-brandNauticalRed text-brandNauticalRed': isListening,
                'border-deepSeablue text-brandDeepSeaBlue': !isListening
              }"
              *ngIf="!isProcessing"
              (click)="isListening ? stopRecording() : startRecording()"
            >
              <span
                class="rounded-full shrink-0 bbicon-{{
                  isListening ? 'stop-circle' : 'microphone'
                }} text-{{
                  isListening ? 'brandNauticalRed' : 'deepSeablue'
                }} text-2xl"
                [ngClass]="{ 'pulse-box': isListening }"
              ></span>
              <span class="font-semibold text-base">
                {{
                  isListening
                    ? (LANGUAGE_DATA?.CAPTAIN_AI.STOP | translate)
                    : (LANGUAGE_DATA?.CAPTAIN_AI.START | translate)
                }}
                {{ LANGUAGE_DATA?.CAPTAIN_AI.RECORDING | translate }}
              </span>
              <img src="assets/icons/audio-waves.svg" class="ml-2" />
            </button>
            <button
              *ngIf="!isListening && !isProcessing && message.value"
              class="w-10 h-10 rounded-full bg-white flex items-center justify-center border-2 border-brandNauticalRed border-solid"
              (click)="clearText()"
            >
              <span
                class="bbicon-delete-outline text-brandNauticalRed text-2xl"
              ></span>
            </button>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3 my-4 px-2">
          <button
            class="bg-deepSeablue rounded-full text-white py-3 px-4 flex gap-1 font-bold text-center items-center justify-center"
            (click)="transformText('revise')"
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.264648"
                y="0.861328"
                width="20.088"
                height="16.3565"
                rx="5.31957"
                fill="#FC744A"
              />
              <path
                d="M3.6709 12.9995L6.61034 4.25391H9.3316L12.271 12.9995H9.93767L9.39827 11.0783H6.54368L6.00427 12.9995H3.6709ZM7.9134 6.18121L6.99217 9.47824H8.94978L8.02855 6.18121H7.9134Z"
                fill="white"
              />
              <path
                d="M13.9115 12.9995V4.25391H16.1358V12.9995H13.9115Z"
                fill="white"
              />
            </svg>

            {{ LANGUAGE_DATA?.CAPTAIN_AI.REVISE | translate | titlecase }}
          </button>
          <button
            class="bg-white border-deepSeablue border-2 border-solid rounded-full text-deepSeablue py-3 px-4 flex gap-1 font-bold text-center items-center justify-center"
            (click)="transformText('translate')"
          >
            <img src="assets/icons/translate-en-es.png" class="w-auto h-5" />
            {{ LANGUAGE_DATA?.CAPTAIN_AI.TRANSLATE | translate | titlecase }}
          </button>
        </div>
        <div class="flex items-start gap-1">
          <span class="bbicon-lightbulb text-xl text-brandNauticalRed"></span>
          <span class="text-sm text-dsGray70 font-medium"
            >{{ LANGUAGE_DATA?.CAPTAIN_AI.CLICK | translate }}
            <strong class="text-deepSeablue"> AI Revise </strong>
            {{ LANGUAGE_DATA?.CAPTAIN_AI.NOTICE | translate }}
            <strong class="text-deepSeablue">
              {{ LANGUAGE_DATA?.CAPTAIN_AI.TRANSLATE | translate }}
            </strong>
            {{ LANGUAGE_DATA?.CAPTAIN_AI.NOTICE_2 | translate }}</span
          >
        </div>
        <div class="w-full mt-2">
          <app-button
            [variant]="'secondary'"
            [label]="LANGUAGE_DATA?.CAPTAIN_AI.SEND | translate"
            (buttonClick)="sendAndDismissModal()"
            [icon]="{
              name: 'update',
              position: 'left'
            }"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</app-base-two-layout>
