import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
} from "@angular/core";

@Directive({
    selector: "[appEnterNext]",
})
export class EnterNextDirective {
    @Input() myTabindex: string;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    @HostListener("keydown", ["$event"]) onKeyDown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            e.preventDefault();

            const selectors = "input, button, select, textarea, a[href]";
            const focusableElements: HTMLElement[] = Array.from(
                this.elementRef.nativeElement
                    .closest("form, .focusable-container")
                    ?.querySelectorAll(selectors) || []
            );

            const currentElement = this.elementRef.nativeElement.querySelector(
                "input, button, select, textarea"
            );
            const currentTabIndex = currentElement
                ? currentElement.tabIndex
                : 0;
            const nextElementIndex = focusableElements.findIndex(
                (el) => el.tabIndex === currentTabIndex + 1
            );

            if (nextElementIndex !== -1) {
                this.renderer
                    .selectRootElement(focusableElements[nextElementIndex])
                    .focus();
            }
        }
    }
}
