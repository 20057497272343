<app-base-two-layout
  class="h-full"
  (goBack)="logoClick()"
  [headerText]="'Your subscription is processing'"
  [hasTextArea]="false"
  [hasGreeting]="false"
>
  <div class="flex flex-col items-center justify-center p-6 h-full" *ngIf="!products">
    <ion-spinner class="scale-[2.5]" *ngIf="available" name="dots"></ion-spinner>
    <h2 class="text-xl text-deepSeablue font-bold text-center" *ngIf="!available">No Packages Available</h2>
    <p class="text-deepSeablue text-lg text-center" *ngIf="!available">
      We are unable to load subscription packages at this time. Please try again later.
    </p>
  </div>
  <div *ngIf="products && products.length">
    <div class="text-center" *ngIf="inApp.isInitialbuy">
      <h2 class="m-0 text-xl text-deepSeablue font-bold text-center p-4">
        Select From Packages Below <br />
        <span class="font-extrabold">30 Day Free Trial</span>
      </h2>
    </div>
    <div class="p-6 py-4">
      <div class="grid grid-cols-2 gap-2">
        <div *ngFor="let slider of subscriptionSliders; let i = index" class="relative top-5">
          <span
            *ngIf="slider.slug === 'PRO'"
            class="absolute top-[-24px] text-brandSunsetOrange m-auto left-0 right-0 w-fit font-bold"
            >Most Popular</span
          >
          <div
            (click)="setActiveSubscription(slider, i)"
            class="border-2 rounded-lg p-6 py-4 text-left relative {{
              slider.active ? 'border-brandSunsetOrange bg-brandSunsetOrangeExtraLight' : 'border-dsGray20'
            }}"
          >
            <h3 class="m-0 text-lg text-deepSeablue font-bold">
              {{ slider.title }}
            </h3>
            <p class="text-deepSeablue text-xl font-extrabold">
              {{ slider.price }}
            </p>
            <span class="text-deepSeablue text-lg font-bold">{{ slider.frequency }}</span>
            <button class="absolute top-2 right-2" *ngIf="slider.active">
              <span class="bbicon-check-circle text-brandSunsetOrange text-3xl"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-6 relative mt-8">
      <div class="bg-brandSunsetOrange rounded-full text-white text-sm font-semibold py-2 px-4 absolute -top-5 left-8">
        <span>Included with {{ getActiveSubscription().title }}</span>
      </div>

      <!-- Display features dynamically based on the active subscription -->
      <ul class="flex flex-col border-2 gap-3 border-brandDeepSeaBlue p-4 rounded-lg text-base font-semibold pt-6">
        <span>{{ getActiveSubscription().header }}</span>
        <ng-container *ngFor="let feature of getActiveSubscription().features">
          <li class="flex gap-2 items-center">
            <span *ngIf="!feature.link" class="{{ 'bbicon-add' }} text-2xl text-brandSunsetOrange"></span>
            <span>{{ feature.name }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div *ngIf="products && products.length">
    <p class="text-xs font-medium px-6 py-2 text-dsGray70">
      By clicking "Get {{ getActiveSubscription().title }}" you will be charged and your subscription will begin. You
      can cancel at any time via App Store Settings and you agree to our
      <a class="font-bold text-deepSeablue" (click)="openPrivacy()">Privacy Policy</a>
      and
      <a class="font-bold text-deepSeablue" (click)="openTerm()">Terms of Use</a>.
    </p>
    <div class="px-5 relative">
      <app-button
        [disabled]="(getActiveSubscription().owned && inApp.hasActiveSubscription()) || isLoading"
        [label]="getButtonLabel()"
        (buttonClick)="onSubscribe()"
        [variant]="'secondary'"
        [icon]="{ name: 'arrow-right', position: 'right' }"
      ></app-button>
      <ion-spinner class="absolute right-[45px] top-[15px] text-white" name="circular" *ngIf="isLoading"></ion-spinner>
    </div>
  </div>
</app-base-two-layout>
