<div class="{{ backgroundColor ? 'bg-' + backgroundColor : 'main-content' }} h-full">
  <div
    style="background-image: url('assets/images/base-one-background.svg')"
    class="bg-bottom bg-contain h-full bg-no-repeat base-one-background"
  >
    <ion-content #content class="h-full"  [scrollEvents]="true"
   >
      <div class="h-full"  #scrollElement >
        <ion-header class="ion-no-border" *ngIf="hasHeader">
          <ion-toolbar class="h-[20vh] ion-padding-start ion-padding-top">
            <button
              type="button"
              (click)="onButtonClick()"
              class="p-2 rounded-full bg-brandSunsetOrange"
              style="margin-top: 1.5rem"
              *ngIf="hasBackButton"
            >
              <ion-ripple-effect></ion-ripple-effect>
              <span class="bbicon-chevron-left text-2xl text-white"></span>
            </button>
            <ng-content select="[header]"> </ng-content>
          </ion-toolbar>
        </ion-header>

        <ng-content> </ng-content>
      </div>
      <button *ngIf="hasAudio" (click)="switchVolume()">
        <div class="fixed right-8 bottom-8 flex items-center" *ngIf="!isMuted">
          <span class="text-xs text-white font-semibold mr-2">Sound On</span><ion-icon name="volume-high-outline"   class=" text-white text-3xl"  ></ion-icon>
        </div>
      
        <div class="fixed right-8 bottom-8 flex items-center" *ngIf="isMuted">
          <span class="text-xs text-white font-semibold mr-2">Sound Off</span><ion-icon name="volume-mute-outline"   class=" text-white  text-3xl"></ion-icon>
        </div>
      
      </button>
    </ion-content>
  </div>
</div>
