import { Injectable } from "@angular/core";
import {
    HttpResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { catchError, map, Observable } from "rxjs";
import { BaseService } from "../shared/base.service";
import { SpinnerVisibilityService } from "ng-http-loader";
import { LoginServiceService } from "../auth/login/login-service.service";
import { LogoutService } from "../yacht-owner/logout.service";

@Injectable({
    providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
    removeAuthorizationHeader = false;
    contentType = { };
    constructor(
        public _baseService: BaseService,
        private spinner: SpinnerVisibilityService,
        private logoutService:LogoutService,
        private loginService:LoginServiceService
    ) {
        // _baseService.removeAuthorizationHeader.subscribe((u) => {
        //     this.removeAuthorizationHeader = u;
        // });
        // _baseService.currentContent.subscribe((u) => {
        //     this.contentType = u;
        // });
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const hideLoader = req.params.get("Hide-Loader");
        if (hideLoader) {
            this.spinner.hide();
        }
        if (req.url.includes('s3.amazonaws.com')){
            return next.handle(new HttpRequest('GET', req.url, { responseType: 'blob' }));
        }
    
        if (!req.url.includes('s3.amazonaws.com')) {
            const tokenExists = this._baseService.getToken();
          if(!req.url.includes('TokenAuth/Authenticate') && !req.url.includes('TokenAuth/RefreshToken') && tokenExists){
            this.loginService.checkIsTokenExpired();
          }
            
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this._baseService.getToken()}`,
                },
            });
        } else {

            req = req.clone({
                setHeaders: this.contentType,
            });
        }
        return next.handle(req);
    }
}
