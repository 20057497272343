<ion-header class="ion-no-border bg-brandDeepSeaBlue">
  <ion-toolbar class="h-[10vh] ion-paading-start">
    <button
      type="button"
      slot="start"
      (click)="onButtonClick()"
      class="p-2 rounded-full bg-brandSunsetOrange mt-2 ml-5"
      *ngIf="hasBackButton"
    >
      <ion-ripple-effect></ion-ripple-effect>
      <span class="bbicon-chevron-left text-2xl text-white"></span>
    </button>

    <div class="w-full whitespace-normal text-white text-xl font-bold no-ellipses pt-5 text-center">
      <span class="{{ !showAiLogo ? 'mr-14' : '' }}">{{ headerText }}</span>
    </div>
    <img
      src="../../../../assets/captain-talk/captain-talk-white-sparks.svg"
      alt="captain ai logo"
      slot="end"
      height="60"
      (click)="clickLogo()"
      width="60"
      [id]="'ai-click-trigger' + triggerId"
      class="mr-2"
      *ngIf="buildType === 'boatowner' && this.showAiLogo"
    />
  </ion-toolbar>
</ion-header>
<ion-popover
  class="ai-menu"
  [trigger]="'ai-click-trigger' + triggerId"
  triggerAction="click"
  [dismissOnSelect]="true"
  *ngIf="buildType === 'boatowner' && this.showAiLogo"
>
  <ng-template>
    <ion-content class="ai-menu-content" color="light">
      <ion-item lines="full" [button]="true" [detail]="false">
        <span class="bbicon-message mr-3 text-xl text-brandDeepSeaBlue"></span>
        <span>{{ LANGUAGE_DATA?.CAPTAIN_TALK.DASHBOARD.ASK_CAPTAIN_AI | translate }}</span>
      </ion-item>
      <ion-item
        lines="full"
        [button]="true"
        [detail]="false"
        (click)="navigateToServiceRequest()"
        *ngIf="isLoggedIn && enableRequestService"
      >
        <span class="bbicon-tools mr-3 text-xl text-brandDeepSeaBlue"></span>
        <span>{{ LANGUAGE_DATA?.CAPTAIN_TALK.BASE_TWO_LAYOUT.SERVICE_REQUEST_TEXT | translate }}</span>
      </ion-item>
      <ion-item lines="full" [button]="true" [detail]="false" (click)="openHelpModal()" *ngIf="toolTipText">
        <span class="bbicon-question mr-3 text-xl text-brandDeepSeaBlue"></span>
        <span>{{ LANGUAGE_DATA?.CAPTAIN_TALK.BASE_TWO_LAYOUT.HELP | translate }}</span>
      </ion-item>
    </ion-content>
  </ng-template>
</ion-popover>

<div class="main-content h-full bg-brandDeepSeaBlue">
  <div class="bb-gradient-bg m-0 flex flex-col rounded-t-[12px] h-full pt-2 overflow-hidden">
    <div class="h-full w-full flex flex-col bg-brandSkyBlueExtraLight rounded-t-[18px] overflow-hidden p-2">
      <div class="flex items-center p-2 justify-between gap-2" *ngIf="hasGreeting">
        <div class="flex items-center gap-3">
          <img src="../../../../assets/captain-talk/captain-talk.svg" alt="" srcset="" class="w-20 flex-none" />
          <h2 class="font-bold bb-gradient-text text-2xl my-0" [innerHTML]="season"></h2>
        </div>

        <!-- <span class="bbicon-info-outline text-3xl text-brandDeepSeaBlue" (click)="openAiInfo()"></span> -->
      </div>

      <div
        class="w-full flex flex-col h-full  {{
          hasBorder ? ' border-brandSkyBlueLighter rounded-3xl overflow-hidden ' : ' border-0'
        }} border-brandSkyBlueLighter border rounded-lg relative {{ hasGreeting ? ' p-2 ' : '' }} {{
          hasScroll ? 'overflow-y-auto' : ''
        }}
        {{ variant === 'clear' ? 'bg-transparent' : 'bg-white' }}"
      >
        <div
          class="
          {{ variant === 'clear' ? 'mb-2' : '' }}
        "
          *ngIf="hasHeader"
        >
          <ng-content select="[header]"></ng-content>
        </div>
        <ion-content
          #content
          class="h-full {{
            variant === 'clear' ? 'bg-[#EAF2FB] border border-[#C4E4EE] rounded-lg' : 'bg-transparent'
          }}"
          [ngStyle]="{ 'padding-bottom': setPadding ? '300px' : '0' }"
        >
          <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" *ngIf="hasRefresher">
            <ion-refresher-content
              pullingIcon="chevron-down-circle-outline"
              pullingText="Pull to refresh"
              refreshingSpinner="circles"
              class="py-2"
            >
            </ion-refresher-content>
          </ion-refresher>
          <div class="rounded-tr-3xl rounded-tl-3xl rounded-bl-none rounded-br-none relative m-0 w-full h-[99%]">
            <div class="h-full" #scrollElement>
              <ng-content></ng-content>
            </div>
          </div>
        </ion-content>
        <ion-footer>
          <div class="">
            <ng-content select="[footer]"></ng-content>
            <div class="bb-textarea relative" *ngIf="hasTextArea">
              <span class="bbicon-sparks text-3xl text-brandDeepSeaBlue absolute -top-2 -right-1"></span>
              <div class="textarea-container bb-gradient-bg rounded-full mb-4 mr-4 overflow-hidden">
                <div class="rounded-full w-full bg-white flex justify-between items-center relative overflow-hidden">
                  <textarea
                    #textarea
                    (input)="onInput()"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    [attr.rows]="rows"
                    [placeholder]="placeholder"
                    [attr.id]="id"
                    [formControl]="control"
                    class="w-full text-base font-medium h-[80%] leading-2 p-4 pr-10 outline-none pl-6 max-h-[130px]"
                  ></textarea>

                  <div class="flex items-center gap-2 pr-2">
                    <button
                      *ngIf="!isTyping"
                      (click)="openCaptainAITalk()"
                      class="bbicon-microphone text-brandDeepSeaBlue text-3xl p-2"
                    ></button>
                    <!-- <button
                  *ngIf="!isTyping"
                  class="bb-textarea-icon bbicon-add-camera text-brandDeepSeaBlue text-3xl"
                ></button> -->
                    <button
                      *ngIf="isTyping"
                      (click)="handleSendClick()"
                      class="bbicon-send-message text-brandDeepSeaBlue text-3xl p-2"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-footer>
      </div>
    </div>
  </div>
</div>
