import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { InAppService } from "../in-app/in-app-service";
import party from "party-js";
import { NewSubscriptionService } from "../in-app/new-subscription.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
@Component({
    selector: "app-in-app-modal",
    templateUrl: "./in-app-modal.component.html",
    styleUrls: ["./in-app-modal.component.css"],
})
export class InAppModalComponent implements OnInit {
    @Input() modalId: string;
    @Input() modalHtml: any;
    @Input() action: any;
    cookieValue;
    LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

    constructor(
        private modalCtrl: ModalController,
        public newSubscriptionService: NewSubscriptionService,
        private inapp: InAppService
    ) {}

    ngOnInit(): void {
        console.log("modalId", this.modalId);
        console.log("modelHtml", this.modalHtml);
        console.log("action", this.action);
        this.cookieValue = localStorage.getItem("lang") || "en";

        if (this.modalId == "new-subscription") {
            setTimeout(() => {
                const el: any = document.getElementById("container");
                party.confetti(el, {
                    count: party.variation.range(200, 400),
                });
            }, 500);
        }
    }

    continue() {
        this.modalCtrl.dismiss();
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    openSubscriptionPackagePage(action: string = "upgrade") {
        this.modalCtrl.dismiss();
        this.inapp.openAccountSubscriptionsModal(action);
    }
}
