import { InjectionToken } from "@angular/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import {Preferences} from "@capacitor/preferences";

export const FILE_STORAGE_SERVICE = new InjectionToken<IClientSideFileStorage>(
  "Client side file storage"
);

export type Entity = "SERVICE_ORDER" | "YACTH_PROFILE";

export interface FileKey {
  entity: Entity;
  entityId: string;
}

export interface ClientSideFile {
  name: string;
  fileDataBase64: string;
}

export interface IClientSideFileStorage {
  saveFile(key: FileKey, file: ClientSideFile[]);

  getSaveFiles(key: FileKey): Promise<string[]>;
}

export class GallaryStorage implements IClientSideFileStorage {
  async getSaveFiles(key: FileKey): Promise<string[]> {
    const PREFERENCE_KEY = `${key.entity}_${key.entityId}`;
    const urlResult = await Preferences.get({ key: PREFERENCE_KEY });
    const urls = (JSON.parse(urlResult.value) as Array<string>) || [];
    const files = await Promise.all(
      urls.map(async (url) =>await Filesystem.readFile({ path: url }))
    );
    const output = files.map((file) => `data:image/jpeg;base64,${file.data}`);
    return output;
  }

  async saveFile(key: FileKey, files: ClientSideFile[]) {
    const PREFERENCE_KEY = `${key.entity}_${key.entityId}`;

    const urls = await Promise.all(
      files.map(async (file) => {
        const savedFile = await Filesystem.writeFile({
          path: file.name,
          data: file.fileDataBase64,
          directory: Directory.Data,
        });
        return savedFile.uri;
      })
    );

    Preferences.set({ key: PREFERENCE_KEY, value: JSON.stringify(urls) });
  }
}
