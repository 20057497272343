import { Component, OnInit, Input } from "@angular/core";
import { InAppService, SubscriptionPlan } from "src/app/shared/in-app/in-app-service";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { Browser } from "@capacitor/browser";
import { NewSubscriptionService } from "src/app/shared/in-app/new-subscription.service";
import { Router } from "@angular/router";
import { retry } from "rxjs";
import { EnvironmentService } from "src/app/environment.service";
import { HttpClient } from "@angular/common/http";
@Component({
    selector: "app-account-subscription-select",
    templateUrl: "./account-subscription-select.component.html",
    styleUrls: ["./account-subscription-select.component.css"],
})
export class AccountSubscriptionSelectComponent implements OnInit {
    @Input() action = "new";

    products:SubscriptionPlan[]
    isLoading = false;
    subscriptionSliders = [];
    available = true;
    isModalOpened;
    modalSubscription;
    purchaseSubscription;

    constructor(
        private http: HttpClient,
        private env: EnvironmentService,
        public inApp: InAppService,
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private platform: Platform,
        private newSubscription: NewSubscriptionService,
        private router: Router
    ) {
        this.action = this.navParams?.data["action"];
        console.log("Action=======>", this.action);

      this.modalSubscription = this.newSubscription.isModalOpenedEvent.subscribe((ev) => {
            if (ev) {
                this.isModalOpened = true;
            } else {
                this.isModalOpened = false;
            }
        });
       this.purchaseSubscription =  this.inApp.isPurchasing.subscribe((ev: any) => {
            if (ev) {
                console.log("is Purchasing===>", ev);
                this.isLoading = true;
            } else {
                this.isLoading = false;
            }
        });

        this.subscriptionSliders = [
            {
                title: "Owner Basic",
                slug: "BASIC",
                price: "$0",
                owned: false,
                frequency: "Monthly",
                active: this.action == "new" ? true : false,
                features: [
                    { name: "1 Primary User", enabled: true },
                    { name: "1 Vessel System", enabled: true },
                    { name: "BoatBot Owner App", enabled: true },
                    { name: "BoatBot Profile", enabled: true },
                    { name: "Cloud Based Vendor Management", enabled: true },
                    { name: "Automated Service Requests", enabled: true },
                    { name: "Real-Time Vendor Geo-Alerts", enabled: true },
                    { name: " Customized Service Orders", enabled: true },
                    { name: "Custom Job Checklists", enabled: true },
                    { name: "Geo-Fencing", enabled: true },
                    { name: "Auto Documentation", enabled: true },
                    {
                        name: "Go to BoatBot.ai for more details!",
                        enabled: false,
                        link: true,
                    },
                ],
            },
            {
                title: "Owner Pro",
                slug: "PRO",
                price: "$0",
                owned: false,
                header: "All Features in Basic Plus",
                frequency: "Monthly",
                active: this.action == "upgrade" ? true : false,
                features: [
                    { name: "All Features in Basic Plus", enabled: false },
                    { name: "1 Additional Primary User", enabled: false },
                    { name: "Start-Up / Shut-Down Checklist", enabled: false },
                    { name: "Captain AI ”Virtual Captain", enabled: false },
                    { name: "Boat Vitals", enabled: false },
                    { name: "Preventative Maintenance", enabled: false },
                    { name: "Personalized Auto-Alerts", enabled: false },
                    { name: "BoatFax Report", enabled: false },
                    { name: "Multi-Boat Capability", enabled: false },
                    { name: "Vessel Transfer", enabled: false },
                    {
                        name: "Go to BoatBot.ai for more details!",
                        enabled: false,
                        link: true,
                    },
                ],
            },
        ];


        // TODO: Subscription reactivation

        // this.inApp.onPurchaseDone.subscribe((ev: any) => {
        //     if (ev.success) {
        //         this.modalCtrl.dismiss({ success: true });
        //         if (!this.isModalOpened) {
        //             this.newSubscription.openNotificationModal(
        //                 "new-subscription"
        //             );
        //         }
        //     }
        // });
    }

    async ngOnInit() {
        try {
            this.products = await this.inApp.getSubscriptionPlans();
          

            if (!this.products || this.products.length < 0) {
                setTimeout(() => {
                    this.available = false;
                }, 2000);
            }
            this.products.forEach((item) => {
               item.prices.forEach((price) => {
                if(price.sourceProductKeyIdentifier.toLocaleLowerCase() == "owner_basic_id"){
                    this.subscriptionSliders[0].price = price.price
                } else if(price.sourceProductKeyIdentifier.toLocaleLowerCase() == "owner_pro_id"){
                    this.subscriptionSliders[1].price = price.price
                }
               })
                
            });
            console.log("Products===>", this.products);
            if (
                (this.inApp.purchasedProduct.toLocaleLowerCase() == "owner_basic_id") &&
                this.inApp.subscriptionState == "ACTIVE"
            ) {
                this.subscriptionSliders[0].owned = true;
                this.subscriptionSliders[1].owned = false;
                this.subscriptionSliders[0].active = false;
                this.subscriptionSliders[1].active = true;
            } else if (
                (this.inApp.purchasedProduct.toLocaleLowerCase() == "owner_pro_id" ) &&
                this.inApp.subscriptionState == "ACTIVE"
            ) {
                this.subscriptionSliders[1].owned = true;
                this.subscriptionSliders[0].owned = false;
                this.subscriptionSliders[1].active = false;
                this.subscriptionSliders[0].active = true;
            }
        } catch (error) {
            this.logErrorToBackend(error);
            this.available = false;
        }
    }

    logErrorToBackend(error) {
        const payload = {
            errorMessage: JSON.stringify(error),
            userId: this.inApp.userId,
            errorType: "Subscription_Product_Error",
            timeStamp: new Date().toISOString(),
        };

        this.http
            .post(
                this.env.apiUrl +
                    `services/app/Utility/SubscriptionErrorLogsForBoatOwner`,
                payload,
                { headers: { "content-type": "application/json" } }
            )
            .subscribe(
                (res: any) => {
                    console.log("result Logged", res);
                },
                (err) => {
                    console.log("Error", err);
                }
            );
    }

    logoClick() {
        this.modalCtrl.dismiss();
        this.router.navigateByUrl("/yacht-owner/dashboard");
    }

    setActiveSubscription(slider: any, index: number): void {
        // this.swiper.swiperRef.slideTo(index, 100);

        // Set the slider as active
        this.subscriptionSliders.forEach((s) => (s.active = false));
        slider.active = true;
    }

    openTerm = async () => {
        await Browser.open({ url: "https://boatbot.ai/terms-of-use/" });
    };

    openPrivacy = async () => {
        await Browser.open({ url: "https://boatbot.ai/privacy-policy/" });
    };

    getButtonLabel() {
        if (
            this.getActiveSubscription().slug == "BASIC" &&
            this.inApp.purchasedProduct == "Owner_Basic_id" &&
            this.inApp.hasActiveSubscription()
        ) {
            return "Subscribed";
        } else if (
            this.getActiveSubscription().slug == "PRO" &&
            this.inApp.purchasedProduct == "Owner_Pro_id" &&
            this.inApp.hasActiveSubscription()
        ) {
            return "Subscribed";
        } else {
            return (
                "Get " +
                this.getActiveSubscription().title +
                " - " +
                this.getActiveSubscription().price
            );
        }
    }

    getActiveSubscription(): any {
        return this.subscriptionSliders.find((s) => s.active);
    }

    onSubscribe() {
        this.inApp.purchaseEvent.emit({
            plan: this.getActiveSubscription().slug,
        });
    }

    ngOnDestroy() {
        this.modalSubscription.unsubscribe();
        this.purchaseSubscription.unsubscribe();
    }
}
