import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class LoaderService {
    loaderSub = new Subject<boolean>();

    slowInternetSubject = new BehaviorSubject<boolean>(false);
    slowInternet$ = this.slowInternetSubject.asObservable();

    constructor() {}

    setSlowInternet(isSlow: boolean) {
        this.slowInternetSubject.next(isSlow);
    }

    open() {
        this.loaderSub.next(true);
    }

    close() {
        this.loaderSub.next(false);
    }
}
