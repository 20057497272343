<app-base-two-layout
  #frame
  class="h-full"
  (goBack)="close()"
  [hasBorder]="false"
  [headerText]="headerTitle | translate"
  [showAiLogo]="true"
  [hasTextArea]="false"
  [hasGreeting]="false"
>
  <div class="w-full" *ngIf="this.imageList?.length; else noImages">
    <div class="grid gap-4 w-full p-4">
      <div
        *ngFor="let image of imageList; let i = index"
        class="p-2 image-container rounded-md border shadow-xl w-full"
      >
        <img [src]="image?.imageUrl || image?.image" alt="Image" class="min-w-full rounded" />
        <div class="button-row flex justify-between top-0 right-0 mt-2 gap-2 items-center">
          <div class="flex justify-start gap-2">
            <div *ngIf="!image?.uploaded" class="flex items-center gap-2 text-dsGray60">
              <span class="bbicon-loading text-xl animate-spin"></span>
              <span class="font-semibold text-base"> {{ LANGUAGE_DATA?.IMAGE_BROWSER.UPLOADING | translate }}</span>
            </div>

            <button
              *ngIf="config.enableSetPrimary && (image?.imageId || image?.clientId) && image.uploaded"
              (click)="onSetPrimary(image, i)"
              class="flex items-center justify-center rounded-full px-[16px] py-2 gap-1 text-deepSeablue border-brandDeepSeaBlue bg-brandSkyBlueLight font-semibold"
            >
              <span
                class="bbicon-anchor text-[16px]"
                [ngClass]="{
                  'text-brandDeepSeaBlue': image?.isPrimary,
                  'text-dsGray50': !image?.isPrimary
                }"
              ></span>
              {{ image.isPrimary ? "" : (LANGUAGE_DATA?.IMAGE_BROWSER.SET_AS | translate) }}
              {{ LANGUAGE_DATA?.IMAGE_BROWSER.PROFILE | translate }}
            </button>
            <button
              (click)="onRemoveImage(image, i)"
              *ngIf="config.enableRemove"
              class="flex items-center justify-center w-10 h-10 bg-white text-brandNauticalRed border-2 border-brandNauticalRed border-solid rounded-full"
            >
              <span class="bbicon-delete-outline text-3xl"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noImages>
    <div class="flex justify-center items-center w-full h-full">
      <div class="absolute inset-0 flex flex-col justify-center items-center">
        <img src="assets/icons/no-image-placeholder.svg" class="w-32 mx-auto" alt="" />
        <h3 class="text-xl max-w-[90%] px-[30px] mb-4 text-center font-semibold">
          {{ LANGUAGE_DATA?.IMAGE_BROWSER.WELCOME_MESSAGE | translate }}
        </h3>
        <p class="mb-4 text-base font-semibold text-dsGray60 text-center">
          {{ LANGUAGE_DATA?.IMAGE_BROWSER.NO_IMAGES | translate }}<br />
          {{ LANGUAGE_DATA?.IMAGE_BROWSER.CLICK_CAMERA | translate }}
        </p>
      </div>
    </div>
  </ng-template>

  <div class="fixed bottom-8 right-8 py-8 z-10" *ngIf="config.enableAdd">
    <div class="container mx-auto flex justify-end flex-col">
      <button
        (click)="openCamera()"
        class="mt-2 flex items-center justify-center w-16 h-16 bg-brandDeepSeaBlue text-white rounded-full drop-shadow"
      >
        <span class="bbicon-add-camera text-4xl text-white"></span>
      </button>
      <button
        (click)="openGallery()"
        class="mt-2 flex items-center justify-center w-16 h-16 bg-brandDeepSeaBlue text-white rounded-full drop-shadow"
      >
        <span class="bbicon-add-images text-4xl text-white"></span>
      </button>
    </div>
  </div>
</app-base-two-layout>
