<div
    class="flex items-center justify-start gap-2"
    (click)="presentPopover($event)"
>
    <button class="flex">
        <span
            class="bbicon-{{ icon }} text-{{ iconSizeClass }} text-{{
                iconColor
            }}"
        ></span>
    </button>
    <span
        *ngIf="label"
        class="text-sm text-deepSeablue font-semibold"
        >{{ label }}</span
    >
</div>
