import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private readonly API_URL =
    'https://translation.googleapis.com/language/translate/v2?key=AIzaSyBhZmdmPxE9UY1pPWbD_udUoIBki0a5wtk';

  constructor(private http: HttpClient) {}

  translate(text: string, targetLanguage: string): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const params = {
      q: text,
      target: targetLanguage,
    };
    console.log('translate', params);
    return this.http.post<any>(this.API_URL, params, { headers }).pipe(
      map((response) => {
        if (
          response.data &&
          response.data.translations &&
          response.data.translations.length > 0
        ) {
          console.log('translation response', response);
          return response.data.translations[0].translatedText;
        } else {
          throw new Error('Translation failed.');
        }
      })
    );
  }
}
