<app-base-two-layout
  #layout
  class="h-full"
  (goBack)="close()"
  [headerText]="getDisplayName()"
  [hasTextArea]="false"
  [hasGreeting]="false"
  [toolTipText]="LANGUAGE_DATA?.MESSAGES.MESSAGE_TOOLTIP | translate"
>
  <div class="status-red py-2 px-4 text-white" *ngIf="data?.state === 2">
    <p class="text-sm font-bold">You have blocked this user, click below to unblock</p>
  </div>
  <ion-spinner
    name="lines-small"
    *ngIf="isLoadingChatMessages"
    class="absolute m-auto left-0 right-0 top-0 bottom-0"
  ></ion-spinner>

  <div class="flex flex-col w-full px-2" [ngClass]="setPadding && isNotWeb() ? 'pb-96' : 'pb-6'">
    <ng-container *ngFor="let group of groupedChats">
      <div class="flex flex-col w-full">
        <div class="flex justify-center items-center my-2">
          <div class="bg-[#f2f2f2] text-xs text-black px-2 py-1 rounded-full">
            {{ group.formattedDate }}
          </div>
        </div>
      </div>
      <div
        class="flex gap-5 items-start my-2 rounded-xl"
        *ngFor="let a of group.messages; let i = index"
        [ngClass]="{
          'justify-start': a.side === 2,
          'justify-end': a.side === 1,
          'animate__animated animate__slideInUp delay-500': addAnimation
        }"
      >
        <div
          class="p-2 max-w-[70%] text-base border-bottom leading-snug font-medium"
          [ngClass]="{
            'rounded-tr-xl rounded-tl-xl rounded-br-xl bg-[#aba3a329] text-black ':
              a.side === 2 && a.messageObject.type === 'plain',
            'status-blue': a.side === 2 && a.messageObject.type === 'deeplink',
            'bg-[#00005C] text-white rounded-t-xl rounded-bl-xl': a.side === 1
          }"
        >
          <ng-container *ngIf="a.messageObject.type === 'deeplink'">
            <ng-container>
              <div class="w-full" (click)="goToExpectedRoute(a.messageObject)">
                <div class="mb-2 min-h-[80px] p-3 relative flex items-center gap-2 bg-deepSeablue rounded-2xl">
                  <div class="">
                    <span
                      *ngIf="a.messageObject?.linktype === 'package'"
                      class="bbicon-package text-deepSeablue text-2xl bg-white rounded-full p-2"
                    ></span>
                    <span
                      *ngIf="a.messageObject?.linktype === 'serviceorder'"
                      class="bbicon-general-service text-deepSeablue text-2xl bg-white rounded-full p-2"
                    ></span>
                    <span
                      *ngIf="a.messageObject?.linktype === 'invoice'"
                      class="bbicon-invoice text-deepSeablue text-2xl bg-white rounded-full p-2"
                    ></span>

                    <span
                      *ngIf="a.messageObject?.linktype === 'boattransfer'"
                      class="bbicon-boat text-deepSeablue text-2xl bg-white rounded-full p-2"
                    ></span>
                  </div>

                  <div class="w-full text-white flex flex-col">
                    <ng-container [ngSwitch]="a.messageObject?.linktype">
                      <p *ngSwitchCase="'boattransfer'" class="text-xs font-semibold flex flex-col">
                        <span class="text-sm capitalize">{{ a.messageObject.MetaData?.boatnName }}</span>
                        <span>
                          {{
                            LANGUAGE_DATA?.YACHT_PROFILE.GENERAL_INFORMATION.BOAT_TRANSFER_TITLE | translate | titlecase
                          }}
                          #{{ a.messageObject.EntityID }}
                        </span>
                      </p>

                      <p *ngSwitchCase="'package'" class="text-xs font-semibold">
                        {{
                          LANGUAGE_DATA?.YACHT_PROFILE.GENERAL_INFORMATION.MY_PACKAGES.TITLE_2 | translate | titlecase
                        }}
                        #{{ a.messageObject.EntityID }}
                      </p>
                      <p *ngSwitchCase="'invoice'" class="text-xs font-semibold">
                        {{ LANGUAGE_DATA?.ENTITIES.INVOICE | translate | titlecase }}
                        #{{ a.messageObject.EntityID }}
                      </p>
                      <p *ngSwitchCase="'serviceorder'" class="font-semibold flex flex-col">
                        <span class="text-sm">{{ a.messageObject.MetaData?.service }}</span>
                        <span class="text-xs"
                          >{{ LANGUAGE_DATA?.ENTITIES.SERVICE_ORDER | translate | titlecase }} #{{
                            a.messageObject.EntityID
                          }}</span
                        >
                      </p>
                    </ng-container>

                    <div class="font-semibold text-lg capitalize">
                      {{ a.messageObject?.MetaData?.title }}
                    </div>
                  </div>

                  <div class="flex absolute top-2 right-6 z-20 justify-end items-center my-1 space-y-2">
                    <button class="text-left"></button>
                  </div>
                </div>
              </div>
            </ng-container>

            <a
              (click)="goToExpectedRoute(a.messageObject)"
              class="text-sm"
              [ngClass]="{
                'text-black': a.side === 2,
                'text-white ': a.side === 1
              }"
              >{{ a.messageObject.data | removeNewline }}</a
            >
          </ng-container>

          <ng-container *ngIf="a.messageObject.type === 'plain'">
            {{ a.messageObject.data | removeNewline }}
          </ng-container>

          <span
            *ngIf="a.messageObject.type === 'plain'"
            class="text-xs mt-2 ml-3 float-right flex items-center"
            [ngClass]="{
              'text-gray-500 text-left': a.side === 2,
              'text-gray-400 text-right': a.side === 1
            }"
          >
            {{ a.creationTime | userDate : "hh:mma" }}
            <ion-icon name="checkmark-done-outline" class="text-sm ml-1" *ngIf="a.side === 1"></ion-icon>
          </span>
          <p
            *ngIf="a.messageObject.type === 'deeplink'"
            class="text-xs mt-2 font-bold text-right flex items-center justify-end"
          >
            {{ a.creationTime | userDate : "hh:mm a" }}
          </p>
        </div>
      </div>
    </ng-container>

    <div class="h-full" *ngIf="!isLoadingChatMessages && groupedChats?.length === 0">
      <div class="h-full flex flex-col items-center justify-center">
        <img src="/assets/icons/messaging-placeholder.svg" class="w-24 mx-auto mb-5" alt="" />
        <h3 class="text-xl my-0">
          {{ LANGUAGE_DATA?.MESSAGES.NO_MESSAGES_YET | translate }}
        </h3>
      </div>
    </div>
  </div>
  <div footer>
    <div class="h-auto w-full px-4 py-2 bg-white" *ngIf="this.messageState === 1">
      <form [formGroup]="ownerInfoForm" class="w-full">
        <app-chat-input
          (focus)="onPassWordFocused()"
          (blur)="onPasswordBlur()"
          [icon]="{
            name: 'send-message',
            color: 'brandSunsetOrange',
            size: '2xl'
          }"
          formControlName="sendMessage"
          (iconClick)="sendMessage()"
          (overlaySubmit)="sendMessage()"
          [enterKeyHint]="'send'"
          [label]="LANGUAGE_DATA?.MESSAGES.TYPE_MESSAGE | translate | titlecase"
          [placeholder]="LANGUAGE_DATA?.MESSAGES.TYPE_MESSAGE | translate | titlecase"
        ></app-chat-input>
      </form>
    </div>

    <div class="px-4 bg-white pt-2 pb-12 left-0 w-full" *ngIf="data?.state === 2">
      <app-button [label]="'Unblock User'" (buttonClick)="unBlockUser()" [variant]="'secondary'"></app-button>
    </div>
  </div>
</app-base-two-layout>
