import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpParams } from "@angular/common/http";
import { SafeUrl } from "@angular/platform-browser";
import { EnvironmentService } from "../environment.service";
import { AwsCredentialsService } from "../shared/imageManagement/file-picker/aws-credentials.service";
import * as AWS from "aws-sdk/";
import * as S3 from "aws-sdk/clients/s3";
import { Observable } from "rxjs";
import { IAwsImageConfigDto, UtilityServiceProxy } from "src/shared/service-proxies/service-proxies";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  private fileToUpload: File = null;
  private awsBucketName = "";

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private env: EnvironmentService,
    private awsCredentialService: AwsCredentialsService,
    private utiliyServiceProxy: UtilityServiceProxy
  ) {
    this.http = new HttpClient(this.handler);
  }

  getAwsCredentials() {
    return new Promise((resolve, reject) => {
      const data = this.awsCredentialService.getData();
      if (data && data.accessKeyId && data.secretAccessKey) {
        this.configAWS(data);
        resolve(true);
      }
    });
  }

  configAWS(data: IAwsImageConfigDto) {
    const { accessKeyId, secretAccessKey, bucketName } = data;
    this.awsBucketName = bucketName;
    AWS.config.update({ accessKeyId, secretAccessKey });
  }

  async uploadFile(uploadInput: FileUploadModel) {
    const storageData: any = localStorage.getItem("UserProfile");
    const user_data = JSON.parse(storageData);

    await this.getAwsCredentials();

    uploadInput.metadata["userId"] = `${user_data.id}`;
    uploadInput.metadata["role"] = user_data.role;
    uploadInput.metadata["apiUrl"] = this.env.apiUrl;

    const bucket = new S3({ params: { Bucket: this.awsBucketName } });
    const params: S3.Types.PutObjectRequest = {
      Key: uploadInput.fileName,
      Body: uploadInput.file,
      Bucket: this.awsBucketName,
      Metadata: uploadInput.metadata,
    };
    return new Observable((subscriber) => {
      bucket.upload(params, (err, data) => {
        if (err) {
          subscriber.error(err);
        }
        subscriber.next(data);
      });
    });
  }

  getSignedUrl(params: FileUploadParams) {
    const body = {
      EntityId: String(params.entityId),
      Entity: params.entity,
      Role: params.role,
      ApiUrl: params.apiUrl,
      ClientId: params.clientId,
    };

    const key = params.fileName;
    return this.utiliyServiceProxy.getAwsPreSignedUrlV2(key, body);
  }

  uploadFileToS3(
    file: File,
    params: FileUploadParams
  ): Promise<{
    objectUrl: string;
    clientId: string;
  }> {
    if (!params.role) params.role = "yachtowner";
    return new Promise((resolve, reject) => {
      if (file) {
        this.getSignedUrl(params).subscribe({
          next: (response) => {
            const presignedUrl = response.preSignedUrl;
            const objectUrl = response.objectUrl;
            this.http
              .put(presignedUrl, file, {
                headers: {
                  "Content-Type": "application/octet-stream",
                  "x-amz-meta-EntityId": params.entityId.toString(),
                  "x-amz-meta-Entity": params.entity.toString(),
                  "x-amz-meta-Role": params.role.toString(),
                  "x-amz-meta-Apiurl": this.env.apiUrl.toString(),
                  "x-amz-meta-ClientId": params.clientId.toString(),
                },
              })
              .subscribe({
                next: (res) => {
                  console.log(res);
                  resolve({ objectUrl, clientId: params.clientId });
                },
                error: (err) => {
                  console.log("Upload error");
                  reject(err);
                },
              });
          },
          error: (err) => {
            console.log("Error", err);
            reject(err);
          },
        });
      } else {
        reject("No file found");
      }
    });
  }

  // File Attachments
  getSecureLink(queryParams: SecureLinkParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get(`${this.env}/api/services/app/FinancialAttachments/GetSecureLink?persona=2`, {
      params,
    });
  }

  getSecureOfflineLink(queryParams: any) {
    return this.http.post(`${this.env}/api/services/app/VendorProspect/GetFinancialDocumentForProspect`, queryParams);
  }

  uploadAttachment(file: File, attachmentUploadParams: AttachmentUploadParams) {
    const formData = new FormData();
    formData.append("file", file);

    Object.keys(attachmentUploadParams).forEach((key) => {
      formData.append(key, attachmentUploadParams[key]);
    });

    return this.http.post(
      `${this.env}/api/services/app/FinancialAttachments/UploadFinancialDocument?persona=2`,
      formData,
      {
        headers: {
          encType: "multipart/form-data",
        },
      }
    );
  }

  deleteAttachment(deleteAttachmentParams: DeleteAttachmentParams) {
    const queryString = Object.entries(deleteAttachmentParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return new Promise((resolve, reject) => {
      this.http
        .delete(`${this.env}/api/services/app/FinancialAttachments/Delete?persona=2&${queryString}`, {
          headers: {
            encType: "multipart/form-data",
          },
        })
        .toPromise()
        .then((res) => {
          console.log(`Image with ID ${deleteAttachmentParams.id} deleted successfully.`);
          resolve(res); // Resolve the promise with the API response
        })
        .catch((error) => {
          console.error(`Error deleting image with ID ${deleteAttachmentParams.id}:`, error);
          reject(error); // Reject the promise with the error
        });
    });
  }

  downloadAttachment(attachment: FinancialAttachmentDto) {
    const { id, estimateId, invoiceId } = attachment;

    const secureLinkParams: SecureLinkParams = {
      id,
      estimateId: estimateId || 0,
      invoiceId: invoiceId || 0,
    };

    this.getSecureLink(secureLinkParams).subscribe({
      next: (link) => {
        // window.open(link, "_blank");
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  downloadOfflineAttachments(attachment: FinancialAttachmentDto, code: string) {
    const { id, estimateId, invoiceId } = attachment;

    const secureOfflineLinkParams: SecureOfflineLinkParams = {
      id,
      estimateId: estimateId || 0,
      invoiceId: invoiceId || 0,
      code: code,
    };
    console.log("the params", secureOfflineLinkParams);
    this.getSecureOfflineLink(secureOfflineLinkParams).subscribe({
      next: (link) => {
        // window.open(link, "_blank");
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  downloadFile(url: SafeUrl): void {
    const a = document.createElement("a");
    a.href = url as string;
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export interface FileUploadParams {
  fileName: string;
  fileType?: string;
  entityId?: number;
  entity: string;
  role?: string;
  apiUrl?: string;
  clientId?: string;
}

export enum UploadEntities {
  SERVICE_ORDER = "SERVICE_ORDER",
  TECHNICIAN_SERVICE_ORDER_SCHEDULE = "TECHNICIAN_SERVICE_ORDER_SCHEDULE",
  YACHT_PROFILE_IMAGES = "YACHT_PROFILE_IMAGES",
  PROFILE_PICTURE_IMAGE = "PROFILE_PICTURE_IMAGE",
  GALLERY_IMAGE = "GALLERY_IMAGE",
  RECOMMENDED_SERVICE = "RECOMMENDED_SERVICE",
  CHECKLIST_ITEM = "CHECKLIST_ITEM",
  MULTIPOINT_INSPECTION_CHECKLIST = "MULTIPOINT_INSPECTION_CHECKLIST",
  VISUAL_INSPECTION_CHECKLIST = "VISUAL_INSPECTION_CHECKLIST",
}

export interface AttachmentUploadParams {
  fileName: string;
  estimateId?: number;
  invoiceId?: number;
  yachtId?: number;
}

export interface DeleteAttachmentParams {
  estimateId?: number;
  invoiceId?: number;
  id: number;
}

export type SecureLinkParams = {
  id?: number;
  estimateId?: number;
  invoiceId?: number;
};
export type SecureOfflineLinkParams = {
  id?: number;
  code?: string;
  estimateId?: number;
  invoiceId?: number;
};

export interface FinancialAttachmentDto {
  documentType?: number;
  estimateId?: number;
  invoiceId?: number;
  fileName: string;
  id: number;
  name: string;
  ownerUserId: number;
  state: number;
}

export interface FileUploadModel {
  fileName: string;
  file: Buffer | Uint8Array | Blob | string;
  metadata: { [key: string]: string };
}
