import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import {IonContent, Platform} from "@ionic/angular";
import {Animation, StatusBar, Style} from "@capacitor/status-bar";

@Component({
  selector: "app-base-one-layout",
  templateUrl: "./base-one-layout.component.html",
  styleUrls: ["./base-one-layout.component.css"],
})
export class BaseOneLayoutComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('content', { static: false }) content: ElementRef<IonContent>;
  @ViewChild('scrollElement', { static: false }) scrollElementRef: ElementRef<HTMLDivElement>;

  @Input() hasBackButton = true;
  @Input() hasLogo = true;
  @Input() backgroundColor = null;
  @Input() title = "";
  @Input() hasAudio = false;
  @Input() hasHeader = true;
  @Input() headerHeight = "80";
  @Output() audioSwitch: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();

  isMuted = false;
private touchStartListener: () => void;

  constructor(private renderer: Renderer2, private platform: Platform,) {

    this.isMuted = localStorage.getItem("isAudioMuted") === 'true' ? true : false;

   
  
  }

  switchVolume() {
    this.isMuted = !this.isMuted;
   this.audioSwitch.emit({isMuted:this.isMuted});
  }

  ngAfterViewInit() {
    setTimeout(() => {
         this.addTouchMoveListener()
    }, 200);
 
  }




  private addTouchMoveListener() {
    if (this.scrollElementRef && this.scrollElementRef.nativeElement) {
   this.touchStartListener =  this.renderer.listen(this.scrollElementRef.nativeElement, 'touchmove', (event) => this.onTouchMove(event));
    } else {
      console.error('touch element not found');
    }
  }


  onTouchMove(event: any) {
    if(Capacitor.isNativePlatform()){
       Keyboard.hide();
    }
   
  }


  async ngOnInit() {
    //
    setTimeout(async () => {
      if (this.platform.is("capacitor")) {
        // if (this.platform.is("android")) {
        // await StatusBar.show({animation: Animation.Fade});
        await StatusBar.setStyle({style: Style.Light});
        // }
      }
    }, 200);


  }

  onButtonClick() {
    this.goBack.emit();
  }


  ngOnDestroy() {
    if(this.touchStartListener){

    this.touchStartListener()
    }
   
  }

  
}
