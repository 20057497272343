<div class="px-4 py-6">
    <div class="flex w-full items-center justify-center">
        <button
            (click)="close()"
            class="flex items-center"
        >
            <span class="bbicon-close text-3xl text-dsGray70"></span>
        </button>
        <h3
            class="font-bold text-center text-xl text-brandDeepSeaBlue grow m-0"
        >
            {{ LANGUAGE_DATA?.PROXIMITY_ALERT.TITLE | translate }}
        </h3>
        <app-popover-button
            [message]="
                'See boats with active jobs for today and provide real-time updates to Boat Owners on arrival.'
            "
        ></app-popover-button>
    </div>
    <div *ngIf="yachtAlertData && yachtAlertData?.length > 0">
        <div
            class="text-base text-dsGray70 font-normal leading-snug text-center pt-3 px-3"
        >
            <p *ngIf="yachtAlertData?.length === 1">
                {{
                    LANGUAGE_DATA?.PROXIMITY_ALERT.MESSAGE_SECTION_1 | translate
                }}
                <span class="font-bold text-deepSeablue">{{
                    yachtAlertData[0]?.name
                }}</span>
                {{
                    LANGUAGE_DATA?.PROXIMITY_ALERT.MESSAGE_SECTION_2 | translate
                }}
            </p>
            <p *ngIf="yachtAlertData?.length > 1">
                {{
                    LANGUAGE_DATA?.PROXIMITY_ALERT.MESSAGE_SECTION_1 | translate
                }}
                <span class="font-bold">{{ yachtAlertData?.length }}</span>
                {{
                    LANGUAGE_DATA?.PROXIMITY_ALERT.MESSAGE_SECTION_3 | translate
                }}
            </p>
        </div>

        <div>
            <!-- Select Yacht -->
            <div class="mt-4">
                <h4
                    *ngIf="yachtAlertData?.length > 1"
                    class="text-lg text-dsGray70 mb-3 text-center font-semibold"
                >
                    {{ LANGUAGE_DATA?.PROXIMITY_ALERT.SELECT_BOAT | translate }}
                </h4>
                <div class="flex gap-4 flex-wrap justify-center">
                    <div
                        class="relative"
                        *ngFor="let item of yachtAlertData"
                    >
                        <div (click)="onSelectYacht(item)">
                            <img
                                *ngIf="item?.imageUrl"
                                [src]="item?.imageUrl"
                                alt=""
                                class="rounded-full aspect-square w-20 object-cover object-center"
                            />
                            <img
                                *ngIf="!item?.imageUrl"
                                src="assets/images/happy-place-placeholder.jpg"
                                class="rounded-full aspect-square w-20 object-cover object-center"
                            />
                            <div
                                *ngIf="item.yachtId === selectYacht.yachtId"
                                class="absolute flex justify-center items-center top-1 right-2 text-white rounded-full w-5 h-5 bg-brandPrimaryBlue border border-brandPrimaryBlue"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                        <!-- <div>{{item?.name}}</div> -->
                    </div>
                </div>
            </div>
            <hr class="bg-brandSkyBlue my-4" />

            <!-- Service order items -->
            <div
                class="mt-4"
                [ngClass]="{
                    'mb-20': serviceItems?.length === 1,
                    'mb-10': serviceItems?.length > 1
                }"
            >
                <h3
                    class="mb-2 font-bold text-deepSeablue text-2xl flex items-center gap-2"
                >
                    <span
                        class="bbicon-boat text-2xl text-brandSunsetOrange"
                    ></span>
                    {{ selectYacht.name }}
                </h3>
                <div
                    class="text-base font-semibold text-black text-center flex justify-between items-center mb-3"
                >
                    <span class="font-semibold text-lg text-deepSeablue">
                        {{
                            LANGUAGE_DATA?.PROXIMITY_ALERT.SERVICE_ORDER
                                | translate
                        }}</span
                    >
                    <span
                        class="rounded-full bg-brandSkyBlue text-brandDeepSeaBlue px-3 py-0.5 text-sm ml-2 font-bold leading-none"
                    >
                        {{ serviceItems?.length }}
                    </span>
                </div>
                <div class="max-h-[200px] overflow-y-auto">
                    <ul class="space-y-2">
                        <li
                            *ngFor="let service of serviceItems"
                            class="border-2 rounded-lg px-4"
                            [ngClass]="{
                                'border-brandPrimaryBlue':
                                    service.id === selectedService.id
                            }"
                        >
                            <span
                                (click)="onSelectService(service)"
                                class="flex items-center justify-between py-4"
                            >
                                <span class="flex items-center">
                                    <span
                                        class="mr-2 py-2 font-semibold text-lg"
                                        >{{
                                            service?.serviceOrder?.title
                                        }}</span
                                    >
                                    <span class="text-dsGray60 text-sm">
                                        (#{{ service.serviceOrder.id }})
                                    </span>
                                </span>
                                <span
                                    *ngIf="service.id === selectedService.id"
                                    class="bbicon-check-circle text-dsSuccess text-3xl"
                                >
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <p class="text-center font-semibold text-black text-lg mb-4 mt-6">
                {{ LANGUAGE_DATA?.PROXIMITY_ALERT.CONFIRM_ARRIVAL | translate }}
            </p>
            <!-- Update and Cancel Buttons -->
            <div class="mt-2 grid grid-cols-2 gap-2">
                <!-- Cancel Button -->
                <app-button
                    [label]="LANGUAGE_DATA?.PROXIMITY_ALERT.CANCEL | translate"
                    [variant]="'tertiary'"
                    (click)="close()"
                ></app-button>

                <app-button
                    [label]="LANGUAGE_DATA?.PROXIMITY_ALERT.CONFIRM | translate"
                    [variant]="'secondary'"
                    (click)="onConfirmArrival()"
                ></app-button>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            (yachtAlertData?.length === 0 || !yachtAlertData) && isOutOfRange
        "
        class="text-lg text-dsGray70 font-normal leading-snug text-center pt-3 px-3 flex flex-col items-center justify-center gap-4 py-10"
    >
        <span class="bbicon-map-point text-6xl text-dsGray40"></span>
        <p>
            {{ LANGUAGE_DATA?.PROXIMITY_ALERT.OUT_OF_RANGE | translate }}
        </p>
    </div>
</div>
