import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { EnvironmentService } from "../environment.service";

@Injectable({
    providedIn: "root",
})
export class Api {
    constructor(
        private env: EnvironmentService,
        private http: HttpClient,
        public router: Router
    ) {}

    doGet<T>(url: string, params?: Map<string, any>): Observable<T> {
        params = params ? params : new Map<string, any>();
        const _params = this.buildParams(params);
        const _url = this.getUrl(url);
        return this.http.get(_url, { params: _params }).pipe(
            map((res: any) => {
                return res.result;
            })
        );
    }

    get(url: any, params: any): Observable<any> {
        //let paramsData = new Map<string, any>([paramsData]);
        params = params ? params : new Map<string, any>();
        const _params: any = this.buildParams(params);
        const _url = this.getUrl(url);
        return this.http.get(_url, { params: _params });
    }

    delete2<T>(url: string, params?: any): Observable<any> {
        // let _url = this.getUrl(url);
        // //let payload = data ? JSON.stringify(data) : undefined;
        // let payload = data;
        // return this.http.delete<T>(_url, payload);

        params = params ? params : new Map<string, any>();
        const _params: any = this.buildParams(params);
        const _url = this.getUrl(url);
        return this.http.delete(_url, { params: _params });
    }
    delete(url: any, params: any): Observable<any> {
        //let paramsData = new Map<string, any>([paramsData]);
        params = params ? params : new Map<string, any>();
        const _params: any = this.buildParams(params);
        const _url = this.getUrl(url);
        return this.http.delete(_url, { params: _params });
    }

    post<T>(url: string, data?: T): Observable<T> {
        const _url = this.getUrl(url);
        //let payload = data ? JSON.stringify(data) : undefined;
        const payload = data;
        return this.http.post<T>(_url, payload);
    }

    doPost(url: any, data: any): Observable<any> {
        return this.post(url, data).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    put<T>(url: string, data: T): Observable<T> {
        const _url = this.getUrl(url);
        const payload = JSON.stringify(data);
        return this.http.put<T>(_url, payload);
    }

    private buildParams(params: Map<string, string>) {
        let _params = new HttpParams();
        for (const entry of params.entries()) {
            const key = entry[0];
            const value = entry[1];
            _params = _params.append(key, value);
        }

        return _params;
    }

    private getUrl(url: string) {
        return `${this.env.apiUrl}${url}`;
    }

    getRoute(position: any = null) {
        if (position == null) {
            return this.router.url.split("/"); // array of states
        } else {
            const routes = this.router.url.split("/");
            return routes[position];
        }
    }

    ProcessApiResponse(response: any, next: any) {
        if (response.result) {
        } else {
            alert("Error");
        }
    }
}
