export class ServiceOrderHelper {
  static YACHT_OWNER_TAG = "Yacht Owner";
  static TECHNICIAN_TAG = "Technician";
  static VENDOR_TAG = "Vendor";

  constructor(private serviceOrder: any) {}

  public getId() {
    return this.serviceOrder?.serviceOrder?.id;
  }

  public hasTechnicianImages() {
    return this.serviceOrder?.technician?.images?.length > 0;
  }

  public hasLightbox() {
    return this.serviceOrder?.serviceOrder?.lightboxImages?.length > 0;
  }

  public hasImagesToSave() {
    return this.serviceOrder?.imageObjectToSave?.length > 0;
  }

  public hasCheckList() {
    return this.serviceOrder && this.serviceOrder.serviceOrder.checklist;
  }

  public getLightboxImages() {
    return this.serviceOrder.serviceOrder.lightboxImages.map((img: any) => {
      return {
        file: img.imageUrl,
        thumbImage: img.imageUrl,
        user: img.role === "vendor" ? ServiceOrderHelper.VENDOR_TAG : ServiceOrderHelper.YACHT_OWNER_TAG,
        id: img.id,
        image: img.imageUrl,
        isFavourite: img.isFavourite,
        isPrimary: img.isPrimary,
        uploaded: img.uploaded,
      };
    });
  }

  public getCheckList() {
    return this.serviceOrder.serviceOrder.checklist;
  }

  getImagesToSave() {
    return this.serviceOrder?.imageObjectToSave?.map((img: any) => {
      return {
        image: img.base64Image,
        thumbImage: img.base64Image,
        user: ServiceOrderHelper.YACHT_OWNER_TAG,
      };
    });
  }

  public getTechnicianImages() {
    return this.serviceOrder.technician.images.map((img: any) => {
      return {
        image: img.imageUrl,
        thumbImage: img.imageUrl,
        isFavourite: img.isFavourite,
        isPrimary: img.isPrimary,
        id: img.id,
        user: ServiceOrderHelper.TECHNICIAN_TAG,
        uploaded: img.uploaded,
      };
    });
  }
}

/**
 * Represents the details of a service order.
 */
export interface IOrderDetail {
  serviceOrder: ServiceOrder;
  technician: any;
  yachts: Yachts;
  service: Service;
  serviceCategory: ServiceCategory;
  vendor: Vendor;
  yachtOwner: YachtOwner;
  schedulerInfo: any;
  recommendedServices: any;
  invoice: any;
  invoiceId?: number;
  isCompleted: boolean;
  selected?: any;
  isNotification: boolean;
  paymentInfo: PaymentInfo;
  estimateId: number;
  estimate: Estimate;
}

interface ServiceOrder {
  paymentType?: string;
  description?: any;
  status?: number;
  statusName?: string;
  priority?: number;
  serviceId?: number;
  instruction?: any;
  location?: any;
  expectedDeliveryDate?: string;
  name?: string;
  yachtId?: number;
  affectShipShape?: boolean;
  title?: string;
  vendorId?: number;
  creationTime?: string;
  isAccepted?: boolean;
  yacht?: any;
  lightboxImages?: any;
  bidRequested?: boolean;
  checkListTemplate?: any;
  bidHistories?: any;
  checklist?: any;
  invitationEmail?: any;
  invitationPhoneNumber?: any;
  invitationBusinessName?: any;
  parentServiceOrderId?: number;
  frequency?: number;
  isActive?: boolean;
  dateOfNextServiceOrder?: any;
  completionDate?: any;
  usedBundleId?: any;
  bundleId?: any;
  isSchedulable?: boolean;
  isBidInProgress?: boolean;
  requiresAction?: boolean;
  requiresActionMessage?: string;
  isRecommendedServiceAcknowledged?: any;
  recommendedServiceDescription?: any;
  recommendation?: any;
  id?: number;
  paymentTypeId?: ServiceOrderPaymentTypeEnum;
}

interface Yachts {
  country?: string;
  isActive?: boolean;
  documentNumber?: string;
  lastLocation?: LastLocation;
  name?: string;
  hailingPort?: string;
  imageUrl?: any;
  isDefault?: boolean;
  yachtOwner?: string;
  yachtOwnerId?: number;
  id?: number;
}

interface LastLocation {
  coordinates?: any;
  latitude?: number;
  longitude?: number;
}

interface Service {
  name?: string;
  isActive?: boolean;
  isSystemDefault?: boolean;
  description?: string;
  id?: number;
}

interface ServiceCategory {
  name?: string;
  isSystemDefault?: boolean;
  description?: any;
}

interface Vendor {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  businessName?: string;
  businessAddress?: string;
  businessState?: any;
  businessCountry?: string;
  phoneNumber?: string;
  descriptions?: string;
  isActive?: boolean;
  customPhoneNumber?: any;
  customEmailAddress?: any;
  customBusinessName?: any;
  contactPerson?: any;
  id?: number;
  imageLoaded?: boolean;
}

interface YachtOwner {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  address?: string;
  state?: string;
  country?: string;
  isActive?: boolean;
  customPhoneNumber?: any;
  customEmailAddress?: any;
  customName?: any;
  yacht?: any;
  id?: number;
}

interface PaymentInfo {
  currentEstimate: any;
  description: string;
  id: number;
  invoiceId: any;
  isPublished: boolean;
  metadata: any;
  packageId: any;
  serviceOrderId: number;
  state: number;
  stateName: string;
  type: number;
  typeName: string;
  estimateDate: any;
  actionDate: any;
}

export interface ServiceOrderStatusObject {
  title: string;
  icon: string;
  className: string;
  progressStatus?: number;
  statusMessage?: string;
  additionalInfo?: string;
}

export enum PaymentInfoStatusEnum {
  PENDING = 0,
  REJECTED = 3,
}

export enum ServiceOrderStatus {
  PENDING = 0,
  REJECTED = 3,
  UNPAID = 1,
  PAID = 2,
}

export enum ServiceOrderProgressStatusEnum {
  PENDING = 0,
  SCHEDULED = 1,
  IN_PROGRESS = 2,
  ON_HOLD = 3,
  COMPLETED = 4,
  CANCELED = 8,
}

export enum ServiceOrderInvoiceStatusEnum {
  PENDING = 0,
  PAID = 1,
  UNPAID = 2,
}

export enum ServiceOrderStatusEnum {
  DRAFTED = 0,
  PUBLISHED = 1,
  SCHEDULED = 4,
  COMPLETED = 5,
  RECOMMENDED_SERVICE = 6,
  CANCELED = 8,
}

export enum TechnicianWorkStatusEnum {
  ON_HOLD = "Blocked",
  IN_PROGRESS = "Doing",
}

export interface Estimate {
  internalId: string;
  estimateDate?: string | Date;
  title: string;
  description: string;
  type: EstimateTypeEnum;
  yachtId: number;
  yachtOwnerId: number;
  serviceOrderId?: number;
  validity: string | Date;
  packageExpiration: string | Date;
  adjustment: number;
  taxes: number;
  terms: string;
  customerNotes: string;
  currency: string;
  isTemplate?: boolean;
  state?: EstimateStatusEnum;
  vendorId?: number;
  id: number;
}

export enum EstimateTypeEnum {
  PACKAGE = 1,
  ONE_OFF = 2,
}
export enum EstimateItemTypeEnum {
  SERVICE = 1,
  PARTS = 2,
  OTHER = 3,
}
export enum EstimateStatusEnum {
  HIDDEN = 0,
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export enum ServiceOrderPaymentTypeEnum {
  ESTIMATE = 0,
  BUNDLE = 1,
}
