<ion-header class="ion-no-border">
  <ion-toolbar>
    <button
      type="button"
      slot="start"
      (click)="onButtonClick()"
      class="p-2 rounded-full bg-brandSunsetOrange mt-2 ml-5"
    >
      <ion-ripple-effect></ion-ripple-effect>
      <span class="bbicon-chevron-left text-2xl text-white"></span>
    </button>
  </ion-toolbar>
</ion-header>
<div class="px-6 pb-6">
  <!-- POSITIVE FEEDBACK -->
  <div class="flex justify-center flex-col" *ngIf="action?.positive_feedback == true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.DESCRIPTION | translate }}
    </p>

    <ion-button (click)="gotoDashboard()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">{{
        LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.DASHBOARD_BUTTON_TEXT | translate
      }}</span>
    </ion-button>

    <button class="w-fit m-auto text-brandMedGray font-bold" (click)="onButtonClick()">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.POSITIVE_FEEDBACK.STAY_HERE_BUTTON_TEXT | translate }}
    </button>
  </div>

  <!-- SERVICE REQUEST -->
  <div class="flex justify-center flex-col" *ngIf="action?.service_request == true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.DESCRIPTION | translate }}
    </p>

    <ion-button (click)="gotoServiceRequest()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">{{
        LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.REQUEST_SERVICE_BUTTON_TEXT | translate
      }}</span>
    </ion-button>

    <button class="w-fit m-auto text-brandMedGray font-bold" (click)="onButtonClick()">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.REQUEST_SERVICE.NO_THANKS_BUTTON_TEXT | translate }}
    </button>
  </div>

  <!-- SIGN UP -->
  <div class="flex justify-center flex-col" *ngIf="action?.sign_up == true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.DESCRIPTION | translate }}
    </p>

    <ion-button (click)="gotoSignUp()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">
        {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.BUTTON_TEXT | translate }}
      </span>
    </ion-button>

    <button class="w-fit m-auto text-brandMedGray font-bold" (click)="onButtonClick()">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.SIGN_UP_NOW.BUTTON_TEXT_TWO | translate }}
    </button>
  </div>
  <!-- SIGN UP -->
  <div class="flex justify-center flex-col" *ngIf="action?.sign_in == true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">Sign in Now</h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      To save your progress, we need some additional information to create a profile and submit your request.
    </p>

    <ion-button (click)="gotoSignIn()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">Get Started</span>
    </ion-button>

    <button class="w-fit m-auto text-brandMedGray font-bold" (click)="onButtonClick()">NO THANKS</button>
  </div>

  <!-- CUSTOM FEEDBACK -->
  <div class="flex justify-center flex-col" *ngIf="action?.custom_feedback == true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">How can this be improved?</h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      Let us know what was wrong with the information provided so CaptainAI can improve our responses and provide you
      with accurate information.
    </p>

    <div class="col-span-2 my-4">
      <button
        (click)="openIssueModal()"
        class="border-solid py-4 px-4 flex items-center rounded-lg justify-between w-full relative border border-dsGray60"
      >
        <div>
          {{ selectedIssue }}
          <label class="bb-floating-label" [class.float]="selectedIssue">Issue</label>
        </div>
        <div>
          <span
            class="bbicon-close text-2xl text-brandDeepSeaBlue"
            (click)="clearSelectedIssue(); $event.stopPropagation()"
            *ngIf="selectedIssue"
          ></span>
          <span class="bbicon-chevron-down text-2xl text-brandDeepSeaBlue"></span>
        </div>
      </button>
    </div>
    <app-textarea
      [icon]="{ name: '', color: '', size: '' }"
      [hasCaptainAi]="false"
      [label]="'Additional Detail'"
    ></app-textarea>
    <ion-button (click)="submitFeedBack()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">Submit Feedback</span>
    </ion-button>

    <button class="w-fit m-auto text-brandMedGray font-bold" (click)="onButtonClick()">CANCEL</button>
  </div>

  <!-- JOB DONE FEEDBACK -->

  <div class="flex justify-center flex-col" *ngIf="action?.job_done_feedback === true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.DESCRIPTION | translate }}
    </p>

    <ion-button (click)="onButtonClick()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">{{
        LANGUAGE_DATA?.CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.GET_STARTED_BUTTON_TEXT | translate
      }}</span>
    </ion-button>

    <!-- <button class="w-fit m-auto text-brandMedGray font-bold" (click)="onButtonClick()">{{LANGUAGE_DATA?.CAPTAIN_TALK.BOAT_PROFILE.ALERT_INFO.NO_THANKS_BUTTON_TEXT | translate}}</button> -->
  </div>

  <!-- LAST SERVICE DATE -->

  <div class="flex justify-center flex-col" *ngIf="action?.last_service_date === true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.LAST_SERVICE_DATE.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.LAST_SERVICE_DATE.DESCRIPTION | translate }}
    </p>

    <ion-button (click)="onButtonClick()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">{{
        LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.LAST_SERVICE_DATE.BUTTON_TEXT | translate
      }}</span>
    </ion-button>
  </div>

  <!-- SKILL LEVEL -->

  <div class="flex justify-center flex-col" *ngIf="action?.skill_level == true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.SKILL_LEVEL.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.SKILL_LEVEL.DESCRIPTION | translate }}
    </p>

    <ion-button (click)="onButtonClick()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold"
        >{{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.SKILL_LEVEL.BUTTON_TEXT | translate }}
      </span>
    </ion-button>
  </div>

  <!-- LOGOUT -->

  <div class="flex justify-center flex-col" *ngIf="action?.logout == true">
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.LOGOUT.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.LOGOUT.DESCRIPTION | translate }}
    </p>

    <ion-button (click)="logout()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">{{
        LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.LOGOUT.LOGOUT_BUTTON_TEXT | translate
      }}</span>
    </ion-button>

    <button class="w-fit m-auto text-brandMedGray font-bold" (click)="onButtonClick()">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.LOGOUT.CANCEL_BUTTON_TEXT | translate }}
    </button>
  </div>

  <!-- AI INFO -->

  <div class="flex justify-center flex-col" *ngIf="action?.ai_info === true">
    <img
      src="assets/captain-talk/captain-talk.svg"
      alt="captain ai logo"
      slot="end"
      height="96"
      width="96"
      class="m-auto"
    />
    <h1 class="text-center text-brandDeepSeaBlue font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.TITLE | translate }}
    </h1>
    <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
      <span *ngIf="!isLoggedIn">
        {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.DESCRIPTION_ONE | translate }}
      </span>
      <span *ngIf="isLoggedIn">
        {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.DESCRIPTION_TWO | translate }}
      </span>
    </p>

    <h4 class="text-center text-black font-extrabold">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.DESCRIPTION_THREE | translate }}
    </h4>

    <ion-button (click)="onButtonClick()" expand="block" shape="round" class="my-5 font-bold h-14">
      <span class="text-xl font-bold">{{ LANGUAGE_DATA?.CAPTAIN_TALK.AI_ALERT.BUTTON_TEXT | translate }}</span>
    </ion-button>
  </div>
</div>
