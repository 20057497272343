import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Platform, NavController, AlertController } from "@ionic/angular";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { Directory, Filesystem } from "@capacitor/filesystem";
import write_blob from "capacitor-blob-writer";

import {
  FinancialDocumentDisplayData,
  FinancialDocumentHeaderInfo,
  FinancialDocumentLineItem,
} from "../../finance.service";
import { EstimateService, IEstimateActionMeta } from "../../estimate.service";
import { IGetEstimateOutput, EstimateStateEnum, EstimateTypeEnum } from "src/shared/service-proxies/service-proxies";

import { MessagingService } from "src/app/services/messaging.service";

import { ToastService } from "src/app/shared/message/toast.service-impl";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";

import { ServiceRequestService } from "../../../serviceRequest/shared/service-request.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-estimate-detail",
  templateUrl: "./estimate-detail.component.html",
  styleUrls: ["./estimate-detail.component.css"],
})
export class EstimateDetailComponent implements OnInit {
  protected LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

  @Input() public estimateId: number;
  @Input() public modalRef: HTMLIonModalElement;

  protected isLoading = false;
  protected isAccepting = false;
  protected isRejecting = false;

  protected documentHeaderInfo: FinancialDocumentHeaderInfo;
  protected estimateDisplayData: FinancialDocumentDisplayData;
  protected lineItems: FinancialDocumentLineItem[] = [];
  private cookieValue: string;

  protected estimateInfo: IGetEstimateOutput;
  protected estimateActionMeta: IEstimateActionMeta;

  constructor(
    private service: ServiceRequestService,
    private toastService: ToastService,
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private router: Router,
    private messagingService: MessagingService,
    private estimateService: EstimateService,
    private platform: Platform,
    private fileOpener: FileOpener,
    private navCtrl: NavController
  ) {}

  ngOnInit(): void {
    try {
      this.cookieValue = localStorage.getItem("lang") || "en";
    } catch (error) {
      console.error("Error getting cookie value", error);
    }

    try {
      const estimateId = Number(this.route.snapshot.paramMap.get("estimateId"));
      if (estimateId) {
        this.estimateId = estimateId;
      }

      if (!this.estimateId) {
        this.toastService.showToast("Estimate not found", 2000, "bottom", null, "danger");
        this.router.navigate(["/yacht-owner/estimates"]);
      }

      this.getEstimate();
    } catch (error) {
      console.error(`Error getting estimate with id: ${this.estimateId}`, error);
      this.toastService.showToast("Error getting estimate", 2000, "bottom", null, "danger");
      this.router.navigate(["/yacht-owner/estimates"]);
    }
  }

  get isPending(): boolean {
    return this.estimateInfo?.estimate?.state === EstimateStateEnum.PENDING;
  }

  get isApproved(): boolean {
    return this.estimateInfo?.estimate?.state === EstimateStateEnum.APPROVED;
  }

  get isRejected(): boolean {
    return this.estimateInfo?.estimate?.state === EstimateStateEnum.REJECTED;
  }

  private getEstimate() {
    this.isLoading = true;

    this.estimateService.getEstimateById(this.estimateId).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.estimateInfo = response;
        this.initializeData();
      },
      error: (error) => {
        this.isLoading = false;
        throw new Error("Error getting estimate: " + error);
      },
    });
  }

  private initializeData() {
    if (this.estimateInfo) {
      const serviceOrderLabel = this.cookieValue === "en" ? "Service Order" : "Orden de Servicio";
      const packageLabel = this.cookieValue === "en" ? "Package" : "Paquete";
      const relatedEntityTypeLabel = this.estimateInfo.serviceOrder?.id ? serviceOrderLabel : packageLabel;

      this.documentHeaderInfo = {
        label: this.cookieValue === "en" ? "Estimate" : "Estimación",
        id: this.estimateInfo.estimate?.id,
        relatedEntityId: this.estimateInfo.serviceOrder?.id || this.estimateInfo.package?.id,
        relatedEntityTypeLabel,
        issuedDate: this.estimateInfo.estimate?.estimateDate?.toJSDate(),
        validityDate: this.estimateInfo.estimate?.validity?.toJSDate(),
        fromName: this.estimateInfo.vendor?.businessName,
        toName: this.estimateInfo.yachtOwner?.firstName + " " + this.estimateInfo.yachtOwner?.lastName,
        logo: this.estimateInfo.vendor?.logo,
      };

      this.lineItems = this.estimateService.getLineItems(this.estimateInfo.estimateItems);

      this.estimateDisplayData = {
        vendorId: this.estimateInfo?.vendor?.id,
        tax: this.estimateInfo.tax,
        discount: this.estimateInfo.estimate?.adjustment,
        balanceDue: this.estimateInfo?.total,
      };

      this.estimateActionMeta = this.estimateService.getEstimateActionMeta(this.estimateInfo);
    }
  }

  protected acceptEstimate() {
    this.isAccepting = true;
    this.estimateService.acceptEstimate(this.estimateId).subscribe({
      next: (response) => {
        this.isAccepting = false;
        if (response) {
          if (this.modalRef) {
            this.modalRef.dismiss();
          } else {
            this.estimateInfo = response;
            this.initializeData();
          }
          this.toastService.showToast("Estimate accepted successfully", 2000, "bottom", null, "success");
        }
      },
      error: (error) => {
        this.isAccepting = false;
        this.toastService.showToast("Error accepting estimate", 2000, "bottom", null, "danger");
        console.error("Error accepting estimate", error);
      },
    });
  }

  protected rejectEstimate() {
    this.isRejecting = true;
    this.estimateService.rejectEstimate(this.estimateId).subscribe({
      next: (response) => {
        this.isRejecting = false;
        if (response) {
          if (this.modalRef) {
            this.modalRef.dismiss();
          } else {
            this.estimateInfo = response;
            this.initializeData();
          }
          this.toastService.showToast("Estimate rejected successfully", 2000, "bottom", null, "success");
        }
      },
      error: (error) => {
        this.isRejecting = false;
        this.toastService.showToast("Error rejecting estimate", 2000, "bottom", null, "danger");
        console.error("Error rejecting estimate", error);
      },
    });
  }

  protected async showRejectAlert() {
    const alert = await this.alertCtrl.create({
      header: "Confirm",
      message: "Are you sure you want to reject this estimate?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Reject",
          handler: () => {
            this.rejectEstimate();
          },
        },
      ],
    });

    alert.present();
  }

  protected async showAcceptAlert() {
    const alert = await this.alertCtrl.create({
      header: "Confirm",
      message: "Are you sure you want to accept this estimate?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Accept",
          handler: () => {
            this.acceptEstimate();
          },
        },
      ],
    });

    alert.present();
  }

  protected getDocumentLink(item) {
    this.service.getDocLink(this.estimateId, item.id).subscribe({
      next: (response) => {
        if (response.result) {
          const downloadObservable = this.service.download(response.result);
          if (this.platform.is("cordova")) {
            this.handleCordovaDownload(downloadObservable, item.name);
          } else {
            this.handleBrowserDownload(downloadObservable, item.name);
          }
        }
      },
      error: (error) => {
        this.toastService.showToast("Unable to get document link", 2000, "bottom", null, "danger");
        console.error("Error", error);
      },
    });
  }

  private handleCordovaDownload(downloadObservable: Observable<Blob>, fileName: string) {
    downloadObservable.subscribe({
      next: (res) => {
        this.nativeDownloadFile(res, fileName, "download");
      },
      error: (error) => {
        this.toastService.showToast("Error downloading document", 2000, "bottom", null, "danger");
        console.error("Error during handleCordovaDownload:", error);
      },
    });
  }

  private handleBrowserDownload(downloadObservable: Observable<Blob>, fileName: string) {
    downloadObservable.subscribe({
      next: (res) => {
        const downloadURL = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = fileName;
        link.click();
      },
      error: (error) => {
        this.toastService.showToast("Error downloading document", 2000, "bottom", null, "danger");
        console.error("Error during handleBrowserDownload:", error);
      },
    });
  }

  private async nativeDownloadFile(blob: Blob, fileName: string, action: string) {
    try {
      const uri = await write_blob({
        path: fileName,
        blob: blob,
        directory: Directory.Documents,
      });

      const fileData = await Filesystem.getUri({
        path: fileName,
        directory: Directory.Documents,
      });

      const path = fileData.uri;
      const mimeType = this.getFileType(fileName);

      this.openFile(path, mimeType, action);
    } catch (error) {
      console.error("Error during nativeDownloadFile:", error);
    }
  }

  private async openFile(path: string, mimeType: string, action: string) {
    if (action == "open") {
      this.fileOpener
        .open(path, mimeType)
        .then(() => {
          console.log("file opened");
        })
        .catch((error) => {
          console.error("error opening file", error);
        });
    } else {
      this.fileOpener
        .showOpenWithDialog(path, mimeType)
        .then(() => {
          console.log("file opened");
        })
        .catch((e) => {
          console.log("error opening file");
        });
    }
  }

  private getFileType(name: string) {
    if (name.indexOf(".pdf") > -1) {
      return "application/pdf";
    } else if (name.indexOf(".doc") > -1) {
      return "application/msword";
    } else if (name.indexOf(".xls") > -1) {
      return "application/vnd.ms-excel";
    } else if (name.indexOf(".ppt") > -1) {
      return "application/vnd.ms-powerpoint";
    } else if (name.indexOf(".jpg") > -1) {
      return "image/jpeg";
    } else if (name.indexOf(".png") > -1) {
      return "image/png";
    } else if (name.indexOf(".jpeg") > -1) {
      return "image/jpeg";
    } else if (name.indexOf(".gif") > -1) {
      return "image/gif";
    } else if (name.indexOf(".bmp") > -1) {
      return "image/bmp";
    }
    return "";
  }

  async openChat(vendorId: number) {
    if (!vendorId) {
      this.toastService.showToast("Vendor not found", 2000, "bottom", null, "danger");
      return;
    }
    await this.router.navigate([`/yacht-owner/yacht-messages/${vendorId}/chat`], {
      queryParams: { key: 'fromServiceOrder' }
    });

  }

  protected close() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    } else {
      this.navCtrl.back();
    }
  }
}
