import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { XmlHttpRequestHelper } from "src/app/helpers/XmlHttpRequestHelper";
import { environment } from "src/environments/environment";
import { merge as _merge } from "lodash";
import { BaseService } from "src/app/shared/base.service";

@Injectable({
    providedIn: "root",
})
export class AbpApplicationService {
    //Emit the userid from the bearer token
    public isReloaded$: BehaviorSubject<number> = new BehaviorSubject(null);
    private abpLocalStorageKey = "abp-localstorage-key";

    constructor(private baseService: BaseService) {}

    loadFunc: Observable<any> = new Observable((subscriber) => {
        const token = this.getToken();
        const requestHeaders = {};
        if (token) {
            requestHeaders["Authorization"] = "Bearer " + token;
        }
        const baseUrl = environment.abpUrl;
        console.log("About to make ABP request");
        XmlHttpRequestHelper.ajax(
            "GET",
            baseUrl + "AbpUserConfiguration/GetAll",
            requestHeaders,
            null,
            (response) => {
                const result = response.result;
                this.setAbpResult(result);

                _merge(abp, result);
                abp.clock.provider = this.getCurrentClockProvider(
                    result.clock.provider
                );
                this.isReloaded$.next(abp.session.userId);
                subscriber.complete();
            },
            () => {
                console.log("ABP FAILURE ...........................");
                this.isReloaded$.next(abp.session.userId);
                subscriber.complete();
            }
        );
    });

    getToken(): string {
        return localStorage.getItem("ABP_Token");
    }

    public static setToken(token: string): void {
        localStorage.setItem("ABP_Token", token);
        // this.updateAbp().subscribe({
        //     next: () => {},
        //     complete: () => {},
        //     error: (err) => {},
        // });
    }

    clearToken() {
        localStorage.removeItem("ABP_Token");
        this.updateAbp().subscribe({
            next: () => {},
            complete: () => {},
            error: (err) => {},
        });
    }

    updateAbp(): Observable<any> {
        const user = this.baseService.getSession(this.baseService.credKey);
        this.restoreAbpResult();
        if (user && abp?.session?.userId) {
            if (user.id === abp.session.userId) {
                setTimeout(() => {
                    this.loadFunc.subscribe();
                }, 500);
                return new Observable<any>((subscriber) => {
                    subscriber.complete();
                });
            }
        }
        return this.loadFunc;
    }

    setAbpResult(result: any) {
        localStorage.setItem(this.abpLocalStorageKey, JSON.stringify(result));
    }

    restoreAbpResult() {
        try {
            const result = localStorage.getItem(this.abpLocalStorageKey);
            _merge(abp, JSON.parse(result));
        } catch {
            //
        }
    }

    getCurrentClockProvider(
        currentProviderName: string
    ): abp.timing.IClockProvider {
        if (currentProviderName === "unspecifiedClockProvider") {
            return abp.timing.unspecifiedClockProvider;
        }
        if (currentProviderName === "utcClockProvider") {
            return abp.timing.utcClockProvider;
        }
        return abp.timing.localClockProvider;
    }
}
