<app-base-one-layout (goBack)="goBack()" [hasBackButton]="false">
  <div class="flex flex-col items-center justify-center p-4" #scrollPageLogin [id]="elementId">
    <img src="../../../assets/captain-talk/captain-talk.svg" class="w-24 h-24 ml-6" />
    <h1 class="text-2xl font-bold text-center mt-4 blue-gradient-text mb-4">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.LOGIN.WELCOME_BACK | translate }}
    </h1>

    <div class="border border-gray-300 bg-white p-1.5 mb-3 w-full rounded-lg">
      <div class="flex space-x-2">
        <button
          (click)="selectOption('phone')"
          [ngClass]="selectedOption === 'phone' ? 'bg-deepSeablue text-white' : 'bg-brandSkyBlueLight text-deepSeablue'"
          class="px-4 w-1/2 py-2.5 font-bold text-sm rounded"
        >
          {{ LANGUAGE_DATA?.CAPTAIN_TALK.LOGIN.CELL_PHONE_TAB_TITLE | translate }}
        </button>
        <button
          (click)="selectOption('email')"
          [ngClass]="selectedOption === 'email' ? 'bg-deepSeablue text-white' : 'bg-brandSkyBlueLight text-deepSeablue'"
          class="px-4 py-2.5 w-1/2 rounded font-bold text-sm"
        >
          {{ LANGUAGE_DATA?.CAPTAIN_TALK.LOGIN.EMAIL_TAB_TITLE | translate }}
        </button>
      </div>
    </div>

    <form class="w-full" [formGroup]="signInForm" autocomplete="off">
      <div class="w-full mb-2">
        <app-input
          appScrollToView
          #userNameOrEmailAddressInput
          (change)="updateValidators()"
          [scrollElementId]="elementId"
          [label]="this.selectedOption === 'phone' ? 'Cell Phone Number' : 'E-Mail Address'"
          [floatingLabel]="'outline'"
          [type]="this.selectedOption === 'phone' ? 'tel' : 'email'"
          [defaultBorderColor]="'border-brandSkyBlue'"
          [required]="true"
          formControlName="userNameOrEmailAddress"
          [enterKeyHint]="'next'"
          appEnterNext
          (keyup.enter)="focusOnPassword()"
          [tabindex]="1"
          [invalid]="
            signInForm.controls['userNameOrEmailAddress'].invalid &&
            signInForm.controls['userNameOrEmailAddress'].touched
          "
          [validationText]="
            this.selectedOption === 'phone' ? 'Provide a valid Cell Phone Number' : 'Provide a valid Email Address'
          "
        ></app-input>
      </div>

      <div class="w-full mb-2">
        <app-input
          appScrollToView
          [scrollElementId]="elementId"
          #passwordInput
          [label]="'Password'"
          [floatingLabel]="'outline'"
          [type]="'password'"
          [required]="true"
          [defaultBorderColor]="'border-brandSkyBlue'"
          formControlName="password"
          [enterKeyHint]="'go'"
          [showPasswordToggle]="true"
          appEnterNext
          (keyup.enter)="signIn()"
          [tabindex]="2"
          [invalid]="signInForm.controls['password'].invalid && signInForm.controls['password'].touched"
          [validationText]="LANGUAGE_DATA?.CAPTAIN_TALK.LOGIN.VALID_PASSWORD | translate"
        ></app-input>
      </div>

      <div class="flex justify-between items-center">
        <div class="flex justify-end ml-2">
          <label class="text-deepSeablue text-base font-semibold" (click)="openForgotPass()">{{
            LANGUAGE_DATA?.START_PAGE.SIGN_IN_PAGE.FORGOT_PASSWORD | translate
          }}</label>
        </div>
        <div class="flex justify-end">
          <label class="text-grey-500 text-sm flex items-center">
            <ion-item lines="none">
              <ion-checkbox slot="start" [checked]="rememberMe" (ionChange)="rememberMe = !rememberMe"></ion-checkbox>
              <ion-label>{{ LANGUAGE_DATA?.START_PAGE.SIGN_IN_PAGE.REMEMBER_ME | translate }}</ion-label>
            </ion-item>
          </label>
        </div>
      </div>
      <div class="space-y-6 mt-2">
        <app-button
          id="regButton"
          (buttonClick)="signIn()"
          [disabled]="!signInForm.valid"
          [variant]="'secondary'"
          [label]="LANGUAGE_DATA?.CAPTAIN_TALK.LOGIN.SIGN_IN_BUTTON_TEXT | translate"
          [loading]="isLoading"
          [icon]="{ name: 'chevron-right', position: 'right' }"
        ></app-button>
      </div>
    </form>

    <div class="flex flex-col justify-center px-6 pt-6 pb-2 items-center gap-[10px]" *ngIf="!isTechnician">
      <label class="text-gray-600 font-light text-[14px]" style="font-weight: 600"
        >{{ LANGUAGE_DATA?.START_PAGE.SIGN_IN_PAGE.DONT_HAVE_ACCOUNT | translate }}
        <a (click)="openRegister()" class="text-deepSeablue">{{
          LANGUAGE_DATA?.START_PAGE.SIGN_IN_PAGE.SIGN_UP | translate
        }}</a></label
      >
    </div>

    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <p class="m-auto w-[80%] text-sm">
          {{ LANGUAGE_DATA?.START_PAGE.SIGN_IN_PAGE.SIGN_IN_NOTICE | translate }}
          <a class="font-bold text-deepSeablue" (click)="openPrivacy()">{{
            LANGUAGE_DATA?.START_PAGE.SIGN_UP_PAGE.PRIVACY_POLICY | translate
          }}</a>
          {{ LANGUAGE_DATA?.START_PAGE.SIGN_UP_PAGE.AND | translate }}
          <a class="font-bold text-deepSeablue" (click)="openTerms()">{{
            LANGUAGE_DATA?.START_PAGE.SIGN_UP_PAGE.TERMS_SERVICE | translate
          }}</a
          >.
        </p>
      </ion-col>
    </ion-row>
  </div>
</app-base-one-layout>
