<app-base-two-layout
  class="h-full"
  (goBack)="close()"
  [headerText]="
    (LANGUAGE_DATA?.ESTIMATE.TITLE | translate) +
    (this.estimateInfo?.estimate?.id ? ' (#' + this.estimateInfo?.estimate?.id + ')' : '')
  "
  [hasTextArea]="false"
  [hasGreeting]="false"
>
  <ion-spinner
    name="dots"
    *ngIf="isLoading"
    class="absolute left-0 right-0 top-0 bottom-0 m-auto scale-[2.5]"
  ></ion-spinner>

  <div class="flex flex-col gap-4 w-full" *ngIf="estimateDisplayData">
    <app-financial-document-header [data]="documentHeaderInfo"></app-financial-document-header>

    <div *ngIf="isPending" class="flex flex-row items-center gap-2 px-2">
      <app-button
        class="w-full"
        label="Reject"
        variant="danger-outline"
        size="small"
        (buttonClick)="showRejectAlert()"
        [disabled]="isAccepting || isRejecting"
        [loading]="isRejecting"
      >
      </app-button>
      <app-button
        class="w-full"
        label="Approve"
        variant="secondary"
        size="small"
        (buttonClick)="showAcceptAlert()"
        [disabled]="isAccepting || isRejecting"
        [loading]="isAccepting"
      ></app-button>
    </div>

    <div
      class="flex flex-row justify-start items-start gap-2 p-4"
      *ngIf="(isApproved || isRejected) && estimateActionMeta"
    >
      <div class="{{ estimateActionMeta?.class }} flex rounded-full w-6 h-6 items-center justify-center">
        <span class="bbicon-{{ estimateActionMeta?.icon }} text-xl"></span>
      </div>
      <div>
        <div>
          <strong>{{ estimateActionMeta?.label }}:</strong>
          {{ estimateActionMeta?.actionDate | userDate : "MMM dd, YYYY" }}
        </div>
        by {{ estimateInfo?.yachtOwner?.firstName }} {{ estimateInfo?.yachtOwner?.lastName }}
      </div>
    </div>

    <!-- Estimate Description -->
    <div class="rounded-xl p-4 bg-dsGray05 mx-2" *ngIf="estimateInfo?.estimate?.description">
      <h2 class="m-0 text-lg font-semibold text-gray-700">
        {{ LANGUAGE_DATA.OPEN_ORDERS.SERVICE_LIST.DESCRIPTION | translate }}
      </h2>
      <p class="text-base text-black font-normal">
        {{ estimateInfo?.estimate?.description }}
      </p>
    </div>

    <div>
      <app-financial-document-items [data]="lineItems"></app-financial-document-items>
    </div>

    <div class="rounded-lg flex flex-col gap-2 px-4 py-2 my-4 mx-4 text-black">
      <div class="text-right flex flex-row gap-2 justify-end items-center">
        <div class="font-semibold text-sm">{{ LANGUAGE_DATA.INVOICES.TAX | translate }}:</div>
        <div *ngIf="estimateInfo?.total !== 0" class="font-bold text-deepSeablue text-base">
          {{ estimateInfo.tax | currency : "USD" : "symbol" : "1.2-2" }}
        </div>
      </div>

      <div class="text-right flex flex-row gap-2 justify-end items-center">
        <div class="font-semibold text-sm">{{ LANGUAGE_DATA.INVOICES.DISCOUNT | translate }}:</div>
        <div *ngIf="estimateDisplayData">
          <span
            *ngIf="estimateDisplayData.discount !== null && estimateDisplayData.discount > 0"
            class="font-bold text-deepSeablue text-base"
          >
            {{ estimateDisplayData.discount }}%
          </span>
          <span
            *ngIf="estimateDisplayData.discount !== null && estimateDisplayData.discount === 0"
            class="font-bold text-deepSeablue text-base"
          >
            {{ estimateDisplayData.discount }}
          </span>
          <span *ngIf="estimateDisplayData.discount === null" class="font-bold text-deepSeablue text-base">
            {{ 0 }}
          </span>
        </div>
      </div>
      <div class="text-right flex flex-row gap-2 justify-end items-center">
        <div class="font-semibold text-sm">{{ LANGUAGE_DATA.INVOICES.BALANCE_DUE | translate }}:</div>
        <div *ngIf="estimateDisplayData">
          <span
            *ngIf="estimateDisplayData.balanceDue !== null && estimateDisplayData.balanceDue > 0"
            class="font-bold text-deepSeablue text-base"
          >
            {{ estimateDisplayData.balanceDue | currency : "USD" : "symbol" : "1.2-2" }}
          </span>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-2 my-4 mx-4 text-black" *ngIf="estimateInfo?.attachments.length > 0">
      <h2 class="text-sm font-bold mb-2">
        {{ LANGUAGE_DATA.INVOICES.ATTACHMENT | translate }}
      </h2>

      <div
        *ngFor="let item of estimateInfo?.attachments"
        class="p-2 rounded-lg bg-[#F3F3F3] flex gap-2 items-center w-[100%]"
        (click)="getDocumentLink(item)"
      >
        <i class="bbicon-paper-clip text-2xl text-[#898989]"></i>
        <div class="flex font-semibold w-[90%]">
          <span class="inline-block text-xs line-clamp-1">{{ item?.name }}</span>
        </div>

        <button class=" bg-orange-400 rounded-full h-6 w-6"><i class="bbicon-arrow-down text-sm text-white"></i></button>
      </div>
    </div>
  </div>

  <div class="flex flex-col items-center justify-center h-full" *ngIf="!estimateDisplayData && !isLoading">
    <div class="flex flex-col items-center justify-center">
      <img src="assets/icons/service-placeholder.svg" class="w-50" alt="no data" />
      <label class="block text-lg text-black py-3">
        {{ LANGUAGE_DATA.INVOICES.NO_INVOICES_YET | translate }}
      </label>
      <p class="text-center text-gray-400">
        {{ LANGUAGE_DATA.INVOICES.NO_INVOICES_YET_TEXT | translate }}
      </p>
    </div>
  </div>

  <ion-toolbar footer *ngIf="estimateDisplayData?.vendorId">
    <div class="pt-2">
      <app-button
        [label]="LANGUAGE_DATA?.OPEN_ORDERS.MESSAGE_VENDOR | translate"
        [icon]="{ name: 'message', position: 'right' }"
        [variant]="'tertiary'"
        (buttonClick)="openChat(estimateDisplayData.vendorId)"
      ></app-button>
    </div>
  </ion-toolbar>
</app-base-two-layout>
