import { Injectable } from "@angular/core";
import { LoadingController, ModalController } from "@ionic/angular";
import {
  AiAlertsComponent,
  AiAlertsProps,
} from "../components/ai-alerts/ai-alerts.component";
import { BehaviorSubject, Observable } from "rxjs";

const STORAGE_KEY = "captainAiData";
const INTENDED_ACTION_KEY = "intendedAction";
@Injectable({
  providedIn: "root",
})
export class UtilityService {

  private serviceOrderCreateFromServiceProviderSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public serviceOrderCreateFromServiceProvider$: Observable<any> = this.serviceOrderCreateFromServiceProviderSubject.asObservable();

  constructor(
    private modalCtrl: ModalController,
    private loader: LoadingController
  ) {}

  async showAlert(props: AiAlertsProps, callback?: Function): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: AiAlertsComponent,
      cssClass: "ai-alerts-modal",
      backdropDismiss: false,
      componentProps: { action: props, callBackFn: callback },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    console.log(data);
    return data;
  }

  async showLoader() {
    const loading = await this.loader.create({
      message: "",
      spinner: "dots",
      cssClass: "custom-loader-class",
      showBackdrop: false,
    });
    await loading.present();
  }

  async hideLoader() {
    await this.loader.dismiss();
  }

  storeData(data) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  }

  getSingleData(key: string) {
    const data = JSON.parse(localStorage.getItem(STORAGE_KEY));
    return data[key];
  }

  setServiceProviderPayload(data: any) {
    this.serviceOrderCreateFromServiceProviderSubject.next(data);
  }

  getServiceProviderPayload() {
    return this.serviceOrderCreateFromServiceProviderSubject.value;
  }

  getAllData() {
    const data = JSON.parse(localStorage.getItem(STORAGE_KEY));
    return data;
  }

  removeData() {
    localStorage.removeItem(STORAGE_KEY);
  }

  getDuration = (durationString: any) => {
    const duration = { hours: "0", minutes: "0", seconds: "0" };
    const durationParts = durationString
      .replace("PT", "")
      .replace("H", ":")
      .replace("M", ":")
      .replace("S", "")
      .split(":");

    if (durationParts.length === 3) {
      duration["hours"] = durationParts[0].padStart(2, "0");
      duration["minutes"] = durationParts[1].padStart(2, "0");
      duration["seconds"] = durationParts[2].padStart(2, "0");
    }

    if (durationParts.length === 2) {
      duration["minutes"] = durationParts[0].padStart(2, "0");
      duration["seconds"] = durationParts[1].padStart(2, "0");
    }

    if (durationParts.length === 1) {
      duration["seconds"] = durationParts[0].padStart(2, "0");
    }

    return `${duration.hours}:${duration.minutes}:${duration.seconds}`;
  };

  // Updated method to store the intended action
  storeIntendedAction(action: string, payload?: string) {
    localStorage.setItem(INTENDED_ACTION_KEY, action);
    if(payload){
      localStorage.setItem("intendedActionPayload", payload);
    }
  }

  removeServiceProviderPayload() {
    localStorage.removeItem("intendedActionPayload");
  }


  // Updated method to retrieve and clear the intended action
  getAndClearIntendedAction(): string | null {
    const action = localStorage.getItem(INTENDED_ACTION_KEY);
    localStorage.removeItem(INTENDED_ACTION_KEY);
    return action;
  }
}
