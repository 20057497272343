import { BoatManagementService } from "./../../../yacht-owner/boat-manager/boat-management.service";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { CameraService } from "../services/camera.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { v4 as uuidv4 } from "uuid";
import { Subject, Subscription } from "rxjs";
import { ImageCropComponent } from "../image-crop/image-crop.component";
import { IFilesData, IImageBrowserConfig, ILightboxImage } from "../services/image-browser.service";
import { BaseTwoLayoutComponent } from "src/app/captain-talk/components/base-two-layout/base-two-layout.component";
import { AlertController } from "@ionic/angular";

@Component({
  selector: "app-image-browser",
  templateUrl: "./image-browser.component.html",
  styleUrls: ["./image-browser.component.css"],
})
export class ImageBrowserComponent implements OnInit, OnDestroy {
  @Input() protected config: IImageBrowserConfig = {
    enableCrop: true,
    enableRemove: true,
    enableAdd: true,
    enableSetPrimary: true,
  };
  @Input() imageList: ILightboxImage[] = [];

  @ViewChild("frame") frame: BaseTwoLayoutComponent;

  public removeImage$: Subject<{
    image: any;
    index: number;
  }> = new Subject<{
    image: any;
    index: number;
  }>();

  public setPrimary$: Subject<{
    image: any;
    index: number;
  }> = new Subject<{
    image: any;
    index: number;
  }>();
  public addImage$: Subject<IFilesData[]> = new Subject<IFilesData[]>();
  public loadMore$: Subject<any> = new Subject<any>();
  public imagesUpdated$: Subject<any> = new Subject<any>();

  public imagesRemoved$: Subject<ILightboxImage[]> = new Subject<ILightboxImage[]>();
  public imagesAdded$: Subject<string[]> = new Subject<string[]>();
  public primaryImageChange$: Subject<ILightboxImage> = new Subject<ILightboxImage>();

  private subscriptions: Subscription = new Subscription();

  @Input() private uploadStatus = false;
  @Input() protected headerTitle: string;

  protected LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

  private cookieValue: string;

  constructor(
    private cameraService: CameraService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {
    this.cookieValue = localStorage.getItem("lang");
  }

  ngOnInit(): void {
    const primaryIndex = this.imageList.findIndex((image: any) => image.isPrimary);

    if (primaryIndex !== -1) {
      const primaryImage = this.imageList.splice(primaryIndex, 1)[0];
      this.imageList.unshift(primaryImage);
    }

    const imagesUpdated = this.imagesUpdated$.subscribe((images) => {
      if (!images || images.length === 0) return;
      this.imageList = images;
      this.moveImageToFront(this.imageList.findIndex((image: any) => image?.isPrimary));
    });

    this.subscriptions.add(imagesUpdated);

    const imagesRemovedSubscription = this.imagesRemoved$.subscribe((images) => {
      if (!images || images.length === 0) return;
      if (this.imageList.length === 0) {
        this.imageList = [];
      }
      this.imageList = this.imageList.filter((image) => !images.includes(image));
    });

    this.subscriptions.add(imagesRemovedSubscription);

    const imagesAddedSubscription = this.imagesAdded$.subscribe((clientIds) => {
      if (!clientIds || clientIds.length === 0) return;
      if (this.imageList.length === 0) {
        this.imageList = [];
      }
      clientIds.forEach((id) => {
        const index = this.imageList.findIndex((image) => image?.clientId === id);
        if (index !== -1) {
          this.imageList[index].uploaded = true;
        }
      });
    });

    this.subscriptions.add(imagesAddedSubscription);

    const primaryImageChangeSubscription = this.primaryImageChange$.subscribe((image) => {
      const index = this.imageList.findIndex((img) => img.clientId === image.clientId);
      if (index !== -1) {
        this.imageList[index] = { ...image, isPrimary: true };
      }
    });

    this.subscriptions.add(primaryImageChangeSubscription);
  }

  protected async onRemoveImage(image: ILightboxImage, index: number) {
    const alert = await this.alertCtrl.create({
      header: "Remove Image",
      message: "Are you sure you want to remove this image?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Remove",
          handler: async () => {
            this.removeImage$.next({ image, index });
          },
        },
      ],
    });

    alert.present();
  }

  protected onSetPrimary(image: ILightboxImage, index: number) {
    if (image.isPrimary) return;

    this.imageList.forEach((img) => {
      img.isPrimary = img === image;
    });

    this.moveImageToFront(index);

    this.frame?.content?.scrollToTop(400);

    this.setPrimary$.next({
      image,
      index,
    });
  }

  private moveImageToFront(index: number) {
    const image = this.imageList[index];
    this.imageList.splice(index, 1);
    this.imageList.unshift(image);
  }

  protected onImageLoadMore() {
    this.loadMore$.next(null);
  }

  protected async openCamera() {
    try {
      const result = await this.cameraService.takePicture();

      const filesData = [];
      const hasValues = this.checkFileAndImage(result);

      if (!hasValues) {
        return;
      }

      filesData.push(result);

      if (this.config.enableCrop) {
        this.openImageCropper(filesData);
      } else {
        this.onFileChange(filesData);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  protected async openGallery() {
    try {
      const result = await this.cameraService.pickImage();
      if (this.config.enableCrop) {
        await this.openImageCropper(result);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  private async openImageCropper(images) {
    const modal = await this.modalCtrl.create({
      component: ImageCropComponent,
      componentProps: {
        data: images,
      },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data) {
      this.onFileChange(data);
    }
  }

  private onFileChange(filesData: IFilesData[]) {
    for (const file of filesData) {
      const newImage = {
        file: file.file,
        uploaded: this.uploadStatus,
        delete: false,
        image: file.image,
        imageId: null,
        clientId: file.clientId ? file.clientId : uuidv4(),
        isPrimary: false,
        user: file?.user,
      };
      this.imageList = [newImage, ...this.imageList];
      console.log("imageList", this.imageList);
      if (this.imageList.length === 1) {
        this.imageList[0].isPrimary = true;
      }
      this.moveImageToFront(this.imageList.findIndex((image: any) => image?.isPrimary));
    }

    this.addImage$.next(filesData);

    this.frame?.content?.scrollToTop(400);
  }

  private checkFileAndImage(item: IFilesData): boolean {
    return !!(item.file && item.image);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected close() {
    return this.modalCtrl.dismiss(this.imageList);
  }
}
