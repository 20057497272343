import { Component, Input } from "@angular/core";
import { NativeSettingsService } from "../native-settings.service";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-permission-denied",
    templateUrl: "./permission-denied.component.html",
    styleUrls: ["./permission-denied.component.css"],
})
export class PermissionDeniedComponent {
    @Input() header: string = "";
    @Input() text: string = "";
    @Input() type: string = "";

    constructor(
        private nativeSettings: NativeSettingsService,
        public modalCtrl: ModalController
    ) {}

    closeModal() {
        this.modalCtrl.dismiss();
    }
    async openSettings() {
        this.modalCtrl.dismiss();
        if (this.type == "location") {
            this.nativeSettings.openLocationSettings();
        } else {
            this.nativeSettings.openSettings();
        }
    }
}
