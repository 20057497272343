import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
    SimpleChanges,
    OnChanges,
} from "@angular/core";

@Component({
    selector: "app-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.css"],
})
export class ButtonComponent implements OnInit, OnChanges {
    @Input() label = "Button";
    @Input() type = "button";
    @Input() variant:
        | "primary"
        | "secondary"
        | "tertiary"
        | "disabled"
        | "cancel"
        | "danger"
        | "danger-outline"
        | "transparent"
        | string = "primary";
    @Input() disabled: "" | true | false = false;
    @Input() size: "small" | "normal" | "large" = "normal";
    @Input() loading = false;
    @Input() hideTextOnLoading = false;

    @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>(); // Output event

    @Input() icon: Partial<{
        position: string;
        name: string;
        size: string;
        color: string;
    }> = {};

    staticClasses =
        "flex items-center justify-center rounded-full shadow-sm font-semibold gap-2 w-full relative ripple-parent overflow-hidden ion-activatable";
    dynamicClasses = "";

    iconSizeMap = {
        small: "lg", // Adjust the icon sizes according to your needs
        normal: "xl",
        large: "2xl",
    };

    sizeClassMap = {
        small: "px-3 py-2 text-base",
        normal: "px-4 py-3.5 text-lg",
        large: "px-6 py-3 text-xl",
    };

    defaultIcon = {
        position: "left",
        name: "",
        size: this.iconSizeMap[this.size], // this refers to the size of the button
        color: "white",
    };

    colorSchemeMap = {
        primary: "bg-brandDeepSeaBlue text-white border border-transparent ",
        "primary-outline":
        "bg-white border-2 border-solid border-brandDeepSeaBlue text-brandDeepSeaBlue ",
        secondary: "bg-brandSunsetOrange text-white border border-transparent ",
        disabled: "bg-buttonDisabledColor border border-transparent ",
        tertiary:
            "bg-white border-2 border-solid border-brandDeepSeaBlue text-brandDeepSeaBlue ",
        cancel: "bg-white border-2 border-solid border-brandDeepSeaBlue text-brandDeepSeaBlue ",
        danger: "bg-dsError text-white border border-transparent ",
        "danger-outline":
            "bg-white border-2 border-solid border-dsError text-dsError ",
    };

    get buttonClasses() {
        const disabledClasses = this.disabled
            ? "bg-buttonDisabledColor cursor-not-allowed"
            : "";
        return `${this.staticClasses} ${
            this.dynamicClasses
        } ${disabledClasses} ${this.colorSchemeMap[this.variant]}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["icon"]) {
            this.icon = { ...this.defaultIcon, ...this.icon };
        }
        if (changes["disabled"]) {
            this.disabled = changes["disabled"].currentValue;
        }
        if (changes["variant"]) {
            this.variant = changes["variant"].currentValue;
        }
        if (changes["size"]) {
            this.defaultIcon.size =
                this.iconSizeMap[changes["size"].currentValue];
        }
        if (changes["loading"]) {
            this.loading = changes["loading"].currentValue;
        }
    }

    ngOnInit(): void {
        this.dynamicClasses =
            this.sizeClassMap[this.size] || this.sizeClassMap["normal"];
        this.defaultIcon.size = this.iconSizeMap[this.size];
    }

    onButtonClick(): void {
        this.buttonClick.emit();
    }
}
