// proximity alert component

import { Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { GpsLocationService } from "src/app/technician/gps-location.service";
import { PriorityEnums } from "src/app/utilities/core-enums";
import { ToastService } from "../message/toast.service-impl";
import { LoaderService } from "../loader/loader.service";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";

@Component({
  selector: "app-proximity-alert",
  templateUrl: "./proximity-alert.component.html",
  styleUrls: ["./proximity-alert.component.css"],
})
export class ProximityAlertComponent implements OnInit {
  yachtAlertData: any;
  selectYacht: any;
  selectedYacht = false;
  selectedService: any;
  prioritiesEnums = PriorityEnums;
  serviceItems: any;
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  cookieValue: any;
  isLoading = false;
  isOutOfRange = false;

  constructor(
    private locationService: GpsLocationService,
    private toastService: ToastService,
    private loader: LoaderService,
    private router: Router,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {
    this.locationService.onRecentlyOutOfGeofence.subscribe((data) => {
      this.isOutOfRange = data;
    });
  }

  ngOnInit(): void {
    this.getSheduleOrder();
    this.cookieValue = localStorage.getItem("lang") || "en";
    this.translate.setDefaultLang(this.cookieValue);
  }

  getSheduleOrder() {
    this.locationService.proximityResults$.subscribe((orders: any) => {
      if (orders) {
        this.yachtAlertData = orders.filter((x) => x.confirmedToday === false);
        console.log("this.yachtAlertData=====>", this.yachtAlertData);
        this.selectYacht = this.yachtAlertData[0];
        this.serviceItems = this.selectYacht?.serviceOrderSchedules.filter(
          (item: any) => !item?.confirmedToday
        );

        if (!this.serviceItems) {
          this.serviceItems = [];
        }

        this.selectedService = this.serviceItems[0];
      }
    });
  }

  onSelectYacht(yacht: any) {
    this.selectYacht = yacht;
    this.serviceItems = this.selectYacht.serviceOrderSchedules.filter(
      (item: any) => !item?.confirmedToday
    );
    this.selectedService = this.serviceItems[0];
  }

  onSelectService(service: any) {
    this.selectedService = service;
  }

  // Call this method when the proximity modal is dismissed
  setLocalStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Call this method when the proximity modal is dismissed
  onProximityDismissed(itemId: string) {
    this.locationService.isOnProximityAlertOpen.next(true);
    this.locationService.isOnProximityRecentlyDismissed.next({
      status: true,
      dismissedDateTime: Date.now(),
    });

    this.setLocalStorage(`dismissed_${itemId}`, Date.now());
  }

  onConfirmArrival() {
    const data = null;
    this.isLoading = true;
    this.locationService
      .confirmArrival(this.selectedService.id, data)
      .subscribe(
        (data: any) => {
          this.isLoading = false;
          if (data.success) {
            this.modalCtrl.dismiss(true);

            this.toastService.showToast(
              "Arrival Successfully Confirmed.",
              3000,
              "bottom",
              null,
              "success"
            );
            this.openServiceDetails();
          } else {
            this.loader.close();
            this.toastService.showToast(
              data.error.message,
              3000,
              "bottom",
              null,
              "danger"
            );
          }
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  close() {
    localStorage.setItem("onProximityAlertOpen", "false");
    this.locationService.isOnProximityAlertOpen.next(false);
    if (this.selectYacht?.yachtId) {
      this.onProximityDismissed(this.selectYacht.yachtId);
    }

    this.modalCtrl.dismiss();
  }

  openServiceDetails() {
    const navigationExtras: NavigationExtras = {
      state: {
        orderDetails: this.selectedService,
      },
      queryParams: { id: this.selectedService.id },
    };
    this.router
      .navigate(["/technician/service-order-detail"], navigationExtras)
      .then(async () => {
        console.log("Navigation succeeded!");

        this.locationService.initiateGetAllYacht();
      });
  }
}
