<div
    *ngIf="showLoader"
    class="loader-image fixed top-0 w-screen h-screen bg-white flex flex-col items-center justify-center z-[99999]"
>
    <ion-progress-bar
        type="indeterminate"
        class="flex-none"
    ></ion-progress-bar>
    <div
        class="w-full h-full flex-col flex items-center justify-center gap-8 flex-1"
    >
        <img
            src="assets/images/loading-boat.gif"
            class="w-28"
        />
        <p
            class="text-center text-base font-semibold text-dsGray40 w-3/4"
            *ngIf="isSlowInternet"
        >
            Looks like it's taking longer than usual. Please wait...
        </p>
    </div>
</div>
