<div class="bb-textarea">
    <div
        class="textarea-container"
        [class.focused]="isFocused"
    >
        <div
            class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center rounded-lg z-10"
            *ngIf="isProcessing"
        >
            <div
                class="flex items-center gap-2 rounded-full px-3 bg-white drop-shadow-md py-1"
            >
                <span class="bbicon-loading text-dsGray70 animate-spin"></span>
                <span class="text-dsGray70 font-medium text-sm">{{
                    LANGUAGE_DATA?.CAPTAIN_AI.PROCESSING | translate
                }}</span>
            </div>
        </div>
        <label
            *ngIf="label"
            [attr.for]="id"
            class="bb-floating-label z-10"
            [class.float]="isFocused"
            (click)="onLabelClick()"
            >{{ label }}</label
        >
        <textarea
            #textarea
            (input)="onInput()"
            (focus)="onFocus()"
            (blur)="onBlur()"
            [attr.rows]="rows"
            [attr.placeholder]="
                isFocused && enablePlaceholder ? placeholder : ''
            "
            [attr.id]="id"
            [attr.enterKeyHint]="enterKeyHint"
            [attr.tabindex]="tabindex"
            [formControl]="control"
            class="p-1 pr-10"
        ></textarea>
        <button
            *ngIf="showCancelButton"
            class="p-2 bb-textarea-icon2"
            (click)="handleCancelClick()"
        >
            <span class="bbicon-close text-brandNauticalRed text-2xl"></span>
        </button>
        <button
            class="p-2 bb-textarea-icon"
            (click)="handleIconClick()"
        >
            <span
                class="bbicon-{{ icon.name }} text-{{ icon.color }} text-{{
                    icon.size
                }} "
            ></span>
        </button>
    </div>
    <div
        *ngIf="control.invalid && control.touched"
        class="bb-formcontrol-error"
    >
        {{ errorMessage }}
    </div>
    <div class="w-full grid grid-cols-2 gap-2 items-center justify-start mt-2" *ngIf="hasCaptainAi" >
        <button
            class="bg-deepSeablue rounded-full text-white font-semibold text-lg px-6 py-1.5 flex items-center justify-center text-center gap-2"
            (click)="reviseDescription()"
            *ngIf="showRevise"
        >
            <img
                src="assets/icons/ai-icon.svg"
                class="h-5"
            />
            {{ LANGUAGE_DATA?.CAPTAIN_AI.REVISE | translate | titlecase }}
        </button>
        <button
            class="bg-deepSeablue rounded-full text-white font-semibold text-lg px-6 py-1.5 flex items-center justify-center text-center gap-2"
            (click)="openCaptainAITalk()"
            *ngIf="showVoice"
        >
            <img
                src="assets/icons/voice-icon.svg"
                class="h-5"
            />
            {{ LANGUAGE_DATA?.CAPTAIN_AI.VOICE | translate | titlecase }}
        </button>
    </div>
</div>
