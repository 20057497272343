<!-- <app-modal-header
    (closeModalEvent)="dismiss()"
    [title]="title"
></app-modal-header> -->

<app-ai-header
    [headerText]="title"
    (goBack)="dismiss()"
    [enableRequestService]="false"></app-ai-header>

<div class="p-4 space-y-3">
    <p
        [innerHTML]="message"
        class="px-6 text-center"
    ></p>
    <div
        class="gap-2"
        [class]="
            actions.length > 1 ? 'grid grid-cols-2' : 'flex justify-center'
        "
    >
        <app-button
            *ngFor="let action of actions"
            (buttonClick)="actionClicked(action)"
            [label]="action.label"
            [variant]="action.variant || 'primary'"
        ></app-button>
    </div>
</div>
