import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { BaseService } from "../../base.service";
import { ModalController } from "@ionic/angular";
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, LoadedImage } from "ngx-image-cropper";
import { DomSanitizer, SafeStyle, SafeUrl } from "@angular/platform-browser";
import { LANGUAGE_DATA_PROVIDER } from "../../../utilities/language-data";
import Swiper, { SwiperOptions } from "swiper";

@Component({
  selector: "app-image-crop",
  templateUrl: "./image-crop.component.html",
  styleUrls: ["./image-crop.component.css"],
})
export class ImageCropComponent implements OnInit {
  @Input() data: any;

  selectedImage;
  selectedImageIndex: number | undefined;

  originalFile;
  croppedImage: any;
  containWithinAspectRatio = false;
  aspectRatio = 1;
  canvasRotation = 0;
  transform: ImageTransform = {
    translateUnit: "px",
  };
  showCropper = false;
  hidden = false;
  imageChangedEvent: any = "";
  imageFile: any;
  imageUrl: SafeUrl;
  isSaveClicked = false;
  swiper: Swiper | undefined;

  @ViewChild("cropper") cropper: ImageCropperComponent;
  @ViewChild("squareImageInput") squareImageInput: ElementRef;
  @ViewChild("logoCropper") logoCropper: ElementRef;

  swiperConfig: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 6,
  };

  constructor(private modalCtrl: ModalController, public baseService: BaseService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.selected(this.data[0], 0);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.imageFile = event.blob;
  }

  imageLoaded(image: LoadedImage) {
    this.showCropper = true;
  }

  removePhoto(image: any, index: number) {
    this.data.splice(index, 1);

    if (this.data.length > 0) {
      const nextIndex = index < this.data.length - 1 ? index + 1 : 0;
      this.selected(this.data[nextIndex], nextIndex);
    } else {
      this.close();
    }
  }

  async saveImage(): Promise<SafeStyle> {
    const croppedImage = this.imageFile;

    if (croppedImage) {
      const base64Data = await this.blobToBase64(croppedImage);
      this.data[this.selectedImageIndex].image = `data:image/jpeg;base64,${base64Data}`;
      this.data[this.selectedImageIndex].resetImage = this.originalFile;
      this.isSaveClicked = true;
      const fileName = this.baseService.generateUniqueFileName(this.imageFile.type);
      this.data[this.selectedImageIndex].file = this.baseService.blobToFile(this.imageFile, fileName);

      this.imageChangedEvent = `data:image/jpeg;base64,${base64Data}`;
    }
    return null;
  }

  returnSavedImages() {
    this.modalCtrl.dismiss(this.data);
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.toString().split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

  cancelImageCropper() {
    if (this.data[this.selectedImageIndex].resetImage) {
      this.data[this.selectedImageIndex].image = this.data[this.selectedImageIndex].resetImage;
      this.imageChangedEvent = this.data[this.selectedImageIndex].resetImage;
    }
  }

  close() {
    return this.modalCtrl.dismiss();
  }

  selected(image, index: number) {
    this.originalFile = image?.image;
    this.selectedImage = image;
    this.selectedImageIndex = index;
    this.imageChangedEvent = image?.image;
  }

  protected readonly LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
}
