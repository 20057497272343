<div class="relative">
    <button
        class="absolute top-3 bg-white right-3 flex items-center justify-center rounded-full w-10 h-10 outline-none ring-0 text-black hover:bg-brandSunsetOrange hover:text-white transition duration-300 z-10"
        (click)="close()"
    >
        <span class="bbicon-close text-xl"></span>
    </button>
    <div class="h-auto">
        <video
            #target
            class="video-js w-full"
            controls
            muted
            playsinline
            preload="none"
        ></video>
    </div>
</div>

<!-- <ion-content>
    <div id="fullscreen"></div>
</ion-content> -->
