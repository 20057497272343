import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuid } from "uuid";
import { BaseService } from "src/app/shared/base.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { TranslateService } from "@ngx-translate/core";
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { ConversationComponent } from '../conversation/conversation.component';
import { ModalController } from '@ionic/angular';
import { BasicAlertComponent } from 'src/app/shared/basic-alert/basic-alert.component';
import {Router} from "@angular/router";
@Component({
  selector: 'app-ai-header',
  templateUrl: './ai-header.component.html',
  styleUrls: ['./ai-header.component.css']
})
export class AiHeaderComponent implements OnInit {

  @Input() hasBackButton = true;
  @Input() headerText = "";
  @Input() videoUrl = "";
  @Input() videoId = "";
  @Input() toolTipText;
  @Input() enableRequestService = true;
  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Input() triggerId: string = uuid();
  isLoggedIn = false;
  isMobile = true;
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  constructor(private modalCtrl:ModalController, private baseService:BaseService,
              private router: Router) { }

  ngOnInit(): void {
    this.isMobile = Capacitor.isNativePlatform();

    const user = this.baseService.getSession(this.baseService.credKey);
    if (user) {
      this.isLoggedIn = true;
    }
  }


  async openHelpModal() {
    const modal = await this.modalCtrl.create({
      component: BasicAlertComponent,
      cssClass: "ai-alerts-modal",
      backdropDismiss: false,
      componentProps: {
        title: "Help?",
        message: this.toolTipText || "",
        videoId: this.videoId,
      },
    });

    await modal.present();
  }


  onButtonClick() {
    if(Capacitor.isNativePlatform()){
      Keyboard.hide();
    }
    this.goBack.emit();
   
  }
  //
  // async openAIConversation() {
  //   const modal = await this.modalCtrl.create({
  //     component: ConversationComponent,
  //     componentProps: {
  //       firstPrompt: {
  //         prompt: "",
  //         context: null,
  //       },
  //     },
  //   });
  //   await modal.present();
  //
  //   const { data } = await modal.onDidDismiss();
  // }


  navigateToCaptainAiDashboard() {
    this.router.navigate(["/captain-talk/ask-captain-ai"]);
  }
}
