import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { BaseService } from "src/app/shared/base.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { environment } from "src/environments/environment";
import { LoginComponent } from "../../../authentication/login/login.component";
import { UtilityService } from "../../services/utility.service";

export interface AiAlertsProps {
  positive_feedback?: boolean;
  negative_feedback?: boolean;
  service_request?: boolean;
  sign_up?: boolean;
  custom_feedback?: boolean;
  job_done_feedback?: boolean;
  last_service_date?: boolean;
  sign_in?: boolean;
  skill_level?: boolean;
  logout?: boolean;
  ai_info?: boolean;
}
@Component({
  selector: "app-ai-alerts",
  templateUrl: "./ai-alerts.component.html",
  styleUrls: ["./ai-alerts.component.css"],
})
export class AiAlertsComponent implements OnInit {
  @Input() action: AiAlertsProps;
  user = { isLoggedIn: true };
  isLoggedIn = false;
  selectedIssue = null;
  subscription = new Subscription();
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

  callBackFn: Function;
  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private navCtrl: NavController,
    private util: UtilityService,
    private baseService: BaseService
  ) {}

  ngOnInit(): void {
    const user = this.baseService.getSession(this.baseService.credKey);
    if (user) {
      this.isLoggedIn = true;
    }
  }

  onButtonClick() {
    this.util.getAndClearIntendedAction();
    this.util.removeServiceProviderPayload();

    this.modalCtrl.dismiss();
  }

  async gotoDashboard() {
    await this.dismissAllModal();

    this.navCtrl.navigateBack(["/captain-talk/ask-captain-ai"]);
  }

  navigateToBoatSpecifics() {
    this.modalCtrl.dismiss();
    this.router.navigateByUrl("/yacht-owner/boat-profile/boat-specifics/view-boat-specifics/navigate");
  }

  logout() {
    this.modalCtrl.dismiss();
    this.callBackFn();
  }


  openIssueModal() {}
  async dismissAllModal() {
    let topModal = await this.modalCtrl.getTop();

    while (topModal) {
      await this.modalCtrl.dismiss();
      topModal = await this.modalCtrl.getTop();
    }
  }
  clearSelectedIssue() {
    this.selectedIssue = null;
  }

  async gotoSignIn(): Promise<any> {
    this.dismissAllModal();
    const modal = await this.modalCtrl.create({
      component: LoginComponent,
      componentProps: { isModal: true },
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    console.log({ data });
    this.modalCtrl.dismiss(data);
    return data;
  }

  async gotoSignUp() {
    this.dismissAllModal();
    const intendedAction = this.util.getAndClearIntendedAction();
    if (intendedAction) {
      localStorage.setItem("intendedAction", intendedAction);
    }
    this.router.navigate(["/captain-talk/authentication/boat-profile"]);
  }

  submitFeedBack() {
    this.modalCtrl.dismiss();
  }

  async gotoServiceRequest() {
    const user = this.baseService.getSession(this.baseService.credKey);
    if (user && environment.buildType === "boatowner") {
      this.openServiceRequest();
    } else {
      const result = await this.util.showAlert({ sign_up: true });
    }
  }

  async openServiceRequest() {
    console.log("to openServiceRequest");
    await this.dismissAllModal();
    this.router.navigate(["/captain-talk/full-request"]);
  }
}
