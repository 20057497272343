<div
    class="h-full w-full bg-transparent flex justify-center items-center"
    id="container"
>
    <!-- free-trial -->
    <div
        class="bg-white w-[80%] py-6 rounded-2xl relative"
        *ngIf="modalId == 'free-trial'"
    >
        <span
            class="bbicon-close text-xl text-gray-400 absolute right-3 top-3"
            (click)="closeModal()"
        ></span>
        <div class="flex flex-col justify-center items-center">
            <div class="flex justify-center items-center">
                <img
                    src="assets/images/boatbot_logo_solid.svg"
                    alt="logo"
                    class="w-[100px] h-[100px]"
                />
            </div>
            <div class="flex justify-center items-center">
                <h1 class="text-3xl font-bold text-deepSeablue text-center">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.FREE_TRIAL.TITLE
                            | translate
                    }}
                </h1>
            </div>
            <div class="flex justify-center items-center">
                <p class="text-md text-center text-gray-600">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.FREE_TRIAL.TEXT
                            | translate
                    }}
                    <b class="text-deepSeablue">30-Day</b>
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.FREE_TRIAL.TEXT_3
                            | translate
                    }}
                    <br />
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.FREE_TRIAL.TEXT_4
                            | translate
                    }}
                    <b class="text-deepSeablue">BoatBot Pro!</b>
                </p>
            </div>
            <div class="w-[80%] mt-4">
                <app-button
                    [label]="
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.FREE_TRIAL
                            .BUTTON_LABEL | translate
                    "
                    [variant]="'secondary'"
                    (buttonClick)="continue()"
                ></app-button>
            </div>
        </div>
    </div>

    <!-- 1-day-left -->
    <div
        class="bg-white w-[80%] py-6 rounded-2xl relative"
        *ngIf="modalId == '1-day-left'"
    >
        <span
            class="bbicon-close text-xl text-gray-400 absolute right-3 top-3"
            (click)="closeModal()"
        ></span>
        <div class="flex flex-col justify-center items-center">
            <div class="flex justify-center items-center">
                <img
                    src="assets/images/boatbot_logo_solid.svg"
                    alt="logo"
                    class="w-[100px] h-[100px]"
                />
            </div>
            <div class="flex justify-center items-center">
                <h1 class="text-3xl font-bold text-deepSeablue text-center">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TITLE
                            | translate
                    }}
                    <br />
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TITLE_2
                            | translate
                    }}
                </h1>
            </div>
            <div class="flex justify-center items-center">
                <p class="text-md text-center text-gray-600">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.ONE_DAY_LEFT.TEXT
                            | translate
                    }}
                </p>
            </div>
            <div class="w-[80%] mt-4">
                <app-button
                    [label]="
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.ONE_DAY_LEFT
                            .BUTTON_LABEL | translate
                    "
                    [variant]="'secondary'"
                    (buttonClick)="openSubscriptionPackagePage()"
                ></app-button>
            </div>
        </div>
    </div>

    <!-- 2-days-left -->
    <div
        class="bg-white w-[80%] py-6 rounded-2xl relative"
        *ngIf="modalId == '2-days-left'"
    >
        <span
            class="bbicon-close text-xl text-gray-400 absolute right-3 top-3"
            (click)="closeModal()"
        ></span>
        <div class="flex flex-col justify-center items-center">
            <div class="flex justify-center items-center">
                <img
                    src="assets/images/boatbot_logo_solid.svg"
                    alt="logo"
                    class="w-[100px] h-[100px]"
                />
            </div>
            <div class="flex justify-center items-center">
                <h1 class="text-3xl font-bold text-deepSeablue text-center">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TITLE
                            | translate
                    }}
                    <br />
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TITLE_2
                            | translate
                    }}
                </h1>
            </div>
            <div class="flex justify-center items-center">
                <p class="text-md text-center text-gray-600">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.TEXT
                            | translate
                    }}
                    {{ newSubscriptionService.getSubscriptionDaysLeft() }} day
                    <span
                        *ngIf="
                            newSubscriptionService.getSubscriptionDaysLeft() > 1
                        "
                    ></span>
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.TWO_DAYS_LEFT.LEFT
                            | translate
                    }}
                </p>
            </div>
            <div class="w-[80%] mt-4">
                <app-button
                    [label]="
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.ONE_DAY_LEFT
                            .BUTTON_LABEL | translate
                    "
                    [variant]="'secondary'"
                    (buttonClick)="openSubscriptionPackagePage()"
                ></app-button>
            </div>
        </div>
    </div>

    <!-- UPgrade to Pro -->

    <div
        class="bg-white w-[80%] py-6 rounded-2xl relative"
        *ngIf="modalId == 'pro'"
    >
        <span
            class="bbicon-close text-xl text-gray-400 absolute right-3 top-3"
            (click)="closeModal()"
        ></span>
        <div class="flex flex-col justify-center items-center">
            <div class="flex justify-center items-center">
                <img
                    src="assets/images/boatbot_logo_solid.svg"
                    alt="logo"
                    class="w-[100px] h-[100px]"
                />
            </div>
            <div class="flex justify-center items-center">
                <h1 class="text-lg px-4 text-deepSeablue text-center p-0 m-0">
                    Your
                    <b class="font-black text-xl">"FREE" 30-Day Trial</b> of
                    <br />
                    <b class="font-bold text-xl">BoatBot Pro</b> Has Expired.
                </h1>
            </div>
            <div class="flex justify-center items-center mt-3">
                <p
                    class="text-md text-center text-deepSeablue"
                    [innerHtml]="modalHtml"
                ></p>
            </div>
            <div class="w-[80%] mt-4">
                <app-button
                    [label]="'Upgrade to Pro!'"
                    [variant]="'secondary'"
                    (buttonClick)="openSubscriptionPackagePage('upgrade')"
                ></app-button>
                <p
                    class="text-brandDeepSeaBlue text-xl underline underline-offset-2 text-center w-full pt-4"
                    (click)="openSubscriptionPackagePage('new')"
                >
                    Go With BoatBot Basic
                </p>
            </div>
        </div>
    </div>

    <!-- new-registration -->
    <div
        class="bg-white w-[80%] h-[46%] rounded-2xl"
        *ngIf="modalId == 'new-subscription'"
    >
        <span
            class="bbicon-close text-xl text-gray-400 absolute right-3 top-3"
            (click)="closeModal()"
        ></span>
        <div class="flex flex-col justify-center items-center">
            <img
                class="mb-[-80px]"
                src="assets/images/celebration.svg"
                alt=""
            />
            <div class="flex justify-center items-center">
                <img
                    src="assets/images/boatbot_logo_solid.svg"
                    alt="logo"
                    class="w-[100px] h-[100px]"
                />
            </div>
            <div class="flex justify-center items-center">
                <h1 class="text-3xl font-bold text-deepSeablue text-center">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.NEW_REGISTRATION.TITLE
                            | translate
                    }}
                </h1>
            </div>
            <div class="flex justify-center items-center">
                <p class="text-md text-center text-gray-600">
                    {{
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.NEW_REGISTRATION.TEXT
                            | translate
                    }}!
                </p>
            </div>
            <div class="w-[80%] mt-4">
                <app-button
                    [label]="
                        LANGUAGE_DATA?.SUBSCRIPTION_MODAL.NEW_REGISTRATION
                            .BUTTON_LABEL | translate
                    "
                    [variant]="'secondary'"
                    (buttonClick)="continue()"
                ></app-button>
            </div>
        </div>
    </div>
</div>
