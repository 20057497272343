import { AbstractControl, ValidationErrors } from '@angular/forms';
  
export class WhiteSpaceValidator {
    static noWhiteSpace(control: AbstractControl) : ValidationErrors | null {
      
        // if((control.value as string).match(/^\s*$/) && (control.value as string).length > 0 ){
        //     return {noWhiteSpace: true}
        // }
        if(control.value==null ){
            return null
        }
        else{
            if((control.value as string).match(/^\s*$/) ){
                return {noWhiteSpace: true}
            }
            
        }

        return null
    }
}

