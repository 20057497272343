import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { IToastService } from "./toast.service";

@Injectable()
export class ToastService implements IToastService {
  private openToasts: HTMLIonToastElement[] = [];
  constructor(private toastController: ToastController) {}

  languageCookie = localStorage.getItem("lang") || "en";

  async showToast(
    message: string,
    duration?: number,
    position?: "top" | "middle" | "bottom",
    toastId: any = null,
    status: any = null,
    goto: any = null,
    gotoText: any = "Update"
  ) {
    this.openToasts.forEach((toast) => toast.dismiss());
    this.openToasts = [];

    if (!position) position = "top";
    if (!duration) duration = 5000;

    if (toastId !== null) {
      const abort = this.hideToast("check", toastId);
      if (abort) {
        return;
      }
    }

    const toastClass = status ? "toast-" + status : "toast-success";
    // const color = status ? "toast-" + status : "toast-success";

    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      cssClass: `${toastClass} toast-custom-class`,
      // color: color,
      buttons: goto
        ? [
            {
              text: gotoText,
              role: "info",
              handler: () => {
                goto();
              },
            },
          ]
        : [
            {
              side: "end",
              text: this.languageCookie == "en" ? "Close" : "Cerrar",
              role: "cancel",
            },
          ],
    });
    this.openToasts.push(toast);
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }

  showErrorMessage(message: string) {
    this.showToast(message, 5000, "bottom", null, "danger");
  }

  showSuccessMessage(message: string) {
    this.showToast(message, 5000, "bottom", null, "success");
  }

  hideToasts() {
    this.openToasts.forEach(async (toast) => {
      await toast.dismiss();
    });
  }

  hideToast(type: any, val: any) {
    if (type == "check") {
      return localStorage.getItem(val);
    }
    if (type == "add") {
      return localStorage.setItem(val, "hide");
    }
  }
}
