import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { EnvironmentService } from "../environment.service";
import { BehaviorSubject, Observable, retry } from "rxjs";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class YachtMessagesService {
  datePipe = new DatePipe("en-US");
  selectedFriend = new BehaviorSubject<any>(null);
  constructor(
    private httpClient: HttpClient,
    private env: EnvironmentService,
    private baseService: BaseService
  ) {}

  GetUserChatFriendsWithSettings(): Observable<any> {
    // if(!this.baseService.authenticated)
    // return;
    // const params = new HttpParams().set('Hide-Loader', 'true');

    return this.httpClient.get(
      this.env.apiUrl + "services/app/Chat/GetUserChatFriendsWithSettings",
      {
        headers: {
          Authorization: `Bearer ${this.baseService.getToken()}`,
          encType: "multipart/form-data",
        },
      }
    );
  }

  MarkAllUnreadMessagesOfUserAsRead(data: any): any {
    return this.httpClient
      .post(
        this.env.apiUrl + "services/app/Chat/MarkAllUnreadMessagesOfUserAsRead",
        data,
        {
          headers: {
            Authorization: `Bearer ${this.baseService.getToken()}`,
            encType: "multipart/form-data",
          },
        }
      )
      .pipe(retry(3));
  }


  getOlderMessages(data: any, lastMessageId: any): any {
    return this.httpClient.get(
        `${this.env.apiUrl}services/app/Chat/GetUserChatMessages?UserId=` +
            data +
            "&TenantId=1&MinMessageId=" +
            lastMessageId
    );
}

  GetUserChatMessages(data: any): any {
    // const params = new HttpParams().set('Hide-Loader', 'true');

    return this.httpClient.get(
      this.env.apiUrl +
        "services/app/Chat/GetUserChatMessages?UserId=" +
        data +
        "&TenantId=1",
      {
        headers: {
          Authorization: `Bearer ${this.baseService.getToken()}`,
          encType: "multipart/form-data",
        },
      }
    );
  }

  unBlockUserOnMessenger(userId): any {
    return this.httpClient.put(
      this.env.apiUrl +
        `services/app/Utility/UnBlockUserOnMessenger?blockedUser=${userId}`,
      {}
    );
  }

  blockUserOnMessenger(userId): any {
    return this.httpClient.put(
      this.env.apiUrl +
        `services/app/Utility/BlockUserOnMessenger?blockedUser=${userId}`,
      {}
    );
  }

  formatCreationTime(creationTime: string): string {
    if (!creationTime) {
      return "";
    }
    const messageDate = new Date(creationTime);
    if (isNaN(messageDate.getTime())) {
      return "";
    }
    const currentDate = new Date();
    const msInADay = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(
      (currentDate.getTime() - messageDate.getTime()) / msInADay
    );
    const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    if (daysDifference === 0) {
      return this.datePipe.transform(messageDate, "shortTime");
    } else if (daysDifference === 1) {
      return "Yesterday";
    } else if (daysDifference < 7) {
      return weekDays[messageDate.getDay()];
    } else {
      return this.datePipe.transform(messageDate, "MM/dd/yy");
    }
  }
}
