import { ApiException } from "./service-proxies";
import {IAjaxResponse} from  "./models";  

export class ProxyErrorHandler {
    constructor(private apiException: ApiException) {
       
    }

    public getErrorMessage(): string {

       const result = JSON.parse(this.apiException.response); 
        if (ProxyErrorHandler.isAjaxResponse(result)){
            if( result.result !== undefined && result.result !== null ){
                return result.result;
            }
           return  result.error.message ;
       }

       return "An error occurred";
       
    }


    static isAjaxResponse(obj: any): obj is IAjaxResponse {
       
        return obj.error !== undefined;
    }
}