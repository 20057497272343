import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';

@Directive({
  selector: '[appScrollToView]'
})
export class ScrollToViewDirective implements AfterViewInit, OnDestroy {
  private inputElement: HTMLElement | null = null;
  private keyboardActive = false;
  private touchStartListener: () => void;
  @Input() scrollElementId: string;  

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.inputElement = this.el.nativeElement.querySelector('input');
    console.log("element", this.inputElement)

      this.addKeyboardListeners()
   
    // this.addTouchMoveListener()
  
  
  }

  getScrollElement(): HTMLElement | null {
    return document.getElementById(this.scrollElementId);
  }

  addKeyboardListeners(){
    if(Capacitor.isNativePlatform()){
     
      Keyboard.addListener('keyboardWillShow', (info) => {
        const scrollElement = this.getScrollElement(); 
        console.log("scrollElement", scrollElement)
        if (document.activeElement === this.inputElement) {
          this.keyboardActive = true;
          const elementBottom = this.inputElement.getBoundingClientRect().bottom;
          const distanceToBottom = window.innerHeight - elementBottom;
          if (distanceToBottom-30 < info.keyboardHeight ) {
         
            scrollElement.style.paddingBottom = `${info.keyboardHeight}px`;
            this.adjustScrollPosition(info.keyboardHeight);
  
          }
        }

       
     
    });

    Keyboard.addListener('keyboardWillHide', () => {
      const scrollElement = this.getScrollElement(); 
      scrollElement.style.paddingBottom = '0px';
      this.keyboardActive = false;
      this.inputElement.blur()
    });
    }


  }

 


  private adjustScrollPosition(keyboardHeight: number) {
    setTimeout(() => {
      this.inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
   
     
    
  }



   ngOnDestroy() {
   
    if (Capacitor.isNativePlatform()) {
      Keyboard.removeAllListeners();
    }
    // this.renderer.destroy()
    
  }
}
