import { EventEmitter, Injectable } from "@angular/core";
import { ConnectionStatus, Network } from "@capacitor/network";

@Injectable({
    providedIn: "root",
})
export class NetworkStatusCheckService {
    hasNetWorkConnectivity = true;
    networkStatusChangeEvent: EventEmitter<boolean> = new EventEmitter();

    constructor() {
        this.listenToNetworkStatusChange();
    }

    listenToNetworkStatusChange() {
        Network.getStatus().then((status) => {
            this.updateNetworkConnectivityStatus(status);
        });

        Network.addListener("networkStatusChange", (status) => {
            this.updateNetworkConnectivityStatus(status);
        });
    }

    updateNetworkConnectivityStatus(status: ConnectionStatus) {
        if (status.connectionType == "none") {
            this.hasNetWorkConnectivity = false;
            this.networkStatusChangeEvent.next(false);
        } else {
            this.hasNetWorkConnectivity = status.connected;
            this.networkStatusChangeEvent.next(status.connected);
        }
    }

    public getNetworkConnectivity() {
        return this.hasNetWorkConnectivity;
    }
}
