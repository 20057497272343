import { LightboxImagesServiceProxy } from "./../../../../shared/service-proxies/service-proxies";
import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ImageBrowserComponent } from "../image-browser/image-browser.component";
import { firstValueFrom, Subject } from "rxjs";
import { UploadEntities, UploadService } from "src/app/utilities/upload.service";
import { EnvironmentService } from "src/app/environment.service";

@Injectable({
  providedIn: "root",
})
export class ImageBrowserService {
  addImage$: Subject<any>;
  imagesUpdated$: Subject<any>;
  imagesRemoved$: Subject<any>;
  imagesAdded$: Subject<any>;
  primaryImageChange$: Subject<any>;

  constructor(
    private modalCtrl: ModalController,
    private uploadService: UploadService,
    private env: EnvironmentService,
    private LightboxImagesServiceProxy: LightboxImagesServiceProxy
  ) {}

  public async openImageBrowser(
    config?: IImageBrowserConfig,
    imageList: ILightboxImage[] = []
  ): Promise<HTMLIonModalElement> {
    const imagesUpdated$ = new Subject<any>();
    const removeImage$ = new Subject<{
      image: any;
      index: number;
    }>();
    const addImage$ = new Subject<any>();
    const setPrimary$ = new Subject<any>();
    const loadMore$ = new Subject<any>();
    const imagesRemoved$ = new Subject<any>();
    const imagesAdded$ = new Subject<any>();
    const primaryImageChange$ = new Subject<any>();

    return await this.modalCtrl.create({
      component: ImageBrowserComponent,
      componentProps: {
        config,
        imageList,
        imagesUpdated$,
        removeImage$,
        addImage$,
        setPrimary$,
        loadMore$,
        imagesRemoved$,
        imagesAdded$,
        primaryImageChange$,
      },
    });
  }

  public async removeBoatImage(image: ILightboxImage): Promise<void> {
    const successfulDeletion = await this.deleteImages([image]);
  }

  async deleteImages(images: ILightboxImage[]): Promise<ILightboxImage[]> {
    const deletePromises = images.map((image) =>
      // TODO: Replace with AWS S3 delete method
      firstValueFrom(this.LightboxImagesServiceProxy.deleteByClientId(image.clientId))
    );
    const results = await Promise.allSettled(deletePromises);

    const failedImages = results
      .map((result, index) => (result.status === "rejected" ? images[index] : null))
      .filter((image) => image !== null);

    failedImages.forEach((image) => {
      console.error(`Failed to delete image ${image.clientId}`);
    });

    if (failedImages.length > 0) {
      console.log(`${failedImages.length} images failed to delete.`);
    }

    const successfulImages = results
      .map((result, index) => (result.status === "fulfilled" ? images[index] : null))
      .filter((image) => image !== null);

    return successfulImages;
  }

  public async addBoatImages(image: IFilesData[], yachtId: number): Promise<string[]> {
    const uploadPromises = image.map((img) =>
      this.uploadService.uploadFileToS3(img.file, {
        fileName: img.file.name,
        entityId: yachtId,
        entity: UploadEntities.YACHT_PROFILE_IMAGES,
        role: "yachtowner",
        apiUrl: this.env.apiUrl,
        clientId: img.clientId,
      })
    );

    const results = await Promise.allSettled(uploadPromises);

    const successfulUploads = results
      .filter((result) => result.status === "fulfilled")
      .map((result) => (result as PromiseFulfilledResult<any>).value);

    const failedUploads = results.filter((result) => result.status === "rejected").map((result, index) => image[index]);

    if (successfulUploads.length > 0) {
      console.log(`${successfulUploads.length} images uploaded successfully.`);
    }

    if (failedUploads.length > 0) {
      console.error(`${failedUploads.length} images failed to upload.`);
    }

    const clientIds = successfulUploads.map((upload) => upload.clientId);
    return clientIds;
  }

  public loadMoreBoatImages(yachtId: number) {
    console.log("specialized load more images from API method");
  }
}

export interface IImageBrowserConfig {
  enableCrop?: boolean;
  enableAdd?: boolean;
  enableRemove?: boolean;
  enableSetPrimary?: boolean;
  textConfig?: {
    title?: string;
    noContentTitle?: string;
    noContentSubtitle?: string;
  };
  multiple?: boolean;
  max?: number;
  crop?: boolean;
  aspectRatio?: number;
  quality?: number;
  resize?: boolean;
}

export interface IGetMyLightBoxImagesOutput {
  items: ILightboxImage[];
  totalCount: number;
  primaryImage: ILightboxImage;
}

export interface ILightboxImage {
  id?: number;
  thumbnailUrl?: string;
  imageUrl?: string;
  isPrimary?: boolean;
  clientId?: string;
  file?: any;
  uploaded?: boolean;
  delete?: boolean;
  imageId?: number;
  image?: any;
  user?: any;
}

export interface IFilesData {
  clientId?: string;
  file: File;
  image?: string;
  user?: string;
}
