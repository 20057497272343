<app-base-two-layout
  class="h-full"
  (goBack)="close()"
  [headerText]="'Crop Image(s)'"
  [hasTextArea]="false"
  [hasGreeting]="false"
>
  <div class="p-4 h-full w-full bg-black flex cropper-wrapper">
    <image-cropper
      [imageBase64]="imageChangedEvent"
      [maintainAspectRatio]="false"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="aspectRatio"
      [alignImage]="'center'"
      [onlyScaleDown]="false"
      [roundCropper]="false"
      [canvasRotation]="canvasRotation"
      [(transform)]="transform"
      imageAltText="Alternative image text"
      output="blob"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
    ></image-cropper>
  </div>
  <div class="w-full pt-2 space-y-2" footer>
    <ion-toolbar>
      <div class="flex flex-col w-full gap-2">
        <div class="grid grid-cols-2 gap-2">
          <app-button [variant]="'primary'" [label]="'Crop'" (buttonClick)="saveImage()" size="small"></app-button>
          <app-button
            [variant]="'tertiary'"
            [label]="'Reset'"
            (buttonClick)="cancelImageCropper()"
            size="small"
          ></app-button>
        </div>
        <swiper *ngIf="data.length > 1" [config]="swiperConfig" class="w-full image-viewer">
          <ng-template swiperSlide *ngFor="let image of data; let i = index">
            <div class="relative h-20 w-20 rounded-lg overflow-hidden">
              <img
                (click)="selected(image, i)"
                [src]="image.image"
                class="bg-cover bg-center !w-full !h-full flex-none transition duration-300 ease-in-out transform"
                [ngClass]="{
                  'border-[3px] border-brandSunsetOrange': selectedImageIndex === i
                }"
              />
              <button
                (click)="removePhoto(image, i); $event.preventDefault()"
                class="absolute top-0 left-0 right-0 bottom-0 m-auto h-fit w-fit bg-white text-brandNauticalRed border-2 border-brandNauticalRed border-solid rounded-full z-10"
                *ngIf="selectedImageIndex === i"
              >
                <span class="bbicon-delete-outline text-2xl"></span>
              </button>
            </div>
          </ng-template>
        </swiper>
        <div>
          <app-button
            [variant]="'secondary'"
            [icon]="{
              name: 'check-circle',
              position: 'left'
            }"
            (buttonClick)="returnSavedImages()"
            [label]="'Submit'"
          >
          </app-button>
        </div>
      </div>
    </ion-toolbar>
  </div>
</app-base-two-layout>
