<div>
    <div class="flex items-center gap-2 w-full">
        <span
            class="bbicon-{{ icon.name }} text-{{ icon.color }} text-{{
                icon.size
            }}"
            *ngIf="
                (icon && icon.position === 'left') ||
                (icon && icon.position === 'floating-left') ||
                (icon && !icon.position)
            "
        ></span>
        <div
            class="relative flex items-center gap-1 w-full border border-solid rounded-lg p-1.5 box-border transition ease-in duration-200 bg-white"
            [ngClass]="getNgClass()"
        >
            <label
                *ngIf="floatingLabel"
                for="{{ id }}"
                class="bb-input-floating-label grow z-[2]"
                [ngClass]="{
                    'bb-input-floating-label-tel': type === 'tel'
                }"
                [class]="shouldLabelFloat ? 'float' : ''"
                (click)="focusInput()"
                >{{ label }}</label
            >
            <label
                for="{{ id }}"
                *ngIf="
                    type === 'date' &&
                    !isFocused &&
                    (model === '' || model === null)
                "
                (click)="handleDateLabelClick()"
                class="absolute top-0 left-0 w-full h-full text-dsGray70 flex items-center justify-start pl-4 bg-white rounded-lg z-[1]"
            >
                {{LANGUAGE_DATA?.DISPLAY_CALENDER |translate}}
            </label>
            <span
                class="bbicon-money text-xl text-dsGray50"
                *ngIf="type === 'money'"
            ></span>
            <ng-container *ngIf="type !== 'tel' && type !== 'number'">
                <input
                    #internalInput
                    [type]="inputType"
                    [id]="id"
                    [readOnly]="readOnly"
                    [disabled]="disabled"
                    [value]="value"
                    [step]="step"
                    (input)="onInput($event)"
                    (keyup)="onKeyUp($event)"
                    (focus)="onFocus($event)"
                    (blur)="onBlur($event)"
                    [attr.tabindex]="tabindex"
                    [attr.enterKeyHint]="enterKeyHint"
                    [placeholder]="enablePlaceholder ? placeholder : ''"
                    class="
              w-full
              outline-none
              border-none
              bg-transparent
                  {{ defaultSize }}"
                />
            </ng-container>
            <ng-container *ngIf="type === 'number'">
                <input
                    #internalInput
                    type="number"
                    [id]="id"
                    [readOnly]="readOnly"
                    [disabled]="disabled"
                    [value]="model"
                    (input)="onInput($event)"
                    (keyup)="onKeyUp($event)"
                    (focus)="onFocus($event)"
                    (blur)="onBlur($event)"
                    [attr.tabindex]="tabindex"
                    min="0"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="
              w-full
              outline-none
              border-none
              bg-transparent
                  {{ defaultSize }}"
                />
            </ng-container>

            <ng-container *ngIf="type === 'tel'">
                <div class="flex items-center gap-1 pl-2 w-full py-2.5">
                    <ngx-intl-tel-input
                        class="w-full ngx-tel-input-wrapper"
                        #telInputComp
                        [cssClass]="'tel-input'"
                        [preferredCountries]="[
                            CountryISO.UnitedStates,
                            CountryISO.Canada
                        ]"
                        [onlyCountries]="[
                            CountryISO.UnitedStates,
                            CountryISO.Canada,
                            CountryISO.Russia,
                            CountryISO.China,
                            CountryISO.UnitedKingdom,
                            CountryISO.France,
                            CountryISO.Germany,
                            CountryISO.Italy,
                            CountryISO.Japan
                        ]"
                        [selectFirstCountry]="true"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="false"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                        ]"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="true"
                        [numberFormat]="PhoneNumberFormat.National"
                        [(ngModel)]="model"
                        (ngModelChange)="checkNumber($event)"
                    >
                    </ngx-intl-tel-input>
                </div>
            </ng-container>

            <app-popover-button
                *ngIf="popoverMessage"
                [message]="popoverMessage"
            ></app-popover-button>

            <span
                class="bbicon-{{ icon.name }} text-{{ icon.color }} text-{{
                    icon.size
                }} mr-2"
                *ngIf="icon && icon.position === 'floating-right'"
            ></span>
            <span
                class="bbicon-{{
                    hidePassword ? 'eye-off' : 'eye'
                }} text-2xl text-dsGray50 cursor-pointer"
                *ngIf="showPasswordToggle"
                (click)="toggleHidePassword()"
            >
            </span>
        </div>
        <span
            class="bbicon-{{ icon.name }} text-{{ icon.color }} text-{{
                icon.size
            }}"
            *ngIf="icon && icon.position === 'right'"
        ></span>
    </div>
    <ng-container *ngIf="!isFocused && invalid">
        <small
            class="text-dsError {{ validationTextClass }}"
            [ngClass]="{
                'ml-8': icon
            }"
        >
            {{ validationText }}
        </small>
    </ng-container>
</div>
